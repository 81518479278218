import dayjs from "dayjs";
import { Row, Col } from "antd";
import React, { useMemo } from "react";

import { Card } from "misc/card";
import { Result } from "misc/results";
import { useParams } from "misc/hooks";
import { RangeSelector } from "misc/selectors";
import { clientUrls, datalayerUrls } from "misc/urls";
import { useSubfleets } from "misc/api/fleetconfiguration";
import { DefectTicketsPerVehicleTable, DefectTicketsTable } from "misc/widgets/consumers";

import SideHeader from "navigation/SideHeader";
import ModuleSettings from "modules/ModuleSettings";

import { TABLE_VIEW_ITEMS } from "app/modules/ModuleSettings";

const VIEW_ITEMS = [...TABLE_VIEW_ITEMS];

const EXPORT_COLUMS = [
    { value: "vin", label: "t_vin" },
    { value: "licensePlate", label: "t_license_plate" },
    { value: "organisationalUnit", label: "t_organisational_unit" },
    { value: "chargingStation", label: "t_charging_station" },
    { value: "manufacturer", label: "t_manufacturer" },
    { value: "model", label: "t_model" },
    { value: "battery", label: "t_battery" },
    { value: "telematicControlUnit", label: "t_telematic_control_unit" },
    { value: "lastSignOfLifeTimestamp", label: "t_last_sign_of_life_timestamp" },
    { value: "lastSignOfLifeSignal", label: "t_last_sign_of_life_signal" },
    { value: "defectTicketId", label: "t_defect_tickets_id" },
    { value: "timestampReport", label: "t_defect_tickets_timestamp_report" },
    { value: "errorCodes", label: "t_defect_tickets_error_codes" },
    { value: "errorDescription", label: "t_defect_tickets_error_description" },
    { value: "remarks", label: "t_defect_tickets_remarks" },
    { value: "isAccident", label: "t_defect_tickets_is_accident" },
    { value: "canRoll", label: "t_defect_tickets_can_roll" },
    { value: "canDrive", label: "t_defect_tickets_can_drive" },
    { value: "replacementRequired", label: "t_defect_tickets_replacement_required" },
    { value: "dateRepairPlanned", label: "t_defect_tickets_date_repair_planned" },
    { value: "processId", label: "t_defect_tickets_process_id" },
    { value: "status", label: "t_defect_tickets_status" }
];

const DefectTicketsFleetView = () => {
    const { subfleets } = useSubfleets({ suspense: true });

    const { params, setParams } = useParams({
        options: [
            { name: "fleet", persist: "global", allowed: value => subfleets.find(fleet => fleet.subfleetId === value) },
            { name: "range", defaultValue: [dayjs().subtract(14, "day").format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")] },
            { name: "columns", defaultValue: ["vin", "licensePlate"], persist: "site" },
            { name: "fixedColumn", defaultValue: "vin", persist: "site" }
        ]
    });

    const fleet = useMemo(() => subfleets.find(fleet => fleet.subfleetId === params.fleet), [params.fleet, subfleets]);

    const exportOptions = useMemo(
        () => ({
            label: "t_defect_tickets",
            columns: EXPORT_COLUMS,
            request: {
                method: "GET",
                url: datalayerUrls.fleet.widgets.list(),
                params: { type: "defectTicketsExport" },
                filter: {
                    hierarchy: fleet?.organisationalUnits,
                    vehicles: fleet?.vehicles
                }
            }
        }),
        [fleet]
    );

    return (
        <Row gutter={[10, 10]}>
            <Col span={24}>
                <ModuleSettings
                    values={params}
                    fleetOptions={{ redirectUrl: clientUrls.modules.defectTickets.fleetConfiguration() }}
                    viewOptions={{ items: VIEW_ITEMS }}
                    exportOptions={{ export: exportOptions }}
                    onChange={values => setParams({ ...params, ...values })}
                />
            </Col>

            {fleet ? (
                <Col span={24}>
                    <Card
                        title="t_defect_tickets"
                        extra={
                            <RangeSelector
                                value={params.range}
                                onChange={range => setParams({ ...params, range })}
                                showTime={false}
                                ranges={["lastWeek", "lastTwoWeeks", "lastFourWeeks"]}
                            />
                        }
                    >
                        <DefectTicketsPerVehicleTable
                            filter={{ hierarchy: fleet.organisationalUnits, vehicles: fleet.vehicles, range: params.range }}
                            view={{
                                columns: params.columns,
                                fixedColumn: params.fixedColumn,
                                dateOrder: params.dateOrder,
                                displayedWeeks: params.displayedWeeks
                            }}
                        />
                    </Card>
                </Col>
            ) : (
                <Col span={24}>
                    <Card>
                        <Result type="noFleetSelected" url={clientUrls.modules.defectTickets.fleetConfiguration()} />
                    </Card>
                </Col>
            )}
        </Row>
    );
};

const DefectTicketsVehicleView = () => {
    const { params, setParams } = useParams({
        options: [
            { name: "vehicle", persist: "site" },
            { name: "range", defaultValue: [dayjs().subtract(14, "day").format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")] },
            { name: "columns", defaultValue: ["vin", "licensePlate"], persist: "site" },
            { name: "fixedColumn", defaultValue: "vin", persist: "site" }
        ]
    });

    const vehicle = useMemo(() => params.vehicle, [params.vehicle]);

    const exportOptions = useMemo(
        () => ({
            label: "t_defect_tickets",
            columns: EXPORT_COLUMS,
            request: {
                method: "GET",
                url: datalayerUrls.fleet.widgets.list(),
                params: { type: "defectTicketsExport" },
                filter: { vehicles: [vehicle] }
            }
        }),
        [vehicle]
    );

    return (
        <Row gutter={[10, 10]}>
            <Col span={24}>
                <ModuleSettings
                    values={params}
                    fleetOptions={{ redirectUrl: clientUrls.modules.defectTickets.fleetConfiguration() }}
                    viewOptions={{ items: VIEW_ITEMS }}
                    exportOptions={{ export: exportOptions }}
                    onChange={values => setParams({ ...params, ...values })}
                    mode="vehicle"
                />
            </Col>

            {vehicle ? (
                <Col span={24}>
                    <Card
                        title="t_defect_tickets"
                        extra={
                            <RangeSelector
                                value={params.range}
                                onChange={range => setParams({ ...params, range })}
                                showTime={false}
                                ranges={["lastWeek", "lastTwoWeeks", "lastFourWeeks"]}
                            />
                        }
                    >
                        <DefectTicketsTable
                            filter={{ vehicles: [vehicle], range: params.range }}
                            view={{
                                columns: params.columns,
                                fixedColumn: params.fixedColumn,
                                dateOrder: params.dateOrder,
                                displayedWeeks: params.displayedWeeks
                            }}
                            hideSearch
                        />
                    </Card>
                </Col>
            ) : (
                <Col span={24}>
                    <Card height={500}>
                        <Result type="noVehicleSelected" />
                    </Card>
                </Col>
            )}
        </Row>
    );
};

const DefectTicketsComponent = () => {
    const { params, setParams } = useParams({
        options: [{ name: "view", defaultValue: "fleet-view", persist: "site" }]
    });

    return (
        <SideHeader
            value={params.view}
            options={[
                { value: "fleet-view", label: "t_view_fleet", render: () => <DefectTicketsFleetView /> },
                { value: "vehicle-view", label: "t_view_vehicle", render: () => <DefectTicketsVehicleView /> }
            ]}
            onChange={view => setParams({ ...params, view: view })}
        />
    );
};

export default DefectTicketsComponent;
