import { Row, Col } from "antd";
import React, { useMemo } from "react";

import { Card } from "misc/card";
import { Result } from "misc/results";
import { useParams } from "misc/hooks";
import { Selector } from "misc/selectors";
import { clientUrls, datalayerUrls } from "misc/urls";
import { useSubfleets } from "misc/api/fleetconfiguration";
import { EnergyMonitorVehiclesView } from "misc/widgets/consumers";

import SideHeader from "navigation/SideHeader";
import ModuleSettings from "modules/ModuleSettings";

import { TABLE_VIEW_ITEMS } from "app/modules/ModuleSettings";

const VIEW_ITEMS = [
    ...TABLE_VIEW_ITEMS,

    {
        name: "dateOrder",
        label: "t_energy_monitor_date_order",
        render: () => (
            <Selector
                options={[
                    { value: "asc", label: "t_ascend" },
                    { value: "desc", label: "t_descend" }
                ]}
            />
        )
    },
    {
        name: "displayedWeeks",
        label: "t_energy_monitor_displayed_weeks",
        render: () => (
            <Selector
                options={[
                    { value: 4, label: "t_weeks_4" },
                    { value: 8, label: "t_weeks_8" },
                    { value: 12, label: "t_weeks_12" }
                ]}
            />
        )
    }
];

const EnergyMonitorComponent = () => {
    const { subfleets } = useSubfleets({ suspense: true });

    const { params, setParams } = useParams({
        options: [
            { name: "fleet", persist: "global", allowed: value => subfleets.find(fleet => fleet.subfleetId === value) },
            { name: "columns", defaultValue: ["vin", "licensePlate"], persist: "site" },
            { name: "fixedColumn", defaultValue: "vin", persist: "site" },
            { name: "dateOrder", defaultValue: "desc", persist: "site" },
            { name: "displayedWeeks", defaultValue: 4, persist: "site" }
        ]
    });

    const fleet = useMemo(() => subfleets.find(fleet => fleet.subfleetId === params.fleet), [params.fleet, subfleets]);

    const exportItem = useMemo(
        () => ({
            label: "t_energy_monitor",
            columns: [
                { value: "vin", label: "t_vin" },
                { value: "licensePlate", label: "t_license_plate" },
                { value: "organisationalUnit", label: "t_organisational_unit" },
                { value: "chargingStation", label: "t_charging_station" },
                { value: "manufacturer", label: "t_manufacturer" },
                { value: "model", label: "t_model" },
                { value: "battery", label: "t_battery" },
                { value: "telematicControlUnit", label: "t_telematic_control_unit" },
                { value: "lastSignOfLifeTimestamp", label: "t_last_sign_of_life_timestamp" },
                { value: "lastSignOfLifeSignal", label: "t_last_sign_of_life_signal" },
                { value: "week", label: "t_week" },
                { value: "mileageStart", label: "t_energy_monitor_mileage_start" },
                { value: "mileageEnd", label: "t_energy_monitor_mileage_end" },
                { value: "distance", label: "t_energy_monitor_distance" },
                { value: "socCharged", label: "t_energy_monitor_soc_charged" },
                { value: "socConsumed", label: "t_energy_monitor_soc_consumed" },
                { value: "energyChargedAC", label: "t_energy_monitor_energy_charged_ac" },
                { value: "energyChargedDC", label: "t_energy_monitor_energy_charged_dc" },
                { value: "energyConsumed", label: "t_energy_monitor_energy_consumed" },
                { value: "temperatureAmbientMin", label: "t_energy_monitor_temperature_min" },
                { value: "temperatureAmbientMax", label: "t_energy_monitor_temperature_max" },
                { value: "temperatureAmbientAvg", label: "t_energy_monitor_temperature_median" },
                { value: "connectivity", label: "t_energy_monitor_connectivity" },
                { value: "organisationalUnitFirst", label: "t_energy_monitor_organisational_unit_first" },
                { value: "organisationalUnitLast", label: "t_energy_monitor_organisational_unit_last" },
                { value: "chargingStationFirst", label: "t_energy_monitor_charging_station_first" },
                { value: "chargingStationLast", label: "t_energy_monitor_charging_station_last" },
                { value: "telematicControlUnitFirst", label: "t_energy_monitor_telematic_control_unit_first" },
                { value: "telematicControlUnitLast", label: "t_energy_monitor_telematic_control_unit_last" }
            ],
            request: {
                method: "GET",
                url: datalayerUrls.fleet.widgets.list(),
                params: { type: "energyMonitorExport" },
                filter: {
                    hierarchy: fleet?.organisationalUnits,
                    vehicles: fleet?.vehicles
                }
            }
        }),
        [fleet]
    );

    return (
        <SideHeader>
            <Row gutter={[10, 10]}>
                <Col span={24}>
                    <ModuleSettings
                        title="t_energy_monitor"
                        values={params}
                        fleetOptions={{ redirectUrl: clientUrls.modules.energyMonitor.fleetConfiguration() }}
                        viewOptions={{ items: VIEW_ITEMS }}
                        exportOptions={{ export: exportItem }}
                        onChange={values => setParams({ ...params, ...values })}
                    />
                </Col>

                {fleet ? (
                    <Col span={24}>
                        <EnergyMonitorVehiclesView
                            filter={{ hierarchy: fleet.organisationalUnits, vehicles: fleet.vehicles }}
                            view={{
                                columns: params.columns,
                                fixedColumn: params.fixedColumn,
                                dateOrder: params.dateOrder,
                                displayedWeeks: params.displayedWeeks
                            }}
                        />
                    </Col>
                ) : (
                    <Col span={24}>
                        <Card>
                            <Result type="noFleetSelected" url={clientUrls.modules.energyMonitor.fleetConfiguration()} />
                        </Card>
                    </Col>
                )}
            </Row>
        </SideHeader>
    );
};

export default EnergyMonitorComponent;
