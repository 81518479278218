import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-query";
import _ from "lodash";

import { request } from "misc/propTypes";
import { StatusBox } from "misc/boxes";

const StatusBoxWidget = ({ title, request, extractData, extractLastUpdate = () => null, icon, queryProps, ...props }) => {
    const { data, isLoading, isRefetching, isError } = useQuery([request], queryProps);

    return (
        <StatusBox
            title={title}
            icon={icon}
            value={data ? (_.isFunction(extractData) ? extractData(data) : data[extractData]) : null}
            lastUpdate={data ? (_.isFunction(extractLastUpdate) ? extractLastUpdate(data) : data[extractLastUpdate]) : null}
            isLoading={isLoading}
            isUpdating={isRefetching}
            isError={isError}
            {...props}
        />
    );
};

StatusBoxWidget.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    request: request.isRequired,
    extractData: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    extractLastUpdate: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    icon: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
    queryProps: PropTypes.object
};

export default StatusBoxWidget;
