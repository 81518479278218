import React from "react";
import PropTypes from "prop-types";
import { Tooltip, Tag } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { TextField } from "misc/fields";

const TooltipTag = ({ tooltip, tag, isLoading, isUpdating, isError, ...props }) => {
    if (isLoading || isUpdating)
        return (
            <Tag icon={<FontAwesomeIcon icon={["fal", "arrows-rotate"]} spin style={{ marginRight: 5 }} />} style={{ cursor: "default" }}>
                <TextField value="t_loading" inline />
            </Tag>
        );

    if (isError)
        return (
            <Tag icon={<FontAwesomeIcon icon={["fal", "times-circle"]} style={{ marginRight: 5 }} />} color="error" style={{ cursor: "default" }}>
                <TextField value="t_error" inline />
            </Tag>
        );

    return (
        <Tooltip title={tooltip}>
            <Tag {...props} style={{ ...props.style, cursor: "default", opacity: 0.8 }}>
                {tag}
            </Tag>
        </Tooltip>
    );
};

TooltipTag.propTypes = {
    tooltip: PropTypes.node.isRequired,
    tag: PropTypes.node.isRequired,
    isLoading: PropTypes.bool,
    isUpdating: PropTypes.bool,
    isError: PropTypes.bool
};

export default TooltipTag;
