import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Menu } from "antd";
import styled from "styled-components";

import { StreetScooterLogo } from "./logo";

import { getSidebarItems } from "./sidebarItems";

import MenuItem from "./helper/MenuItem";

const Header = styled(Row)({
    background: "#155292",
    padding: 20
});

const HamburgerBar = styled(FontAwesomeIcon)({
    color: "#FFFFFF",
    cursor: "pointer"
});

const MobileSidebar = () => {
    const [collapsed, setCollapsed] = useState(false);

    return (
        <>
            <Header type="flex" justify="space-between" align="middle">
                <div>
                    <StreetScooterLogo />
                </div>

                <HamburgerBar icon="bars" size="2x" onClick={() => setCollapsed(!collapsed)} />
            </Header>
            {collapsed && (
                <Menu theme="dark" mode="inline">
                    {getSidebarItems().map(item => (
                        <MenuItem key={item.title} item={item} />
                    ))}
                </Menu>
            )}
        </>
    );
};

export default MobileSidebar;
