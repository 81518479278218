export const baseUrl =
    process.env.REACT_APP_STAGE === "production"
        ? "https://analysis.streetscooter-cloud-system.eu/services/data"
        : process.env.REACT_APP_STAGE === "staging"
        ? "https://api-staging.streetscooter-cloud-system.eu/services/data"
        : "https://api-staging.streetscooter-cloud-system.eu/services/data"; // "http://localhost:8001/services/data";

export const datalayerUrls = {
    baseUrl: () => baseUrl,

    diagnostics: () => `${baseUrl}/diagnostics`,

    general: {
        baseUrl: () => `${baseUrl}/general`,
        vehicles: () => `${datalayerUrls.general.baseUrl()}/vehicles/`,
        signals: () => `${datalayerUrls.general.baseUrl()}/signals/`,
        configurations: () => `${datalayerUrls.general.baseUrl()}/configurations/`,
        manufacturers: () => `${datalayerUrls.general.baseUrl()}/manufacturers/`,
        errors: () => `${datalayerUrls.general.baseUrl()}/errors/`,
        geoData: () => `${datalayerUrls.general.baseUrl()}/geo-data/`,
        powertrainTypes: () => `${datalayerUrls.general.baseUrl()}/powertrain-types/`,
        fieldCarePackages: () => `${datalayerUrls.general.baseUrl()}/field-care-packages/`,
        hierarchy: () => `${datalayerUrls.general.baseUrl()}/hierarchy/`
    },

    fleet: {
        baseUrl: () => `${baseUrl}/fleet`,
        values: () => `${datalayerUrls.fleet.baseUrl()}/values/`,
        widgets: {
            baseUrl: () => `${datalayerUrls.fleet.baseUrl()}/widgets`,
            list: () => `${datalayerUrls.fleet.widgets.baseUrl()}/list/`,
            pieChart: () => `${datalayerUrls.fleet.widgets.baseUrl()}/pie-chart/`,
            barChart: () => `${datalayerUrls.fleet.widgets.baseUrl()}/bar-chart/`,
            map: () => `${datalayerUrls.fleet.widgets.baseUrl()}/map/`
        }
    },

    vehicles: {
        baseUrl: () => `${baseUrl}/vehicles`,
        values: () => `${datalayerUrls.vehicles.baseUrl()}/values/`,
        widgets: {
            baseUrl: () => `${datalayerUrls.vehicles.baseUrl()}/widgets`,
            list: () => `${datalayerUrls.vehicles.widgets.baseUrl()}/list/`,
            map: () => `${datalayerUrls.vehicles.widgets.baseUrl()}/map/`,
            pieChart: () => `${datalayerUrls.vehicles.widgets.baseUrl()}/pie-chart/`,
            barChart: () => `${datalayerUrls.vehicles.widgets.baseUrl()}/bar-chart/`
        }
    },

    rawData: () => `${baseUrl}/raw-data/`
};
