import { useQuery, useMutation } from "react-query";

import { useKeycloak } from "misc/api/keycloak";
import { keycloakapiUrls } from "misc/urls";

import { queryClient } from "queryClient";

import de_DE from "antd/lib/locale/de_DE";
import en_US from "antd/lib/locale/en_US";
import ja_JP from "antd/lib/locale/ja_JP";

export const useUsers = queryProps => {
    const { data = [], ...query } = useQuery(["users"], {
        meta: { request: { method: "GET", url: keycloakapiUrls.users() } },
        refetchOnMount: true,
        ...queryProps
    });

    return {
        users: data,
        ...query
    };
};

export const useLoggedInUser = queryProps => {
    const { keycloak } = useKeycloak();

    const { data = {}, ...query } = useQuery(["loggedInUser", keycloak.idTokenParsed?.sub], {
        meta: { request: { method: "GET", url: `${keycloakapiUrls.users()}${keycloak.idTokenParsed?.sub}` } },
        enabled: keycloak.authenticated,
        // select: data => ({
        //     ...data
        //     // abbreviation: (`${data.firstName[0] || ""}${data.lastName[0] || ""}` || data.email[0]).toUpperCase(),
        //     // fullName: `${data.firstName} ${data.lastName}`.trim() || data.email
        // }),
        ...queryProps
    });

    return {
        loggedInUser: data,
        ...query
    };
};

export const useSubscription = queryProps => {
    const { loggedInUser, ...query } = useLoggedInUser(queryProps);

    return {
        subscription: loggedInUser?.roles?.find(role => role.name.includes("role_subscription_")) ?? {},
        ...query
    };
};

export const useLocale = queryProps => {
    const { loggedInUser, ...query } = useLoggedInUser(queryProps);

    const browserLanguage = navigator.language ?? navigator.userLanguage;

    const locale = loggedInUser.locale ?? browserLanguage.toLowerCase().slice(0, 2) ?? "de";

    const i18nLocales = {
        en: "en-US",
        de: "de-DE",
        jp: "jp-JP",
        ja: "jp-JP"
    };

    const momentLocales = {
        en: "en-us",
        de: "de",
        jp: "jp",
        ja: "jp"
    };

    const antdLocales = {
        en: en_US,
        de: de_DE,
        jp: ja_JP,
        ja: ja_JP
    };

    return { locale: { default: locale, i18n: i18nLocales[locale], moment: momentLocales[locale], antd: antdLocales[locale] }, ...query };
};

export const useUpdateLocale = () => {
    const { mutate, ...mutation } = useMutation();

    const { loggedInUser } = useLoggedInUser();

    return {
        updateLocale: (locale, { onSuccess, ...props } = {}) => {
            mutate(
                {
                    url: `${keycloakapiUrls.users()}${loggedInUser.id}/set_locale/`,
                    method: "PATCH",
                    body: JSON.stringify({ locale: locale })
                },
                {
                    onSuccess: newData => {
                        queryClient.setQueryData(["loggedInUser", loggedInUser.id], () => newData);
                        onSuccess && onSuccess(newData);
                    },
                    ...props
                }
            );
        },
        ...mutation
    };
};

export const useCreateUser = () => {
    const { mutate, ...mutation } = useMutation();

    return {
        createUser: (user, { onSuccess, ...props } = {}) => {
            mutate(
                {
                    method: "POST",
                    url: keycloakapiUrls.users(),
                    body: JSON.stringify({
                        client: "analysisframework",
                        email: user.email,
                        locale: user.locale,
                        roles: user.roles
                    })
                },
                {
                    onSuccess: newData => {
                        queryClient.setQueryData("users", oldData => [...oldData, newData]);
                        queryClient.invalidateQueries(["roles"]);

                        onSuccess && onSuccess(newData);
                    },
                    ...props
                }
            );
        },
        ...mutation
    };
};

export const useUpdateUser = () => {
    const { mutate, ...mutation } = useMutation();

    return {
        updateUser: (user, { onSuccess, ...props } = {}) => {
            mutate(
                {
                    method: "PATCH",
                    url: `${keycloakapiUrls.users()}${user.id}/`,
                    body: JSON.stringify({
                        client: "analysisframework",
                        email: user.email,
                        locale: user.locale,
                        roles: user.roles
                    })
                },
                {
                    onSuccess: newData => {
                        queryClient.setQueryData("users", oldData => oldData.map(user => (newData.id === user.id ? newData : user)));
                        queryClient.invalidateQueries(["roles"]);

                        onSuccess && onSuccess(newData);
                    },
                    ...props
                }
            );
        },
        ...mutation
    };
};

export const useDeleteUser = () => {
    const { mutate, ...mutation } = useMutation();

    return {
        deleteUser: (user, { onSuccess, ...props } = {}) => {
            mutate(
                {
                    method: "DELETE",
                    url: `${keycloakapiUrls.users()}${user.id}/`
                },
                {
                    onSuccess: newData => {
                        queryClient.setQueryData("users", oldData => oldData.filter(user => user.id !== newData));
                        queryClient.invalidateQueries(["roles"]);

                        onSuccess && onSuccess();
                    },
                    ...props
                }
            );
        },
        ...mutation
    };
};

// Old
export const useResetPassword = () => {
    const { mutate, ...mutation } = useMutation();

    return {
        resetPassword: (user, { onSuccess, onError, ...props } = {}) => {
            mutate(
                {
                    method: "PUT",
                    url: `${keycloakapiUrls.users()}${user.id}/reset_password/`,
                    body: JSON.stringify({
                        client: "analysisframework"
                    })
                },
                {
                    onSuccess: data => {
                        onSuccess && onSuccess(data);
                    },
                    onError: data => {
                        onError && onError(data);
                    },
                    ...props
                }
            );
        },
        ...mutation
    };
};

export const useRoles = queryProps => {
    const { data = [], ...query } = useQuery(["roles"], {
        meta: { request: { method: "GET", url: keycloakapiUrls.roles(), params: { client: "analysisframework" } } },
        refetchOnMount: true,
        ...queryProps
    });

    return { roles: data, ...query };
};

export const useCreateRole = () => {
    const { mutate, ...mutation } = useMutation();

    return {
        createRole: (role, { onSuccess, ...props } = {}) => {
            mutate(
                {
                    method: "POST",
                    url: keycloakapiUrls.roles(),
                    body: JSON.stringify({
                        client: "analysisframework",
                        name: role.name,
                        permissions: role.permissions,
                        users: role.users,
                        attributes: { expiration: role.expiration }
                    })
                },
                {
                    onSuccess: newData => {
                        queryClient.setQueryData(["roles"], oldData => [...oldData, newData]);
                        queryClient.invalidateQueries(["users"]);
                        onSuccess && onSuccess();
                    },
                    ...props
                }
            );
        },
        ...mutation
    };
};

export const useUpdateRole = () => {
    const { mutate, ...mutation } = useMutation();

    return {
        updateRole: (role, { onSuccess, ...props } = {}) => {
            mutate(
                {
                    method: "PATCH",
                    url: `${keycloakapiUrls.roles()}${role.id}/`,
                    body: JSON.stringify({
                        client: "analysisframework",
                        name: role.name,
                        permissions: role.permissions,
                        users: role.users,
                        attributes: { expiration: role.expiration }
                    })
                },
                {
                    onSuccess: newData => {
                        queryClient.setQueryData(["roles"], oldData => oldData.map(role => (role.id === newData.id ? newData : role)));
                        queryClient.invalidateQueries(["users"]);
                        onSuccess && onSuccess();
                    },
                    ...props
                }
            );
        },
        ...mutation
    };
};

export const useDeleteRole = () => {
    const { mutate, ...mutation } = useMutation();

    return {
        deleteRole: (role, { onSuccess, ...props } = {}) => {
            mutate(
                {
                    method: "DELETE",
                    url: `${keycloakapiUrls.roles()}${role.id}/`
                },
                {
                    onSuccess: newData => {
                        queryClient.setQueryData(["roles"], oldData => oldData.filter(role => role.id !== newData));
                        queryClient.invalidateQueries(["users"]);
                        onSuccess && onSuccess();
                    },
                    ...props
                }
            );
        },
        ...mutation
    };
};

export const usePermissions = queryProps => {
    const { data = [], ...query } = useQuery(["permissions"], {
        meta: { request: { method: "GET", url: keycloakapiUrls.permissions(), params: { client: "analysisframework" } } },
        ...queryProps
    });

    return { permissions: data, ...query };
};
