import React from "react";
import PropTypes from "prop-types";

import { Widget } from "misc/widgets";
import { Error } from "misc/error";

const ErrorWidget = ({ height, direction, ...props }) => {
    return (
        <Widget height={height} {...props}>
            <Error direction={direction} />
        </Widget>
    );
};

ErrorWidget.propTypes = {
    height: PropTypes.number,
    direction: PropTypes.oneOf(["horizontal", "vertical"])
};

export default ErrorWidget;
