export const stsColors = {
    transparent: "rgba(255, 255, 255, 0)",
    aliceblue: "#f0f3ff",
    ceil: "#93a2dd",
    green1: "#90be6d",
    green2: "#337f4c",
    green3: "black", // TDB
    yellow1: "#fbdf9d",
    yellow2: "#f9c74f",
    yellow3: "#f8961e",
    red1: "#f94144",
    red2: "#d00000",
    red3: "#9d0208",
    grey1: "#d9d9d9",
    grey2: "#a1a1a1",
    grey3: "#707070",
    blue: "#155292",
    blue1: "#6d8ea0",
    blue2: "#155292",
    white1: "#ffffff",
    white2: "#f8f9fa",
    white3: "#f5f5f5",
    black1: "#505050",
    black2: "#393939",
    black3: "#000000"
};

export const orgGgbColors = {
    "GBB Ost": "#4472C4",
    "GBB Süd": "#A6A6A6",
    "GBB Nord": "#FFC000",
    "GBB West": "#ED7D31"
};
