import PropTypes from "prop-types";

export const request = PropTypes.shape({
    method: PropTypes.string,
    url: PropTypes.string.isRequired,
    data: PropTypes.object,
    params: PropTypes.object
});

export const ChartWidgetPropType = {
    title: PropTypes.string.isRequired,
    request: PropTypes.shape({
        url: PropTypes.string.isRequired,
        params: PropTypes.object
    }).isRequired
};

export const range = PropTypes.array;
