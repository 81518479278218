import { useTranslation } from "react-i18next";
import React, { useMemo } from "react";
import { isObject } from "lodash";
import { DatePicker } from "antd";
import PropTypes from "prop-types";
import moment from "moment";
import dayjs from "dayjs";

const RangeSelector = ({ defaultValue, value, ranges, onChange, showTime = true, allowClear = true, minDate, maxDate, ...props }) => {
    const [t] = useTranslation();

    const defaultRanges = [
        { name: "lastDay", label: t("t_range_last_day"), value: [dayjs().subtract(1, "days"), dayjs()] },
        { name: "lastThreeDays", label: t("t_range_last_three_days"), value: [dayjs().subtract(3, "days"), dayjs()] },
        { name: "lastWeek", label: t("t_range_last_week"), value: [dayjs().subtract(1, "week"), dayjs()] },
        { name: "lastTwoWeeks", label: t("t_range_last_two_weeks"), value: [dayjs().subtract(2, "weeks"), dayjs()] },
        { name: "lastFourWeeks", label: t("t_range_last_four_weeks"), value: [dayjs().subtract(4, "weeks"), dayjs()] }
    ];

    const filteredRanges = useMemo(() => {
        const getRanges = option => {
            const range = defaultRanges.find(item => item.name === option);
            return range ? range : {};
        };

        if (!ranges) return defaultRanges;

        if (ranges.length === 0) return [];

        return ranges.map(option => {
            return isObject(option)
                ? {
                      ...option,
                      label: t(option.label),
                      value: [moment(option.value[0]), moment(option.value[1])]
                  }
                : getRanges(option);
        });
    }, [defaultRanges, ranges, t]);

    return (
        <>
            <DatePicker.RangePicker
                disabledDate={date => (minDate && date.isBefore(minDate)) || (maxDate && date.isAfter(maxDate))}
                format={showTime ? "DD.MM.YY HH:mm:ss" : "DD.MM.YYYY"}
                ranges={filteredRanges.reduce((acc, { label, value }) => {
                    return label && value && { ...acc, [label]: value };
                }, {})}
                placeholder={[t("t_start"), t("t_end")]}
                defaultValue={defaultValue && [dayjs(defaultValue[0]), dayjs(defaultValue[1])]}
                value={value && [dayjs(value[0]), dayjs(value[1])]}
                onChange={range => {
                    if (!range) return range;
                    const modifiedRange = showTime ? range.map(timestamp => timestamp.toISOString()) : range;

                    onChange(modifiedRange);
                }}
                showTime={showTime}
                allowClear={allowClear}
                style={{ width: "100%" }}
                {...props}
            />
        </>
    );
};

RangeSelector.propTypes = {
    defaultValue: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(dayjs)])),
    value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(dayjs)])),
    ranges: PropTypes.array,
    onChange: PropTypes.func,
    showTime: PropTypes.bool,
    allowClear: PropTypes.bool,
    minDate: PropTypes.instanceOf(dayjs),
    maxDate: PropTypes.instanceOf(dayjs)
};

export default RangeSelector;
