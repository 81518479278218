import React from "react";
import { InputNumber, Select } from "antd";

import { TextField } from "misc/fields";

import FleetConfiguration from "modules/FleetConfiguration";

const FLEET_PARAMETERS = [
    {
        name: "cddaChargingSocStart",
        label: "t_cdda_charging_soc_start",
        render: () => <InputNumber style={{ width: 300 }} min={0} max={100} />,
        description: "t_cdda_soc_start_description",
        group: "t_cdda_definition_charging",
        required: true,
        initialValue: 90
    },
    {
        name: "cddaChargingSocRise",
        label: "t_cdda_charging_soc_rise",
        render: () => <InputNumber style={{ width: 300 }} min={0} max={100} />,
        description: "t_cdda_soc_rise_description",
        group: "t_cdda_definition_charging",
        required: true,
        initialValue: 20
    },
    {
        name: "cddaDrivingDistance",
        label: "t_cdda_driving_distance",
        render: () => <InputNumber style={{ width: 300 }} min={0} />,
        description: "t_cdda_driving_distance_description",
        group: "t_cdda_definition_driving",
        required: true,
        initialValue: 10
    },
    {
        name: "cddaDeliveryDistance",
        label: "t_cdda_delivery_distance",
        render: () => <InputNumber style={{ width: 300 }} min={0} />,
        description: "t_cdda_delivery_distance_description",
        group: "t_cdda_definition_delivery",
        required: true,
        initialValue: 10
    },
    {
        name: "cddaDeliveryStops",
        label: "t_cdda_delivery_stops",
        render: () => <InputNumber style={{ width: 300 }} min={0} />,
        description: "t_cdda_delivery_stops_description",
        definition: "t_cdda_delivery_stops_definition",
        group: "t_cdda_definition_delivery",
        required: true,
        initialValue: 10
    },
    {
        name: "cddaDeliveryDriversLogEntry",
        label: "t_cdda_delivery_drivers_log_entry",
        render: () => (
            <Select style={{ width: 300 }}>
                <Select.Option value={true}>
                    <TextField value="t_yes" />
                </Select.Option>
                <Select.Option value={false}>
                    <TextField value="t_no" />
                </Select.Option>
            </Select>
        ),
        description: "t_cdda_delivery_drivers_log_entry_description",
        group: "t_cdda_definition_delivery",
        required: true,
        initialValue: false
    }
];

const CddaFleetConfigurationComponent = () => {
    return <FleetConfiguration parameters={FLEET_PARAMETERS} />;
};

export default CddaFleetConfigurationComponent;
