import PropTypes from "prop-types";

const When = ({ is, in: list, children }) => list.includes(is) && children;

When.propTypes = {
    is: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    in: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
};

export default When;
