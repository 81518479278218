import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const IconWithMargin = styled(FontAwesomeIcon)({
    marginRight: 10
});

const MenuItemIcon = ({ icon }) => (
    <>
        {icon && (
            <i className="anticon">
                <IconWithMargin icon={icon} />
            </i>
        )}
    </>
);
export default MenuItemIcon;
