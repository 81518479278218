import moment from "moment";
import { isNumber } from "lodash";
import { useQuery, useMutation } from "react-query";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse, Table, Descriptions, Tag, Space, Input, Button, Tooltip, notification } from "antd";

import { Card } from "misc/card";
import { Result } from "misc/results";
import { Widget } from "misc/widgets";
import { useParams } from "misc/hooks";
import { TransparentButton } from "misc/buttons";
import { TextField, DatetimeField } from "misc/fields";
import { CheckAppPermissions } from "misc/authentication";
import { datalayerUrls, documentslayerUrls } from "misc/urls";
import { RangeSelector, VehicleSelector } from "misc/selectors";

import { stsColors } from "styles";

const DiagnosticsComponent = () => {
    // TODO: Refactor whole component

    const { params, setParams } = useParams({ options: [{ name: "vehicle", persist: "module" }] });

    const {
        data,
        error,
        isLoading,
        isFetching: isFetchingDiagnostics
    } = useQuery(
        [
            {
                method: "GET",
                url: datalayerUrls.diagnostics(),
                params: { vin: params.vehicle }
            }
        ],
        {
            placeholderData: { diagnosticSessions: [], configurationProperties: [], hardware: [], software: [] }
        }
    );

    const [selectedRange, setSelectedRange] = useState(null);
    const [range, setRange] = useState(null);
    const [loadingProtocol, setLoadingProtocol] = useState(null);

    const [t] = useTranslation();

    const { mutate, isFetching } = useMutation();

    const getHardwareStatus = record => record.targetHardware.includes(record.currentHardware) || record.targetHardware.includes("*");

    const getSoftwareStatus = record => record.targetSoftware.includes(record.currentSoftware) || record.targetSoftware.includes("*");

    const getTool = session => {
        switch (session.tool) {
            case "DIAG":
                return "SIA";

            case "EOLT":
                return "TEO";

            default:
                return session.tool;
        }
    };

    // const onDownloadPdf = data => {
    //     const configurationProperties = {
    //         caption: t("t_configuration_properties"),
    //         columns: [t("t_configuration"), t("t_value")],
    //         content: data.configurationProperties.map(element => [element.property, element.value ?? "N/A"])
    //     };

    //     const ecuHardwareStatus = {
    //         caption: t("t_ecu_hardware_status"),
    //         columns: [t("t_ecu"), t("t_current_hardware"), t("t_target_hardware"), t("t_serial_number"), t("t_status")],
    //         content: data.hardware.map(element => [
    //             element.ecu,
    //             element.currentHardware,
    //             element.targetHardware.join(", "),
    //             element.serialNumber,
    //             getHardwareStatus(element) ? "i.O." : "n.i.O."
    //         ])
    //     };

    //     const ecuSoftwareStatus = {
    //         caption: t("t_ecu_software_status"),
    //         columns: [t("t_ecu"), t("t_current_software"), t("t_target_software"), t("t_status")],
    //         content: data.software.map(element => [
    //             element.ecu,
    //             element.currentSoftware,
    //             element.targetSoftware.join(", "),
    //             getSoftwareStatus(element) ? "i.O." : "n.i.O."
    //         ])
    //     };

    //     const diagnosticSessions = [];

    //     data.diagnosticSessions.forEach(session => {
    //         diagnosticSessions.push({
    //             category: "information",
    //             caption: moment(session.timestamp).format("DD.MM.YYYY HH:mm:SS"),
    //             data: [
    //                 {
    //                     key: t("t_tool"),
    //                     value: session.tool
    //                 },
    //                 {
    //                     key: t("t_version"),
    //                     value: session.version
    //                 },
    //                 {
    //                     key: t("t_status"),
    //                     value: session.status
    //                 }
    //             ]
    //         });

    //         diagnosticSessions.push({
    //             category: "table",
    //             columns: [t("t_ecu"), t("t_hardware_version"), t("t_software_version")],
    //             content: session.results.map(element => [element.ecu, element.hardwareVersion, element.softwareVersion])
    //         });
    //     });

    //     const body = {
    //         title: data.vin,
    //         data: [
    //             {
    //                 category: "table",
    //                 caption: configurationProperties.caption,
    //                 columns: configurationProperties.columns,
    //                 content: configurationProperties.content
    //             },
    //             {
    //                 category: "table",
    //                 caption: ecuHardwareStatus.caption,
    //                 columns: ecuHardwareStatus.columns,
    //                 content: ecuHardwareStatus.content
    //             },
    //             {
    //                 category: "spacer",
    //                 value: 40
    //             },
    //             {
    //                 category: "table",
    //                 caption: ecuSoftwareStatus.caption,
    //                 columns: ecuSoftwareStatus.columns,
    //                 content: ecuSoftwareStatus.content
    //             },
    //             {
    //                 category: "spacer",
    //                 value: 50
    //             },
    //             {
    //                 category: "information",
    //                 caption: diagnosticSessions.length ? t("t_diagnostic_sessions") : t("t_no_diagnostic_sessions_available"),
    //                 data: []
    //             },
    //             ...diagnosticSessions
    //         ]
    //     };

    //     performRequests({ method: "POST", url: reportingUrls.reportEngine(), body: JSON.stringify(body) }, data => {
    //         var fileDownload = require("js-file-download");
    //         fileDownload(data, "Fahrzeug Lebenslauf.pdf");
    //     });
    // };

    const onDownloadProtocol = (vin, timestamp, filename) => {
        const openNotificationWithIcon = (type, message, description) => {
            notification[type]({
                message: message && <TextField value={message} />,
                description: description && <TextField value={description} />
            });
        };

        mutate(
            {
                method: "GET",
                url: `${documentslayerUrls.protocol()}/${filename}`,
                headers: { "Content-Type": "application/zip" }
            },
            {
                onSettled: () => setLoadingProtocol(null),
                onSuccess: data => {
                    const downloadProtocol = require("js-file-download");
                    downloadProtocol(data, filename);

                    openNotificationWithIcon("success", "t_download_diagnostic_procotol_succeded");
                },
                onError: error => {
                    if (error.status === 404)
                        openNotificationWithIcon("error", "t_download_diagnostic_procotol_failed", "t_diagnostic_procotol_not_found");
                    else if (isNumber(error.status))
                        openNotificationWithIcon("error", "t_download_diagnostic_procotol_failed", `t_http_error_${error.status}`);
                    else openNotificationWithIcon("error", "t_download_diagnostic_procotol_failed", "t_http_error_undefined");
                }
            }
        );

        setLoadingProtocol(timestamp);
    };

    const getSessions = useCallback(() => {
        if (!data) return [];

        if (range) return data?.diagnosticSessions.filter(session => moment(session.timestamp).isBetween(...range));

        return data?.diagnosticSessions;
    }, [data]);

    return (
        <Space direction="vertical" size={10} style={{ width: "100%" }}>
            <Card>
                <Space direction="vertical" size={30} style={{ width: "100%" }}>
                    <VehicleSelector value={params.vehicle} onChange={vehicle => setParams({ ...params, vehicle })} />
                </Space>
            </Card>

            {params.vehicle ? (
                <Widget
                    title="t_diagnostics"
                    height={700}
                    bodyStyle={{ height: "100%" }}
                    error={error}
                    isLoading={isLoading || isFetchingDiagnostics}
                    stretchHeight
                >
                    <Collapse>
                        <Collapse.Panel header={t("t_configuration_properties")} key="1">
                            <Table
                                dataSource={data?.configurationProperties}
                                columns={[
                                    {
                                        title: <TextField value="t_property" />,
                                        dataIndex: "property",
                                        render: text => text,
                                        width: "50%"
                                    },
                                    {
                                        title: <TextField value="t_value" />,
                                        dataIndex: "value",
                                        render: text => text ?? "N/A",
                                        width: "50%"
                                    }
                                ]}
                                pagination={false}
                            ></Table>
                        </Collapse.Panel>
                        <Collapse.Panel header={t("t_ecu_hardware_status")} key="2">
                            <Table
                                dataSource={data?.hardware}
                                columns={[
                                    {
                                        title: <TextField value="t_ecu" />,
                                        dataIndex: "ecu",
                                        render: text => text,
                                        width: "20%"
                                    },
                                    {
                                        title: <TextField value="t_current_hardware" />,
                                        dataIndex: "currentHardware",
                                        render: text => text,
                                        width: "20%"
                                    },
                                    {
                                        title: <TextField value="t_target_hardware" />,
                                        dataIndex: "targetHardware",
                                        render: text => <TextField value={text.join(", ")} />,
                                        width: "20%"
                                    },
                                    {
                                        title: <TextField value="t_serial_number" />,
                                        dataIndex: "serialNumber",
                                        render: text => text,
                                        width: "20%"
                                    },
                                    {
                                        title: <TextField value="t_status" />,
                                        key: "status",
                                        render: (text, record) => {
                                            const equal = getHardwareStatus(record);

                                            return (
                                                <Tag color={equal ? stsColors.green2 : stsColors.yellow2}>
                                                    <TextField value={equal ? "t_hardware_version_ok" : "t_hardware_change_required"} />
                                                </Tag>
                                            );
                                        },

                                        width: "20%"
                                    }
                                ]}
                                pagination={false}
                            />
                        </Collapse.Panel>
                        <Collapse.Panel header={t("t_ecu_software_status")} key="3">
                            <Table
                                dataSource={data?.software}
                                columns={[
                                    {
                                        title: <TextField value="t_ecu" />,
                                        dataIndex: "ecu",
                                        render: text => text,
                                        width: "25%"
                                    },
                                    {
                                        title: <TextField value="t_current_software" />,
                                        dataIndex: "currentSoftware",
                                        render: text => text,
                                        width: "25%"
                                    },
                                    {
                                        title: <TextField value="t_target_software" />,
                                        dataIndex: "targetSoftware",
                                        render: text => <TextField value={text.join(", ")} />,
                                        width: "25%"
                                    },
                                    {
                                        title: <TextField value="t_status" />,
                                        key: "status",
                                        render: (text, record) => {
                                            const equal = getSoftwareStatus(record);

                                            return (
                                                <Tag color={equal ? stsColors.green2 : stsColors.yellow2}>
                                                    <TextField value={equal ? "t_software_version_ok" : "t_software_update_required"} />
                                                </Tag>
                                            );
                                        },
                                        width: "25%"
                                    }
                                ]}
                                pagination={false}
                            />
                        </Collapse.Panel>
                        <Collapse.Panel header={`${t("t_diagnostic_sessions")}`} key="4">
                            <Space direction="vertical" size={60} style={{ width: "100%" }}>
                                {data?.diagnosticSessions?.length > 0 && (
                                    <Input.Group compact>
                                        <RangeSelector initialData={selectedRange} onChange={setSelectedRange} />
                                        <TransparentButton onClick={() => setRange(selectedRange)}>
                                            <TextField value="t_search" />
                                        </TransparentButton>
                                    </Input.Group>
                                )}

                                <Space direction="vertical" size={25}>
                                    {getSessions().length ? (
                                        getSessions().map(session => {
                                            return (
                                                <Space direction="vertical" size={15}>
                                                    <Descriptions
                                                        column={1}
                                                        title={<DatetimeField value={session.timestamp} />}
                                                        extra={
                                                            <CheckAppPermissions allowed={["permission_diagnostic-protocol-download"]}>
                                                                <Tooltip title={<TextField value="t_download_diagnostic_protocol" />}>
                                                                    <Button
                                                                        type="default"
                                                                        icon={<FontAwesomeIcon icon={["fal", "arrow-down-to-line"]} />}
                                                                        onClick={() =>
                                                                            onDownloadProtocol(params.vehicle, session.timestamp, session.filename)
                                                                        }
                                                                        size="middle"
                                                                        loading={isFetching && session.timestamp === loadingProtocol}
                                                                    />
                                                                </Tooltip>
                                                            </CheckAppPermissions>
                                                        }
                                                    >
                                                        <Descriptions.Item label={<TextField value="t_tool" inline />}>
                                                            <TextField value={getTool(session)} />
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label={<TextField value="t_version" inline />}>
                                                            <TextField value={session.version} />
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label={<TextField value="t_status" inline />}>
                                                            <TextField
                                                                value={
                                                                    session.tool === "DIAG"
                                                                        ? session.status.includes("SIA")
                                                                            ? session.status
                                                                            : "N/A"
                                                                        : session.processedStatus
                                                                        ? session.processedStatus
                                                                        : session.status
                                                                }
                                                                inline
                                                            />
                                                        </Descriptions.Item>
                                                    </Descriptions>

                                                    <Table
                                                        dataSource={session.results}
                                                        columns={[
                                                            {
                                                                title: <TextField value="t_ecu" />,
                                                                dataIndex: "ecu",
                                                                render: text => text,
                                                                width: "33%"
                                                            },
                                                            {
                                                                title: <TextField value="t_hardware_version" />,
                                                                dataIndex: "hardwareVersion",
                                                                render: text => text,
                                                                width: "33%"
                                                            },
                                                            {
                                                                title: <TextField value="t_software_version" />,
                                                                dataIndex: "softwareVersion",
                                                                render: text => text,
                                                                width: "33%"
                                                            }
                                                        ]}
                                                        pagination={false}
                                                    />
                                                </Space>
                                            );
                                        })
                                    ) : (
                                        <TextField value="t_no_diagnostic_sessions_available" />
                                    )}
                                </Space>
                            </Space>
                        </Collapse.Panel>
                    </Collapse>
                </Widget>
            ) : (
                <Card height={500}>
                    <Result type="noVehicleSelected" />
                </Card>
            )}
        </Space>
    );
};

export default DiagnosticsComponent;
