import React from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

const DangerButton = ({ children, ...props }) => {
    const [t] = useTranslation();
    if (typeof children === "string") children = t(children);

    return <Button danger {...props} children={children} />;
};

export default DangerButton;
