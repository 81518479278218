import { isArray } from "lodash";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Button, Tooltip, Modal, List, Avatar, Spin, Input, Space } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { TextField } from "misc/fields";
import { request } from "misc/propTypes";
import { ConditionalWrapper } from "misc/conditional";

import { stsColors } from "styles";

const sanitizeSheetTitle = title => {
    return title.replace(/}|\\|\/|\?|\*|\[|]/g, "_");
};

const Export = ({ title, exportOptions, ...props }) => {
    const { mutate, isLoading } = useMutation();

    const [t] = useTranslation();

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isCreatingExcel, setIsCreatingExcel] = useState(false);
    const [filter, setFilter] = useState("");

    useEffect(() => setFilter(""), [modalIsOpen]);

    const fetchData = (optionTitle, request, extractData, header) => {
        mutate(request, { onSuccess: data => downloadData(optionTitle, extractData(data), header) });
    };

    const downloadData = (optionTitle, data, header) => {
        import("xlsx").then(XLSX => {
            const workbook = XLSX.utils.book_new();

            setIsCreatingExcel(true);

            const sheet = XLSX.utils.aoa_to_sheet([header.map(text => t(text))]);

            if (data.length)
                isArray(data[0])
                    ? XLSX.utils.sheet_add_aoa(sheet, data, { skipHeader: true, origin: -1 })
                    : XLSX.utils.sheet_add_json(sheet, data, { skipHeader: true, origin: -1 });

            // Bypass sheet name cannot exceed 31 chars when creating, the file name when saving will be remained
            let fileName = t(optionTitle);
            fileName = fileName.length <= 31 ? fileName : fileName.substring(0, Math.min(31, fileName.length));

            XLSX.utils.book_append_sheet(workbook, sheet, sanitizeSheetTitle(fileName));

            XLSX.writeFile(workbook, `${t(title)}_${t(optionTitle)}.xlsx`);

            setIsCreatingExcel(false);
        });
    };

    return (
        <>
            <Tooltip title={<TextField value="t_export_excel" />}>
                <Button onClick={() => setModalIsOpen(true)} icon={<FontAwesomeIcon icon={["fal", "file-excel"]} />} {...props} />
            </Tooltip>
            <Modal
                title={title ? React.isValidElement(title) ? title : <TextField value={title} /> : <TextField value="t_export_excel" />}
                visible={modalIsOpen}
                footer={null}
                onCancel={() => setModalIsOpen(false)}
                destroyOnClose
            >
                <ConditionalWrapper
                    condition={isLoading || isCreatingExcel}
                    wrapper={children => <Spin style={{ paddingTop: 180 }}>{children}</Spin>}
                >
                    <Space direction="vertical" size={20} style={{ width: "100%" }}>
                        {exportOptions.length > 1 && (
                            <Input value={filter} placeholder={t("t_search")} onChange={e => setFilter(e.target.value)} allowClear />
                        )}
                        <List
                            dataSource={exportOptions.filter(option => option.title.toLowerCase().includes(filter.toLowerCase()))}
                            renderItem={item => (
                                <List.Item
                                    actions={[
                                        <FontAwesomeIcon
                                            icon={["fal", "arrow-down-to-line"]}
                                            color={stsColors.black1}
                                            style={{ width: 15, height: 15, cursor: "pointer", marginRight: 10 }}
                                            onClick={() =>
                                                item.data
                                                    ? downloadData(item.title, item.data, item.header)
                                                    : fetchData(item.title, item.request, item.extractData, item.header)
                                            }
                                        />
                                    ]}
                                >
                                    <List.Item.Meta
                                        avatar={
                                            <Avatar style={{ backgroundColor: stsColors.green2 }}>
                                                <FontAwesomeIcon icon={["fal", "file-excel"]} />
                                            </Avatar>
                                        }
                                        title={<TextField value="t_export" />}
                                        description={<TextField value={item.title} />}
                                    />
                                </List.Item>
                            )}
                            style={{ height: 400, overflow: "auto" }}
                            locale={{ emptyText: " " }}
                        />
                    </Space>
                </ConditionalWrapper>
            </Modal>
        </>
    );
};

Export.propTypes = {
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    exportOptions: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            header: PropTypes.arrayOf(PropTypes.string).isRequired,
            data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])),
            request: request,
            extractData: PropTypes.func
        })
    ).isRequired
};

export default Export;
