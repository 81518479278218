import React from "react";
import PropTypes from "prop-types";
import { Tooltip, Space } from "antd";

const Field = ({ defaultValue = "-", showDefaultValue = false, hint = {}, children }) => {
    if (showDefaultValue) return <p>{defaultValue}</p>;

    if (hint.condition)
        return (
            <p>
                <Tooltip title={hint.tooltip}>
                    <Space>
                        <span>{children}</span>
                        <span>*</span>
                    </Space>
                </Tooltip>
            </p>
        );

    return <p>{children}</p>;
};

Field.propTypes = {
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    showDefaultValue: PropTypes.bool,
    hint: PropTypes.shape({
        condition: PropTypes.bool,
        tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    })
};

export default Field;
