import React from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

import { stsColors } from "styles";

const style = {
    background: stsColors.green2,
    color: stsColors.white1
};

const SubmitButton = ({ children, ...props }) => {
    const [t] = useTranslation();
    if (typeof children === "string") children = t(children);

    return <Button children={children} {...props} style={style} />;
};

export default SubmitButton;
