import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Result } from "antd";

import { clientUrls } from "misc/urls/clientUrls";

import { TransparentButton } from "misc/buttons";
import { TextField } from "misc/fields";

import { stsColors } from "styles";

const ErrorSite = ({ error }) => {
    const goHome = () => {
        window.history.pushState("", "", clientUrls.dashboards.default());
        window.location.reload();
    };

    const StyledResult = styled(Result)({
        height: "100%",
        flexDirection: "column",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    });

    const ErrorIcon = styled(FontAwesomeIcon)({
        color: stsColors.grey2
    });

    const Title = styled(TextField)({
        color: stsColors.grey3
    });

    const getVerboseError = error => {
        if (!error) return "t_http_error_undefined";

        if (error.includes("Failed to fetch")) return "t_http_error_connection_refused";

        return "t_http_error_client_error";
    };

    return (
        <StyledResult
            status="error"
            icon={<ErrorIcon size="9x" icon={["fas", "car-crash"]} />}
            title={<Title value={getVerboseError(error)} />}
            subTitle={<TextField value="t_http_error_occurred" />}
            extra={[
                <TransparentButton size="large" onClick={goHome}>
                    <TextField value="t_retry" />
                </TransparentButton>
            ]}
        />
    );
};

export default ErrorSite;
