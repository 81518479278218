const baseUrl =
    process.env.REACT_APP_STAGE === "production"
        ? "https://analysis.streetscooter-cloud-system.eu/services/documents"
        : process.env.REACT_APP_STAGE === "staging"
        ? "https://api-staging.streetscooter-cloud-system.eu/services/documents"
        : "https://api-staging.streetscooter-cloud-system.eu/services/documents"; // "http://localhost:8003/services/documents"

export const documentslayerUrls = {
    baseUrl: () => baseUrl,
    protocol: () => `${baseUrl}/procotol`
};
