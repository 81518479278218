import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip, Space, Button, message, Row, Popover, Avatar, List } from "antd";

import { StreetScooterLogo } from "../Sidebar/logo";

import { clientUrls } from "misc/urls";
import { TextField } from "misc/fields";
import { useKeycloak } from "misc/api/keycloak";
import { LanguageSelector } from "misc/selectors";
import { clearLocaleStorage } from "misc/helpfunctions";
import { CheckAppPermissions } from "misc/authentication";
import { useLoggedInUser, useLocale, useUpdateLocale } from "misc/api/keycloakApi";

import { stsColors } from "styles";

const ListItem = styled(List.Item)`
    &:hover {
        color: ${stsColors.blue2};
    }
`;

const Header = () => {
    const [t] = useTranslation();

    const history = useHistory();

    const { pathname } = useLocation();
    const { keycloak } = useKeycloak();

    const [open, setOpen] = useState(false);
    const { loggedInUser } = useLoggedInUser({ suspense: true });

    const { locale } = useLocale();
    const { updateLocale, isLoading: isUpdatingLanguage } = useUpdateLocale();

    const isAdminPath = useMemo(() => pathname.includes("user-management"), [pathname]);

    const onSelectLanguage = value =>
        updateLocale(value, {
            onError: error => message.error(t(`t_http_error_${error.status}`))
        });

    const onClickSettings = () => {
        history.push(clientUrls.header.settings());
        setOpen(false);
    };

    const onLogout = () => {
        clearLocaleStorage();
        keycloak.logout();
    };

    return (
        <Row justify="space-between" className="header" align="middle" style={{ padding: "25px 20px 20px 20px", background: "#f5f5f5" }}>
            <Link to={clientUrls.dashboards.baseUrl()}>
                <StreetScooterLogo height="50" />
            </Link>
            <Space size={15}>
                <Space size={5}>
                    <CheckAppPermissions fallback={null} allowed={["permission_module_user-management"]}>
                        <Tooltip title={<TextField value={isAdminPath ? "t_user_management_exit" : "t_user_management"} />} placement="bottomRight">
                            <Button onClick={() => history.push(isAdminPath ? clientUrls.baseUrl() : clientUrls.userManagement.users.baseUrl())}>
                                <FontAwesomeIcon icon={isAdminPath ? ["far", "arrow-right-from-bracket"] : ["far", "user-gear"]} />
                            </Button>
                        </Tooltip>
                    </CheckAppPermissions>

                    <LanguageSelector
                        value={locale.default}
                        onChange={onSelectLanguage}
                        loading={isUpdatingLanguage}
                        verbose={false}
                        style={{ width: 65 }}
                        showSearch={false}
                    />
                </Space>

                <Popover
                    placement="bottomRight"
                    content={
                        <div style={{ display: "flex", flexDirection: "column", rowGap: "20px", width: "100%", padding: "8px 0" }}>
                            <Space direction="vertical" align="center" size={10}>
                                <Avatar
                                    icon={
                                        loggedInUser.email ? (
                                            loggedInUser.email[0].toUpperCase()
                                        ) : (
                                            <FontAwesomeIcon icon={["fas", "user"]} style={{ fontSize: 20 }} />
                                        )
                                    }
                                    size={50}
                                    style={{ fontSize: "25px", background: stsColors.blue2 }}
                                />
                                <TextField value={loggedInUser.email} style={{ fontSize: "14px" }} />
                            </Space>
                            <List bordered style={{ width: "100%", borderLeft: 0, borderRight: 0, borderRadius: 0, borderColor: stsColors.white3 }}>
                                <ListItem onClick={onClickSettings} style={{ cursor: "pointer" }}>
                                    <Space size={10}>
                                        <FontAwesomeIcon icon={["fal", "gears"]} />
                                        <TextField value="t_header_user_settings" />
                                    </Space>
                                </ListItem>
                            </List>

                            <Button onClick={onLogout}>{t("t_button_label_sign_out")}</Button>
                        </div>
                    }
                    trigger="click"
                    overlayInnerStyle={{ padding: "20px 30px" }}
                    open={open}
                    onOpenChange={setOpen}
                >
                    <Space style={{ cursor: "pointer" }}>
                        <Avatar
                            size={45}
                            icon={
                                loggedInUser.email ? (
                                    loggedInUser.email[0].toUpperCase()
                                ) : (
                                    <FontAwesomeIcon icon={["fas", "user"]} style={{ fontSize: 22 }} />
                                )
                            }
                            style={{ background: stsColors.blue2 }}
                        />
                        <FontAwesomeIcon style={{ color: stsColors.grey1 }} icon={["fas", "chevron-down"]} />
                    </Space>
                </Popover>
            </Space>
        </Row>
    );
};

export default Header;
