import React from "react";
import { Space } from "antd";
import { isNil } from "lodash";
import PropTypes from "prop-types";

import { NumberField, TextField } from "misc/fields";

const NumberRangeField = ({ first, last, separator = "/", ...props }) => {
    if (isNil(first) && isNil(last)) return <TextField value="-" />;

    return (
        <Space>
            <NumberField value={first} {...props} />
            <TextField value={separator} />
            <NumberField value={last} {...props} />
        </Space>
    );
};

NumberRangeField.propTypes = {
    first: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    last: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    separator: PropTypes.string
};

export default NumberRangeField;
