import { G2 } from "@ant-design/charts";
export { default as TimeseriesChartWrapper } from "./timeseries/TimeseriesChartWrapper";
export { default as TimeseriesLineChartElement } from "./timeseries/TimeseriesLineChartElement";
// export { default as TimeseriesBarChartElement } from "./timeseries/TimeseriesBarChartElement";
export { default as BulletChart } from "./BulletChart";
export { default as DualAxesChart } from "./DualAxesChart";
export { default as PieChart } from "./PieChart";
export { default as LineChart } from "./LineChart";
export { default as BarChart } from "./BarChart";
export { default as ColumnChart } from "./ColumnChart";
export { default as AreaChart } from "./AreaChart";
export { default as FacetChart } from "./FacetChart";

// register global hover for bar charts
G2.registerInteraction("element-link", {
    start: [
        {
            trigger: "interval:mouseenter",
            action: "element-link-by-color:link"
        }
    ],
    end: [
        {
            trigger: "interval:mouseleave",
            action: "element-link-by-color:unlink"
        }
    ]
});
