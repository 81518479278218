import React from "react";

const StackIcon = ({ children, ...props }) => (
    <span
        className="fa-layers fa-fw"
        style={{
            display: "grid",
            verticalAlign: "middle",
            height: "100%",
            position: "relative",
            width: "100%"
        }}
        {...props}
    >
        {children}
    </span>
);

export default StackIcon;
