import React from "react";
import { Collapse } from "antd";
import { snakeCase } from "lodash";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { TextField } from "misc/fields";
import { datalayerUrls } from "misc/urls";

import { stsColors } from "styles";
import DualAxesChartWidget from "./DualAxesChartWidget";

const ChargingInformationHistogram = ({ filter, vin, view = "day", mode = "default", height, ...props }) => {
    const [t] = useTranslation();

    return (
        <DualAxesChartWidget
            title="t_charging_information_histogram"
            request={{
                method: "GET",
                url: datalayerUrls.vehicles.widgets.barChart(),
                params: {
                    type: "chargingInformation",
                    vin: vin,
                    view: view
                },
                filter: filter
            }}
            extractData={data => {
                if (mode === "default") {
                    const socDefaultData = data.reduce(
                        (previous, current) => [
                            ...previous,
                            {
                                date: current.date,
                                week: current.week,
                                value: current.socMin,
                                type: "socMin"
                            },
                            {
                                date: current.date,
                                week: current.week,
                                value: current.socMax,
                                type: "socMax"
                            },
                            {
                                date: current.date,
                                week: current.week,
                                value: current.socMax - current.socMin,
                                type: "socDelta"
                            }
                        ],
                        []
                    );
                    const distanceDefaultData = data.reduce(
                        (result, { distance, ...rest }) => [
                            ...result,
                            {
                                date: rest.date,
                                week: rest.week,
                                distance: distance,
                                category: "distance"
                            }
                        ],
                        []
                    );
                    return [socDefaultData, distanceDefaultData];
                } else {
                    const socTecData = data.reduce(
                        (previous, current) => [
                            ...previous,
                            {
                                date: current.date,
                                week: current.week,
                                value: current.socNormalizedSignalsMin,
                                type: "socNormalizedSignalsMin"
                            },
                            {
                                date: current.date,
                                week: current.week,
                                value: current.socNormalizedSignalsMax,
                                type: "socNormalizedSignalsMax"
                            },
                            {
                                date: current.date,
                                week: current.week,
                                value: current.socChargingStatsMin,
                                type: "socChargingStatsMin"
                            },
                            {
                                date: current.date,
                                week: current.week,
                                value: current.socChargingStatsMax,
                                type: "socChargingStatsMax"
                            }
                        ],
                        []
                    );
                    const distanceTecData = data.reduce(
                        (result, { distance, ...rest }) => [
                            ...result,
                            {
                                date: rest.date,
                                week: rest.week,
                                distance: distance,
                                category: "distance"
                            }
                        ],
                        []
                    );
                    return [socTecData, distanceTecData];
                }
            }}
            chartConfig={{
                xField: view === "day" ? "date" : "week",
                yField: ["value", "distance"],
                yAxis: {
                    value: {
                        title: { text: t("t_soc_in_percent") },
                        tickInterval: 20,
                        min: 0
                    },
                    distance: {
                        title: { text: t("t_covered_distance") },
                        min: 0
                    }
                },
                geometryOptions: [
                    {
                        geometry: "column",
                        color: ({ type }) => {
                            switch (type) {
                                case "socMin":
                                    return stsColors.grey1;

                                case "socMax":
                                    return stsColors.green2;

                                case "socDelta":
                                    return stsColors.yellow3;

                                case "socNormalizedSignalsMin":
                                    return stsColors.grey1;

                                case "socNormalizedSignalsMax":
                                    return stsColors.green2;

                                case "socChargingStatsMin":
                                    return stsColors.blue1;

                                case "socChargingStatsMax":
                                    return stsColors.blue2;

                                default:
                                    return stsColors.black3;
                            }
                        },
                        isGroup: true,
                        seriesField: "type",
                        marginRatio: 0
                    },
                    {
                        geometry: "line",
                        seriesField: "category",
                        point: {},
                        lineStyle: { lineWidth: 3 },
                        color: stsColors.black1
                    }
                ],
                meta: {
                    date: {
                        formatter: date => moment(date).format("DD.MM")
                    },
                    week: {
                        formatter: week => week
                    },
                    value: {
                        formatter: value => `${value} %`
                    },
                    distance: {
                        formatter: value => `${value} km`
                    },
                    type: {
                        formatter: value => t(`t_${snakeCase(value)}`)
                    },
                    category: {
                        formatter: value => {
                            return t(`t_${value}`);
                        }
                    }
                },
                tooltip: {
                    formatter: data => {
                        return {
                            name: data.type ? t(`t_${snakeCase(data.type)}`) : t(`t_${data.category}`),
                            value: data.value ? `${data.value} %` : data.distance ? `${data.distance} km` : t("t_no_data")
                        };
                    }
                }
            }}
            help={{
                renderHelp: () => (
                    <Collapse defaultActiveKey={["description"]}>
                        <Collapse.Panel key="description" header={<TextField value="t_description" inline />}>
                            <TextField value="t_charging_information_histogram_description_x_axis" />
                            <TextField value="t_charging_information_histogram_description_y_axis" />
                        </Collapse.Panel>

                        {mode === "default" ? (
                            <>
                                <Collapse.Panel key="socMin" header={<TextField value="t_soc_min" inline />}>
                                    <TextField value="t_soc_min_description" />
                                </Collapse.Panel>
                                <Collapse.Panel key="socMax" header={<TextField value="t_soc_max" inline />}>
                                    <TextField value="t_soc_max_description" />
                                </Collapse.Panel>
                                <Collapse.Panel key="socDelta" header={<TextField value="t_soc_delta" inline />}>
                                    <TextField value="t_soc_delta_description" />
                                </Collapse.Panel>
                                <Collapse.Panel key="distance" header={<TextField value="t_distance" inline />}>
                                    <TextField value="t_distance_description" />
                                </Collapse.Panel>
                            </>
                        ) : (
                            <>
                                <Collapse.Panel key="socNormalizedSignalsMin" header={<TextField value="t_soc_normalized_signals_min" inline />}>
                                    <TextField value="t_soc_normalized_signals_min_description" />
                                </Collapse.Panel>
                                <Collapse.Panel key="socNormalizedSignalsMax" header={<TextField value="t_soc_normalized_signals_max" inline />}>
                                    <TextField value="t_soc_normalized_signals_max_description" />
                                </Collapse.Panel>
                                <Collapse.Panel key="socChargingStatsMin" header={<TextField value="t_soc_charging_stats_min" inline />}>
                                    <TextField value="t_soc_charging_stats_min_description" />
                                </Collapse.Panel>
                                <Collapse.Panel key="socChargingStatsMax" header={<TextField value="t_soc_charging_stats_max" inline />}>
                                    <TextField value="t_soc_charging_stats_max_description" />
                                </Collapse.Panel>
                                <Collapse.Panel key="distance" header={<TextField value="t_distance" inline />}>
                                    <TextField value="t_distance_description" />
                                </Collapse.Panel>
                            </>
                        )}
                    </Collapse>
                )
            }}
            {...props}
        />
    );
};

export default ChargingInformationHistogram;
