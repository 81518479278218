import { useMemo } from "react";

import { Table } from "misc/tables";
import { Widget } from "misc/widgets";
import { TextField } from "misc/fields";
import { useChargingMonitorParameters } from "misc/api/datalayer";
import SideHeader from "navigation/SideHeader";

const CmParameters = () => {
    const { chargingMonitorParameters, isLoading, isError } = useChargingMonitorParameters({ latest: true, suspense: true });

    const columns = [
        {
            title: () => <TextField value="t_name" />,
            dataIndex: "name",
            key: "name",
            width: 400
        },
        {
            title: () => <TextField value="t_value" />,
            dataIndex: "value",
            key: "value",
            width: 100
        }
    ];

    const extractedData = useMemo(() => {
        return chargingMonitorParameters && Object.entries(chargingMonitorParameters).map(([name, value]) => ({ name, value }));
    }, [chargingMonitorParameters]);

    return (
        <SideHeader>
            <Widget title="t_cm_parameters">
                <Table
                    isLoading={isLoading}
                    isError={isError}
                    showHeader={true}
                    size="small"
                    dataSource={extractedData}
                    columns={columns}
                    pagination={false}
                />
            </Widget>
        </SideHeader>
    );
};

export default CmParameters;
