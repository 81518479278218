import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Menu, Space, Dropdown, Spin, Button, Modal, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import fileDownload from "js-file-download";
// import isObject from "lodash/isObject";
import domtoimage from "dom-to-image";
// import isArray from "lodash/isArray";
import { round } from "lodash";
import PropTypes from "prop-types";
import shortid from "shortid";
import moment from "moment";

import { buildUrl } from "misc/helpfunctions";
// import { filterTimeseriesData } from "misc/helpfunctions";
import { TextField } from "misc/fields";
import { request } from "misc/propTypes";
import { clientUrls } from "misc/urls";
import { Card } from "misc/card";

const Widget = ({ title, height, download, help, signals, extra, error, isLoading, children, ...props }) => {
    const [t] = useTranslation();

    const id = shortid.generate();

    const [modalIsVisbile, setModalIsVisible] = useState(false);

    const downloading = false;

    const renderExtra = () => {
        if (!download && !help && !signals && !extra) return null;

        // const downloadExcel = data => {
        //     import("xlsx").then(XLSX => {
        //         const workbook = XLSX.utils.book_new();

        //         if (isArray(data[0])) {
        //             XLSX.utils.book_append_sheet(workbook, XLSX.utils.aoa_to_sheet(filterTimeseriesData(data), "data"));
        //         } else if (isObject(data)) {
        //             if (isArray(Object.values(data)[0])) {
        //                 Object.entries(data).forEach(([key, value]) =>
        //                     XLSX.utils.book_append_sheet(workbook, XLSX.utils.aoa_to_sheet(filterTimeseriesData(value)), key.substring(0, 30))
        //                 );
        //             } else {
        //                 XLSX.utils.book_append_sheet(workbook, XLSX.utils.json_to_sheet(data), "data");
        //             }
        //         }
        //         XLSX.writeFile(workbook, `${t(title)}.xlsx`);
        //     });
        // };

        const downloadImage = () => {
            const imageName = `${t(title)}.png`;
            domtoimage.toBlob(document.getElementById(id), { bgcolor: "white" }).then(blob => {
                fileDownload(blob, imageName);
            });
        };

        const menu = (
            <Menu>
                <Menu.Item>
                    <TextField value="t_download_image" onClick={downloadImage} />
                </Menu.Item>
                <Menu.Item>
                    <TextField
                        value="t_download_excel"
                        onClick={
                            () => null
                            // download.data
                            //     ? downloadExcel(download.data)
                            //     : performRequests(download.request, data => {
                            //           downloadExcel(download.extractData(data));
                            //       })
                        }
                    />
                </Menu.Item>
            </Menu>
        );

        const getSignalsUrl = () => {
            const start = moment(signals.range[0])._f === "YYYY-MM-DD" ? moment(signals.range[0]).startOf("day") : signals.range[0];
            const end = moment(signals.range[1])._f === "YYYY-MM-DD" ? moment(signals.range[1]).endOf("day") : signals.range[1];

            const initialRate = end.diff(start, "seconds") / 10000;

            let rate = initialRate;
            let interval = "second";

            if (initialRate > 60) {
                rate /= 60;
                interval = "minute";
            } else if (initialRate > 3600) {
                rate /= 60;
                interval = "hour";
            } else if (initialRate > 86400) {
                rate /= 24;
                interval = "day";
            }

            return buildUrl({
                path: clientUrls.vehicles.signals(),
                queryParameters: {
                    vehicles: signals.vehicles,
                    range: [start.toISOString(), end.toISOString()],
                    signals: signals.signals,
                    rate: round(rate),
                    interval: interval
                }
            });
        };

        return (
            <Space direction="horizontal" size={5}>
                {extra}

                {download && (
                    <Tooltip title={<TextField value="t_download" />}>
                        <Dropdown overlay={menu} placement="bottomRight" trigger={["click"]}>
                            <Button icon={<FontAwesomeIcon icon={["fal", "arrow-down-to-line"]} />} />
                        </Dropdown>
                    </Tooltip>
                )}

                {help && (
                    <Tooltip title={<TextField value="t_help" />}>
                        <Button icon={<FontAwesomeIcon icon={["fal", "circle-info"]} />} onClick={() => setModalIsVisible(true)} />
                    </Tooltip>
                )}

                {signals && (
                    <Tooltip title={<TextField value="t_signals_view" />}>
                        {/* TODO: Mechanism for Sampling Rate */}

                        <Link to={getSignalsUrl()} target="_blank">
                            <Button icon={<FontAwesomeIcon icon={["fal", "wave-square"]} />} />
                        </Link>
                    </Tooltip>
                )}
            </Space>
        );
    };

    if (downloading) {
        return (
            <Spin tip={<TextField value="t_downloading_data" />}>
                <Card title={title} height={height} extra={renderExtra()} />
            </Spin>
        );
    }

    return (
        <>
            <Card id={id} title={title} height={height} extra={renderExtra()} error={error} isLoading={isLoading} {...props}>
                {children}
            </Card>
            <Modal
                title={<TextField value="t_help" />}
                visible={modalIsVisbile}
                onCancel={() => setModalIsVisible(false)}
                footer={null}
                width={600}
                destroyOnClose
            >
                {help?.renderHelp()}
            </Modal>
        </>
    );
};

Widget.propTypes = {
    title: PropTypes.string,
    height: PropTypes.number,
    download: PropTypes.shape({
        data: PropTypes.any,
        request: request,
        extractData: PropTypes.func
    }),
    help: PropTypes.shape({
        renderHelp: PropTypes.func.isRequired
    }),
    signals: PropTypes.shape({
        vins: PropTypes.arrayOf(PropTypes.string).isRequired,
        range: PropTypes.arrayOf(PropTypes.string).isRequired,
        signals: PropTypes.arrayOf(PropTypes.number).isRequired
    }),
    extra: PropTypes.node
};

export default Widget;
