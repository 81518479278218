import dayjs from "dayjs";
import queryString from "query-string";
import i18next from "i18next";
import pickBy from "lodash/pickBy";
import isEmpty from "lodash/isEmpty";
import identity from "lodash/identity";
import last from "lodash/last";
import map from "lodash/map";
import assign from "lodash/assign";
import omit from "lodash/omit";
import flattenDeep from "lodash/flattenDeep";
import union from "lodash/union";
import moment from "./moment";

import "moment/locale/de";
import "moment/locale/es-us";

export const buildUrl = ({ path, urlParameters = [], queryParameters = {}, props = {} }) => {
    let url = path;

    urlParameters = urlParameters.filter(parameter => Boolean(parameter));
    queryParameters = pickBy(queryParameters, identity);

    if (urlParameters.length > 0) url += `/${urlParameters.join("/")}`;

    if (!isEmpty(queryParameters))
        url += `?${queryString.stringify(queryParameters, { sort: false, arrayFormat: props.arrayFormat ?? "bracket-separator" })}`;

    return url;
};

export const getQueryParameters = (location, defaultValue) => {
    const result = queryString.parse(location?.search, { arrayFormat: "bracket-separator", parseNumbers: true, parseBooleans: true });

    return result ?? defaultValue;
};

export const getQueryParameter = (location, parameterName, defaultValue) => {
    const result = queryString.parse(location?.search, { arrayFormat: "bracket-separator", parseNumbers: true, parseBooleans: true })[parameterName];

    return result ?? defaultValue;
};

export const filterTimeseriesData = data => {
    return data
        .filter((row, indexRow) =>
            indexRow === 0 ? true : row.some((col, indexCol) => indexCol > 0 && Math.abs(data[indexRow - 1][indexCol] - col) > 0)
        )
        .concat([last(data)]);
};

export const flattenTree = (tree, key) => {
    function recurse(nodes, path) {
        return map(nodes, function (node) {
            var newPath = union(path, [node.name]);
            return [assign({ pathname: newPath.join(" > "), level: path.length }, omit(node, key)), recurse(node[key], newPath)];
        });
    }
    return flattenDeep(recurse(tree, []));
};

export const setLocalStorageItem = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorageItem = (key, defaultValue) => {
    return JSON.parse(localStorage.getItem(key)) ?? defaultValue;
};

export const clearLocaleStorage = () => {
    localStorage.clear();
};

export const getDatesFromRange = range => {
    const [startDate, endDate] = range;

    let dates = [];

    const days = dayjs(endDate).diff(startDate, "day");

    let currentDate = startDate.startOf("day");

    for (let i = 0; i <= days; i++) {
        dates.push(dayjs(currentDate).startOf("day"));
        currentDate = dayjs(currentDate).add(1, "day");
    }

    return dates;
};

export const getWeeksFromRange = range => {
    const [startDate, endDate] = range;

    let dates = [];

    const startWeek = dayjs(startDate).startOf("week");
    const endWeek = dayjs(endDate).startOf("week");

    const weeks = dayjs(endWeek).diff(startWeek, "week");

    let currentWeek = startWeek;

    for (let i = 0; i <= weeks; i++) {
        dates.push(dayjs(currentWeek).startOf("week"));
        currentWeek = dayjs(currentWeek).add(1, "week");
    }

    return dates;
};

// Date (momentjs) helper functions
export const getDateRange = (view, range) =>
    view === "day"
        ? [moment().startOf("week").subtract({ days: range }).formatDatetimeForApi(), NOW]
        : [moment().startOf("week").subtract({ weeks: range }).formatDatetimeForApi(), NOW];

export const NOW = moment().endOf("day").formatDatetimeForApi();

export const DATE_RANGES = {
    today: {
        name: i18next.t("t_today"),
        range: [moment().startOf("day").formatDatetimeForApi(), NOW] // Betrachtungstag
    },
    last4Weeks: {
        name: i18next.t("t_last_week", { count: 4 }),
        range: [moment().startOf("day").subtract({ weeks: 4 }).formatDatetimeForApi(), NOW]
    },
    last8Weeks: {
        name: i18next.t("t_last_week", { count: 8 }),
        range: [moment().startOf("day").subtract({ weeks: 8 }).formatDatetimeForApi(), NOW]
    },
    thisWeek: {
        name: i18next.t("t_this_week"),
        range: [moment().startOf("day").startOf("week").formatDatetimeForApi(), NOW]
    },
    lastWeek: {
        name: i18next.t("t_last_week"),
        range: [
            moment().startOf("day").startOf("week").subtract({ weeks: 1 }).formatDatetimeForApi(),
            moment().startOf("day").startOf("week").subtract({ weeks: 1 }).endOf("week").formatDatetimeForApi()
        ]
    },
    lastLastWeek: {
        name: i18next.t("t_last_week"),
        range: [
            moment().startOf("day").startOf("week").subtract({ weeks: 2 }).formatDatetimeForApi(),
            moment().startOf("day").startOf("week").subtract({ weeks: 2 }).endOf("week").formatDatetimeForApi()
        ]
    },
    previous4Weeks: {
        name: i18next.t("t_last_week", { count: 4 }),
        range: [moment().startOf("day").startOf("week").subtract({ weeks: 4 }).formatDatetimeForApi(), NOW]
    },
    previous8Weeks: {
        name: i18next.t("t_last_week", { count: 8 }),
        range: [moment().startOf("day").startOf("week").subtract({ weeks: 8 }).formatDatetimeForApi(), NOW]
    },

    last7Days: {
        name: i18next.t("t_last_days", { count: 7 }),
        range: [
            moment().startOf("day").subtract({ days: 7 }).formatDatetimeForApi(),
            moment().startOf("day").subtract({ days: 1 }).endOf("day").formatDatetimeForApi()
        ]
    },
    last7DaysPreviousWeek: {
        name: i18next.t("t_last_week", { count: 7 }),
        range: [
            moment().startOf("day").subtract({ weeks: 1, days: 7 }).formatDatetimeForApi(),
            moment().startOf("day").subtract({ weeks: 1, days: 1 }).endOf("day").formatDatetimeForApi()
        ]
    },
    last14weeks: {
        name: "last14weeks",
        range: [moment().startOf("day").startOf("week").subtract({ weeks: 14 }).formatDatetimeForApi(), NOW]
    },
    last14days: {
        name: "last14days",
        range: [moment().startOf("day").subtract({ days: 14 }).formatDatetimeForApi(), NOW]
    }
};

export const getDateRanges = ranges => ranges.map(range => DATE_RANGES[range]?.range);

export const humanReadableDateRange = (range, view) => Object.values(DATE_RANGES).find(RANGE => RANGE.range.join("---") === range)?.name;

export const isDay = date => date.match(/\d{4}-\d{2}-\d{2}/);
export const isWeek = date => date.match(/\d{2}-\d{4}/);

/* Split given date Range into multiple ranges
 * interval can be: weeks, days,...
 */
export const splitRangeBy = (range, interval, options = { step: 1 }) => {
    const ranges = [];
    const startDateMoment = moment(range[0]);
    const endDateMoment = moment(range[1]);

    const step = options.step;

    const diff = Math.abs(startDateMoment.diff(endDateMoment, interval)) / step;

    for (let i = 0; i <= diff; i++) {
        const current = startDateMoment.clone().add(i * step, interval);
        const next = current.clone().add(step, interval);

        const startDate = current.add(i === 0 ? 0 : 1, "d");

        startDate.isSameOrBefore(endDateMoment) &&
            ranges.push([
                moment(startDate).format("YYYY-MM-DD"),
                moment(next.isSameOrAfter(endDateMoment) ? endDateMoment : next).format("YYYY-MM-DD")
            ]);
    }
    return ranges;
};
