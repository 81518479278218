import React, { useState, useRef, useCallback, useMemo } from "react";
import { isArray, isNumber } from "lodash";
import { useMutation } from "react-query";
import fileDownload from "js-file-download";
import Queue from "misc/queue";

import { notification, Button, Progress, Col, Row } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextField } from "misc/fields";

import { keycloak } from "keycloak";
import { datalayerUrls } from "misc/urls";
import { buildUrl } from "misc/helpfunctions";

const openNotification = (key, config = { type: null }) => {
    notification.open({
        key: key,
        ...config
    });
};

const closeNotification = key => {
    notification.destroy(key);
};

const DownloadIndicator = ({ percent, onClick, fileName, ...props }) => (
    <div>
        <Row>
            <Col span={24}>
                <TextField value={fileName} ellipsis />
            </Col>
        </Row>
        <Row align="middle" gutter={16}>
            <Col span={18}>
                <Progress
                    style={{
                        marginBottom: 5
                    }}
                    percent={percent}
                    status="active"
                    {...props}
                />
            </Col>
            <Col span={6}>
                <Button icon={<FontAwesomeIcon icon={["fas", "ban"]} />} onClick={onClick} />
            </Col>
        </Row>
    </div>
);

const exportFileFormat = {
    csv: ".csv",
    excel: ".xlsx",
    pdf: ".pdf",
    default: ""
};

const useExport = ({ onError, onSuccess, format = "default", mutateOptions = {} } = {}) => {
    const shouldExportRef = useRef(true);
    const controllersRef = useRef([]);
    const queueRef = useRef(null);
    const notificationKeyRef = useRef(null);
    const mountedRef = useRef(false);
    const exportFileNameRef = useRef(null);

    const [isLoading, setIsLoading] = useState(false);
    const [percent, setPercent] = useState(0);
    const [error, setError] = useState(null);

    const { mutate, mutateAsync } = useMutation(
        request => {
            const { url, params, filter, headers = {} } = request;

            const defaultHeaders = {
                "Content-Type": "application/json",
                Authorization: keycloak.token
            };

            if (filter) headers.filter = JSON.stringify(filter);

            // To cancel running fetches
            const controller = new AbortController();

            const promise = new Promise(async (resolve, reject) => {
                try {
                    const response = await fetch(buildUrl({ path: url, queryParameters: params, props: { arrayFormat: "bracket" } }), {
                        ...request,
                        headers: { ...defaultHeaders, ...headers },
                        signal: controller.signal
                    });

                    if (!response.ok) {
                        return reject({ status: response.status, statusText: response.statusText });
                    }

                    if (response.status === 204) resolve();

                    const data = await (headers["Content-Type"] === "application/zip" ? response.blob() : response.json());

                    // Specific for backend service datalayer
                    if (url.includes(datalayerUrls.baseUrl())) return resolve(params?.type ? data[params.type] ?? data : data);

                    return resolve(data);
                } catch (error) {
                    if (error && error.name !== "AbortError") {
                        reject(new Error(error.name));
                    }
                }
            });

            promise.cancel = () => {
                controller.abort();
            };

            controllersRef.current.push(controller);
            return promise;
        },
        {
            ...mutateOptions,
            onError: error => {
                // always cancel the running fetches when error occurs
                setError(error);
                onCancelCallback();
            }
        }
    );

    const onSuccessCallback = useCallback(() => {
        // open success notification
        setTimeout(() => {
            closeNotification(notificationKeyRef.current);
        }, 1000);

        onSuccess && onSuccess();
    }, [onSuccess]);

    const setPercentageCallback = useCallback(({ loaded, total }) => {
        const pct = Math.round((loaded / total) * 100);

        setPercent(() => pct);
    }, []);

    const clearAllStateCallback = useCallback(() => {
        shouldExportRef.current = true;
        controllersRef.current = [];
        setIsLoading(() => false);
        setPercent(() => 0);
        setError(() => null);
    }, []);

    const onCancelCallback = useCallback(() => {
        if (queueRef.current) {
            queueRef.current.stop();
            queueRef.current.clear();
        }
        shouldExportRef.current = false;
        controllersRef.current.forEach(controller => controller.abort());
        setIsLoading(() => false);
    }, []);

    const addMutateTask = useCallback(
        async (zip, groupRequest = [], fileNames, columns = [], convertDataToFile, extractData) => {
            groupRequest.forEach((request, idx) => {
                queueRef.current.enqueue(() =>
                    mutateAsync(request).then(data => {
                        const fileName = fileNames.shift() ?? idx;
                        columns.length > 0 &&
                            zip.file(
                                `${fileName}${exportFileFormat[format] || exportFileFormat["default"]}`,
                                convertDataToFile({
                                    fileName: fileName,
                                    data: isArray(data) ? extractData(data) : extractData(data.data),
                                    columns: columns
                                }),
                                {
                                    binary: true
                                }
                            );
                    })
                );
            });
        },
        [mutateAsync, format]
    );

    const runAllTasks = useCallback(
        async queue => {
            while (queue.shouldRun) {
                await queue.dequeue();

                setPercentageCallback({ loaded: queue.maxSize - queue.size, total: queue.maxSize });
            }
        },
        [setPercentageCallback]
    );

    const showProgressCallback = useCallback(
        ({ title }) => {
            if (!isLoading) return;
            openNotification(notificationKeyRef.current, {
                type: "info",
                message: <TextField value={title} />,
                description: error ? (
                    <TextField value={`t_http_error_${isNumber(error.status) ? error.status : "undefined"}`} />
                ) : (
                    <DownloadIndicator
                        fileName={exportFileNameRef.current}
                        percent={percent}
                        onClick={() => {
                            onCancelCallback();
                            closeNotification(notificationKeyRef.current);
                        }}
                    />
                ),
                placement: "bottomRight",
                closeIcon: percent === 100 || error ? "X" : <></>,
                duration: 0
            });
        },
        [isLoading, error, percent, onCancelCallback]
    );

    const downloadZip = useCallback(
        async (zipName, exports, convertDataToFile) => {
            const zip = require("jszip")();
            notificationKeyRef.current = zipName;
            exportFileNameRef.current = `${zipName}.zip`;

            queueRef.current = new Queue({
                concurrent: 1,
                interval: 100,
                start: false
            });

            clearAllStateCallback();
            setIsLoading(() => true);

            exports.forEach(({ request, folderName, fileNames, columns, extractData }, idx) => {
                // prevent zipName clash with folderName
                let folder = folderName === zipName ? `${folderName}_${idx}` : folderName;

                addMutateTask(zip.folder(folder), request, fileNames, columns, convertDataToFile, extractData);
            });

            // running queue
            await runAllTasks(queueRef.current);

            if (shouldExportRef.current) {
                zip.generateAsync({ type: "blob" }).then(content => {
                    fileDownload(content, `${zipName}.zip`);
                });
                onSuccessCallback();
            }

            clearAllStateCallback();
        },
        [clearAllStateCallback, runAllTasks, addMutateTask, onSuccessCallback]
    );

    const download = useCallback(
        (fileName, request, columns, extractData, convertDataToFile) => {
            notificationKeyRef.current = fileName;
            exportFileNameRef.current = `${fileName}${exportFileFormat[format] || exportFileFormat["default"]}`;
            clearAllStateCallback();
            setIsLoading(() => true);

            mutate(request, {
                onSuccess: data => {
                    convertDataToFile({
                        fileName: fileName,
                        data: isArray(data) ? extractData(data) : extractData(data.data),
                        columns: columns
                    }).then(buffer => {
                        shouldExportRef.current && fileDownload(buffer, `${fileName}${exportFileFormat[format] || exportFileFormat["default"]}`);
                        clearAllStateCallback();
                    });
                    onSuccessCallback();
                },
                onError: error => {
                    onError && onError(error);
                }
            });
        },
        [clearAllStateCallback, mutate, onError, onSuccessCallback, format]
    );

    // Cancel Export when unmount
    React.useLayoutEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
            onCancelCallback();
        };
    }, [onCancelCallback]);

    return useMemo(
        () => ({
            isLoading,
            percent,
            error,
            setIsLoading,
            showProgress: showProgressCallback,
            downloadZip,
            download
        }),
        [isLoading, percent, error, showProgressCallback, downloadZip, download]
    );
};

export default useExport;
