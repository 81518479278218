import React from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { useSignals } from "misc/api/datalayer";

const normalizedSignalsTranslation = {
    normalized_online_status: "t_signals_normalized_online_status",
    normalized_gps_latitude: "t_signals_normalized_gps_latitude",
    normalized_gps_longitude: "t_signals_normalized_gps_longitude",
    normalized_ignition_status: "t_signals_normalized_ignition_status",
    normalized_chargeplug_status: "t_signals_normalized_chargeplug_status",
    normalized_soc: "t_signals_normalized_soc",
    normalized_ambient_temperature: "t_signals_normalized_ambient_temperature",
    normalized_charging_current_bool: "t_signals_normalized_charging_current_bool",
    normalized_charging_voltage_bool: "t_signals_normalized_charging_voltage_bool",
    normalized_charging_power_dc_set_offboard: "t_signals_normalized_charging_power_dc_set_offboard",
    normalized_bms_status: "t_signals_normalized_bms_status",
    normalized_charging_power_dc_set_onboard: "t_signals_normalized_charging_power_dc_set_onboard",
    normalized_charging_power_dc_max_derating: "t_signals_normalized_charging_power_dc_max_derating",
    normalized_charging_current_dc: "t_signals_normalized_charging_current_dc",
    normalized_charging_voltage_dc: "t_signals_normalized_charging_voltage_dc",
    normalized_odometer: "t_signals_normalized_odometer"
};

const SignalSelector = ({ defaultValue, value, onChange, restrictions = () => true, ...props }) => {
    const [t] = useTranslation();

    const { signals } = useSignals({ suspense: true });

    return (
        <Select
            defaultValue={defaultValue}
            value={value}
            onChange={onChange}
            placeholder={t("t_select_signals")}
            filterOption={(inputValue, option) => option.children.toLowerCase().includes(inputValue.toLowerCase())}
            style={{ width: "100%" }}
            maxTagCount="responsive"
            showSearch
            {...props}
        >
            {signals
                .filter(signal => restrictions(signal))
                .map(signal => (
                    <Select.Option key={signal.signalId} value={signal.signalId}>
                        {t(normalizedSignalsTranslation[signal.name] ?? signal.name)}
                    </Select.Option>
                ))}
        </Select>
    );
};

SignalSelector.propTypes = {
    defaultValue: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    onChange: PropTypes.func,
    restrictions: PropTypes.func
};

export default SignalSelector;
