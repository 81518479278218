import { clientUrls } from "misc/urls/clientUrls";

const standardSidebarItems = [
    {
        title: "t_vlv",
        key: "modules-vlv",
        icon: ["fas", "signal-stream"],
        url: clientUrls.modules.vlv.baseUrl(),
        allowed: ["permission_module_vlv"],
        items: [
            {
                title: "t_vlv_status",
                key: "vlv-status",
                url: clientUrls.modules.vlv.status()
            },

            {
                title: "t_vlv_drivers_log",
                key: "vlv-drivers-log",
                url: clientUrls.modules.vlv.driversLog()
            }
        ]
    },
    {
        title: "t_cdda",
        key: "modules-cdda",
        icon: ["fas", "magnifying-glass-chart"],
        url: clientUrls.modules.cdda.baseUrl(),
        allowed: ["permission_module_cdda"]
    },
    {
        title: "t_defect_tickets",
        key: "modules-defect-tickets",
        icon: ["fas", "clipboard-list-check"],
        url: clientUrls.modules.defectTickets.baseUrl(),
        allowed: ["permission_module_defect-tickets"]
    },
    {
        title: "t_energy_monitor",
        key: "modules-energy-monitor",
        icon: ["fas", "bolt"],
        url: clientUrls.modules.energyMonitor.baseUrl(),
        allowed: ["permission_module_energy-monitor"]
    },
    {
        title: "t_cm",
        key: "modules-cm",
        icon: ["fas", "charging-station"],
        url: clientUrls.modules.cm.baseUrl(),
        allowed: ["permission_module_cm"],
        items: [
            {
                title: "t_cm_dashboard",
                key: "cm-dashboard",
                url: clientUrls.modules.cm.dashboard()
            },
            {
                title: "t_cm_fleet",
                key: "cm-fleet-view",
                url: clientUrls.modules.cm.fleetView()
            },
            {
                title: "t_cm_wiki",
                key: "wiki",
                url: clientUrls.modules.cm.wiki()
            },
            {
                title: "t_cm_parameters",
                key: "parameters",
                url: clientUrls.modules.cm.parameters(),
                allowed: ["permission_module_cm-extended"]
            }
        ]
    },
    {
        title: "t_bm",
        key: "modules-battery-monitor",
        icon: ["fas", "battery-full"],
        url: "/",
        allowed: ["permission_module_bm"]
    },
    {
        title: "t_engineering",
        key: "modules-engineering",
        icon: ["fas", "user-helmet-safety"],
        url: clientUrls.modules.engineering.baseUrl(),
        allowed: ["permission_module_engineering"],
        items: [
            {
                title: "t_engineering_errors",
                key: "engineering-errors",
                url: clientUrls.modules.engineering.errors(),
                allowed: ["permission_module_engineering-extended"]
            },
            {
                title: "t_engineering_signals",
                key: "engineering-signals",
                url: clientUrls.modules.engineering.signals()
            },
            {
                title: "t_engineering_diagnostics",
                key: "engineering-diagnostics",
                url: clientUrls.modules.engineering.diagnostics(),
                allowed: ["permission_module_engineering-extended"]
            }
        ]
    }
];

const adminSidebarItems = [
    {
        title: "t_users",
        key: "user-management-users",
        url: clientUrls.userManagement.users.baseUrl(),
        allowed: ["permission_module_user-management"],
        icon: "users"
    },
    {
        title: "t_roles",
        key: "user-management-roles",
        url: clientUrls.userManagement.roles.baseUrl(),
        allowed: ["permission_module_user-management"],
        icon: "money-check-pen"
    }
];

export const getSidebarItems = () => {
    const location = window.location.pathname;

    if (location.includes("user-management")) return adminSidebarItems;

    return standardSidebarItems;
};
