import React from "react";
import { Switch, Route, Redirect } from "react-router";

import { clientUrls } from "misc/urls";

import VlvLiveStatusComponent from "../components/VlvLiveStatus";
import VlvDriversLogComponent from "../components/VlvDriversLogComponent";
import VlvViewFleetConfiguration from "../components/VlvViewFleetConfiguration";

const VlvContainer = () => {
    return (
        <Switch>
            <Route exact path={clientUrls.modules.vlv.fleetConfiguration()}>
                <VlvViewFleetConfiguration />
            </Route>

            <Route exact path={clientUrls.modules.vlv.status()}>
                <VlvLiveStatusComponent />
            </Route>

            <Route exact path={clientUrls.modules.vlv.driversLog()}>
                <VlvDriversLogComponent />
            </Route>

            <Redirect to={clientUrls.modules.vlv.status()} />
        </Switch>
    );
};

export default VlvContainer;
