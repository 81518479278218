import React from "react";
import PropTypes from "prop-types";
import { Empty as AntdEmpty, Result } from "antd";

import { TextField } from "misc/fields";

const Empty = ({ title, height, subTitle, ...props }) => (
    <Result
        icon={<AntdEmpty description={false} image={AntdEmpty.PRESENTED_IMAGE_SIMPLE} />}
        title={<TextField value={title ? title : "t_no_data"} style={{ fontSize: 22 }} />}
        subTitle={<TextField value={subTitle ? subTitle : "t_no_data_available"} />}
        style={{ height: height }}
        {...props}
    />
);

Empty.propTypes = {
    height: PropTypes.number,
    title: PropTypes.string,
    subTitle: PropTypes.string
};

export default Empty;
