const baseUrl =
    process.env.REACT_APP_STAGE === "production"
        ? "https://analysis.streetscooter-cloud-system.eu/services/telematics"
        : process.env.REACT_APP_STAGE === "staging"
        ? "https://api-staging.streetscooter-cloud-system.eu/services/telematics"
        : "https://api-staging.streetscooter-cloud-system.eu/services/telematics"; //"http://localhost:8003/services/telematics";

export const telematicsservicesUrls = {
    baseUrl: () => baseUrl,

    request: {
        baseUrl: () => `${baseUrl}`,
        logdata: () => `${telematicsservicesUrls.baseUrl()}/data/request/`
    },
    wakeup: () => `${baseUrl}/wakeup`
};
