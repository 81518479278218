import { useTranslation } from "react-i18next";
import moment from "moment";
import isNumber from "lodash/isNumber";

import { TooltipTag } from "misc/tags";
import { TextField } from "misc/fields";

import { stsColors } from "styles/colors";

export const OnlineStatusTag = ({ onlineStatus, timestamp, ...props }) => {
    const ts = isNumber(timestamp) ? moment.unix(timestamp) : moment(timestamp);

    return (
        <TooltipTag
            tag={<TextField value={onlineStatus ? "t_online" : "t_offline"} />}
            tooltip={timestamp ? ts.fromNow() : "t_never_online"}
            color={onlineStatus ? stsColors.green2 : timestamp && moment().diff(ts, "hours") <= 72 ? stsColors.yellow2 : stsColors.red2}
            {...props}
        />
    );
};

export const LastSignOfLifeTag = ({ signal, timestamp, ...props }) => {
    const [t] = useTranslation();

    const ts = isNumber(timestamp) ? moment.unix(timestamp) : moment(timestamp);

    return (
        <TooltipTag
            tag={<TextField value={timestamp ? ts.fromNow() : "t_never"} />}
            tooltip={timestamp ? `${ts.format("DD.MM.YYYY HH:mm:ss")} ${signal ?? ""}` : t("t_never")}
            color={
                moment().diff(timestamp, "hours") <= 24
                    ? stsColors.green2
                    : timestamp && moment().diff(ts, "hours") <= 72
                    ? stsColors.yellow2
                    : stsColors.red2
            }
            {...props}
        />
    );
};

export const TelemacticControlUnitTag = ({ telematicControlUnit, ...props }) => {
    const [t] = useTranslation();

    const validation = telematicControlUnit => {
        if (!telematicControlUnit) return [0, "t_no_unit", stsColors.red2];
        if (telematicControlUnit.includes("c2cbox")) return [1, "t_c2c", stsColors.yellow2];
        if (telematicControlUnit.includes("tcu")) return [2, "t_tcu", stsColors.green2];
        if (telematicControlUnit.includes("olu")) return [3, "t_olu", stsColors.green2];
        else return [4, "t_unknown", stsColors.red2];
    };

    return (
        <TooltipTag
            tag={<TextField value={validation(telematicControlUnit)[1]} />}
            tooltip={telematicControlUnit ?? t("t_no_unit")}
            color={validation(telematicControlUnit)[2]}
            {...props}
        />
    );
};
