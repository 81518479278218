import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import React, { useState, useMemo } from "react";
import { Popover, List, Tooltip, Col, Row } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isNumber, map, maxBy, meanBy, minBy, pick, sumBy, uniq, every, isNil } from "lodash";

import { Card } from "misc/card";
import { datalayerUrls } from "misc/urls";
import { DateSelector } from "misc/selectors";
import { getDatesFromRange } from "misc/helpfunctions";
import { VehiclesTableTemplate } from "misc/widgets/consumers";
import { BooleanField, DatetimeField, NumberField, RangeField, TextField } from "misc/fields";

import { stsColors } from "styles";

const CheckedIcon = ({ checked, colorChecked = stsColors.green2, colorUnchecked = stsColors.red1 }) => {
    if (checked === null) return <TextField />;

    return checked ? (
        <FontAwesomeIcon icon={["fas", "check"]} color={colorChecked} />
    ) : (
        <FontAwesomeIcon icon={["fas", "xmark"]} color={colorUnchecked} />
    );
};

const EvaluationPopover = ({ title = "t_cdda_evaluation", evaluations, children }) => {
    const data = evaluations.filter(evaluation => !evaluation?.hidden);

    return (
        <Popover
            overlayInnerStyle={{ padding: "0 -16px", minWidth: "400px" }}
            title={<TextField value={title} />}
            placement="topLeft"
            trigger="click"
            content={
                <div>
                    <List
                        size="small"
                        split={false}
                        dataSource={data}
                        renderItem={item => (
                            <List.Item style={{ padding: "2px 0" }}>
                                <List.Item.Meta avatar={<CheckedIcon colored checked={item.status} />} title={item.text} />
                            </List.Item>
                        )}
                    />
                </div>
            }
        >
            <div style={{ cursor: "pointer" }}>{children}</div>
        </Popover>
    );
};

const transpond = (data, transpondIndex = "date", childrenColumnName = "children", columns = [], rows = []) => {
    const transpondedData = data.map(element => ({
        ...element,
        ...columns.reduce(
            (previous, current) => ({
                ...previous,
                [current.dataIndex]: {
                    ...pick(
                        element[childrenColumnName].find(item => item[transpondIndex] === current.dataIndex),
                        current.dataKey
                    ),
                    render: current.render
                }
            }),
            {}
        ),
        children: rows.map(row => ({
            title: row.title,
            ...columns.reduce(
                (previous, current) => ({
                    ...previous,
                    [current.dataIndex]: {
                        ...pick(
                            element[childrenColumnName].find(item => item[transpondIndex] === current.dataIndex),
                            row.dataKey
                        ),
                        render: row.render
                    }
                }),
                {}
            ),
            ...row
        }))
    }));

    return transpondedData;
};

const CddaFleetTable = ({ filter, view, params, queryProps }) => {
    const { t } = useTranslation();

    const [selectedDay, setSelectedDay] = useState(dayjs());

    const shouldHideCategory = category => {
        if (view.cddaCategories) {
            return !(view.cddaCategories.includes("all") || view.cddaCategories.includes(category));
        } else {
            return false;
        }
    };

    const dates = useMemo(() => {
        const dates = getDatesFromRange([dayjs(selectedDay).subtract(view.displayedDays - 1, "days"), dayjs(selectedDay)]);

        if (view.dateOrder === "desc") return dates.sort((a, b) => b.unix() - a.unix());

        return dates.sort((a, b) => a.unix() - b.unix());
    }, [selectedDay, view.dateOrder, view.displayedDays]);

    const columns = useMemo(() => {
        const CustomTableHead = ({ title }) => {
            const { t } = useTranslation();

            const cddaCategories = [
                {
                    name: "charging",
                    title: "t_cdda_category_charging",
                    icon: ["fas", "battery-full"]
                },
                {
                    name: "driving",
                    title: "t_cdda_category_driving",
                    icon: ["fas", "truck"]
                },
                {
                    name: "delivery",
                    title: "t_cdda_category_delivery",
                    icon: ["fas", "person-carry-box"]
                },
                {
                    name: "defectTickets",
                    title: "t_cdda_category_defect_tickets",
                    icon: ["fas", "clipboard-list-check"]
                },
                {
                    name: "driversLogEntry",
                    title: "t_cdda_category_drivers_log_entry",
                    icon: ["fas", "steering-wheel"]
                },
                {
                    name: "errors",
                    title: "t_cdda_category_errors",
                    icon: ["fas", "triangle-exclamation"]
                }
            ];

            return (
                <Row justify="space-around" align="middle" gutter={[16, 16]}>
                    <Col span={24} style={{ textAlign: "center" }}>
                        <TextField value={title} />
                    </Col>

                    {cddaCategories.map(category => {
                        return (
                            <Col key={category.name} hidden={shouldHideCategory(category.name)} flex="1" style={{ textAlign: "center" }}>
                                <Tooltip title={t(category.title)}>
                                    <FontAwesomeIcon icon={category.icon} color={stsColors.black1} />
                                </Tooltip>
                            </Col>
                        );
                    })}

                    {/* <Col flex="2" style={{ overflowWrap: "break-word", textAlign: "center" }}>
                        <TextField value="t_cdda_mileage_start" style={{}} />
                    </Col>
                    <Col flex="2" style={{ overflowWrap: "break-word", textAlign: "center" }}>
                        <TextField value="t_cdda_mileage_end" />
                    </Col> */}
                </Row>
            );
        };

        const getColumnWidth = (cddaCategories = []) => {
            if (cddaCategories.includes("all")) return 450;
            else return 150 + 50 * cddaCategories.length;
        };

        return [
            {
                title: <CustomTableHead title="t_cdda_total_range" />,
                dataIndex: "total",
                render: text =>
                    text?.render ? (
                        <span style={{ textAlign: "center" }}>{text.render(text)}</span>
                    ) : (
                        <span style={{ textAlign: "center" }}>{text}</span>
                    ),
                onCell: () => ({ style: { borderLeft: "3px solid #f0f0f0" } }),
                onHeaderCell: () => ({ style: { borderLeft: "3px solid #f0f0f0" } }),
                width: 30 + getColumnWidth(view.cddaCategories)
            },
            ...dates.map(date => ({
                title: <CustomTableHead title={date.format("dd, DD.MM.YY")} />,
                dataIndex: date.format("YYYY-MM-DD"),
                render: text =>
                    text?.render ? (
                        <span style={{ textAlign: "center" }}>{text.render(text)}</span>
                    ) : (
                        <span style={{ textAlign: "center" }}>{text}</span>
                    ),
                onCell: () => ({ style: { borderLeft: "3px solid #f0f0f0" } }),
                onHeaderCell: () => ({ style: { borderLeft: "3px solid #f0f0f0" } }),
                width: getColumnWidth(view.cddaCategories)
            }))
        ];
    }, [dates, shouldHideCategory, view.cddaCategories]);

    const extractData = ({ data }) => {
        const getStatusCharging = ({ socCharge, socStart }) => {
            const chargingSocStart = params.cddaChargingSocStart ? socStart >= params.cddaChargingSocStart : true;
            const chargingSocCharge = params.cddaChargingSocRise ? socCharge >= params.cddaChargingSocRise : true;

            return { statusCharging: chargingSocCharge && chargingSocStart, chargingSocCharge, chargingSocStart };
        };

        const getStatusDriving = ({ distance }) => {
            const drivingDistance = params.cddaDrivingDistance ? distance >= params.cddaDrivingDistance : true;

            return { statusDriving: drivingDistance, drivingDistance };
        };

        const getStatusDelivery = ({ distance, stops, driversLogEntry }) => {
            const deliveryDistance = params.cddaDeliveryDistance ? distance >= params.cddaDeliveryDistance : true;
            const deliveryStops = params.cddaDeliveryStops ? stops >= params.cddaDeliveryStops : true;
            const deliveryDriversLogEntry = params.cddaDeliveryDriversLogEntry ? driversLogEntry : true;

            return {
                statusDelivery: deliveryDistance && deliveryStops && deliveryDriversLogEntry,
                deliveryDistance,
                deliveryStops,
                deliveryDriversLogEntry
            };
        };

        const getStatusDefectTickets = ({ defectTicketsCount }) => {
            const defectTickets = defectTicketsCount === 0;

            return { statusDefectTickets: defectTickets, defectTickets };
        };

        const getStatusDriversLog = ({ driversLogEntry }) => {
            return { statusDriversLog: driversLogEntry, driversLogEntry };
        };

        const getStatusErrors = ({ errorsCount }) => {
            const errors = errorsCount === 0;

            return { statusErrors: errors, errors };
        };

        const columns = [
            {
                dataIndex: "total",
                dataKey: [
                    "mileageStart",
                    "mileageEnd",
                    "statusCharging",
                    "statusDriving",
                    "statusDelivery",
                    "statusDefectTickets",
                    "statusErrors",
                    "statusDriversLog"
                ],
                render: record => {
                    return (
                        <Row justify="space-around" align="middle" gutter={[16, 0]}>
                            <Col hidden={shouldHideCategory("charging")} flex="1" style={{ textAlign: "center" }}>
                                <NumberField value={100 * record.statusCharging} suffix="%" />
                            </Col>
                            <Col hidden={shouldHideCategory("driving")} flex="1" style={{ textAlign: "center" }}>
                                <NumberField value={100 * record.statusDriving} suffix="%" />
                            </Col>
                            <Col hidden={shouldHideCategory("delivery")} flex="1" style={{ textAlign: "center" }}>
                                <NumberField value={100 * record.statusDelivery} suffix="%" />
                            </Col>
                            <Col hidden={shouldHideCategory("defectTickets")} flex="1" style={{ textAlign: "center" }}>
                                <NumberField value={100 * record.statusDefectTickets} suffix="%" />
                            </Col>
                            <Col hidden={shouldHideCategory("driversLogEntry")} flex="1" style={{ textAlign: "center" }}>
                                <NumberField value={100 * record.statusDriversLog} suffix="%" />
                            </Col>
                            <Col hidden={shouldHideCategory("defectTickets")} flex="1" style={{ textAlign: "center" }}>
                                <NumberField value={100 * record.statusErrors} suffix="%" />
                            </Col>
                        </Row>
                    );
                }
            },
            ...dates.map(date => ({
                dataIndex: date.format("YYYY-MM-DD"),
                dataKey: [
                    "mileageStart",
                    "mileageEnd",
                    "socCharge",
                    "socStart",
                    "distance",
                    "stops",
                    "driversLogEntry",
                    "errorsCount",
                    "defectTicketsCount"
                ],
                render: record => {
                    const { statusCharging, chargingSocCharge, chargingSocStart } = getStatusCharging(record);
                    const { statusDriving, drivingDistance } = getStatusDriving(record);
                    const { statusDelivery, deliveryDistance, deliveryStops, deliveryDriversLogEntry } = getStatusDelivery(record);
                    const { statusDefectTickets, defectTickets } = getStatusDefectTickets(record);
                    const { statusDriversLog, driversLogEntry } = getStatusDriversLog(record);
                    const { statusErrors, errors } = getStatusErrors(record);

                    return (
                        <Row justify="space-around" align="middle" gutter={[16, 0]}>
                            <Col hidden={shouldHideCategory("charging")} flex="1" style={{ textAlign: "center" }}>
                                <EvaluationPopover
                                    evaluations={[
                                        {
                                            status: chargingSocCharge,
                                            text: `${t("t_cdda_condition")} ${t("t_cdda_soc_charge")} ${"\u2265"} ${params.cddaChargingSocRise} %`,
                                            hidden: !params.cddaChargingSocRise
                                        },
                                        {
                                            status: chargingSocStart,
                                            text: `${t("t_cdda_condition")} ${t("t_cdda_soc_start")} ${"\u2265"} ${params.cddaChargingSocStart} %`,
                                            hidden: !params.cddaChargingSocStart
                                        }
                                    ]}
                                >
                                    <CheckedIcon checked={statusCharging} />
                                </EvaluationPopover>
                            </Col>
                            <Col hidden={shouldHideCategory("driving")} flex="1" style={{ textAlign: "center" }}>
                                <EvaluationPopover
                                    evaluations={[
                                        {
                                            status: drivingDistance,
                                            text: `${t("t_cdda_condition")} ${t("t_cdda_distance")} ${"\u2265"} ${params.cddaDrivingDistance} km`
                                        }
                                    ]}
                                >
                                    <CheckedIcon checked={statusDriving} />
                                </EvaluationPopover>
                            </Col>
                            <Col hidden={shouldHideCategory("delivery")} flex="1" style={{ textAlign: "center" }}>
                                <EvaluationPopover
                                    evaluations={[
                                        {
                                            status: deliveryDistance,
                                            text: `${t("t_cdda_condition")} ${t("t_cdda_distance")} ${"\u2265"} ${params.cddaDeliveryDistance} km`,
                                            hidden: !params.cddaDeliveryDistance
                                        },
                                        {
                                            status: deliveryStops,
                                            text: `${t("t_cdda_condition")} ${t("t_cdda_stops")} ${"\u2265"} ${params.cddaDeliveryStops}`,
                                            hidden: !params.cddaDeliveryStops
                                        },
                                        {
                                            status: deliveryDriversLogEntry,
                                            text: `${t("t_cdda_condition")} ${t("t_cdda_drivers_log_entry")} ${"="} ${
                                                params.cddaDeliveryDriversLogEntry
                                            }`,
                                            hidden: !params.cddaDeliveryDriversLogEntry
                                        }
                                    ]}
                                >
                                    <CheckedIcon checked={statusDelivery} />
                                </EvaluationPopover>
                            </Col>
                            <Col hidden={shouldHideCategory("defectTickets")} flex="1" style={{ textAlign: "center" }}>
                                <EvaluationPopover
                                    evaluations={[
                                        {
                                            status: defectTickets,
                                            text: `${t("t_cdda_condition")} ${t("t_cdda_defect_tickets_count")} ${"="} 0`
                                        }
                                    ]}
                                >
                                    <CheckedIcon checked={statusDefectTickets} />
                                </EvaluationPopover>
                            </Col>
                            <Col hidden={shouldHideCategory("driversLogEntry")} flex="1" style={{ textAlign: "center" }}>
                                <EvaluationPopover
                                    evaluations={[
                                        {
                                            status: driversLogEntry,
                                            text: `${t("t_cdda_condition")} ${t("t_cdda_drivers_log_entry")} ${"="} true`
                                        }
                                    ]}
                                >
                                    <CheckedIcon checked={statusDriversLog} />
                                </EvaluationPopover>
                            </Col>
                            <Col hidden={shouldHideCategory("errors")} flex="1" style={{ textAlign: "center" }}>
                                <EvaluationPopover
                                    evaluations={[
                                        {
                                            status: errors,
                                            text: `${t("t_cdda_condition")} ${t("t_cdda_errors_count")} ${"="} 0`
                                        }
                                    ]}
                                >
                                    <CheckedIcon checked={statusErrors} />
                                </EvaluationPopover>
                            </Col>
                            {/* <Col flex="2" style={{ textAlign: "center" }}>
                                <NumberField value={record.mileageStart} suffix="km" />
                            </Col>
                            <Col flex="2" style={{ textAlign: "center" }}>
                                <NumberField value={record.mileageEnd} suffix="km" />
                            </Col> */}
                        </Row>
                    );
                }
            }))
        ];

        const rows = [
            {
                title: "t_cdda_mileage_start",
                dataKey: "mileageStart",
                render: record => <NumberField value={record.mileageStart} decimals={1} suffix="km" />,
                enumerate: false,
                enableCopy: false
            },
            {
                title: "t_cdda_mileage_end",
                dataKey: "mileageEnd",
                render: record => <NumberField value={record.mileageEnd} decimals={1} suffix="km" />,
                enumerate: false
            },
            {
                title: "t_cdda_distance",
                dataKey: "distance",
                render: record => <NumberField value={record.distance} decimals={1} suffix="km" />,
                enumerate: false
            },
            {
                title: "t_cdda_stops",
                dataKey: "stops",
                render: record => <NumberField value={record.stops} />,
                enumerate: false
            },
            {
                title: "t_cdda_door_open_count",
                dataKey: "doorOpenCount",
                render: record => <NumberField value={record.doorOpenCount} />,
                enumerate: false
            },
            {
                title: "t_cdda_ignition_count",
                dataKey: "ignitionCount",
                render: record => <NumberField value={record.ignitionCount} />,
                enumerate: false
            },
            {
                title: "t_cdda_charging_station",
                dataKey: ["date", "chargingStation"],
                render: record => (record.date === "total" ? "" : <TextField value={record.chargingStation} />),
                enumerate: false
            },
            {
                title: "t_cdda_soc_start",
                dataKey: ["date", "socStart"],
                render: record => {
                    return record.date === "total" ? (
                        <NumberField prefix="&#xd8;" value={record.socStart} suffix="%" />
                    ) : (
                        <NumberField value={record.socStart} suffix="%" />
                    );
                },
                enumerate: false
            },
            {
                title: "t_cdda_soc_end",
                dataKey: ["date", "socEnd"],
                render: record => {
                    return record.date === "total" ? (
                        <NumberField prefix="&#xd8;" value={record.socEnd} suffix="%" />
                    ) : (
                        <NumberField value={record.socEnd} suffix="%" />
                    );
                },
                enumerate: false
            },
            {
                title: "t_cdda_soc_consumption",
                dataKey: ["date", "socConsumption"],
                render: record => {
                    return record.date === "total" ? (
                        <NumberField prefix="&#xd8;" value={record.socConsumption} suffix="%" />
                    ) : (
                        <NumberField value={record.socConsumption} suffix="%" />
                    );
                },
                enumerate: false
            },
            {
                title: "t_cdda_energy_consumption_total",
                dataKey: ["energyConsumption", "distance", "minDistance"],
                render: record => {
                    return (
                        <NumberField
                            value={record.energyConsumption}
                            decimals={1}
                            suffix="kWh"
                            hint={{
                                condition: isNumber(record.minDistance ?? record.distance) && (record.minDistance ?? record.distance) < 5,
                                tooltip: <TextField value="t_cdda_energy_consumption_hint" />
                            }}
                        />
                    );
                },
                enumerate: false
            },
            {
                title: "t_cdda_energy_consumption_per_100_km",
                dataKey: ["energyConsumption", "distance", "minDistance"],
                render: record => {
                    return (
                        <NumberField
                            value={record.distance ? record.energyConsumption * (100 / record.distance) : null}
                            decimals={1}
                            suffix="kWh"
                            hint={{
                                condition: isNumber(record.minDistance ?? record.distance) && (record.minDistance ?? record.distance) < 5,
                                tooltip: <TextField value="t_cdda_energy_consumption_hint" />
                            }}
                        />
                    );
                },
                enumerate: false
            },
            {
                title: "t_cdda_departure_time_llw",
                dataKey: ["date", "departureTimeLlw"],
                render: record => (record.date === "total" ? "" : <TextField value={record.departureTimeLlw} />),
                enumerate: false
            },
            // {
            //     title: "t_cdda_preconditioning_timestamp_sended",
            //     dataKey: "test",
            //     render: record => <TextField value="TODO" />,
            //     enumerate: false
            // },
            // {
            //     title: "t_cdda_preconditioning_timestamp_received",
            //     dataKey: "test",
            //     render: record => <TextField value="TODO" />,
            //     enumerate: false
            // },
            {
                title: "t_cdda_soc_charge",
                dataKey: ["date", "socCharge"],
                render: record => {
                    return record.date === "total" ? (
                        <NumberField prefix="&#xd8;" value={record.socCharge} suffix="%" />
                    ) : (
                        <NumberField value={record.socCharge} suffix="%" />
                    );
                },

                enumerate: false
            },
            {
                title: "t_cdda_charge_completed",
                dataKey: ["chargeCompleted", "chargeCompletedTimestamp"],
                render: record =>
                    record.date === "total" ? (
                        <NumberField value={record.chargeCompleted} />
                    ) : (
                        <BooleanField
                            renderTrue={() => (
                                <DatetimeField
                                    prefix={t("t_cdda_charged_completed_true")}
                                    value={record.chargeCompletedTimestamp}
                                    format="HH:mm:ss"
                                />
                            )}
                            renderFalse={() => <TextField value="t_cdda_charged_completed_false" />}
                            value={record.chargeCompleted}
                        />
                    ),
                enumerate: false
            },
            {
                title: "t_cdda_organisational_unit",
                dataKey: ["date", "organisationalUnitStart", "organisationalUnitLlwStart"],
                render: record =>
                    record.date === "total" ? "" : <RangeField first={record.organisationalUnitStart} last={record.organisationalUnitLlwStart} />,
                enumerate: false
            },
            {
                title: "t_cdda_distance_to_organisational_unit_start",
                dataKey: ["date", "distanceToOrganisationalUnitStart", "distanceToOrganisationalUnitLlwStart"],
                render: record =>
                    record.date === "total" ? (
                        ""
                    ) : (
                        <RangeField
                            first={record.distanceToOrganisationalUnitStart}
                            last={record.distanceToOrganisationalUnitLlwStart}
                            render={value => <NumberField value={value} suffix="m" />}
                        />
                    ),
                enumerate: false
            },
            {
                title: "t_cdda_distance_to_organisational_unit_end",
                dataKey: ["date", "distanceToOrganisationalUnitEnd", "distanceToOrganisationalUnitLlwEnd"],
                render: record =>
                    record.date === "total" ? (
                        ""
                    ) : (
                        <RangeField
                            first={record.distanceToOrganisationalUnitEnd}
                            last={record.distanceToOrganisationalUnitLlwEnd}
                            render={value => <NumberField value={value} suffix="m" />}
                        />
                    ),
                enumerate: false
            },
            {
                title: "t_cdda_connectivity",
                dataKey: ["date", "connectivity"],
                render: record => {
                    return record.date === "total" ? (
                        <NumberField prefix="&#xd8;" value={record.connectivity} suffix="%" />
                    ) : (
                        <NumberField value={record.connectivity} suffix="%" />
                    );
                },
                enumerate: false
            },
            {
                title: "t_cdda_telematic_control_unit",
                dataKey: ["date", "telematicControlUnitStart"],
                render: record => (record.date === "total" ? "" : <TextField value={record.telematicControlUnitStart} />),
                enumerate: false
            },
            {
                title: "t_cdda_temperature_ambient_range",
                dataKey: ["date", "temperatureAmbientMin", "temperatureAmbientMax"],
                render: record => (
                    <RangeField
                        first={record.temperatureAmbientMin}
                        last={record.temperatureAmbientMax}
                        render={value => <NumberField value={value} suffix="°C" />}
                    />
                ),
                enumerate: false
            },
            {
                title: "t_cdda_defect_tickets_count",
                dataKey: "defectTicketsCount",
                render: record => <NumberField value={record.defectTicketsCount} />,
                enumerate: false
            },
            {
                title: "t_cdda_errors_count",
                dataKey: "errorsCount",
                render: record => <NumberField value={record.errorsCount} />,
                enumerate: false
            },
            {
                title: "t_cdda_drivers_log_entry",
                dataKey: "driversLogEntry",
                render: record => <NumberField value={record.driversLogEntry} />,
                enumerate: false
            }
        ];

        const total = data.map(vehicle => ({
            ...vehicle,
            data: [
                {
                    date: "total",
                    mileageStart: minBy(vehicle.data, "mileageStart")?.mileageStart, // Workaround for lodash bug
                    mileageEnd: maxBy(vehicle.data, "mileageEnd")?.mileageEnd, // Workaround for lodash bug
                    minDistance: minBy(vehicle.data, "distance")?.distance, // Workaround for lodash bug
                    distance: sumBy(vehicle.data, "distance"),
                    stops: every(vehicle.data, element => isNil(element.stops)) ? null : sumBy(vehicle.data, "stops"),
                    doorOpenCount: every(vehicle.data, element => isNil(element.doorOpenCount)) ? null : sumBy(vehicle.data, "doorOpenCount"),
                    ignitionCount: every(vehicle.data, element => isNil(element.ignitionCount)) ? null : sumBy(vehicle.data, "ignitionCount"),
                    socStart: every(vehicle.data, element => isNil(element.socStart)) ? null : meanBy(vehicle.data, "socStart"),
                    socEnd: every(vehicle.data, element => isNil(element.socEnd)) ? null : meanBy(vehicle.data, "socEnd"),
                    socConsumption: every(vehicle.data, element => isNil(element.socConsumption)) ? null : meanBy(vehicle.data, "socConsumption"),
                    energyConsumption: every(vehicle.data, element => isNil(element.energyConsumption))
                        ? null
                        : sumBy(vehicle.data, "energyConsumption"),
                    socCharge: every(vehicle.data, element => isNil(element.socCharge)) ? null : meanBy(vehicle.data, "socCharge"),
                    chargeCompleted: every(vehicle.data, element => isNil(element.chargeCompleted)) ? null : sumBy(vehicle.data, "chargeCompleted"),
                    connectivity: meanBy(vehicle.data, "connectivity"),
                    temperatureAmbientMin: minBy(vehicle.data, "temperatureAmbientMin")?.temperatureAmbientMin, // Workaround for lodash bug
                    temperatureAmbientMax: maxBy(vehicle.data, "temperatureAmbientMax")?.temperatureAmbientMax, // Workaround for lodash bug
                    defectTicketsCount: sumBy(vehicle.data, "defectTicketsCount"),
                    errorsCount: sumBy(vehicle.data, "errorsCount"),
                    driversLogEntry: sumBy(vehicle.data, "driversLogEntry"),
                    statusCharging: sumBy(vehicle.data, element => getStatusCharging(element).statusCharging) / vehicle.data.length,
                    statusDriving: sumBy(vehicle.data, element => getStatusDriving(element).statusDriving) / vehicle.data.length,
                    statusDelivery: sumBy(vehicle.data, element => getStatusDelivery(element).statusDelivery) / vehicle.data.length,
                    statusDefectTickets: sumBy(vehicle.data, element => getStatusDefectTickets(element).statusDefectTickets) / vehicle.data.length,
                    statusErrors: sumBy(vehicle.data, element => getStatusErrors(element).statusErrors) / vehicle.data.length,
                    statusDriversLog: sumBy(vehicle.data, element => getStatusDriversLog(element).statusDriversLog) / vehicle.data.length
                },
                ...vehicle.data
            ]
        }));

        return transpond(total, "date", "data", columns, rows);
    };

    return (
        <Card
            title="t_cdda"
            height={700}
            stretchHeight
            extra={<DateSelector value={selectedDay} onChange={setSelectedDay} disabledDate={date => date.isAfter(dayjs())} allowClear={false} />}
        >
            <VehiclesTableTemplate
                queryKey="cddaVehiclesView"
                request={{
                    method: "GET",
                    url: datalayerUrls.fleet.widgets.list(),
                    params: { type: "vehiclesHistory" },
                    filter: {
                        ...filter,
                        range: [dayjs.min(dates).format("YYYY-MM-DD"), dayjs.max(dates).format("YYYY-MM-DD")]
                    }
                }}
                view={view}
                extractData={extractData}
                extractCount={data => data.statistics.count}
                rowKey="vin"
                columns={columns}
                tableLayout="fixed"
                scroll={{ x: "100%" }}
                queryProps={queryProps}
                sticky
            />
        </Card>
    );
};

CddaFleetTable.propTypes = {
    filter: PropTypes.object,
    view: PropTypes.string,
    params: PropTypes.object,
    queryProps: PropTypes.object
};

export default CddaFleetTable;
