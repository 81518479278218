import React from "react";
import { Button } from "antd";
import styled from "styled-components";

import { stsColors } from "styles";

const StyledButton = styled(Button)`
    border: solid 1px ${stsColors.grey1}!important;
    color: ${({ active }) => (active ? stsColors.blue2 : stsColors.grey3)} !important;

    &:hover {
        background: ${stsColors.white2};
    }
`;

const TransparentButton = props => <StyledButton {...props} type="ghost" />;

export default TransparentButton;
