import { withRouter } from "react-router";
import { Layout, Menu } from "antd";
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { flattenTree } from "misc/helpfunctions";

import { getSidebarItems } from "./sidebarItems";

import MenuItem from "./helper/MenuItem";

const { Sider } = Layout;

const Sidebar = ({ location }) => {
    const [collapsed, setCollapsed] = useState(false);
    const [openKeys, setOpenKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);

    useEffect(() => {
        const pathname = location.pathname.split("/").filter(element => element !== "");

        setOpenKeys(pathname.map((element, index) => pathname.slice(0, index).join("-")));
        setSelectedKeys(
            flattenTree(getSidebarItems(), "items")
                .filter(item => pathname.join("-").includes(item.key))
                .map(item => item.key)
        );
    }, [location]);

    const renderTrigger = () => (collapsed ? <FontAwesomeIcon icon="chevrons-right" /> : <FontAwesomeIcon icon="chevrons-left" />);

    const onSelect = ({ key }) => setSelectedKeys(key);

    const onCollapse = collapsed => {
        if (collapsed) {
            setOpenKeys([]);
            setSelectedKeys([]);
        }

        setCollapsed(collapsed);
    };

    return (
        <Sider theme="light" onCollapse={onCollapse} width={280} trigger={renderTrigger()} className="sidebar">
            <Menu mode="inline" inlineIndent={20} openKeys={openKeys} selectedKeys={selectedKeys} onOpenChange={setOpenKeys} onSelect={onSelect}>
                {getSidebarItems().map(item => (
                    <MenuItem key={item.key} item={item} />
                ))}
            </Menu>
        </Sider>
    );
};

export default withRouter(Sidebar);
