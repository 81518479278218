import { useQuery } from "react-query";
import { map, flattenDeep, assign, union, omit } from "lodash";

import { datalayerUrls } from "misc/urls";

export const useVehicles = (queryProps = {}) => {
    const { data = [], ...query } = useQuery(["vehicles"], {
        meta: {
            request: { method: "GET", url: datalayerUrls.general.vehicles() }
        },
        ...queryProps
    });

    return {
        vehicles: data.vehicles,
        ...query
    };
};

export const useSignals = (queryProps = {}) => {
    const { data = [], ...query } = useQuery(["signals"], {
        meta: {
            request: { method: "GET", url: datalayerUrls.general.signals() }
        },
        ...queryProps
    });

    return {
        signals: data.signals,
        ...query
    };
};

export const useConfigurations = (queryProps = {}) => {
    const { data = [], ...query } = useQuery(["configurations"], {
        meta: {
            request: { method: "GET", url: datalayerUrls.general.configurations() }
        },
        ...queryProps
    });

    return {
        configurations: data.configurations,
        ...query
    };
};

export const useManufacturers = (queryProps = {}) => {
    // const { data = [], ...query } = useQuery(["manufacturers"], {
    //     meta: {
    //         request: { method: "GET", url: datalayerUrls.general.manufacturers() }
    //     },
    //     ...queryProps
    // });

    // return {
    //     manufacturers: data.manufacturers,
    //     ...query
    // };

    // TODO: Remove this workaround when manufacturers are exported from FMM

    return {
        manufacturers: [
            { manufacturerId: 1, uuid: "streetscooter", name: "StreetScooter" },
            { manufacturerId: 2, uuid: "streetscooter-engineering", name: "StreetScooter Engineering" },
            { manufacturerId: 3, uuid: "volkswagen", name: "Volkswagen" },
            { manufacturerId: 4, uuid: "daimler", name: "Daimler" },
            { manufacturerId: 5, uuid: "ford", name: "Ford" },
            { manufacturerId: 6, uuid: "iveco", name: "Iveco" }
        ]
    };
};

export const useErrors = (queryProps = {}) => {
    const { data = [], ...query } = useQuery(["errors"], {
        meta: {
            request: { method: "GET", url: datalayerUrls.general.errors() }
        },
        ...queryProps
    });

    return {
        errors: data.errors,
        ...query
    };
};

export const usePowertrainTypes = (queryProps = {}) => {
    const { data = [], ...query } = useQuery(["powertrainTypes"], {
        meta: {
            request: { method: "GET", url: datalayerUrls.general.powertrainTypes() }
        },
        ...queryProps
    });

    return {
        powertrainTypes: data.powertrain,
        ...query
    };
};

export const usePermittedHierarchy = (queryProps = {}) => {
    const { data = { hierarchy: [] }, ...query } = useQuery(["permittedHierarchy"], {
        meta: {
            request: { method: "GET", url: datalayerUrls.general.hierarchy() }
        },
        ...queryProps
    });

    return {
        permittedHierarchy: data.hierarchy,
        ...query
    };
};

export const useFlatHierarchy = (queryProps = {}) => {
    const { permittedHierarchy, ...query } = usePermittedHierarchy(queryProps);

    const getFlatHierarchy = hierarchy => {
        const flattenTree = tree => {
            function recurse(nodes, path) {
                return map(nodes, function (node) {
                    var newPath = union(path, [node.name]);
                    return [assign({ pathname: newPath.join(" > "), level: path.length }, omit(node, "children")), recurse(node.children, newPath)];
                });
            }
            return flattenDeep(recurse(tree, []));
        };

        return flattenTree(hierarchy);
    };

    return { flatHierarchy: getFlatHierarchy(permittedHierarchy), ...query };
};

export const useRawData = (queryProps = {}) => {
    const { data = {}, ...query } = useQuery(["rawData", queryProps.params], {
        meta: { request: { method: "GET", url: datalayerUrls.rawData(), params: queryProps.params } },
        ...queryProps
    });

    return {
        rawData: data.rawData ?? {},
        ...query
    };
};

export const useOnlineStatus = (queryProps = {}) => {
    const { data = {}, ...query } = useQuery(["onlineStatus", queryProps.params], {
        meta: {
            request: { method: "GET", url: datalayerUrls.vehicles.values(), params: { type: "onlineStatus", ...queryProps.params } }
        },
        ...queryProps
    });

    return {
        onlineStatus: data,
        ...query
    };
};

export const useTelematicControlUnit = (queryProps = {}) => {
    const { data = {}, ...query } = useQuery(["telematicControlUnit", queryProps.params], {
        meta: {
            request: { method: "GET", url: datalayerUrls.vehicles.values(), params: { type: "telematicControlUnit", ...queryProps.params } }
        },
        ...queryProps
    });

    return {
        telematicControlUnit: data,
        ...query
    };
};

export const useChargingMonitorParameters = (queryProps = {}) => {
    const mixedQueryProps = Object.assign({ latest: false }, queryProps);

    const { data = {}, ...query } = useQuery(["chargingMonitorParameters", queryProps.params], {
        meta: {
            request: {
                method: "GET",
                url: datalayerUrls.fleet.widgets.list(),
                params: { type: "chargingMonitorParameterSets", ...queryProps.params }
            }
        },
        select: data => {
            if (!mixedQueryProps.latest) return data;

            const latestVersion = Math.max(...data?.data.map(element => Number(element.version) || 0));

            return data?.data.find(element => Number(element.version) === Number(latestVersion)).parameters || {};
        },
        ...mixedQueryProps
    });

    return {
        chargingMonitorParameters: data,
        ...query
    };
};
