import dayjs from "dayjs";
import { Row, Col } from "antd";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Card } from "misc/card";
import { Result } from "misc/results";
import { useParams } from "misc/hooks";
import { clientUrls, datalayerUrls } from "misc/urls";
import { useSubfleets } from "misc/api/fleetconfiguration";
import { RangeSelector, MultiSelector } from "misc/selectors";
import { ErrorsPerVehicle, ErrorsTable } from "misc/widgets/consumers";

import SideHeader from "navigation/SideHeader";
import ModuleSettings from "app/modules/ModuleSettings";

import { TABLE_VIEW_ITEMS } from "modules/ModuleSettings";

const VIEW_ITEMS = [...TABLE_VIEW_ITEMS];

const ErrorsVehicleView = () => {
    const [t] = useTranslation();

    const { params, setParams } = useParams({
        options: [
            { name: "vehicle", persist: "module" },
            { name: "range", defaultValue: [dayjs().subtract("days", 14).format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")] },
            { name: "columns", defaultValue: ["vin", "licensePlate"], persist: "site" },
            { name: "fixedColumn", defaultValue: "vin", persist: "site" }
        ]
    });

    const vehicle = useMemo(() => params.vehicle, [params.vehicle]);

    const exportOptions = useMemo(
        () => ({
            label: "t_errors",
            columns: [
                { value: "vin", label: "t_vin" },
                { value: "licensePlate", label: "t_license_plate" },
                { value: "organisationalUnit", label: "t_organisational_unit" },
                { value: "chargingStation", label: "t_charging_station" },
                { value: "manufacturer", label: "t_manufacturer" },
                { value: "model", label: "t_model" },
                { value: "battery", label: "t_battery" },
                { value: "telematicControlUnit", label: "t_telematic_control_unit" },
                { value: "lastSignOfLifeTimestamp", label: "t_last_sign_of_life_timestamp" },
                { value: "lastSignOfLifeSignal", label: "t_last_sign_of_life_signal" },
                { value: "timestamp", label: "t_errors_timestamp" },
                { value: "error", label: "t_errors_error_type", render: record => t(`t_error_${record}`) }
            ],
            request: {
                method: "GET",
                url: datalayerUrls.fleet.widgets.list(),
                params: { type: "errorsExport" },
                filter: { vehicles: [vehicle] }
            }
        }),
        [vehicle]
    );

    return (
        <Row gutter={[10, 10]}>
            <Col span={24}>
                <ModuleSettings
                    values={params}
                    fleetOptions={{ redirectUrl: clientUrls.modules.defectTickets.fleetConfiguration() }}
                    viewOptions={{ items: VIEW_ITEMS }}
                    exportOptions={{ export: exportOptions }}
                    onChange={values => setParams({ ...params, ...values })}
                    mode="vehicle"
                />
            </Col>

            {vehicle ? (
                <Col span={24}>
                    <Card
                        title="t_engineering_errors"
                        extra={
                            <RangeSelector
                                value={params.range}
                                onChange={range => setParams({ ...params, range })}
                                showTime={false}
                                ranges={["lastWeek", "lastTwoWeeks", "lastFourWeeks"]}
                            />
                        }
                    >
                        <ErrorsTable
                            filter={{ vehicles: [params.vehicle], range: params.range }}
                            view={{ columns: params.columns, fixedColumn: params.fixedColumn }}
                            hideSearch
                        />
                    </Card>
                </Col>
            ) : (
                <Col span={24}>
                    <Card height={500}>
                        <Result type="noVehicleSelected" />
                    </Card>
                </Col>
            )}
        </Row>
    );
};

const ErrorsFleetView = () => {
    const [t] = useTranslation();

    const { subfleets } = useSubfleets({ suspense: true });

    const { params, setParams } = useParams({
        options: [
            { name: "fleet", persist: "global", allowed: value => subfleets.find(fleet => fleet.subfleetId === value) },
            { name: "range", defaultValue: [dayjs().subtract(14, "day").format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")] },
            { name: "columns", defaultValue: ["vin", "licensePlate"], persist: "site" },
            { name: "fixedColumn", defaultValue: "vin", persist: "site" },
            { name: "engineeringErrors", defaultValue: ["all"], persist: "site" }
        ]
    });

    const fleet = useMemo(() => subfleets.find(fleet => fleet.subfleetId === params.fleet), [params.fleet, subfleets]);

    const exportItem = useMemo(
        () => ({
            label: "t_errors",
            columns: [
                { value: "vin", label: "t_vin" },
                { value: "licensePlate", label: "t_license_plate" },
                { value: "organisationalUnit", label: "t_organisational_unit" },
                { value: "chargingStation", label: "t_charging_station" },
                { value: "manufacturer", label: "t_manufacturer" },
                { value: "model", label: "t_model" },
                { value: "battery", label: "t_battery" },
                { value: "telematicControlUnit", label: "t_telematic_control_unit" },
                { value: "lastSignOfLifeTimestamp", label: "t_last_sign_of_life_timestamp" },
                { value: "lastSignOfLifeSignal", label: "t_last_sign_of_life_signal" },
                { value: "timestamp", label: "t_errors_timestamp" },
                { value: "error", label: "t_errors_error_type", render: record => t(`t_error_${record}`) }
            ],
            request: {
                method: "GET",
                url: datalayerUrls.fleet.widgets.list(),
                params: { type: "errorsExport" },
                filter: {
                    hierarchy: fleet?.organisationalUnits,
                    vehicles: fleet?.vehicles
                }
            }
        }),
        [fleet]
    );

    return (
        <Row gutter={[10, 10]}>
            <Col span={24}>
                <ModuleSettings
                    values={params}
                    viewOptions={{
                        items: [
                            ...VIEW_ITEMS,
                            {
                                name: "engineeringErrors",
                                label: "t_engineering_errors",
                                render: () => (
                                    <MultiSelector
                                        options={[
                                            { value: 1, label: "t_error_1" },
                                            { value: 2, label: "t_error_2" },
                                            { value: 3, label: "t_error_3" },
                                            { value: 4, label: "t_error_4" },
                                            { value: 5, label: "t_error_5" },
                                            { value: 101, label: "t_error_101" },
                                            { value: 102, label: "t_error_102" },
                                            { value: 103, label: "t_error_103" }
                                        ]}
                                        maxTagCount={1}
                                    />
                                )
                            }
                        ]
                    }}
                    exportOptions={{ export: exportItem }}
                    fleetOptions={{ redirectUrl: clientUrls.modules.vlv.fleetConfiguration() }}
                    onChange={values => setParams({ ...params, ...values })}
                />
            </Col>
            {fleet ? (
                <Col span={24}>
                    <Card
                        title="t_engineering_errors"
                        extra={
                            <RangeSelector
                                value={params.range}
                                onChange={range => setParams({ ...params, range })}
                                showTime={false}
                                ranges={["lastWeek", "lastTwoWeeks", "lastFourWeeks"]}
                                maxDate={dayjs()}
                            />
                        }
                    >
                        <ErrorsPerVehicle
                            filter={{ hierarchy: fleet.organisationalUnits, vehicles: fleet.vehicles, range: params.range }}
                            view={{ columns: params.columns, fixedColumn: params.fixedColumn, engineeringErrors: params.engineeringErrors }}
                        />
                    </Card>
                </Col>
            ) : (
                <Col span={24}>
                    <Card height={500}>
                        <Result type="noFleetSelected" url={clientUrls.modules.vlv.fleetConfiguration()} />
                    </Card>
                </Col>
            )}
        </Row>
    );
};

const EngineeringErrors = () => {
    const { params, setParams } = useParams({
        options: [{ name: "view", defaultValue: "fleet-view", persist: "site" }]
    });

    return (
        <SideHeader
            value={params.view}
            options={[
                { value: "fleet-view", label: "t_view_fleet", render: () => <ErrorsFleetView /> },
                { value: "vehicle-view", label: "t_view_vehicle", render: () => <ErrorsVehicleView /> }
            ]}
            onChange={view => setParams({ ...params, view: view })}
        />
    );
};

export default EngineeringErrors;
