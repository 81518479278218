import { Row, Col } from "antd";
import PropTypes from "prop-types";
import React, { useMemo, useCallback } from "react";

import { Card } from "misc/card";
import { Table } from "misc/tables";
import { MarkerMap } from "misc/map";
import { TitleField } from "misc/fields";
import { datalayerUrls } from "misc/urls";
import { CheckAppPermissions } from "misc/authentication";
import { VehiclesTableTemplate } from "misc/widgets/consumers";
import { TextField, NumberField, DatetimeField } from "misc/fields";

const DriversLog = ({ filter, view, params, queryProps, ...props }) => {
    const columns = useMemo(
        () => [
            {
                key: "timestampStart",
                title: <TextField value="t_drivers_log_timestamp_start" />,
                dataIndex: "timestampStart",
                render: text => <DatetimeField value={text} />,
                width: 200,
                sorter: true,
                sortFields: ["timestampStart"]
            },
            {
                key: "timestampEnd",
                title: <TextField value="t_drivers_log_timestamp_end" />,
                dataIndex: "timestampEnd",
                render: text => <DatetimeField value={text} />,
                width: 200,
                sorter: true,
                sortFields: ["timestampEnd"]
            },
            {
                key: "mileageStart",
                title: <TextField value="t_drivers_log_mileage_start" />,
                dataIndex: "mileageStart",
                render: text => <NumberField value={text} decimals={1} suffix="km" />,
                width: 200,
                sorter: true,
                sortFields: ["mileageStart"]
            },
            {
                key: "mileageEnd",
                title: <TextField value="t_drivers_log_mileage_end" />,
                dataIndex: "mileageEnd",
                render: text => <NumberField value={text} decimals={1} suffix="km" />,
                width: 200,
                sorter: true,
                sortFields: ["mileageEnd"]
            },
            {
                key: "distance",
                title: <TextField value="t_drivers_log_distance" />,
                dataIndex: "distance",
                render: text => <NumberField value={text} decimals={1} suffix="km" />,
                width: 160,
                sorter: true,
                sortFields: ["distance"]
            }
        ],
        []
    );

    const expandedRowRender = useCallback(rowData => {
        const expanderColumns = [
            {
                dataIndex: "name",
                render: name => <TextField value={name} />,
                width: "50%"
            },
            {
                dataIndex: "value",
                render: (text, record) =>
                    record.renderValue ? (
                        record.renderValue(text, record)
                    ) : (
                        <NumberField value={text} decimals={record.decimals} suffix={record.suffix} />
                    ),
                width: "50%"
            }
        ];

        const tableData = [
            {
                name: "t_drivers_log_timestamp_start",
                value: rowData.timestampStart,
                renderValue: text => <DatetimeField value={text} />
            },
            {
                name: "t_drivers_log_timestamp_end",
                value: rowData.timestampEnd,
                renderValue: text => <DatetimeField value={text} />
            },
            {
                name: "t_drivers_log_mileage_start",
                value: rowData.mileageStart,
                decimals: 1,
                suffix: "km"
            },
            {
                name: "t_drivers_log_mileage_end",
                value: rowData.mileageEnd,
                decimals: 1,
                suffix: "km"
            },
            {
                name: "t_drivers_log_distance",
                value: rowData.distance,
                decimals: 1,
                suffix: "km"
            },
            {
                name: "t_drivers_log_stops",
                value: rowData.stops
            },
            {
                name: "t_drivers_log_door_open_count",
                value: rowData.doorOpenCount
            },
            {
                name: "t_drivers_log_soc_start",
                value: rowData.socStart,
                suffix: "%"
            },
            {
                name: "t_drivers_log_soc_end",
                value: rowData.socEnd,
                suffix: "%"
            },
            {
                name: "t_drivers_log_energy_consumed_total",
                value: rowData.energyConsumption,
                decimals: 1,
                suffix: "kWh"
            },
            {
                name: "t_drivers_log_energy_consumed_per_100_km",
                value: rowData.distance > 0 ? (100 * rowData.energyConsumption) / rowData.distance : null,
                decimals: 1,
                suffix: "kWh"
            }
        ];

        const basicInformation = (
            <Card height={560}>
                <TitleField value="t_drivers_log_details" level={5} />
                <Table
                    size="small"
                    dataSource={tableData}
                    columns={expanderColumns}
                    pagination={false}
                    style={{ paddingTop: 5 }}
                    showHeader={false}
                    enumerate={false}
                    stretchWidth={false}
                    bordered
                />
            </Card>
        );

        const gpsInformation = (
            <Card height={560} style={{ paddingTop: 40 }}>
                <MarkerMap
                    height={440}
                    markers={[
                        { position: [rowData.gpsLatitudeStart, rowData.gpsLongitudeStart], tooltip: "t_drivers_log_gps_start" },
                        { position: [rowData.gpsLatitudeEnd, rowData.gpsLongitudeEnd], tooltip: "t_drivers_log_gps_end" }
                    ]}
                />
            </Card>
        );

        return (
            <Row align="start" gutter={[10, 10]}>
                <CheckAppPermissions allowed={["permission_feature_gps-data"]} fallback={<Col span={24}>{basicInformation}</Col>}>
                    <Col xl={16} lg={24}>
                        {basicInformation}
                    </Col>
                    <Col xl={8} lg={24}>
                        {gpsInformation}
                    </Col>
                </CheckAppPermissions>
            </Row>
        );
    }, []);

    return (
        <VehiclesTableTemplate
            queryKey="driversLog"
            request={{
                method: "GET",
                url: datalayerUrls.fleet.widgets.list(),
                params: { type: "driversLog" },
                filter: filter
            }}
            queryProps={queryProps}
            view={view}
            extractData="data"
            extractCount={data => data.statistics.count}
            rowKey={row => `${row.vin}-${row.timestampEnd}`}
            columns={columns}
            expandedRowRender={expandedRowRender}
            scroll={{ x: "100%" }}
            sticky
            {...props}
        />
    );
};

DriversLog.propTypes = {
    filter: PropTypes.object,
    view: PropTypes.object,
    params: PropTypes.object,
    queryProps: PropTypes.object
};

export default DriversLog;
