import { isNil } from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography, Space, Modal, message } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { stsColors } from "styles";

const TextField = ({ value, defaultValue, prefix, suffix, ellipsis, inline, help, enableCopy = false, ...props }) => {
    const [t] = useTranslation();

    const [helpIsOpen, setHelpIsOpen] = useState(false);
    const [showCopy, setShowCopy] = useState(false);

    const getPrefix = () => {
        if (!value) return "";
        if (isNil(prefix)) return "";

        return String(prefix).includes("t_") ? `${t(prefix)} ` : `${prefix} `;
    };

    const getSuffix = () => {
        if (!value) return "";
        if (isNil(suffix)) return "";

        return String(suffix).includes("t_") ? ` ${t(suffix)}` : ` ${suffix}`;
    };

    const getValue = () => {
        if (isNil(value)) return !isNil(defaultValue) ? defaultValue : "-";

        return String(value).includes("t_") ? t(value) : value;
    };

    const renderText = () => {
        if (ellipsis)
            return (
                <Typography.Text ellipsis={{ tooltip: true }} style={{ color: "unset", ...props.style }} {...props}>
                    {getPrefix()}
                    {getValue()}
                    {getSuffix()}
                </Typography.Text>
            );

        if (inline)
            return (
                <span>
                    {getPrefix()}
                    {getValue()}
                    {getSuffix()}
                </span>
            );

        return (
            <p {...props}>
                {getPrefix()}
                {getValue()}
                {getSuffix()}
            </p>
        );
    };

    if (help)
        return (
            <>
                <Space size={7}>
                    {renderText()}
                    <FontAwesomeIcon
                        onClick={() => setHelpIsOpen(true)}
                        icon={["fas", "circle-question"]}
                        color={stsColors.grey1}
                        style={{ cursor: "pointer" }}
                    />
                </Space>
                <Modal title={getValue()} onCancel={() => setHelpIsOpen(false)} visible={helpIsOpen} footer="">
                    {help}
                </Modal>
            </>
        );

    if (enableCopy)
        return (
            <Space
                size={10}
                onMouseEnter={() => setShowCopy(true)}
                onMouseLeave={() => setShowCopy(false)}
                onClick={() => {
                    showCopy &&
                        navigator.clipboard
                            .writeText(getValue())
                            .then(() => {
                                message.success({
                                    icon: <FontAwesomeIcon icon={["fas", "copy"]} style={{ color: stsColors.black1 }} />,
                                    content: <span style={{ marginLeft: 10 }}>{t("t_text_field_copied_text")}</span>
                                });
                            })
                            .catch(error => {
                                console.error("Failed to copy text: ", error);
                            });
                }}
            >
                {renderText()}
                {showCopy && <FontAwesomeIcon icon={["fal", "copy"]} />}
            </Space>
        );

    return renderText();
};

TextField.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultValue: PropTypes.string,
    prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ellipsis: PropTypes.bool,
    inline: PropTypes.bool,
    help: PropTypes.node,
    enableCopy: PropTypes.bool
};

export default TextField;
