import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Transfer as AntdTransfer } from "antd";
import React, { useEffect, useState } from "react";

const Transfer = ({ dataSource, defaultValue, value, onChange = () => null, ...props }) => {
    const [t] = useTranslation();

    const [targetKeys, setTargetKeys] = useState(defaultValue ?? value ?? []);

    useEffect(() => setTargetKeys(value), [setTargetKeys, value]);

    return (
        <AntdTransfer
            className={"transfer-component"}
            showSearch
            titles={[t("t_available"), t("t_selected")]}
            dataSource={dataSource}
            render={item => item.title}
            targetKeys={targetKeys}
            onChange={(nextTargetKeys, direction, moveKeys) => {
                setTargetKeys(nextTargetKeys);
                onChange(nextTargetKeys, direction, moveKeys);
            }}
            locale={{
                itemUnit: t("t_item"),
                itemsUnit: t("t_items"),
                searchPlaceholder: t("t_search"),
                notFoundContent: t("t_empty_list")
            }}
            {...props}
        />
    );
};

Transfer.propTypes = {
    dataSource: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string, title: PropTypes.string })).isRequired,
    defaultValue: PropTypes.arrayOf(PropTypes.string),
    value: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func
};

export default Transfer;
