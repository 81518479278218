import React from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";

const LANGUAGES = [
    { name: "german", locale: "de" },
    { name: "english", locale: "en" },
    { name: "japanese", locale: "jp" }
];

const LanguageSelector = ({ verbose = true, ...props }) => {
    const [t] = useTranslation();

    return (
        <Select
            filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
            options={LANGUAGES.map(language => ({
                label: verbose ? t(`t_${language.name}`) : language.locale.toLocaleUpperCase(),
                value: language.locale
            }))}
            showSearch
            {...props}
        />
    );
};

export default LanguageSelector;
