import PropTypes from "prop-types";
import { useQuery } from "react-query";
import { isEmpty, isFunction } from "lodash";
import { useTranslation } from "react-i18next";
import { Input, Space, Row, Button, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState, useMemo, useEffect } from "react";

import { Table } from "misc/tables";
import { TextField } from "misc/fields";
import { request } from "misc/propTypes";

const PaginatedTableWidget = ({
    queryKey,
    request,
    queryProps,
    extractData,
    extractCount,
    rowKey,
    columns = [],
    hideSearch,
    empty,
    enableManuallyRefresh,
    ...props
}) => {
    const [t] = useTranslation();

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [orderBy, setOrderBy] = useState(null);
    const [order, setOrder] = useState(null);
    const [filters, setFilters] = useState(null);

    const { data, isLoading, isRefetching, isError, refetch } = useQuery([queryKey, request.filter, { page, pageSize, orderBy, order, filters }], {
        meta: {
            request: {
                ...request,
                params: {
                    ...request.params,
                    page: page,
                    pageSize: pageSize,
                    orderBy: orderBy,
                    order: order,
                    filter: filters
                        ? Object.entries(filters)
                              .filter(([key, values]) => values)
                              .map(([key, values]) => `${key}=${values.join(",")}`)
                        : null
                }
            }
        },
        keepPreviousData: true,
        ...queryProps
    });

    useEffect(() => setPage(1), [request, setPage]);

    const tableRef = useRef(null);

    const extractedData = useMemo(() => (data ? (isFunction(extractData) ? extractData(data) : data[extractData]) : null), [data, extractData]);

    const extractedCount = useMemo(() => (data ? (isFunction(extractCount) ? extractCount(data) : data[extractCount]) : null), [data, extractCount]);

    return (
        <div ref={tableRef}>
            <Space direction="vertical" size={20} style={{ width: "100%" }}>
                {!hideSearch && (
                    <Row justify="space-between">
                        <Input.Search
                            onSearch={value => {
                                setPage(1);
                                setFilters({ ...filters, search: [value] });
                            }}
                            placeholder={t("t_search")}
                            style={{ width: 350 }}
                            allowClear
                        />
                        {!isLoading && enableManuallyRefresh && (
                            <Tooltip title={t("t_refresh")}>
                                <Button onClick={() => !isRefetching && refetch()} style={{ cursor: isRefetching ? "not-allowed" : "pointer" }}>
                                    <FontAwesomeIcon icon={["fal", "arrows-rotate"]} spin={isRefetching} />
                                </Button>
                            </Tooltip>
                        )}
                    </Row>
                )}
                <Table
                    size="small"
                    dataSource={extractedData}
                    rowKey={rowKey}
                    columns={columns}
                    pagination={{
                        current: page,
                        defaultPageSize: 20,
                        pageSizeOptions: [20, 50, 100],
                        total: extractedCount,
                        showSizeChanger: true,
                        showTotal: (total, range) => <TextField prefix={range.join("-")} value="t_of" suffix={total} />,
                        onShowSizeChange: () => {
                            window.scrollTo({
                                top: 0,
                                left: tableRef.current.offsetTop,
                                behavior: "smooth"
                            });
                        }
                    }}
                    onExpand={(expanded, _) => {
                        if (!expanded) {
                            // Workaround to fix double scroll bars when collapsing a row
                            window.scrollTo({
                                top: document.documentElement.scrollTop + 0.01,
                                behavior: "smooth"
                            });
                        }
                    }}
                    onChange={(pagination, newFilters, sorter) => {
                        setPage(pagination.current);
                        setPageSize(pagination.pageSize);

                        setFilters({ ...filters, ...newFilters });

                        if (!isEmpty(sorter)) {
                            setOrderBy(sorter.order ? sorter.column?.sortFields ?? [sorter.field] : null);
                            setOrder(sorter.order);
                        }
                    }}
                    isLoading={isLoading}
                    isError={isError}
                    isUpdating={isRefetching}
                    empty={empty}
                    style={{ minHeight: 885 }}
                    {...props}
                />
            </Space>
        </div>
    );
};

PaginatedTableWidget.propTypes = {
    queryKey: PropTypes.string.isRequired,
    request: request.isRequired,
    queryProps: PropTypes.object,
    extractData: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
    extractCount: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    rowKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    hideSearch: PropTypes.bool,
    empty: PropTypes.shape({
        title: PropTypes.string,
        subTitle: PropTypes.string
    }),
    enableManuallyRefresh: PropTypes.bool
};

export default PaginatedTableWidget;
