import { Row, Col } from "antd";
import React, { useMemo } from "react";

import { Card } from "misc/card";
import { Result } from "misc/results";
import { useParams } from "misc/hooks";
import { clientUrls, datalayerUrls } from "misc/urls";
import { Selector, MultiSelector } from "misc/selectors";
import { CddaFleetTable } from "misc/widgets/consumers";
import { useSubfleets } from "misc/api/fleetconfiguration";

import ModuleSettings from "modules/ModuleSettings";
import { TABLE_VIEW_ITEMS } from "modules/ModuleSettings";

import SideHeader from "navigation/SideHeader";

const VIEW_ITEMS = [
    ...TABLE_VIEW_ITEMS,
    {
        name: "cddaCategories",
        label: "t_cdda_categories",
        render: () => (
            <MultiSelector
                options={[
                    { value: "charging", label: "t_cdda_category_charging" },
                    { value: "driving", label: "t_cdda_category_driving" },
                    { value: "delivery", label: "t_cdda_category_delivery" },
                    { value: "defectTickets", label: "t_cdda_category_defect_tickets" },
                    { value: "driversLogEntry", label: "t_cdda_category_drivers_log_entry" },
                    { value: "errors", label: "t_cdda_category_errors" }
                ]}
                maxTagCount={1}
            />
        )
    },
    {
        name: "displayedDays",
        label: "t_cdda_displayed_days",
        render: () => (
            <Selector
                options={[
                    { value: 7, label: "t_days_7" },
                    { value: 10, label: "t_days_10" },
                    { value: 14, label: "t_days_14" }
                ]}
            />
        )
    },
    {
        name: "dateOrder",
        label: "t_cdda_date_order",
        render: () => (
            <Selector
                options={[
                    { value: "asc", label: "t_ascend" },
                    { value: "desc", label: "t_descend" }
                ]}
            />
        )
    }
];

const CddaComponent = () => {
    const { subfleets } = useSubfleets({ suspense: true });

    const { params, setParams } = useParams({
        options: [
            { name: "fleet", persist: "global", allowed: value => subfleets.find(fleet => fleet.subfleetId === value) },
            { name: "columns", defaultValue: ["vin", "licensePlate"], persist: "site" },
            { name: "fixedColumn", defaultValue: "vin", persist: "site" },
            { name: "cddaCategories", defaultValue: ["all"], persist: "site" },
            { name: "displayedDays", defaultValue: "7", persist: "site" },
            { name: "dateOrder", defaultValue: "desc", persist: "site" }
        ]
    });

    const fleet = useMemo(() => subfleets.find(fleet => fleet.subfleetId === params.fleet), [params.fleet, subfleets]);

    const exportOptions = useMemo(
        () => ({
            label: "t_cdda",
            columns: [
                { value: "vin", label: "t_vin" },
                { value: "licensePlate", label: "t_license_plate" },
                { value: "organisationalUnit", label: "t_organisational_unit" },
                { value: "chargingStation", label: "t_charging_station" },
                { value: "manufacturer", label: "t_manufacturer" },
                { value: "model", label: "t_model" },
                { value: "battery", label: "t_battery" },
                { value: "telematicControlUnit", label: "t_telematic_control_unit" },
                { value: "lastSignOfLifeTimestamp", label: "t_last_sign_of_life_timestamp" },
                { value: "lastSignOfLifeSignal", label: "t_last_sign_of_life_signal" },
                { value: "date", label: "t_date" },
                { value: "mileageStart", label: "t_cdda_mileage_start" },
                { value: "mileageEnd", label: "t_cdda_mileage_end" },
                { value: "distance", label: "t_cdda_distance" },
                { value: "stops", label: "t_cdda_stops" },
                { value: "doorOpenCount", label: "t_cdda_door_open_count" },
                { value: "ignitionCount", label: "t_cdda_ignition_count" },
                { value: "socStart", label: "t_cdda_soc_start" },
                { value: "socEnd", label: "t_cdda_soc_end" },
                { value: "socConsumption", label: "t_cdda_soc_consumption" },
                { value: "energyConsumption", label: "t_cdda_energy_consumption_total" },
                { value: "departureTime", label: "t_cdda_departure_time_llw" },
                // { value: "preconditioningReceived", label: "t_preconditioning" },
                // { value: "preconditioningTimestamp", label: "t_preconditioning_timestamp" },
                { value: "socCharge", label: "t_cdda_soc_charge" },
                { value: "chargeCompleted", label: "t_cdda_charge_completed" },
                { value: "chargeCompletedTimestamp", label: "t_cdda_charge_completed_timestamp" },
                { value: "organisationalUnitStart", label: "t_cdda_organisational_unit" },
                { value: "distanceToOrganisationalUnitStart", label: "t_cdda_distance_to_organisational_unit_start" },
                { value: "distanceToOrganisationalUnitEnd", label: "t_cdda_distance_to_organisational_unit_end" },
                { value: "organisationalUnitLlwStart", label: "t_cdda_organisational_unit_llw" },
                { value: "distanceToOrganisationalUnitLlwStart", label: "t_cdda_distance_to_organisational_unit_llw_start" },
                { value: "distanceToOrganisationalUnitLlwEnd", label: "t_cdda_distance_to_organisational_unit_llw_start" },
                { value: "telematicControlUnitStart", label: "t_cdda_telematic_control_unit" },
                { value: "connectivity", label: "t_cdda_connectivity" },
                { value: "temperatureAmbientMax", label: "t_cdda_temperature_ambient_max" },
                { value: "temperatureAmbientMin", label: "t_cdda_temperature_ambient_min" },
                { value: "driversLogEntry", label: "t_cdda_drivers_log_entry" },
                { value: "errorsCount", label: "t_cdda_errors_count" },
                { value: "defectTicketsCount", label: "t_cdda_defect_tickets_count" }
            ],
            request: {
                method: "GET",
                url: datalayerUrls.fleet.widgets.list(),
                params: { type: "vehiclesHistoryExport" },
                filter: {
                    hierarchy: fleet?.organisationalUnits,
                    vehicles: fleet?.vehicles
                }
            }
        }),
        [fleet]
    );

    return (
        <SideHeader>
            <Row gutter={[10, 10]}>
                <Col span={24}>
                    <ModuleSettings
                        title="t_cdda"
                        values={{ ...params, range: fleet?.parameters.cddaRange }}
                        fleetOptions={{ redirectUrl: clientUrls.modules.cdda.fleetConfiguration() }}
                        viewOptions={{ items: VIEW_ITEMS }}
                        exportOptions={{ export: exportOptions }}
                        onChange={values => setParams({ ...params, ...values })}
                    />
                </Col>
                {fleet ? (
                    <Col span={24}>
                        <CddaFleetTable
                            filter={{ hierarchy: fleet.organisationalUnits, vehicles: fleet.vehicles }}
                            view={{
                                columns: params.columns,
                                fixedColumn: params.fixedColumn,
                                cddaCategories: params.cddaCategories,
                                displayedDays: params.displayedDays,
                                dateOrder: params.dateOrder
                            }}
                            params={{ ...fleet.parameters }}
                        />
                    </Col>
                ) : (
                    <Col span={24}>
                        <Card>
                            <Result type="noFleetSelected" url={clientUrls.modules.cdda.fleetConfiguration()} />
                        </Card>
                    </Col>
                )}
            </Row>
        </SideHeader>
    );
};

export default CddaComponent;
