import React from "react";
import { Select } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { TextField } from "misc/fields";
import { useErrors } from "misc/api/datalayer";

const ErrorSelector = ({ defaultValue, value, onChange, allowClear = true }) => {
    const [t] = useTranslation();

    const { errors } = useErrors();

    return (
        <Select
            mode="multiple"
            placeholder={t("t_select_error")}
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            maxTagCount={3}
            style={{ width: "100%" }}
            allowClear={allowClear}
            filterOption={(input, option) => {
                return option.title.toLowerCase().includes(input.toLowerCase());
            }}
        >
            {errors.map(error => (
                <Select.Option title={t(`t_error_${error.errorId}`)} key={error.errorId} value={error.errorId.toString()}>
                    <TextField value={`t_error_${error.errorId}`} />
                </Select.Option>
            ))}
        </Select>
    );
};

ErrorSelector.propTypes = {
    defaultValue: PropTypes.arrayOf(PropTypes.string),
    value: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
    allowClear: PropTypes.bool
};

export default ErrorSelector;
