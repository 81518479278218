import dayjs from "dayjs";
import moment from "moment";
import { Row, Col } from "antd";
import React, { useMemo } from "react";

import { Card } from "misc/card";
import { Result } from "misc/results";
import { useParams } from "misc/hooks";
import { RangeSelector } from "misc/selectors";
import { DriversLog } from "misc/widgets/consumers";
import { clientUrls, datalayerUrls } from "misc/urls";
import { useSubfleets } from "misc/api/fleetconfiguration";

import SideHeader from "navigation/SideHeader";

import ModuleSettings from "modules/ModuleSettings";
import { TABLE_VIEW_ITEMS } from "modules/ModuleSettings";

const VIEW_ITEMS = [...TABLE_VIEW_ITEMS];

const EXPORT_COLUMNS = [
    { value: "vin", label: "t_vin" },
    { value: "licensePlate", label: "t_license_plate" },
    { value: "organisationalUnit", label: "t_organisational_unit" },
    { value: "chargingStation", label: "t_charging_station" },
    { value: "manufacturer", label: "t_manufacturer" },
    { value: "model", label: "t_model" },
    { value: "battery", label: "t_battery" },
    { value: "telematicControlUnit", label: "t_telematic_control_unit" },
    { value: "lastSignOfLifeTimestamp", label: "t_last_sign_of_life_timestamp" },
    { value: "lastSignOfLifeSignal", label: "t_last_sign_of_life_signal" },
    { value: "timestampStart", label: "t_drivers_log_timestamp_start", render: record => dayjs.unix(record).format() },
    { value: "timestampEnd", label: "t_drivers_log_timestamp_end", render: record => dayjs.unix(record).format() },
    { value: "mileageStart", label: "t_drivers_log_mileage_start" },
    { value: "mileageEnd", label: "t_drivers_log_mileage_end" },
    { value: "distance", label: "t_drivers_log_distance" },
    { value: "stops", label: "t_drivers_log_stops" },
    { value: "doorOpenCount", label: "t_drivers_log_door_open_count" },
    { value: "socStart", label: "t_drivers_log_soc_start" },
    { value: "socEnd", label: "t_drivers_log_soc_end" },
    { value: "energyConsumed", label: "t_drivers_log_energy_consumed_total" }
];

const DriversLogFleetView = () => {
    const { subfleets } = useSubfleets({ suspense: true });

    const { params, setParams } = useParams({
        options: [
            { name: "fleet", persist: "global", allowed: value => subfleets.find(fleet => fleet.subfleetId === value) },
            { name: "range", defaultValue: [moment().subtract("days", 14).format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")] },
            { name: "columns", defaultValue: ["vin", "licensePlate"], persist: "site" },
            { name: "fixedColumn", defaultValue: "vin", persist: "site" }
        ]
    });

    const fleet = useMemo(() => subfleets.find(fleet => fleet.subfleetId === params.fleet), [params.fleet, subfleets]);

    const exportOptions = useMemo(
        () => ({
            label: "t_drivers_log",
            columns: EXPORT_COLUMNS,
            request: {
                method: "GET",
                url: datalayerUrls.fleet.widgets.list(),
                params: { type: "driversLogExport" },
                filter: {
                    hierarchy: fleet?.organisationalUnits,
                    vehicles: fleet?.vehicles
                }
            }
        }),
        [fleet]
    );

    return (
        <Row gutter={[10, 10]}>
            <Col span={24}>
                <ModuleSettings
                    values={{ fleet: params.fleet, columns: params.columns, fixedColumn: params.fixedColumn }}
                    fleetOptions={{ redirectUrl: clientUrls.modules.vlv.fleetConfiguration() }}
                    viewOptions={{ items: VIEW_ITEMS }}
                    exportOptions={{ export: exportOptions }}
                    onChange={values => setParams({ ...params, ...values })}
                />
            </Col>

            {fleet ? (
                <Col span={24}>
                    <Card
                        title="t_vlv_drivers_log"
                        extra={
                            <RangeSelector
                                value={params.range}
                                onChange={range => setParams({ ...params, range })}
                                showTime={false}
                                ranges={["lastWeek", "lastTwoWeeks", "lastFourWeeks"]}
                            />
                        }
                    >
                        <DriversLog
                            filter={{ hierarchy: fleet.organisationalUnits, vehicles: fleet.vehicles, range: params.range }}
                            view={{ columns: params.columns, fixedColumn: params.fixedColumn }}
                        />
                    </Card>
                </Col>
            ) : (
                <Col span={24}>
                    <Card height={500}>
                        <Result type="noFleetSelected" url={clientUrls.modules.vlv.fleetConfiguration()} />
                    </Card>
                </Col>
            )}
        </Row>
    );
};

const DriversLogVehicleView = () => {
    const { params, setParams } = useParams({
        options: [
            { name: "vehicle", persist: "site" },
            { name: "columns", defaultValue: ["vin", "licensePlate"], persist: "site" },
            { name: "fixedColumn", defaultValue: "vin", persist: "site" },
            { name: "range", defaultValue: [moment().subtract("days", 14).format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")] }
        ]
    });

    const vehicle = useMemo(() => params.vehicle, [params.vehicle]);

    const exportOptions = useMemo(
        () => ({
            label: "t_drivers_log",
            columns: EXPORT_COLUMNS,
            request: {
                method: "GET",
                url: datalayerUrls.fleet.widgets.list(),
                params: { type: "driversLogExport" },
                filter: {
                    vehicles: [vehicle]
                }
            }
        }),
        [vehicle]
    );
    return (
        <Row gutter={[10, 10]}>
            <Col span={24}>
                <ModuleSettings
                    values={params}
                    viewOptions={{ items: VIEW_ITEMS }}
                    exportOptions={{ export: exportOptions }}
                    onChange={values => setParams({ ...params, ...values })}
                    mode="vehicle"
                />
            </Col>

            {vehicle ? (
                <Col span={24}>
                    <Card
                        title="t_vlv_drivers_log"
                        extra={
                            <RangeSelector
                                value={params.range}
                                onChange={range => setParams({ ...params, range })}
                                showTime={false}
                                ranges={["lastWeek", "lastTwoWeeks", "lastFourWeeks"]}
                            />
                        }
                    >
                        <DriversLog
                            filter={{ vehicles: [vehicle], range: params.range }}
                            view={{ columns: params.columns, fixedColumn: params.fixedColumn }}
                            hideSearch
                        />
                    </Card>
                </Col>
            ) : (
                <Col span={24}>
                    <Card height={500}>
                        <Result type="noVehicleSelected" />
                    </Card>
                </Col>
            )}
        </Row>
    );
};

const VlvDriversLogComponent = () => {
    const { params, setParams } = useParams({
        options: [{ name: "view", defaultValue: "fleet-view", persist: "site" }]
    });

    return (
        <SideHeader
            value={params.view}
            options={[
                { value: "fleet-view", label: "t_view_fleet", render: () => <DriversLogFleetView /> },
                { value: "vehicle-view", label: "t_view_vehicle", render: () => <DriversLogVehicleView /> }
            ]}
            onChange={view => setParams({ ...params, view: view })}
        />
    );
};

export default VlvDriversLogComponent;
