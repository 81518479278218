import moment from "moment";
import PropTypes from "prop-types";

import { useSubscription } from "misc/api/keycloakApi";

const CheckExpiration = ({ fallback = null, children }) => {
    const { subscription } = useSubscription({ suspense: true });

    const expiration = subscription.attributes?.find(({ name }) => name === "expiration");
    const isNotExpired = expiration && (expiration.value === "unlimited" || moment(expiration.value).isAfter(moment.now()));

    return isNotExpired ? children : fallback;
};

CheckExpiration.propTypes = {
    allowed: PropTypes.arrayOf(PropTypes.string),
    fallback: PropTypes.node
};

export default CheckExpiration;
