import dayjs from "dayjs";
import { pick } from "lodash";
import { useTranslation } from "react-i18next";
import React, { useMemo, useState } from "react";
import { Space, Tooltip, Row, Col, Divider } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Card } from "misc/card";
import { datalayerUrls } from "misc/urls";
import { WeekSelector } from "misc/selectors";
import { getWeeksFromRange } from "misc/helpfunctions";
import { VehiclesTableTemplate } from "misc/widgets/consumers";
import { TextField, NumberField, DateField } from "misc/fields";

import { stsColors } from "styles";

const transpond = (data, transpondIndex = "date", childrenColumnName = "children", columns = [], rows = []) => {
    const transpondedData = data.map(element => ({
        ...element,
        ...columns.reduce(
            (previous, current) => ({
                ...previous,
                [current.dataIndex]: {
                    ...pick(
                        element[childrenColumnName].find(item => item[transpondIndex] === current.dataIndex),
                        current.dataKey
                    ),
                    render: current.render
                }
            }),
            {}
        ),
        children: rows.map(row => ({
            title: row.title,
            ...columns.reduce(
                (previous, current) => ({
                    ...previous,
                    [current.dataIndex]: {
                        ...pick(
                            element[childrenColumnName].find(item => item[transpondIndex] === current.dataIndex),
                            row.dataKey
                        ),
                        render: row.render
                    }
                }),
                {}
            ),
            ...row
        }))
    }));

    return transpondedData;
};

const ColumnTitle = ({ start, end, children }) => {
    const [t] = useTranslation();

    return (
        <Row justify="center" align="middle" gutter={[0, 10]} style={{ textAlign: "center" }}>
            <Col span={24}>
                <Space direction="vertical" size={2}>
                    {children}
                    <Space direction="horizontal">
                        <DateField value={start} />
                        <TextField value="-" />
                        <DateField value={end} />
                    </Space>
                </Space>
            </Col>

            <Col span={4} style={{ textAlign: "center" }}>
                <Tooltip title={t("Netzseitig entnommene Energie")}>
                    <FontAwesomeIcon icon={["fas", "charging-station"]} color={stsColors.black1} />
                </Tooltip>
            </Col>
            <Col span={1}>
                <Divider type="vertical" />
            </Col>
            <Col span={4} style={{ textAlign: "center" }}>
                <Tooltip title={t("Geladene Energie")}>
                    <FontAwesomeIcon icon={["fas", "car-battery"]} color={stsColors.black1} />
                </Tooltip>
            </Col>
            <Col span={1}>
                <Divider type="vertical" />
            </Col>
            <Col span={4} style={{ textAlign: "center" }}>
                <Tooltip title={t("Vebrauchte Energie")}>
                    <FontAwesomeIcon icon={["fas", "truck-bolt"]} color={stsColors.black1} />
                </Tooltip>
            </Col>
        </Row>
    );
};

const EnergyMonitorVehiclesView = ({ filter, view = { dateOrder: "desc", displayedWeeks: 4 }, queryProps }) => {
    const [selectedWeek, setSelectedWeek] = useState(dayjs().startOf("week"));

    const weeks = useMemo(() => {
        const weeks = getWeeksFromRange([dayjs(selectedWeek).subtract(view.displayedWeeks - 1, "week"), dayjs(selectedWeek)]);

        if (view.dateOrder === "desc") return weeks.sort((a, b) => b.unix() - a.unix());

        return weeks.sort((a, b) => a.unix() - b.unix());
    }, [selectedWeek, view.dateOrder, view.displayedWeeks]);

    const columns = useMemo(
        () => [
            {
                title: (
                    <ColumnTitle start={dayjs.min(weeks).startOf("week")} end={dayjs.max(weeks).endOf("week")}>
                        <TextField value="t_energy_monitor_total_range" />
                    </ColumnTitle>
                ),
                dataIndex: "total",
                render: text =>
                    text?.render ? (
                        <span style={{ textAlign: "center" }}>{text.render(text)}</span>
                    ) : (
                        <span style={{ textAlign: "center" }}>{text}</span>
                    ),
                onCell: () => ({ style: { borderLeft: "3px solid #f0f0f0" } }),
                onHeaderCell: () => ({ style: { borderLeft: "3px solid #f0f0f0" } }),
                width: 580
            },
            ...weeks.map(week => {
                return {
                    title: (
                        <ColumnTitle start={dayjs(week).startOf("week")} end={dayjs(week).endOf("week")}>
                            <TextField value="t_week" suffix={week.week()} />
                        </ColumnTitle>
                    ),
                    dataIndex: dayjs(week).startOf("week").format("YYYY-MM-DD"),
                    render: text =>
                        text?.render ? (
                            <span style={{ textAlign: "center" }}>{text.render(text)}</span>
                        ) : (
                            <span style={{ textAlign: "center" }}>{text}</span>
                        ),
                    onCell: () => ({ style: { borderLeft: "3px solid #f0f0f0" } }),
                    onHeaderCell: () => ({ style: { borderLeft: "3px solid #f0f0f0" } }),
                    width: 580
                };
            })
        ],
        [weeks]
    );

    const extractData = data => {
        const columns = [
            {
                dataIndex: "total",
                dataKey: ["energyChargedAC", "energyChargedDC", "energyConsumed"],
                render: record => (
                    <Row justify="center" align="middle" style={{ textAlign: "center" }}>
                        <Col span={4} style={{ textAlign: "center" }}>
                            <NumberField value={record.energyChargedAC} decimals={2} suffix="kWh" />
                        </Col>
                        <Col span={1}>
                            <Divider type="vertical" />
                        </Col>
                        <Col span={4} style={{ textAlign: "center" }}>
                            <NumberField value={record.energyChargedDC} decimals={2} suffix="kWh" />
                        </Col>
                        <Col span={1}>
                            <Divider type="vertical" />
                        </Col>
                        <Col span={4} style={{ textAlign: "center" }}>
                            <NumberField value={record.energyConsumed} decimals={2} suffix="kWh" />
                        </Col>
                    </Row>
                )
            },
            ...weeks.map(week => ({
                dataIndex: dayjs(week).startOf("week").format("YYYY-MM-DD"),
                dataKey: ["energyChargedAC", "energyChargedDC", "energyConsumed"],
                render: record => (
                    <Row justify="center" align="middle" style={{ textAlign: "center" }}>
                        <Col span={4} style={{ textAlign: "center" }}>
                            <NumberField value={record.energyChargedAC} decimals={2} suffix="kWh" />
                        </Col>
                        <Col span={1}>
                            <Divider type="vertical" />
                        </Col>
                        <Col span={4} style={{ textAlign: "center" }}>
                            <NumberField value={record.energyChargedDC} decimals={2} suffix="kWh" />
                        </Col>
                        <Col span={1}>
                            <Divider type="vertical" />
                        </Col>
                        <Col span={4} style={{ textAlign: "center" }}>
                            <NumberField value={record.energyConsumed} decimals={2} suffix="kWh" />
                        </Col>
                    </Row>
                )
            }))
        ];

        const rows = [
            {
                title: "t_energy_monitor_mileage_start",
                dataKey: "mileageStart",
                render: record => <NumberField value={record.mileageStart} decimals={1} suffix="km" />,
                enumerate: false
            },
            {
                title: "t_energy_monitor_mileage_end",
                dataKey: "mileageEnd",
                render: record => <NumberField value={record.mileageEnd} decimals={1} suffix="km" />,
                enumerate: false
            },
            {
                title: "t_energy_monitor_distance",
                dataKey: "distance",
                render: record => <NumberField value={record.distance} decimals={1} suffix="km" />,
                enumerate: false
            },
            {
                title: "t_energy_monitor_soc_charged",
                dataKey: "socCharged",
                render: record => <NumberField value={record.socCharged} suffix="%" />,
                enumerate: false
            },
            {
                title: "t_energy_monitor_soc_consumed",
                dataKey: "socConsumed",
                render: record => <NumberField value={record.socConsumed} suffix="%" />,
                enumerate: false
            },
            {
                title: "t_energy_monitor_energy_charged_ac",
                dataKey: ["energyChargedAC", "distance"],
                render: record => (
                    <div>
                        <Space>
                            <NumberField value={record.energyChargedAC} decimals={2} suffix="kWh" />
                            <TextField value="/" />
                            <NumberField value={(100 * record.energyChargedAC) / record.distance} decimals={2} suffix="kWh" />
                        </Space>
                    </div>
                ),
                enumerate: false
            },
            {
                title: "t_energy_monitor_energy_charged_dc",
                dataKey: ["energyChargedDC", "distance"],
                render: record => (
                    <div>
                        <Space>
                            <NumberField value={record.energyChargedDC} decimals={2} suffix="kWh" />
                            <TextField value="/" />
                            <NumberField value={(100 * record.energyChargedDC) / record.distance} decimals={2} suffix="kWh" />
                        </Space>
                    </div>
                ),
                enumerate: false
            },
            {
                title: "t_energy_monitor_efficiency_charging",
                dataKey: ["energyChargedAC", "energyChargedDC"],
                render: record => <NumberField value={(100 * record.energyChargedDC) / record.energyChargedAC} decimals={1} suffix="%" />,
                enumerate: false
            },
            {
                title: "t_energy_monitor_energy_consumed",
                dataKey: ["energyConsumed", "distance"],
                render: record => (
                    <div>
                        <Space>
                            <NumberField value={record.energyConsumed} decimals={2} suffix="kWh" />
                            <TextField value="/" />
                            <NumberField value={(100 * record.energyConsumed) / record.distance} decimals={2} suffix="kWh" />
                        </Space>
                    </div>
                ),
                enumerate: false
            },
            {
                title: "t_energy_monitor_temperature_range",
                dataKey: ["temperatureAmbientMin", "temperatureAmbientMax"],
                render: record =>
                    record.temperatureAmbientMin && record.temperatureAmbientMax ? (
                        <div>
                            <Space>
                                <NumberField value={record.temperatureAmbientMin} suffix="°C" />
                                <TextField value="/" />
                                <NumberField value={record.temperatureAmbientMax} suffix="°C" />
                            </Space>
                        </div>
                    ) : (
                        <TextField value={null} />
                    ),
                enumerate: false
            },
            {
                title: "t_energy_monitor_temperature_median",
                dataKey: ["temperatureAmbientAvg"],
                render: record => <NumberField value={record.temperatureAmbientAvg} suffix="°C" />,
                enumerate: false
            },
            {
                title: "t_energy_monitor_connectivity",
                dataKey: ["connectivity"],
                render: record => <NumberField value={record.connectivity} decimals={1} suffix="%" />,
                enumerate: false
            },
            {
                title: "t_energy_monitor_organisational_unit",
                dataKey: ["organisationalUnitFirst", "organisationalUnitLast"],
                render: record =>
                    record.organisationalUnitFirst && record.organisationalUnitLast ? (
                        <div>
                            <Space>
                                <TextField value={record.organisationalUnitFirst} />
                                <TextField value="/" />
                                <TextField value={record.organisationalUnitLast} />
                            </Space>
                        </div>
                    ) : (
                        <TextField value={null} />
                    ),
                enumerate: false
            },
            {
                title: "t_energy_monitor_charging_station",
                dataKey: ["chargingStationFirst", "chargingStationLast"],
                render: record =>
                    record.chargingStationFirst && record.chargingStationLast ? (
                        <div>
                            <Space>
                                <TextField value={record.chargingStationFirst} />
                                <TextField value="/" />
                                <TextField value={record.chargingStationLast} />
                            </Space>
                        </div>
                    ) : (
                        <TextField value={null} />
                    ),
                enumerate: false
            },
            {
                title: "t_energy_monitor_telematic_control_unit",
                dataKey: ["telematicControlUnitFirst", "telematicControlUnitLast"],
                render: record =>
                    record.telematicControlUnitFirst && record.telematicControlUnitLast ? (
                        <div>
                            <Space>
                                <TextField value={record.telematicControlUnitFirst} />
                                <TextField value="/" />
                                <TextField value={record.telematicControlUnitLast} />
                            </Space>
                        </div>
                    ) : (
                        <TextField value={null} />
                    ),
                enumerate: false
            }
        ];

        return transpond(data.data, "week", "data", columns, rows);
    };

    return (
        <Card
            title="t_energy_monitor"
            height={700}
            stretchHeight
            extra={<WeekSelector value={selectedWeek} onChange={setSelectedWeek} disabledDate={date => date.isAfter(dayjs())} allowClear={false} />}
        >
            <VehiclesTableTemplate
                queryKey="energyMonitor"
                request={{
                    method: "GET",
                    url: datalayerUrls.fleet.widgets.list(),
                    params: { type: "energyMonitor" },
                    filter: {
                        ...filter,
                        range: [dayjs.min(weeks).startOf("week").format("YYYY-MM-DD"), dayjs.max(weeks).endOf("week").format("YYYY-MM-DD")]
                    }
                }}
                view={view}
                extractData={extractData}
                extractCount={data => data.statistics.count}
                rowKey="vin"
                columns={columns}
                tableLayout="fixed"
                scroll={{ x: "100%" }}
                queryProps={queryProps}
                sticky
            />
        </Card>
    );
};

export default EnergyMonitorVehiclesView;
