import PropTypes from "prop-types";
import React, { useMemo } from "react";

import { Card } from "misc/card";
import { TextField } from "misc/fields";
import { datalayerUrls } from "misc/urls";
import { VehiclesTableTemplate } from "misc/widgets/consumers";

import { ErrorsTable } from "misc/widgets/consumers";

const DEFAULT_ERRORS = [1, 2, 3, 4, 5, 101, 102, 103];

const ErrorsPerVehicle = ({ filter, view, params, queryProps, ...props }) => {
    const errors = useMemo(() => (filter.errors?.length ? filter.errors : DEFAULT_ERRORS), [filter]);

    const columns = useMemo(
        () => [
            // {
            //     key: "errors",
            //     dataIndex: "errors",
            //     title: () => <TextField value="t_errors_count" />,
            //     render: text => <TextField value={text} />,
            //     sorter: true,
            //     width: errors.length > 3 ? 190 : null
            // },
            ...errors
                .filter(error => view.engineeringErrors.includes("all") || view.engineeringErrors.includes(error))
                .map(error => ({
                    key: `error${error}`,
                    dataIndex: `error${error}`,
                    title: () => (
                        <TextField
                            value={
                                {
                                    1: "t_errors_error_type_1",
                                    2: "t_errors_error_type_2",
                                    3: "t_errors_error_type_3",
                                    4: "t_errors_error_type_4",
                                    5: "t_errors_error_type_5",
                                    6: "t_errors_error_type_6",
                                    101: "t_errors_error_type_101",
                                    102: "t_errors_error_type_102",
                                    103: "t_errors_error_type_103"
                                }[error]
                            }
                        />
                    ),
                    render: text => <TextField value={text} />,
                    sorter: true,
                    width: errors.length > 3 ? 190 : null
                }))
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [filter, errors]
    );

    return (
        <VehiclesTableTemplate
            queryKey="errorsPerVehicle"
            request={{
                method: "GET",
                url: datalayerUrls.fleet.widgets.list(),
                params: { type: "errorsPerVehicle" },
                filter: filter
            }}
            view={view}
            extractData="data"
            extractCount={data => data.statistics?.count}
            columns={columns}
            rowKey="vin"
            scroll={{ x: "100%" }}
            empty={{ title: "t_no_errors", subTitle: "t_no_errors_existing" }}
            queryProps={queryProps}
            expandedRowRender={({ vin }) => {
                return (
                    <Card>
                        <ErrorsTable
                            filter={{ ...filter, vehicles: [vin], hierarchy: [] }}
                            view={{ columns: ["vin"], fixedColumn: "vin" }}
                            hideSearch
                        />
                    </Card>
                );
            }}
            {...props}
        />
    );
};

ErrorsPerVehicle.propTypes = {
    filter: PropTypes.object,
    view: PropTypes.object,
    params: PropTypes.object,
    queryProps: PropTypes.object
};

export default ErrorsPerVehicle;
