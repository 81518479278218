import { Row, Col } from "antd";
import React, { useMemo } from "react";

import { Card } from "misc/card";
import { Result } from "misc/results";
import { useParams } from "misc/hooks";
import { VehicleSelector } from "misc/selectors";
import { TextField, NumberField } from "misc/fields";
import { clientUrls, datalayerUrls } from "misc/urls";
import { FleetStatusTable } from "misc/widgets/consumers";
import { CheckAppPermissions } from "misc/authentication";
import { useSubfleets } from "misc/api/fleetconfiguration";
import { StatusBoxWidget, PositionMapWidget } from "misc/widgets/consumers";

import ModuleSettings from "modules/ModuleSettings";

import SideHeader from "navigation/SideHeader";

import { TABLE_VIEW_ITEMS } from "modules/ModuleSettings";

const REFETCH_INTERVAL = 30 * 60 * 1000; // 30 minutes

const LiveStatusFleetView = () => {
    const VIEW_ITEMS = [...TABLE_VIEW_ITEMS];

    const { subfleets } = useSubfleets({ suspense: true });

    const { params, setParams } = useParams({
        options: [
            { name: "fleet", persist: "global", allowed: value => subfleets.find(fleet => fleet.subfleetId === value) },
            { name: "columns", defaultValue: ["vin", "licensePlate"], persist: "site" },
            { name: "fixedColumn", defaultValue: "vin", persist: "site" }
        ]
    });

    const fleet = useMemo(() => subfleets.find(fleet => fleet.subfleetId === params.fleet), [params.fleet, subfleets]);

    const exportOptions = useMemo(
        () => ({
            label: "t_fleet_status",
            columns: [
                { value: "vin", label: "t_vin" },
                { value: "licensePlate", label: "t_license_plate" },
                { value: "organisationalUnit", label: "t_organisational_unit" },
                { value: "chargingStation", label: "t_charging_station" },
                { value: "manufacturer", label: "t_manufacturer" },
                { value: "model", label: "t_model" },
                { value: "battery", label: "t_battery" },
                { value: "telematicControlUnit", label: "t_telematic_control_unit" },
                { value: "lastSignOfLifeTimestamp", label: "t_last_sign_of_life_timestamp" },
                { value: "lastSignOfLifeSignal", label: "t_last_sign_of_life_signal" },
                { value: "mileage", label: "t_fleet_status_mileage" },
                { value: "soc", label: "t_fleet_status_soc" },
                { value: "socTimestamp", label: "t_fleet_status_soc_timestamp" },
                { value: "chargeplugStatus", label: "t_fleet_status_chargeplug_status" },
                { value: "chargeplugStatusTimestamp", label: "t_fleet_status_chargeplug_status_timestamp" },
                { value: "chargingCurrent", label: "t_fleet_status_charging_current" },
                { value: "chargingCurrentTimestamp", label: "t_fleet_status_charging_current_timestamp" },
                { value: "ignitionStatus", label: "t_fleet_status_ignition_status" },
                { value: "ignitionStatusTimestamp", label: "t_fleet_status_ignition_status_timestamp" },
                { value: "onlineStatus", label: "t_fleet_status_online_status" },
                { value: "onlineStatusTimestamp", label: "t_fleet_status_online_status_timestamp" }
            ],
            request: {
                method: "GET",
                url: datalayerUrls.fleet.widgets.list(),
                params: { type: "vehicleStatusExport" },
                filter: {
                    hierarchy: fleet?.organisationalUnits,
                    vehicles: fleet?.vehicles
                }
            }
        }),
        [fleet]
    );

    return (
        <Row gutter={[10, 10]}>
            <Col span={24}>
                <ModuleSettings
                    values={{ fleet: params.fleet, columns: params.columns, fixedColumn: params.fixedColumn }}
                    fleetOptions={{ redirectUrl: clientUrls.modules.vlv.fleetConfiguration() }}
                    viewOptions={{ items: VIEW_ITEMS }}
                    exportOptions={{ export: exportOptions, range: false }}
                    onChange={values => setParams({ ...params, ...values })}
                />
            </Col>
            {fleet ? (
                <Col span={24}>
                    <FleetStatusTable
                        filter={{ hierarchy: fleet.organisationalUnits, vehicles: fleet.vehicles }}
                        view={{ columns: params.columns, fixedColumn: params.fixedColumn }}
                    />
                </Col>
            ) : (
                <Col span={24}>
                    <Card height={500}>
                        <Result type="noFleetSelected" url={clientUrls.modules.vlv.fleetConfiguration()} />
                    </Card>
                </Col>
            )}
        </Row>
    );
};

const LiveStatusVehicleView = () => {
    const { params, setParams } = useParams({ options: [{ name: "vehicle", persist: "site" }] });

    return (
        <Row gutter={[10, 10]}>
            <Col span={24}>
                <Card>
                    <VehicleSelector value={params.vehicle} onChange={vehicle => setParams({ ...params, vehicle })} />
                </Card>
            </Col>

            {params.vehicle ? (
                <Col span={24}>
                    <Row gutter={[10, 10]}>
                        <Col md={24} lg={8}>
                            <StatusBoxWidget
                                title="t_soc"
                                request={{
                                    url: datalayerUrls.vehicles.values(),
                                    params: { type: "soc", vin: params.vehicle }
                                }}
                                extractData="value"
                                extractLastUpdate="timestamp"
                                renderValue={value => <NumberField value={value} suffix="%" />}
                                icon={value => {
                                    return value >= 99
                                        ? ["fas", "battery-full"]
                                        : value >= 75
                                        ? ["fas", "battery-three-quarters"]
                                        : value >= 50
                                        ? ["fas", "battery-half"]
                                        : value >= 25
                                        ? ["fas", "battery-quarter"]
                                        : ["fas", "battery-empty"];
                                }}
                                queryProps={{ REFETCH_INTERVAL }}
                            />
                        </Col>
                        <Col md={24} lg={8}>
                            <StatusBoxWidget
                                title="t_chargeplug"
                                request={{
                                    url: datalayerUrls.vehicles.values(),
                                    params: { type: "chargeplugStatus", vin: params.vehicle }
                                }}
                                extractData="value"
                                extractLastUpdate="timestamp"
                                renderValue={value => <TextField value={value === 1 ? "t_connected" : value === 0 ? "t_disconnected" : null} />}
                                icon={["fas", "charging-station"]}
                                queryProps={{ REFETCH_INTERVAL }}
                            />
                        </Col>

                        <Col md={24} lg={8}>
                            <StatusBoxWidget
                                title="t_online_status"
                                request={{
                                    url: datalayerUrls.vehicles.values(),
                                    params: { type: "onlineStatus", vin: params.vehicle }
                                }}
                                renderValue={value => <TextField value={value ? "t_online" : "t_offline"} />}
                                extractData="value"
                                extractLastUpdate="timestamp"
                                icon={["fas", "globe"]}
                                queryProps={{ REFETCH_INTERVAL }}
                            />
                        </Col>

                        <Col md={24} lg={8}>
                            <StatusBoxWidget
                                title="t_organisational_unit_depot"
                                request={{
                                    url: datalayerUrls.vehicles.values(),
                                    params: { type: "organisationalUnit", vin: params.vehicle }
                                }}
                                extractData="value"
                                renderValue={value => <TextField value={value} />}
                                icon={["fas", "warehouse-full"]}
                            />
                        </Col>

                        <Col md={24} lg={8}>
                            <StatusBoxWidget
                                title="t_mileage"
                                request={{
                                    url: datalayerUrls.vehicles.values(),
                                    params: { type: "mileage", vin: params.vehicle }
                                }}
                                renderValue={value => <NumberField value={value} suffix="km" decimals={1} />}
                                extractData="value"
                                extractLastUpdate="timestamp"
                                icon={["fas", "road"]}
                                queryProps={{ REFETCH_INTERVAL }}
                            />
                        </Col>

                        <Col md={24} lg={8}>
                            <StatusBoxWidget
                                title="t_ignition_status"
                                request={{
                                    url: datalayerUrls.vehicles.values(),
                                    params: { type: "ignitionStatus", vin: params.vehicle }
                                }}
                                extractData="value"
                                extractLastUpdate="timestamp"
                                renderValue={value => <TextField value={value === 1 ? "t_on" : value === 0 ? "t_off" : null} />}
                                icon={["fas", "key"]}
                                queryProps={{ REFETCH_INTERVAL }}
                            />
                        </Col>

                        <CheckAppPermissions allowed={["permission_feature_gps-data"]}>
                            <Col md={24} lg={12}>
                                <PositionMapWidget
                                    title="t_current_position_global"
                                    request={{
                                        url: datalayerUrls.vehicles.values(),
                                        params: { type: "position", vin: params.vehicle }
                                    }}
                                    extractData="value"
                                    extractLastUpdate="timestamp"
                                    queryProps={{ REFETCH_INTERVAL }}
                                    zoom={3}
                                    isStatic
                                />
                            </Col>

                            <Col md={24} lg={12}>
                                <PositionMapWidget
                                    title="t_current_position_local"
                                    request={{
                                        url: datalayerUrls.vehicles.values(),
                                        params: { type: "position", vin: params.vehicle }
                                    }}
                                    extractData="value"
                                    extractLastUpdate="timestamp"
                                    queryProps={{ REFETCH_INTERVAL }}
                                />
                            </Col>
                        </CheckAppPermissions>
                    </Row>
                </Col>
            ) : (
                <Col span={24}>
                    <Card height={500}>
                        <Result type="noVehicleSelected" />
                    </Card>
                </Col>
            )}
        </Row>
    );
};

const VlvLiveStatusComponent = () => {
    const { params, setParams } = useParams({
        options: [{ name: "view", defaultValue: "fleet-view" }]
    });

    return (
        <SideHeader
            value={params.view}
            options={[
                { value: "fleet-view", label: "t_view_fleet", render: () => <LiveStatusFleetView /> },
                { value: "vehicle-view", label: "t_view_vehicle", render: () => <LiveStatusVehicleView /> }
            ]}
            onChange={view => setParams({ ...params, view: view })}
        />
    );
};

export default VlvLiveStatusComponent;
