import moment from "moment";
import { Tooltip, Tag } from "antd";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

import { clientUrls } from "misc/urls";
import { buildUrl } from "misc/helpfunctions";
import { PaginatedTableWidget } from "misc/widgets/consumers";
import { DatetimeField, TextField, NumberField } from "misc/fields";

const VehiclesTableTemplate = ({ filter = {}, view = { columns: ["all"], fixedColumn: "vin" }, columns = [], ...props }) => {
    const fixedColumns = useMemo(() => {
        if (view?.fixedColumn) {
            const fields = [
                "vin",
                "licensePlate",
                "organisationalUnit",
                "chargingStation",
                "manufacturer",
                "model",
                "battery",
                "telematicControlUnit",
                "lastSignOfLife"
            ];
            const index = fields.indexOf(view.fixedColumn);
            if (index >= 0) {
                return fields.slice(0, index + 1);
            }
        }
        return [];
    }, [view]);

    const defaultColumns = useMemo(
        () =>
            [
                {
                    dataIndex: "vin",
                    title: <TextField value="t_vin" />,
                    render: (_, record) => {
                        return record.vin ? <TextField value={record.vin} enableCopy /> : <TextField value={record.title} />;
                    },
                    url: text => buildUrl({ path: clientUrls.modules.vlv.vehicleStatus(), queryParameters: { vehicle: text, range: filter.range } }),
                    sorter: true,
                    fixed: fixedColumns?.includes("vin") || false,
                    width: 250
                },

                {
                    dataIndex: "licensePlate",
                    title: <TextField value="t_license_plate" />,
                    render: (text, record) => text && <TextField value={text} enableCopy />,
                    sorter: true,
                    fixed: fixedColumns?.includes("licensePlate") || false,
                    width: 140,
                    hidden: !["licensePlate", "all"].some(item => view.columns.includes(item))
                },
                {
                    dataIndex: "organisationalUnit",
                    title: <TextField value="t_organisational_unit" />,
                    render: (text, record) => text && <TextField value={text} />,
                    sorter: true,
                    fixed: fixedColumns?.includes("organisationalUnit") || false,
                    width: 190,
                    hidden: !["organisationalUnit", "all"].some(item => view.columns.includes(item)),
                    ellipsis: true
                },
                {
                    dataIndex: "chargingStation",
                    title: <TextField value="t_charging_station" />,
                    render: (text, record) => text && <TextField value={text} />,
                    fixed: fixedColumns?.includes("chargingStation") || false,
                    width: 180,
                    hidden: !["chargingStation", "all"].some(item => view.columns.includes(item)),
                    ellipsis: true,
                    sorter: true,
                    filters: [
                        { text: <TextField value="t_charging_station_assigned" inline />, value: true },
                        { text: <TextField value="t_charging_station_unassigned" inline />, value: false }
                    ]
                },
                {
                    dataIndex: "manufacturer",
                    title: <TextField value="t_manufacturers" />,
                    render: (text, record) => text && <TextField value={text} />,
                    sorter: true,
                    filters: [
                        { text: <TextField value="t_manufacturer_streetscooter" inline />, value: "StreetScooter" },
                        { text: <TextField value="t_manufacturer_daimler" inline />, value: "Daimler" },
                        { text: <TextField value="t_manufacturer_ford" inline />, value: "Ford" },
                        { text: <TextField value="t_manufacturer_streetscooter_engineering" inline />, value: "Streetscooter Engineering" },
                        { text: <TextField value="t_manufacturer_volkswagen" inline />, value: "Volkswagen" },
                        { text: <TextField value="t_manufacturer_iveco" inline />, value: "iveco" },
                        { text: <TextField value="t_manufacturer_abt_e_line" inline />, value: "ABT e-Line" },
                        { text: <TextField value="t_manufacturer_bon" inline />, value: "B-ON" },
                        { text: <TextField value="t_manufacturer_mercedes_benz" inline />, value: "Mercedes-Benz" },
                        { text: <TextField value="t_manufacturer_evolution" inline />, value: "eVolution" },
                        { text: <TextField value="t_manufacturer_bmw" inline />, value: "BMW" },
                        { text: <TextField value="t_manufacturer_audi" inline />, value: "Audi" },
                        { text: <TextField value="t_manufacturer_posche_e" inline />, value: "Posche E" }
                    ],
                    fixed: fixedColumns?.includes("manufacturer") || false,
                    width: 170,
                    hidden: !["manufacturer", "all"].some(item => view.columns.includes(item))
                },
                {
                    dataIndex: "model",
                    title: <TextField value="t_model" />,
                    render: (text, record) => text && <TextField value={text} />,
                    sorter: true,
                    fixed: fixedColumns?.includes("model") || false,
                    filters: [
                        { text: <TextField value="t_model_work" inline />, value: "Work" },
                        { text: <TextField value="t_model_work_l" inline />, value: "Work L" },
                        { text: <TextField value="t_model_work_xl" inline />, value: "Work XL" },
                        { text: <TextField value="t_model_evito" inline />, value: "eVito" },
                        { text: <TextField value="t_model_eabt" inline />, value: "eAbt" },
                        { text: <TextField value="t_model_max" inline />, value: "Max" }
                    ],
                    width: 145,
                    hidden: !["model", "all"].some(item => view.columns.includes(item))
                },
                {
                    dataIndex: "battery",
                    title: <TextField value="t_battery" />,
                    render: (text, record) => text && <NumberField value={text} suffix="kWh" decimals={0} />,
                    sorter: true,
                    fixed: fixedColumns?.includes("battery") || false,
                    filters: [
                        { text: <TextField value="t_battery_size_more_than_60kwh" inline />, value: ["76", "78", "68", "60"] },
                        { text: <TextField value="t_battery_size_36_to_43kwh" inline />, value: ["43", "40", "38", "37"] },
                        { text: <TextField value="t_battery_size_30_to_36kwh" inline />, value: ["36", "35", "32", "30"] },
                        { text: <TextField value="t_battery_size_less_than_30kw" inline />, value: ["20", "15", "0"] }
                    ],
                    hidden: !["battery", "all"].some(item => view.columns.includes(item)),
                    width: 145
                },
                {
                    dataIndex: "telematicControlUnit",
                    title: <TextField value="t_telematic_control_unit" />,
                    render: (text, record) => text && <TextField value={text} />,
                    fixed: fixedColumns?.includes("telematicControlUnit") || false,
                    width: 180,
                    hidden: !["telematicControlUnit", "all"].some(item => view.columns.includes(item)),
                    ellipsis: true,
                    sorter: true,
                    filters: [
                        { text: <TextField value="t_c2c" inline />, value: "c2c" },
                        { text: <TextField value="t_tcu" inline />, value: "tcu" },
                        { text: <TextField value="t_others" inline />, value: ["olu"] }
                    ]
                },
                {
                    dataIndex: "lastSignOfLifeTimestamp",
                    title: <TextField value="t_last_sign_of_life" />,
                    render: (text, record) => {
                        return (
                            text && (
                                <Tooltip title={<DatetimeField value={text} suffix={`/ ${record.lastSignOfLifeSignal}`} />}>
                                    <Tag style={{ cursor: "default" }}>{moment(text).fromNow()}</Tag>
                                </Tooltip>
                            )
                        );
                    },
                    sorter: true,
                    fixed: fixedColumns?.includes("lastSignOfLife") || false,
                    hidden: !["lastSignOfLife", "all"].some(item => view.columns.includes(item)),
                    width: 195
                }
            ].filter(item => !item.hidden),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [view, fixedColumns]
    );

    return <PaginatedTableWidget columns={[...defaultColumns, ...columns]} scroll={{ x: "100%" }} sticky {...props} />;
};

VehiclesTableTemplate.propTypes = {
    filter: PropTypes.object,
    view: PropTypes.object,
    columns: PropTypes.array
};

export default VehiclesTableTemplate;
