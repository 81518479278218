import ExcelJS from "exceljs";
import { useCallback, useMemo } from "react";
import fileDownload from "js-file-download";
import { useTranslation } from "react-i18next";

import useExport from "./useExport";

const createWorkbook = (title, data, columns, options = {}) => {
    const workbook = new ExcelJS.Workbook();

    // Bypass sheet name cannot exceed 31 chars when creating
    title = title.length <= 31 ? title : title.substring(0, Math.min(31, title.length));

    const worksheet = workbook.addWorksheet(title);

    worksheet.columns = columns.map(column => ({
        header: column.label,
        key: column.value,
        width: column.width || 20,
        outlineLevel: column.outlineLevel || 0
    }));

    worksheet.addRows(data);

    return workbook;
};

const convertDataToExcel = ({ fileName, data, columns }) => {
    // convert download data to excel
    return createWorkbook(`${fileName}`, data, columns).xlsx.writeBuffer();
};

const convertDataToCsv = ({ fileName, data, columns }) => {
    // convert download data to csv
    return createWorkbook(`${fileName}`, data, columns).csv.writeBuffer();
};

const useExcelCSVExport = ({ onError, onSuccess, mutateOptions = {}, format = "excel" } = {}) => {
    // format can be excel or csv
    const { ...result } = useExport({
        onError,
        onSuccess,
        mutateOptions,
        format: format
    });
    const [t] = useTranslation();
    const { showProgress, percent, isLoading, setIsLoading, downloadZip, download } = result;

    const createExcel = useCallback(
        (data, fileName, columns = [], extractData) => {
            const workbook = createWorkbook(t(fileName), extractData(data), columns);

            setIsLoading(true);

            workbook.xlsx.writeBuffer().then(buffer => {
                fileDownload(buffer, `${t(fileName)}.xlsx`);
            });

            setIsLoading(false);
        },
        [setIsLoading, t]
    );
    const createCSV = useCallback(
        (data, fileName, columns = [], extractData) => {
            const workbook = createWorkbook(t(fileName), extractData(data), columns);

            setIsLoading(true);

            workbook.csv.writeBuffer().then(buffer => {
                fileDownload(buffer, `${t(fileName)}.csv`);
            });

            setIsLoading(false);
        },
        [setIsLoading, t]
    );

    const fetchGroupExcel = useCallback(
        async (zipName, exports = []) => {
            return new Promise(async (resolve, reject) => {
                await downloadZip(zipName, exports, convertDataToExcel);

                return resolve();
            });
        },
        [downloadZip]
    );
    const fetchGroupCSV = useCallback(
        async (zipName, exports = []) => {
            return new Promise(async (resolve, reject) => {
                await downloadZip(zipName, exports, convertDataToCsv);

                return resolve();
            });
        },
        [downloadZip]
    );

    const fetchExcel = useCallback(
        (request, fileName, columns = [], extractData) => {
            download(fileName, request, columns, extractData, convertDataToExcel);
        },
        [download]
    );
    const fetchCSV = useCallback(
        (request, fileName, columns = [], extractData) => {
            download(fileName, request, columns, extractData, convertDataToCsv);
        },
        [download]
    );

    if (isLoading) showProgress({ title: format === "excel" ? `${t("t_export_excel")}` : `${t("t_export_csv")}`, percent: percent });

    return useMemo(
        () => ({ ...result, createExcel, fetchGroupExcel, fetchExcel, createCSV, fetchGroupCSV, fetchCSV }),
        [result, createExcel, fetchGroupExcel, fetchExcel, createCSV, fetchGroupCSV, fetchCSV]
    );
};

export default useExcelCSVExport;
