export const fonts = {
    size10: "0.714em",
    size11: "0.7855em",
    size12: "0.857em",
    size13: "0.929em",
    size14: "1em",
    size15: "1.071em",
    size16: "1.143em",
    size17: "1.214em",
    size18: "1.285em",
    size19: "1.3571em",
    size20: "1.428em",
    size22: "1.571em",
    size24: "1.714em",
    size26: "1.8574em",
    size28: "2em",
    size30: "2.143em",
    size32: "2.286em",
    size34: "2.429em",
    size36: "2.571em",
    size38: "2.7143em",
    size40: "2.857em",
    size70: "5em"
};
