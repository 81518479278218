import { Select } from "antd";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { TextField } from "misc/fields";

const MultiSelector = ({ defaultValue = [], value = [], onChange = () => null, options = [], allSelectable = true, ...props }) => {
    const [t] = useTranslation();

    const [currentValue, setCurrentValue] = useState(isEmpty(value) ? defaultValue : value);

    return (
        <Select
            mode="multiple"
            value={currentValue}
            onChange={value => {
                const newValue = value.includes("all") ? ["all"] : value.length === options.length ? ["all"] : isEmpty(value) ? defaultValue : value;

                setCurrentValue(newValue);
                onChange(newValue);
            }}
            placeholder={t("t_multi_selector_placeholder_default")}
            filterOption={(inputValue, option) => t(option.label).toLowerCase().includes(inputValue.toLowerCase())}
            {...props}
        >
            {allSelectable && (
                <Select.Option key="all" value="all">
                    <TextField value="t_all" />
                </Select.Option>
            )}

            {options.map(option => (
                <Select.Option
                    key={option.value}
                    value={option.value}
                    label={t(option.label)}
                    disabled={option.disabled || currentValue.includes("all")}
                >
                    <TextField value={option.label} />
                </Select.Option>
            ))}
        </Select>
    );
};

MultiSelector.propTypes = {
    defaultValue: PropTypes.array,
    value: PropTypes.array,
    onChange: PropTypes.func,
    options: PropTypes.array,
    allSelectable: PropTypes.bool
};

export default MultiSelector;
