import React from "react";

import { useTranslation } from "react-i18next";
import { Transfer } from "misc/transfer";
import { usePermissions } from "misc/api/keycloakApi";

const MODULES = {
    permission_module_cdda: "t_module_cdda",
    permission_module_cm: "t_module_cm",
    "permission_module_cm-extended": "t_module_cm_extended",
    "permission_module_defect-tickets": "t_module_defect_tickets",
    "permission_module_energy-monitor": "t_module_energy_monitor",
    permission_module_engineering: "t_module_engineering",
    "permission_module_engineering-extended": "t_module_engineering_extended",
    "permission_module_user-management": "t_module_user_management",
    permission_module_vlv: "t_module_vlv"
};

const ModulesTransfer = props => {
    const [t] = useTranslation();
    const { permissions } = usePermissions({ suspense: true });

    return (
        <Transfer
            dataSource={permissions
                .filter(module => module.name.includes("permission_module_"))
                .map(permission => ({
                    key: permission.name.replace("permission_module_", ""),
                    title: t(MODULES[permission.name])
                }))}
            filterOption={(inputValue, option) => {
                return option.title.toLowerCase().includes(inputValue.toLowerCase());
            }}
            {...props}
        />
    );
};

export default ModulesTransfer;
