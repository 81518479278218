import React from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { TextField } from "misc/fields";
import { usePowertrainTypes } from "misc/api/datalayer";

const PowertrainSelector = ({ defaultValue, value, onChange, allowClear = true, ...props }) => {
    const [t] = useTranslation();

    const { powertrainTypes } = usePowertrainTypes();

    const handleChange = values => onChange && onChange(values);

    return (
        <Select
            mode="multiple"
            value={value}
            defaultValue={defaultValue}
            onChange={handleChange}
            placeholder={t("t_select_powertrain_type")}
            style={{ width: "100%" }}
            allowClear={allowClear}
            {...props}
        >
            {powertrainTypes.map(item => (
                <Select.Option key={item.powertrainId} value={item.powertrain}>
                    <TextField value={`t_${item.powertrain}`} />
                </Select.Option>
            ))}
        </Select>
    );
};

PowertrainSelector.propTypes = {
    defaultValue: PropTypes.arrayOf(PropTypes.string),
    value: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
    allowClear: PropTypes.bool
};

export default PowertrainSelector;
