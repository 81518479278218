import styled from "styled-components";
import { Layout, Skeleton, Row, Col } from "antd";
import { useLocation } from "react-router";
import { Switch, Redirect, Route } from "react-router-dom";
import React, { useState, useEffect, Suspense } from "react";

import { Card } from "misc/card";
import { Result } from "misc/results";
import { clientUrls } from "misc/urls";
import { CheckExpiration } from "misc/authentication";
import { useSignals, useVehicles } from "misc/api/datalayer";

import { Footer } from "navigation/Footer";
import Header from "navigation/header/Header";
import SidebarWeb from "navigation/Sidebar/SidebarWeb";
import SidebarMobile from "navigation/Sidebar/SidebarMobile";

import ModulesContainer from "app/modules/ModulesContainer";

// Lazy load not often used domponents
const AdminContainer = React.lazy(() => import("admin/containers/AdminContainer"));
const SettingsContainer = React.lazy(() => import("settings/containers/SettingsContainer"));

const { Content } = Layout;

const MainContent = styled(Content)`
    padding: 25px 15px;
`;

const RootAuthenticated = () => {
    const location = useLocation();

    const [windowSize, setWindowSize] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener("resize", () => setWindowSize(window.innerWidth));
        return () => window.removeEventListener("resize", () => setWindowSize(window.innerWidth));
    }, [windowSize]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    // Background fetching for better UX
    useVehicles();
    useSignals();

    return (
        <Row justify="center">
            <Col span={24} style={{ maxWidth: 1920, minHeight: "100vh" }}>
                <Layout>
                    <Header />
                    <Layout>
                        {windowSize > 768 ? <SidebarWeb /> : <SidebarMobile />}

                        <MainContent>
                            <Suspense
                                fallback={
                                    <div style={{ paddingRight: 30 }}>
                                        <Skeleton paragraph={{ rows: 4 }} />
                                    </div>
                                }
                            >
                                {/* TODO: Add roles */}
                                <CheckExpiration
                                    fallback={
                                        <Card>
                                            <Result type="roleExpired" />
                                        </Card>
                                    }
                                >
                                    <Switch>
                                        <Route path={clientUrls.userManagement.baseUrl()} component={AdminContainer} />
                                        <Route path={clientUrls.header.settings()} component={SettingsContainer} />
                                        <Route path={clientUrls.modules.baseUrl()} component={ModulesContainer} />
                                        <Redirect to={clientUrls.modules.baseUrl()} />
                                    </Switch>
                                </CheckExpiration>
                            </Suspense>
                            <Footer />
                        </MainContent>
                    </Layout>
                </Layout>
            </Col>
        </Row>
    );
};

export default RootAuthenticated;
