import React, { PureComponent } from "react";

import LegalComponent from "../components/LegalComponent";

class ImprintContainer extends PureComponent {
    render() {
        return <LegalComponent title="t_imprint" fileName="imprint.md" />;
    }
}

export default ImprintContainer;
