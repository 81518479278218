import { useMemo } from "react";
import { Col, Row } from "antd";

import { Card } from "misc/card";
import { Result } from "misc/results";
import { useParams } from "misc/hooks";
import { clientUrls, datalayerUrls } from "misc/urls";
import { MultiSelector, Selector } from "misc/selectors";
import { useSubfleets } from "misc/api/fleetconfiguration";
import CmFleetTable from "misc/widgets/consumers/tables/CmFleetTable";

import ModuleSettings from "app/modules/ModuleSettings";
import SideHeader from "navigation/SideHeader";

import { TABLE_VIEW_ITEMS } from "modules/ModuleSettings";

const VIEW_ITEMS = [
    ...TABLE_VIEW_ITEMS,
    {
        name: "displayedDays",
        label: "t_cdda_displayed_days",
        render: () => (
            <Selector
                options={[
                    { value: 7, label: "t_days_7" },
                    { value: 10, label: "t_days_10" },
                    { value: 14, label: "t_days_14" }
                ]}
            />
        )
    },
    {
        name: "dateOrder",
        label: "t_cdda_date_order",
        render: () => (
            <Selector
                options={[
                    { value: "asc", label: "t_ascend" },
                    { value: "desc", label: "t_descend" }
                ]}
            />
        )
    },
    {
        name: "cmCluster",
        label: "t_cm_cluster",
        render: () => (
            <MultiSelector
                options={[
                    { value: "post", label: "t_cm_wiki_location_post" },
                    { value: "production", label: "t_cm_wiki_location_production" },
                    { value: "test", label: "t_cm_wiki_location_test_vehicle" }
                ]}
                maxTagCount={1}
            />
        )
    }
];

const CmFleetViewComponent = () => {
    const { subfleets } = useSubfleets({ suspense: true });

    const { params, setParams } = useParams({
        options: [
            { name: "fleet", persist: "module" },
            { name: "columns", defaultValue: ["vin", "licensePlate"], persist: "site" },
            { name: "fixedColumn", defaultValue: "vin", persist: "site" },
            { name: "cmCluster", defaultValue: ["all"], persist: "module" },
            { name: "displayedDays", defaultValue: "7", persist: "site" },
            { name: "dateOrder", defaultValue: "desc", persist: "site" }
        ]
    });

    const fleet = useMemo(() => subfleets.find(fleet => fleet.subfleetId === params.fleet), [params.fleet, subfleets]);

    const exportItem = useMemo(
        () => ({
            label: "t_cm_fleet",
            columns: [
                { value: "vin", label: "t_vin" },
                { value: "licensePlate", label: "t_license_plate" },
                { value: "date", label: "t_date" },
                { value: "manufacturer", label: "t_manufacturer" },
                { value: "model", label: "t_model" },
                { value: "battery", label: "t_battery" },
                { value: "lastSignOfLifeTimestamp", label: "t_cm_last_sign_of_life_timestamp" },
                { value: "lastSignOfLifeSignal", label: "t_cm_last_sign_of_life_signal" },
                { value: "chargingControlSystem", label: "t_cm_charging_management" },
                { value: "evaluationCase", label: "t_cm_case" },
                { value: "organisationalUnitType", label: "t_cm_organisational_unit_type" },
                { value: "chargingStation", label: "t_cm_charging_station" },
                { value: "socFirst", label: "t_cm_soc_start" },
                { value: "socLast", label: "t_cm_soc_end" },
                { value: "socCharged", label: "t_cm_soc_charge" },
                { value: "timestampDepartureLlw", label: "t_cm_departure_time_llw" },
                { value: "chargeCompletedCount", label: "t_cm_charge_completed" },
                { value: "chargeCompletedTimestamp", label: "t_cm_charge_completed_timestamp" },
                { value: "assignedOrganisationalUnitFmm", label: "t_cm_fleet_assigned_organisational_unit_fmm" },
                { value: "distanceToAssignedOrganisationalUnitFmm", label: "t_cm_fleet_distance_to_assigned_organisational_unit_fmm" },
                { value: "distanceToNearestOrganisationalUnitFmm", label: "t_cm_fleet_distance_to_nearest_organisational_unit_fmm" },
                { value: "assignedOrganisationalUnitLlw", label: "t_cm_fleet_assigned_organisational_unit_llw" },
                { value: "distanceToAssignedOrganisationalUnitLlw", label: "t_cm_fleet_distance_to_assigned_organisational_unit_llw" },
                { value: "distanceToNearestOrganisationalUnitLlw", label: "t_cm_fleet_distance_to_nearest_organisational_unit_llw" },
                { value: "connectivity", label: "t_cm_connectivity" },
                { value: "tcu", label: "t_telematic_control_unit" },
                { value: "temperatureAmbientMax", label: "t_ambient_temperature_max" },
                { value: "temperatureAmbientMin", label: "t_ambient_temperature_min" },
                { value: "moduleParameterSetVersion", label: "t_cm_version" },
                { value: "intervalsChargeplug", label: "t_cm_intervals_chargeplug" },
                { value: "intervalsError", label: "t_cm_intervals_error" },
                { value: "intervalsConnectivity", label: "t_cm_intervals_connectivity" },
                { value: "intervalsObserved", label: "t_cm_intervals_observed" }
            ],
            request: {
                method: "GET",
                url: datalayerUrls.fleet.widgets.list(),
                params: { type: "chargingMonitorFleetViewExport" },
                filter: {
                    hierarchy: fleet?.organisationalUnits,
                    vehicles: fleet?.vehicles,
                    range: fleet?.parameters.cddaRange
                }
            }
        }),
        [fleet]
    );

    return (
        <SideHeader>
            <Row gutter={[10, 10]}>
                <Col span={24}>
                    <ModuleSettings
                        title="t_cm"
                        values={{ ...params, range: fleet?.parameters.cddaRange }}
                        fleetOptions={{ redirectUrl: clientUrls.modules.cm.fleetConfiguration() }}
                        viewOptions={{ items: VIEW_ITEMS }}
                        exportOptions={{ export: exportItem }}
                        onChange={values => setParams({ ...params, ...values })}
                    />
                </Col>

                {fleet ? (
                    <Col span={24}>
                        <CmFleetTable
                            filter={{ hierarchy: fleet.organisationalUnits, vehicles: fleet.vehicles }}
                            view={{
                                columns: params.columns,
                                fixedColumn: params.fixedColumn,
                                cmCluster: params.cmCluster,
                                displayedDays: params.displayedDays,
                                dateOrder: params.dateOrder
                            }}
                        />
                    </Col>
                ) : (
                    <Col span={24}>
                        <Card height={500}>
                            <Result type="noFleetSelected" url={clientUrls.modules.cm.fleetConfiguration()} />
                        </Card>
                    </Col>
                )}
            </Row>
        </SideHeader>
    );
};

export default CmFleetViewComponent;
