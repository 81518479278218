import dayjs from "dayjs";

var minMax = require("dayjs/plugin/minMax");
dayjs.extend(minMax);

var isSameOrBefore = require("dayjs/plugin/isSameOrBefore");
dayjs.extend(isSameOrBefore);

var updateLocale = require("dayjs/plugin/updateLocale");
dayjs.extend(updateLocale);

var weekOfYear = require("dayjs/plugin/weekOfYear");
dayjs.extend(weekOfYear);

var weekYear = require("dayjs/plugin/weekYear");
dayjs.extend(weekYear);

require("dayjs/locale/de");
dayjs.locale("de");
