import React, { PureComponent } from "react";

import LegalComponent from "../components/LegalComponent";

class PrivacyPolicyContainer extends PureComponent {
    render() {
        return <LegalComponent title="t_privacy_policy" fileName="privacyPolicy.md" />;
    }
}

export default PrivacyPolicyContainer;
