import PropTypes from "prop-types";
import React, { useState } from "react";
import { InputNumber, Select, Input } from "antd";

import { TextField } from "misc/fields";

const SamplingRateSelector = ({ defaultValue, value, onChange, allowClear = true, ...props }) => {
    const options = [
        { interval: "second", name: "t_second" },
        { interval: "minute", name: "t_minute" },
        { interval: "hour", name: "t_hour" },
        { interval: "day", name: "t_day" }
    ];

    const [rate, setRate] = useState(value ? value.rate : defaultValue.value);
    const [interval, setInterval] = useState(value ? value.interval : defaultValue.interval);

    const onChangeSize = value => {
        const floatValue = parseFloat(value);

        setRate(floatValue);

        onChange &&
            onChange({
                rate: floatValue,
                interval: interval
            });
    };

    const onChangeInterval = interval => {
        setInterval(interval);

        onChange &&
            onChange({
                rate: rate,
                interval: interval
            });
    };

    return (
        <Input.Group compact>
            <InputNumber
                value={rate}
                placeholder="t_select_sampling_rate"
                onChange={onChangeSize}
                min={0.1}
                step={0.1}
                decimalSeparator=","
                {...props}
            />

            <Select value={interval} onChange={onChangeInterval} {...props}>
                {options.map(option => (
                    <Select.Option key={option.interval} value={option.interval}>
                        <TextField value={option.name} />
                    </Select.Option>
                ))}
            </Select>
        </Input.Group>
    );
};

SamplingRateSelector.propTypes = {
    defaultValue: PropTypes.shape({ rate: PropTypes.number.isRequired, interval: PropTypes.string.isRequired }),
    value: PropTypes.shape({ rate: PropTypes.number.isRequired, interval: PropTypes.string.isRequired }),
    onChange: PropTypes.func,
    allowClear: PropTypes.bool
};

export default SamplingRateSelector;
