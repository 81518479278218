import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { Row, Col, Breadcrumb, Segmented } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { TextField } from "misc/fields";
import { clientUrls } from "misc/urls";

const SideHeader = ({ value, options = [], onChange = () => null, children }) => {
    const [t] = useTranslation();

    const location = useLocation();

    const breadcrumbItems = useMemo(() => {
        const pathSnippets = location.pathname.split("/").filter(path => path);

        return pathSnippets.map((path, index) => {
            const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
            return (
                <Breadcrumb.Item key={url}>
                    <Link to={url}>
                        <TextField value={path.toUpperCase() === path ? path : `t_${path.replace("-", "_")}`} inline />
                    </Link>
                </Breadcrumb.Item>
            );
        });
    }, [location]);

    return (
        <Row gutter={[0, 10]}>
            <Col span={24}>
                <Row>
                    <Col span={12}>
                        <div style={{ fontWeight: 600 }}>
                            <Breadcrumb separator=">" style={{ cursor: "default" }}>
                                {[
                                    <Breadcrumb.Item key="home">
                                        <Link to={clientUrls.baseUrl()}>
                                            <FontAwesomeIcon icon={["far", "display"]} style={{ marginRight: 2, height: 13 }} />
                                        </Link>
                                    </Breadcrumb.Item>,
                                    ...breadcrumbItems
                                ]}
                            </Breadcrumb>
                        </div>
                    </Col>

                    <Col span={12}>
                        <Row justify="end" align="middle">
                            <Segmented
                                value={value}
                                options={options.map(option => ({ ...option, label: t(option.label) }))}
                                onChange={option => onChange(option)}
                            />
                        </Row>
                    </Col>
                </Row>
            </Col>

            <Col span={24} style={{ marginTop: 10 }}>
                {children ?? options.find(option => option.value === value)?.render() ?? ""}
            </Col>
        </Row>
    );
};

SideHeader.propTypes = {
    value: PropTypes.string,
    options: PropTypes.array,
    onChange: PropTypes.func,
    children: PropTypes.any
};

export default SideHeader;
