import i18n from "i18n";
import moment from "moment";
import Moment from "react-moment";
import { ConfigProvider } from "antd";
import React, { useEffect } from "react";
import { Switch, Route } from "react-router";

import { useLocale } from "misc/api/keycloakApi";
import { clientUrls } from "misc/urls/clientUrls";
import { CheckAuthentication } from "misc/authentication";

import ImprintContainer from "legal/containers/ImprintContainer";
import CookiesContainer from "legal/containers/CookiesContainer";
import PrivacyPolicyContainer from "legal/containers/PrivacyPolicyContainer";

import RootAuthenticated from "./RootAuthenticated";

import "misc/icons/icons";

import { theme } from "styles";

const Root = () => {
    const { locale } = useLocale({ suspense: true });

    useEffect(() => {
        // i18n
        i18n.changeLanguage(locale.i18n);

        // React moment
        Moment.globalLocal = true;
        Moment.globalLocale = locale.moment;

        // momentjs
        moment.updateLocale(locale.moment, {
            week: {
                dow: 1, // First day of week is Monday
                doy: 4 // First week of year must contain 4 January (7 + 1 - 4)
            }
        });
    }, [locale]);

    return (
        <ConfigProvider locale={locale.antd} theme={theme}>
            <Switch>
                <Route path={clientUrls.legal.imprint()}>
                    <ImprintContainer />
                </Route>
                <Route path={clientUrls.legal.privacyPolicy()}>
                    <PrivacyPolicyContainer />
                </Route>
                <Route path={clientUrls.legal.cookies()}>
                    <CookiesContainer />
                </Route>
                <Route path="/">
                    <CheckAuthentication>
                        <RootAuthenticated />
                    </CheckAuthentication>
                </Route>
            </Switch>
        </ConfigProvider>
    );
};

export default Root;
