import React, { useMemo } from "react";
import { Button, Input, Select } from "antd";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { useVehicles } from "misc/api/datalayer";

const VehicleSelector = ({ defaultValue, value, onChange, multiselect, browseWithArrows = false, ...props }) => {
    const [t] = useTranslation();

    const { vehicles } = useVehicles({ suspense: true });

    const [disabledPrevious, disabledNext] = useMemo(
        () => {
            const index = vehicles.map(({ vin }) => vin).indexOf(value);
            return [index <= 0, index >= vehicles.length - 1];
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [value]
    );

    const [handlePrevious, handleNext] = useMemo(
        () => {
            if (multiselect || !value || vehicles.length === 0) return [];

            const index = vehicles.map(({ vin }) => vin).indexOf(value);

            const prevVin = vehicles[!disabledPrevious ? index - 1 : index].vin;
            const nextVin = vehicles[!disabledNext ? index + 1 : index].vin;

            const handleChange = vin => {
                onChange(vin);
            };

            return [() => handleChange(prevVin), () => handleChange(nextVin)];
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [value]
    );

    const showBrowseArrows = useMemo(
        () => browseWithArrows && !multiselect,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <Input.Group compact>
            {showBrowseArrows && (
                <Button onClick={handlePrevious} disabled={disabledPrevious} icon={<FontAwesomeIcon icon={["fal", "chevron-left"]} />} />
            )}

            <Select
                defaultValue={defaultValue}
                value={value}
                onChange={onChange}
                mode={multiselect ? "multiple" : null}
                placeholder={t(multiselect ? "t_select_vehicles" : "t_select_vehicle")}
                filterOption={(inputValue, option) =>
                    inputValue.length > 1 ? option.children.join().toLowerCase().includes(inputValue.toLowerCase()) : true
                }
                style={{ width: "100%" }}
                maxTagCount="responsive"
                showSearch
                {...props}
            >
                {vehicles.map(vehicle => (
                    <Select.Option key={vehicle.vin} value={vehicle.vin}>
                        {vehicle.vin}
                        {vehicle.licensePlate && `|${vehicle.licensePlate}`}
                    </Select.Option>
                ))}
                )
            </Select>

            {showBrowseArrows && <Button onClick={handleNext} disabled={disabledNext} icon={<FontAwesomeIcon icon={["fal", "chevron-right"]} />} />}
        </Input.Group>
    );
};

VehicleSelector.propTypes = {
    defaultValue: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    onChange: PropTypes.func,
    multiselect: PropTypes.bool
};

export default VehicleSelector;
