import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Button, Form, Space, Input, Drawer } from "antd";

import { Card } from "misc/card";
import { TextField } from "misc/fields";
import { useExcelCSVExport } from "misc/hooks";
import { splitRangeBy } from "misc/helpfunctions";
import { MultiSelector, Selector, SubfleetSelector, RangeSelector, VehicleSelector } from "misc/selectors";

const TABLE_VIEW_ITEMS = [
    {
        name: "columns",
        label: "t_module_settings_base_columns",
        render: () => (
            <MultiSelector
                defaultValue={["vin"]}
                options={[
                    { value: "vin", label: "t_vin", disabled: true },
                    { value: "licensePlate", label: "t_license_plate" },
                    { value: "organisationalUnit", label: "t_organisational_unit" },
                    { value: "chargingStation", label: "t_charging_station" },
                    { value: "manufacturer", label: "t_manufacturer" },
                    { value: "model", label: "t_model" },
                    { value: "battery", label: "t_battery" },
                    { value: "telematicControlUnit", label: "t_telematic_control_unit" },
                    { value: "lastSignOfLife", label: "t_last_sign_of_life" }
                ]}
                maxTagCount={3}
            />
        )
    },
    {
        name: "fixedColumn",
        label: "t_module_settings_fixed_column",
        render: () => (
            <Selector
                defaultValue={["vin"]}
                options={[
                    { value: "vin", label: "t_vin" },
                    { value: "licensePlate", label: "t_license_plate" },
                    { value: "organisationalUnit", label: "t_organisational_unit" },
                    { value: "chargingStation", label: "t_charging_station" },
                    { value: "manufacturer", label: "t_manufacturer" },
                    { value: "model", label: "t_model" },
                    { value: "battery", label: "t_battery" },
                    { value: "telematicControlUnit", label: "t_telematic_control_unit" },
                    { value: "lastSignOfLife", label: "t_last_sign_of_life" }
                ]}
            />
        )
    }
];

const MODE_ITEMS = {
    vehicle: "vehicle",
    fleet: "fleet"
};

const ModuleSettings = ({
    values = {},
    fleetOptions = {},
    viewOptions = { items: TABLE_VIEW_ITEMS },
    exportOptions = { export: {}, range: true },
    mode = MODE_ITEMS.fleet,
    onChange = () => null
}) => {
    const [t] = useTranslation();
    const history = useHistory();

    const [fleetForm] = Form.useForm();
    const [viewForm] = Form.useForm();
    const [exportForm] = Form.useForm();

    const [exportFormat, setExportFormat] = useState("excel");

    const [activeDrawer, setActiveDrawer] = useState(null);

    const { fetchGroupExcel, fetchGroupCSV, isLoading: isExporting } = useExcelCSVExport({ format: exportFormat });

    useEffect(() => {
        fleetForm.setFieldsValue(values);
        viewForm.setFieldsValue(values);
    }, [fleetForm, viewForm, values]);

    const onChangeFleet = () => {
        onChange({ ...fleetForm.getFieldsValue(), ...viewForm.getFieldsValue() });
    };

    const onChangeView = () => {
        onChange({ ...fleetForm.getFieldsValue(), ...viewForm.getFieldsValue() });
        setActiveDrawer(null);
    };

    const onFinishExport = values => {
        setActiveDrawer(null);
        const exportOption = exportOptions.export;

        // columns is array of object, with keys: value, label, render
        const selectedColumns = exportOption.columns.filter(col => values.exportColumns.includes(col.value) || values.exportColumns.includes("all"));

        // Data transformation
        const extractData = data => {
            const filteredData = data.map(item => {
                return Object.fromEntries(
                    Object.entries(item)
                        .filter(([key]) => selectedColumns.some(({ value }) => value === key))
                        .map(([key, value]) => {
                            const column = selectedColumns.find(({ value: columnValue }) => columnValue === key);
                            const transformedValue = column.render ? column.render(value) : value;
                            return [key, transformedValue];
                        })
                );
            });

            return filteredData;
        };

        let request = [exportOption.request];
        if (values.exportRange) {
            const range = values.exportRange.map(date => date.startOf("day").format("YYYY-MM-DD"));
            const exportRange = splitRangeBy(range, "weeks", { step: 1 });
            request = exportRange.map(range => ({ ...exportOption.request, filter: { ...exportOption.request.filter, range: range } }));
        }
        let exports = [
            {
                request: request,
                fileNames: request.map(request =>
                    request.filter.range ? `${t(exportOption.label)}_${request.filter.range}` : t(exportOption.label)
                ),
                folderName: `${t("t_export")}_${t(exportOption.label)}`,
                columns: selectedColumns.map(column => ({ ...column, label: t(column.label) })),
                extractData
            }
        ];

        values.exportFormat === "excel"
            ? fetchGroupExcel(`${t("t_export")}_${t(exportOption.label)}_${values.exportFormat}`, exports)
            : fetchGroupCSV(`${t("t_export")}_${t(exportOption.label)}_${values.exportFormat}`, exports);
    };

    return (
        <>
            <Card>
                <Row gutter={[0, 0]} align="middle">
                    <Col span={20}>
                        <Form form={fleetForm} layout="inline" labelAlign="left" onFieldsChange={onChangeFleet}>
                            <Form.Item label={<TextField value={mode === MODE_ITEMS.fleet ? "t_fleet" : "t_vehicles"} />}>
                                <Input.Group compact>
                                    <Form.Item name={MODE_ITEMS[mode]} noStyle>
                                        {mode === MODE_ITEMS.fleet ? (
                                            <SubfleetSelector style={{ width: 400 }} />
                                        ) : (
                                            <VehicleSelector style={{ width: 400 }} />
                                        )}
                                    </Form.Item>

                                    {mode === MODE_ITEMS.fleet && (
                                        <Form.Item noStyle>
                                            <Button onClick={() => history.push(fleetOptions.redirectUrl, { fleet: values.fleet })}>
                                                <FontAwesomeIcon icon={["fal", "gear"]} />
                                            </Button>
                                        </Form.Item>
                                    )}
                                </Input.Group>
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col span={4}>
                        <Row align="end">
                            <Space size={4}>
                                <Button onClick={() => setActiveDrawer("view")}>
                                    <FontAwesomeIcon icon={["far", "sliders"]} />
                                </Button>
                                <Button onClick={() => setActiveDrawer("export")}>
                                    <FontAwesomeIcon icon={["far", "arrow-down-to-line"]} />
                                </Button>
                            </Space>
                        </Row>
                    </Col>
                </Row>
            </Card>

            <Drawer visible={activeDrawer === "view"} onClose={() => setActiveDrawer(null)} title={t("t_module_settings_configure_view_title")}>
                <Form form={viewForm} layout="vertical" labelAlign="left">
                    <Row gutter={[40, 0]}>
                        {viewOptions.items.map(item => (
                            <Col key={item.name} span={24}>
                                <Form.Item name={item.name} label={<TextField value={item.label} />}>
                                    {item.render()}
                                </Form.Item>
                            </Col>
                        ))}
                        {/* <Col span={24}>
                                {viewOptions.redirectUrl && (
                                    <Button>
                                        <Link to={viewOptions.redirectUrl}>
                                            <Space>
                                                <FontAwesomeIcon icon={["fal", "gear"]} />
                                                <TextField value="t_module_settings_further_view_settings" />
                                            </Space>
                                        </Link>
                                    </Button>
                                )}
                            </Col> */}
                        {/* {values.range ? (
                                <Col span={24}>
                                    <Tooltip title={<TextField value="t_module_settings_range_description" />} placement="bottomLeft">
                                        <Form.Item name="range" label={<TextField value="t_module_settings_range" />}>
                                            <RangeSelector showTime={false} disabled />
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                            ) : null} */}
                        <Col span={24}>
                            <Button onClick={onChangeView}>
                                <Space>
                                    <TextField value="t_apply" />
                                </Space>
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Drawer>

            <Drawer visible={activeDrawer === "export"} onClose={() => setActiveDrawer(null)} title={t("t_module_settings_export_title")}>
                <Form
                    form={exportForm}
                    layout="vertical"
                    labelAlign="left"
                    onFinish={onFinishExport}
                    validateMessages={{ required: `\${label}  ${t("t_is_required")} ` }}
                >
                    <Row gutter={[40, 0]}>
                        <Col key="exportColumns" span={24}>
                            <Form.Item name="exportColumns" label={t("t_module_settings_export_columns")} rules={[{ required: true }]}>
                                <MultiSelector
                                    options={exportOptions.export.columns?.map(col => ({ label: col.label, value: col.value }))}
                                    maxTagCount={2}
                                />
                            </Form.Item>
                        </Col>

                        <Col key="exportRange" span={24}>
                            <Form.Item name="exportRange" label={t("t_module_settings_export_range")} hidden={exportOptions.range === false}>
                                <RangeSelector ranges={["lastWeek", "lastTwoWeeks", "lastFourWeeks"]} showTime={false} />
                            </Form.Item>
                        </Col>

                        <Col key="exportFormat" span={24}>
                            <Form.Item name="exportFormat" label={t("t_module_settings_export_format")} rules={[{ required: true }]}>
                                <Selector
                                    onChange={value => setExportFormat(value)}
                                    options={[
                                        {
                                            value: "excel",
                                            label: "Excel"
                                        },
                                        {
                                            value: "csv",
                                            label: "CSV"
                                        }
                                    ]}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Button htmlType="submit" disabled={isExporting} loading={isExporting}>
                                <Space>
                                    <FontAwesomeIcon icon={["fal", "arrow-down-to-line"]} />
                                    <TextField value="t_export" />
                                </Space>
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </>
    );
};

ModuleSettings.propTypes = {
    title: PropTypes.string,
    values: PropTypes.object,
    fleetOptions: PropTypes.shape({ redirectUrl: PropTypes.string }),
    viewOptions: PropTypes.shape({ items: PropTypes.array, redirectUrl: PropTypes.string }),
    exportOptions: PropTypes.shape({ exports: PropTypes.array }),
    onChange: PropTypes.func
};

export default ModuleSettings;
export { TABLE_VIEW_ITEMS };
