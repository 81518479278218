export const clientUrls = {
    baseUrl: () => "/",

    userManagement: {
        baseUrl: () => "/user-management",
        users: {
            baseUrl: () => `${clientUrls.userManagement.baseUrl()}/users`,
            create: () => `${clientUrls.userManagement.users.baseUrl()}/create`,
            edit: () => `${clientUrls.userManagement.users.baseUrl()}/edit`
        },

        roles: {
            baseUrl: () => `${clientUrls.userManagement.baseUrl()}/roles`,
            create: () => `${clientUrls.userManagement.roles.baseUrl()}/create`,
            edit: () => `${clientUrls.userManagement.roles.baseUrl()}/edit`
        },

        permissions: {
            baseUrl: () => `${clientUrls.userManagement.baseUrl()}/permissions`,
            application: () => `${clientUrls.userManagement.permissions.baseUrl()}/application`,
            vehicle: () => `${clientUrls.userManagement.permissions.baseUrl()}/vehicle`
        }
    },

    authentication: {
        baseUrl: () => "/authentication",
        login: () => `${clientUrls.authentication.baseUrl()}/login`,
        activate: token => `${clientUrls.authentication.baseUrl()}/activate/${token}`
    },

    dashboards: {
        baseUrl: () => "/dashboards",
        default: () => `${clientUrls.dashboards.baseUrl()}/default`
        // emil: () => `${clientUrls.dashboards.baseUrl()}/emil`,
        // availability: () => `${clientUrls.dashboards.baseUrl()}/availability`,
        // defects: {
        //     baseUrl: () => `${clientUrls.dashboards.baseUrl()}/defects`,
        //     categories: () => `${clientUrls.dashboards.defects.baseUrl()}/categories`,
        //     reports: () => `${clientUrls.dashboards.defects.baseUrl()}/reports`,
        //     gbb: () => `${clientUrls.dashboards.defects.baseUrl()}/gbb`,
        //     branch: () => `${clientUrls.dashboards.defects.baseUrl()}/branch`,
        //     zsps: () => `${clientUrls.dashboards.defects.baseUrl()}/zsps`,
        //     vehicles: () => `${clientUrls.dashboards.defects.baseUrl()}/vehicles`
        // },
        // fppTracking: () => `${clientUrls.dashboards.baseUrl()}/fpp-tracking`
    },

    header: { logout: () => "/", settings: () => "/settings" },

    legal: {
        cookies: () => "/cookies",
        imprint: () => "/imprint",
        privacyPolicy: () => "/privacy_policy"
    },

    reporting: {
        baseUrl: () => "/reporting",
        create: () => `${clientUrls.reporting.baseUrl()}/create`,
        edit: () => `${clientUrls.reporting.baseUrl()}/edit`
    },

    modules: {
        baseUrl: () => "/modules",
        cdda: {
            baseUrl: () => `${clientUrls.modules.baseUrl()}/cdda`,
            fleetConfiguration: () => `${clientUrls.modules.cdda.baseUrl()}/fleet-configuration`
        },
        defectTickets: {
            baseUrl: () => `${clientUrls.modules.baseUrl()}/defect-tickets`,
            fleetConfiguration: () => `${clientUrls.modules.defectTickets.baseUrl()}/fleet-configuration`
        },
        cm: {
            baseUrl: () => `${clientUrls.modules.baseUrl()}/cm`,
            fleetConfiguration: () => `${clientUrls.modules.cm.baseUrl()}/fleet-configuration`,
            dashboard: () => `${clientUrls.modules.cm.baseUrl()}/dashboard`,
            fleetView: () => `${clientUrls.modules.cm.baseUrl()}/fleet-view`,
            wiki: () => `${clientUrls.modules.cm.baseUrl()}/wiki`,
            parameters: () => `${clientUrls.modules.cm.baseUrl()}/parameters`
        },
        vlv: {
            baseUrl: () => `${clientUrls.modules.baseUrl()}/vlv`,
            status: () => `${clientUrls.modules.vlv.baseUrl()}/status`,
            driversLog: () => `${clientUrls.modules.vlv.baseUrl()}/drivers-log`,
            fleetConfiguration: () => `${clientUrls.modules.vlv.baseUrl()}/fleet-configuration`
        },
        diagnostics: {
            baseUrl: () => `${clientUrls.modules.baseUrl()}/diagnostics`
        },
        energyMonitor: {
            baseUrl: () => `${clientUrls.modules.baseUrl()}/energy-monitor`,
            fleetConfiguration: () => `${clientUrls.modules.energyMonitor.baseUrl()}/fleetconfiguration`
        },
        engineering: {
            baseUrl: () => `${clientUrls.modules.baseUrl()}/engineering`,
            errors: () => `${clientUrls.modules.engineering.baseUrl()}/errors`,
            signals: () => `${clientUrls.modules.engineering.baseUrl()}/signals`,
            diagnostics: () => `${clientUrls.modules.engineering.baseUrl()}/diagnostics`
        }
    }
};
