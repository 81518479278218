import React from "react";
import dayjs from "dayjs";
import { isNil } from "lodash";
import PropTypes from "prop-types";

import { TextField } from "misc/fields";

const DateField = ({ value, format = "DD.MM.YYYY", ...props }) => {
    if (isNil(value)) return <TextField value={null} />;

    return <TextField value={dayjs(value).format(format)} {...props} />;
};

DateField.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    format: PropTypes.string
};

export default DateField;
