import React from "react";
import { clientUrls } from "misc/urls";
import { Switch, Route, Redirect } from "react-router";

import CmParameters from "../components/CmParameters";
import CmWikiComponent from "../components/CmWikiComponent";
import CmDashboardComponent from "../components/CmDashboardComponent";
import CmFleetViewComponent from "../components/CmFleetViewComponent";
import CmFleetConfigurationComponent from "../components/CmFleetConfigurationComponent";

const CmContainer = () => {
    return (
        <Switch>
            <Route exact path={clientUrls.modules.cm.dashboard()}>
                <CmDashboardComponent />
            </Route>
            <Route exact path={clientUrls.modules.cm.fleetView()}>
                <CmFleetViewComponent />
            </Route>
            <Route exact path={clientUrls.modules.cm.wiki()}>
                <CmWikiComponent />
            </Route>
            <Route exact path={clientUrls.modules.cm.parameters()}>
                <CmParameters />
            </Route>
            <Route exact path={clientUrls.modules.cm.fleetConfiguration()}>
                <CmFleetConfigurationComponent />
            </Route>
            <Redirect to={clientUrls.modules.cm.dashboard()} />
        </Switch>
    );
};

export default CmContainer;
