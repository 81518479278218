import moment from "moment";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import _, { isNil, pick } from "lodash";
import { useHistory } from "react-router";
import React, { useMemo, useState } from "react";
import { Col, Popover, Row, Space, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Card } from "misc/card";
import { DateSelector } from "misc/selectors";
import { CheckedIcon } from "misc/icons/CheckedIcon";
import { datalayerUrls, clientUrls } from "misc/urls";
import { getDatesFromRange } from "misc/helpfunctions";
import { VehiclesTableTemplate } from "misc/widgets/consumers";
import { useChargingMonitorParameters } from "misc/api/datalayer";
import { DateField, DatetimeField, NumberField, TextField, NumberRangeField } from "misc/fields";
import { stsColors } from "styles";
import CmCaseInfoModal from "app/modules/cm/components/CmCaseInfoModal";
import { useTranslation } from "react-i18next";

const CmFleetTable = ({ filter = {}, view = {} }) => {
    const { chargingMonitorParameters } = useChargingMonitorParameters({ suspense: true });
    const { t } = useTranslation();
    const history = useHistory();

    const [selectedDate, setSelectedDate] = useState(dayjs());

    const range = useMemo(
        () => [
            dayjs(selectedDate)
                .subtract(view.displayedDays - 1, "day")
                .startOf("day"),
            dayjs(selectedDate).endOf("day")
        ],
        [selectedDate, view.displayedDays]
    );

    const dates = useMemo(() => {
        const dates = getDatesFromRange(range);

        if (view.dateOrder === "desc") return dates.sort((a, b) => b.unix() - a.unix());

        return dates.sort((a, b) => a.unix() - b.unix());
    }, [range, view.dateOrder]);

    const columns = useMemo(
        () => [
            {
                title: () => (
                    <Space>
                        <TextField value="t_cm_cases" />
                        <FontAwesomeIcon
                            icon={["fal", "circle-info"]}
                            style={{ cursor: "pointer" }}
                            onClick={() => history.push(clientUrls.modules.cm.wiki())}
                        />
                    </Space>
                ),
                dataIndex: "cases",
                children: [
                    {
                        title: <TextField value="t_cm_wiki_location_post" />,
                        dataIndex: "post",
                        children: [
                            {
                                title: <CmCaseInfoModal case="1.1.1" />,
                                dataIndex: ["total", "cases", "case_1_1_1"],
                                width: 50
                            },
                            { title: <CmCaseInfoModal case="1.1.2" />, dataIndex: ["total", "cases", "case_1_1_2"], width: 50 },
                            { title: <CmCaseInfoModal case="1.2.1" />, dataIndex: ["total", "cases", "case_1_2_1"], width: 50 },
                            { title: <CmCaseInfoModal case="1.2.2" />, dataIndex: ["total", "cases", "case_1_2_2"], width: 50 },
                            { title: <CmCaseInfoModal case="1.3.1" />, dataIndex: ["total", "cases", "case_1_3_1"], width: 50 },
                            { title: <CmCaseInfoModal case="1.3.2" />, dataIndex: ["total", "cases", "case_1_3_2"], width: 50 },
                            { title: <CmCaseInfoModal case="1.4.1" />, dataIndex: ["total", "cases", "case_1_4_1"], width: 50 },
                            { title: <CmCaseInfoModal case="1.4.2" />, dataIndex: ["total", "cases", "case_1_4_2"], width: 50 },
                            { title: <CmCaseInfoModal case="1.5" />, dataIndex: ["total", "cases", "case_1_5"], width: 50 },
                            { title: <CmCaseInfoModal case="1.6" />, dataIndex: ["total", "cases", "case_1_6"], width: 50 }
                        ]
                    },
                    {
                        title: <TextField value="t_cm_wiki_location_production" />,
                        dataIndex: "production",
                        children: [
                            { title: <CmCaseInfoModal case="2.1.1" />, dataIndex: ["total", "cases", "case_2_1_1"], width: 50 },
                            { title: <CmCaseInfoModal case="2.1.2" />, dataIndex: ["total", "cases", "case_2_1_2"], width: 50 },
                            { title: <CmCaseInfoModal case="2.2.1" />, dataIndex: ["total", "cases", "case_2_2_1"], width: 50 },
                            { title: <CmCaseInfoModal case="2.2.2" />, dataIndex: ["total", "cases", "case_2_2_2"], width: 50 },
                            { title: <CmCaseInfoModal case="2.3.1" />, dataIndex: ["total", "cases", "case_2_3_1"], width: 50 },
                            { title: <CmCaseInfoModal case="2.3.2" />, dataIndex: ["total", "cases", "case_2_3_2"], width: 50 },
                            { title: <CmCaseInfoModal case="2.4" />, dataIndex: ["total", "cases", "case_2_4"], width: 50 },
                            { title: <CmCaseInfoModal case="2.5" />, dataIndex: ["total", "cases", "case_2_5"], width: 50 }
                        ]
                    },
                    {
                        title: <TextField value={"t_cm_wiki_location_test_vehicle"} />,
                        dataIndex: "test",
                        children: [
                            { title: <CmCaseInfoModal case="3.1" />, dataIndex: ["total", "cases", "case_3_1"], width: 50 },
                            { title: <CmCaseInfoModal case="3.2" />, dataIndex: ["total", "cases", "case_3_2"], width: 50 },
                            { title: <CmCaseInfoModal case="3.3" />, dataIndex: ["total", "cases", "case_3_3"], width: 50 },
                            { title: <CmCaseInfoModal case="3.4" />, dataIndex: ["total", "cases", "case_3_4"], width: 50 },
                            { title: <CmCaseInfoModal case="4.1" />, dataIndex: ["total", "cases", "case_4_1"], width: 50 },
                            { title: <CmCaseInfoModal case="4.2" />, dataIndex: ["total", "cases", "case_4_2"], width: 50 }
                        ]
                    }
                ]
                    .filter(cluster => view.cmCluster[0] === "all" || view.cmCluster.includes(cluster.dataIndex))
                    .reduce(
                        (acc, { children, ...item }) => [
                            ...acc,
                            {
                                ...item,
                                children: children.map(element => ({
                                    ...element,
                                    render: (text, record) => {
                                        const renderedItem = text?.render ? text.render(text, record) : text;

                                        return element?.render ? element.render(renderedItem, record) : renderedItem;
                                    }
                                }))
                            }
                        ],
                        []
                    )
            },
            ...dates.map(date => ({
                title: (
                    <Space direction="vertical" style={{ width: "100%" }}>
                        <Space direction="vertical" size={0}>
                            <DateField value={date} format="dd, DD.MM.YY" />
                            <TextField
                                value={dayjs(date).isAfter(dayjs()) ? "t_cm_no_evaluation_available" : "t_cm_evaluation_range"}
                                style={{ fontSize: "10px" }}
                            />
                        </Space>
                        <Row>
                            <Col span={8}>
                                <Tooltip title={<TextField value="t_cm_case" />}>
                                    <FontAwesomeIcon icon={["fas", "nfc-magnifying-glass"]} />
                                </Tooltip>
                            </Col>
                            <Col span={8}>
                                <Tooltip title={<TextField value="t_cm_charge_status" />}>
                                    <FontAwesomeIcon icon={["fas", "battery-full"]} />
                                </Tooltip>
                            </Col>
                            <Col span={8}>
                                <Tooltip title={<TextField value="t_cm_connectivity" />}>
                                    <FontAwesomeIcon icon={["fas", "signal-bars"]} />
                                </Tooltip>
                            </Col>
                        </Row>
                    </Space>
                ),
                dataIndex: date.format("YYYY-MM-DD"),
                align: "center",
                render: text => <TextField value={text?.render ? text.render(text) : text} />,
                width: 300,
                onCell: () => ({ style: { borderLeft: "3px solid #f0f0f0" } }),
                onHeaderCell: () => ({ style: { borderLeft: "3px solid #f0f0f0" } })
            }))
        ],
        [dates, view.cmCluster]
    );

    const extractData = ({ data }) => {
        const transpond = (data, options = { transpondIndex: "date", topLevelKey: "vin", childrenColumnName: "data" }) => {
            const columns = [
                {
                    dataIndex: "total",
                    dataKey: "cases"
                },
                ...dates.map(date => ({
                    title: date,
                    dataIndex: date.format("YYYY-MM-DD"),
                    dataKey: ["evaluationCase", "chargingIntervalsEvaluation", "connectivity", "moduleParameterSetVersion"],
                    render: ({ render, ...record }) => {
                        if (!Object.keys(record).length) return <TextField />;

                        const { parameters } = chargingMonitorParameters?.data?.find(({ version }) => version === record.moduleParameterSetVersion);

                        return (
                            <Row>
                                <Col span={8}>
                                    <CmCaseInfoModal case={record.evaluationCase} />
                                </Col>
                                <Col span={8}>
                                    {!_.isNull(record.chargingIntervalsEvaluation) ? (
                                        <Popover
                                            content={
                                                <Space size={5}>
                                                    <TextField
                                                        value={
                                                            record.chargingIntervalsEvaluation >= parameters.threshold_evaluation
                                                                ? "t_cm_charge_status_performed_as_intended"
                                                                : "t_cm_charge_status_did_not_perform_as_intended"
                                                        }
                                                    />
                                                    {`(>= ${parameters.threshold_evaluation * 100} %)`}
                                                </Space>
                                            }
                                        >
                                            <div>
                                                <CheckedIcon
                                                    checked={record.chargingIntervalsEvaluation >= parameters.threshold_evaluation}
                                                    colorChecked={stsColors.grey3}
                                                    colorUnchecked={stsColors.grey3}
                                                />
                                            </div>
                                        </Popover>
                                    ) : (
                                        <TextField />
                                    )}
                                </Col>
                                <Col span={8}>
                                    <NumberField value={record.connectivity} decimals={1} suffix="%" />
                                </Col>
                            </Row>
                        );
                    }
                }))
            ];

            const rows = [
                {
                    title: "t_cm_charging_management",
                    dataKey: ["chargingControlSystem"],
                    render: ({ chargingControlSystem }) =>
                        ({
                            llw: <TextField value="t_cm_charging_management_llw" />,
                            lulm: <TextField value="t_cm_charging_management_lulm" />
                        }[chargingControlSystem] ?? <TextField />)
                },
                { title: "t_cm_charging_station", dataKey: ["chargingStation"], render: ({ chargingStation }) => chargingStation },
                {
                    title: "t_cm_charging_station_valid",
                    dataKey: ["chargingStation"],
                    render: ({ chargingStation }) =>
                        chargingStation ? (
                            <Popover content={<TextField value="t_cm_fleet_vehicle_is_assigned_to_charging_station" />}>
                                <div>
                                    <CheckedIcon checked={!!chargingStation} colorChecked={stsColors.grey3} colorUnchecked={stsColors.grey3} />
                                </div>
                            </Popover>
                        ) : (
                            <TextField value="t_cm_charging_station_not_assgined" />
                        )
                },
                {
                    title: "t_cm_organisational_unit_type",
                    dataKey: ["organisationalUnitType"],
                    render: ({ organisationalUnitType }) =>
                        ({
                            operation: <TextField value="t_cm_wiki_location_post" />,
                            production: <TextField value="t_cm_wiki_location_production" />,
                            other: <TextField value="t_cm_wiki_location_test_vehicle" />
                        }[organisationalUnitType] ?? <TextField />)
                },
                {
                    title: "t_cm_distance_to_assigned_organisational_unit_valid",
                    dataKey: ["distanceToAssignedOrganisationalUnitLlw", "organisationalUnitType", "moduleParameterSetVersion"],
                    render: ({ distanceToAssignedOrganisationalUnitLlw, organisationalUnitType, moduleParameterSetVersion }) => {
                        const latestParameterSet = chargingMonitorParameters?.data?.find(
                            ({ version }) => version === moduleParameterSetVersion
                        )?.parameters;

                        if (!latestParameterSet) return <TextField />;

                        const definition = [
                            {
                                type: "operation",
                                checked: distanceToAssignedOrganisationalUnitLlw
                                    ? distanceToAssignedOrganisationalUnitLlw <= latestParameterSet.distance_limit_operation
                                    : false
                            },
                            {
                                type: "production",
                                checked: distanceToAssignedOrganisationalUnitLlw
                                    ? distanceToAssignedOrganisationalUnitLlw <= latestParameterSet.distance_limit_production
                                    : false
                            }
                        ].find(item => item.type === organisationalUnitType) ?? {
                            type: "other",
                            checked: false
                        };

                        return (
                            <Popover
                                content={
                                    <TextField
                                        value={definition.checked ? "t_cm_wiki_vehicle_close_to_llw" : "t_cm_wiki_vehicle_far_away_from_llw"}
                                    />
                                }
                            >
                                <div>
                                    <CheckedIcon checked={definition.checked} colorChecked={stsColors.grey3} colorUnchecked={stsColors.grey3} />
                                </div>
                            </Popover>
                        );
                    }
                },
                {
                    title: "t_cm_soc_range",
                    dataKey: ["socFirst", "socLast"],
                    render: ({ socFirst, socLast }) => <NumberRangeField first={socFirst} last={socLast} suffix="%" />
                },
                { title: "t_cm_soc_charged", dataKey: "socCharged", render: ({ socCharged }) => <NumberField value={socCharged} suffix="%" /> },
                {
                    title: "t_cdda_departure_time_llw",
                    dataKey: "timestampDepartureLlw",
                    render: ({ timestampDepartureLlw }) => timestampDepartureLlw
                },
                {
                    title: "t_cm_charge_completed",
                    dataKey: ["chargeCompletedCount", "chargeCompletedTimestamp"],
                    render: ({ chargeCompletedCount, chargeCompletedTimestamp }) => {
                        if (isNil(chargeCompletedCount)) return <TextField />;

                        return chargeCompletedCount > 0 && moment(chargeCompletedTimestamp).isValid() ? (
                            <Space size={5}>
                                <TextField value="t_cm_charge_completed_reached" />
                                <DatetimeField value={chargeCompletedTimestamp} formt="DD.MM.YY HH:mm:SS" />
                            </Space>
                        ) : (
                            <TextField value={"t_cm_charge_completed_not_reached"} />
                        );
                    }
                },
                {
                    title: "t_cm_organisational_unit_fmm",
                    dataKey: ["assignedOrganisationalUnitFmm"],
                    render: ({ assignedOrganisationalUnitFmm }) => <TextField value={assignedOrganisationalUnitFmm} />
                },
                {
                    title: "t_cm_organisational_unit_llw",
                    dataKey: ["assignedOrganisationalUnitLlw"],
                    render: ({ assignedOrganisationalUnitLlw }) => <TextField value={assignedOrganisationalUnitLlw} />
                },
                {
                    title: "t_cm_distance_to_assigned_organisational_unit_fmm_llw",
                    dataKey: ["distanceToAssignedOrganisationalUnitFmm", "distanceToAssignedOrganisationalUnitLlw"],
                    render: ({ distanceToAssignedOrganisationalUnitFmm, distanceToAssignedOrganisationalUnitLlw }) => (
                        <NumberRangeField first={distanceToAssignedOrganisationalUnitFmm} last={distanceToAssignedOrganisationalUnitLlw} suffix="m" />
                    )
                },
                {
                    title: "t_cm_distance_to_nearest_organisational_unit_fmm_llw",
                    dataKey: ["distanceToNearestOrganisationalUnitFmm", "distanceToNearestOrganisationalUnitLlw"],
                    render: ({ distanceToNearestOrganisationalUnitFmm, distanceToNearestOrganisationalUnitLlw }) => (
                        <NumberRangeField first={distanceToNearestOrganisationalUnitFmm} last={distanceToNearestOrganisationalUnitLlw} suffix="m" />
                    )
                },
                {
                    title: "t_telematic_control_unit",
                    dataKey: "tcu",
                    render: ({ tcu }) => tcu
                },
                {
                    title: <TextField value="t_cm_fleet_temperature_range" />,
                    dataKey: ["temperatureAmbientMin", "temperatureAmbientMax"],
                    render: ({ temperatureAmbientMin, temperatureAmbientMax }) => (
                        <NumberRangeField first={temperatureAmbientMin} last={temperatureAmbientMax} suffix="°C" />
                    )
                },
                {
                    title: "t_cm_version",
                    dataKey: "moduleParameterSetVersion",
                    render: ({ moduleParameterSetVersion: version }) => version && `v${version}`
                }
            ];

            const transpondedData = data.map(element => ({
                ...element,
                key: element[options.topLevelKey],
                ...columns.reduce(
                    (previous, current) => ({
                        ...previous,
                        [current.dataIndex]: {
                            ...pick(
                                element[options.childrenColumnName].find(item => item[options.transpondIndex] === current.dataIndex),
                                current.dataKey
                            ),
                            render: current.render
                        }
                    }),
                    {}
                ),
                children: rows.map(row => ({
                    title: row.title,
                    ...columns.reduce(
                        (previous, current) => ({
                            ...previous,
                            [current.dataIndex]: {
                                ...pick(
                                    element[options.childrenColumnName].find(item => item[options.transpondIndex] === current.dataIndex),
                                    row.dataKey
                                ),
                                render: row.render
                            }
                        }),
                        {}
                    )
                }))
            }));

            return transpondedData;
        };

        // Move totalData object into data array
        const preparedData = data.map(({ totalData, data, ...element }) => {
            if (!totalData) return { ...element, data };

            return { ...element, data: [totalData, ...data] };
        });

        return transpond(preparedData);
    };

    return (
        <Card
            title="t_cm_fleet"
            extra={<DateSelector value={selectedDate} onChange={setSelectedDate} disabledDate={date => date.isAfter(dayjs())} />}
        >
            <VehiclesTableTemplate
                queryKey="chargingMonitorFleetView"
                request={{
                    method: "GET",
                    url: datalayerUrls.fleet.widgets.list(),
                    params: { type: "chargingMonitorFleetView" },
                    filter: { ...filter, range }
                }}
                extractData={extractData}
                extractCount={data => data.statistics.count}
                view={view}
                filter={filter}
                tableLayout="fixed"
                columns={columns}
                rowKey="vin"
                size="small"
                scroll={{ x: 2250 }}
                sticky
            />
        </Card>
    );
};

CmFleetTable.propTypes = {
    filter: PropTypes.object,
    view: PropTypes.object
};

export default CmFleetTable;
