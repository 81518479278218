import React from "react";
import { merge } from "lodash";
import PropTypes from "prop-types";
import { Bullet } from "@ant-design/charts";

import { defaultBulletConfig } from "./config";

const BulletChart = ({ chartConfig }) => {
    return <Bullet {...merge({}, defaultBulletConfig, chartConfig)} />;
};

BulletChart.propTypes = {
    chartConfig: PropTypes.shape({
        data: PropTypes.array.isRequired
    }).isRequired
};

export default BulletChart;
