import styled from "styled-components";

import { ReactComponent as StreetScooterLogo } from "./streetscooter_logo.svg";
import { ReactComponent as StreetScooterLogoSmall } from "./streetscooter_logo2.svg";

import { stsColors } from "styles";

const StyledStreetScooterLogo = styled(StreetScooterLogo)(({ padding }) => ({
    fill: stsColors.black1,
    maxWidth: "200px",
    height: "unset",
    padding: padding,
    fill: stsColors.grey3
}));

const StyledStreetScooterLogoSmall = styled(StreetScooterLogoSmall)({
    fill: stsColors.grey3
});

export { StyledStreetScooterLogoSmall as StreetScooterLogoSmall, StyledStreetScooterLogo as StreetScooterLogo };
