import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Select } from "antd";

import { TextField } from "misc/fields";

import { useManufacturers } from "misc/api/datalayer";

const ManufacturerSelector = ({ defaultValue, value, onChange, allowClear = true, ...props }) => {
    const [t] = useTranslation();

    const { manufacturers, isLoading } = useManufacturers();

    return (
        <Select
            loading={isLoading}
            disabled={isLoading}
            mode="multiple"
            defaultValue={defaultValue}
            value={!isLoading && value}
            onChange={onChange}
            placeholder={t("t_select_manufacturer")}
            allowClear={allowClear}
            {...props}
        >
            {manufacturers.map(item => (
                <Select.Option key={item.id} value={item.permissionName}>
                    <TextField value={item.description} />
                </Select.Option>
            ))}
        </Select>
    );
};

ManufacturerSelector.propTypes = {
    defaultValue: PropTypes.arrayOf(PropTypes.string),
    value: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
    allowClear: PropTypes.bool
};

export default ManufacturerSelector;
