import React from "react";
import { Tooltip } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TooltipIcon = ({ icon, tooltip, ...props }) => {
    const [t] = useTranslation();

    return (
        <Tooltip title={t(tooltip)}>
            <FontAwesomeIcon icon={icon} {...props} />
        </Tooltip>
    );
};

TooltipIcon.propTypes = {
    tooltip: PropTypes.node.isRequired,
    icon: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.arrayOf(PropTypes.string)]).isRequired
};

export default TooltipIcon;
