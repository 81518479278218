import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { clientUrls } from "misc/urls";

import DiagnosticsComponent from "../components/DiagnosticsComponent";

const DiagnosticsContainer = () => {
    return (
        <Switch>
            <Route path={clientUrls.modules.diagnostics.baseUrl()}>
                <DiagnosticsComponent />
            </Route>

            <Redirect to={clientUrls.modules.diagnostics.baseUrl()} />
        </Switch>
    );
};

export default DiagnosticsContainer;
