import moment from "moment";
import PropTypes from "prop-types";
import { useLocation } from "react-router";
import { has, isNil, pickBy } from "lodash";
import { useState, useMemo, useCallback } from "react";

import { useRedirectEffect } from "misc/hooks";

import { getQueryParameter, setLocalStorageItem, getLocalStorageItem } from "misc/helpfunctions";

const useParams = ({ options = [] } = {}) => {
    const location = useLocation();

    const persistFilterItem = useCallback(
        (key, value) => {
            const persist = options.find(option => option === key || option.name === key)?.persist;

            switch (persist) {
                case "site":
                    setLocalStorageItem(`${location.pathname.replace("/", "").replaceAll("/", "-")}-${key}`, value);
                    return;

                case "module":
                    setLocalStorageItem(`${location.pathname.replace("/", "").split("/").slice(0, 2).join("-")}-${key}`, value);
                    return;

                case "global":
                    setLocalStorageItem(key, value);
                    return;

                default:
                    return;
            }
        },
        [options, location]
    );

    const getFilterItem = useCallback(
        key => {
            const queryParameter = getQueryParameter(location, key);

            if (queryParameter) {
                persistFilterItem(key, queryParameter);
            }

            return (
                queryParameter ??
                getLocalStorageItem(`${location.pathname.replace("/", "").replaceAll("/", "-")}-${key}`) ??
                getLocalStorageItem(`${location.pathname.replace("/", "").split("/").slice(0, 2).join("-")}-${key}`) ??
                getLocalStorageItem(key) ??
                options.find(option => option.name === key)?.defaultValue
            );
        },
        [options, location, persistFilterItem]
    );

    const [currentFilter, setCurrentFilter] = useState({
        fleet: getFilterItem("fleet"),
        vehicle: getFilterItem("vehicle"),
        vehicles: getFilterItem("vehicle") ? [getFilterItem("vehicle")] : getFilterItem("vehicles"),
        signals: getFilterItem("signals"),

        range: getFilterItem("range") ?? [moment().subtract(14, "days").formatDateForApi(), moment().formatDateForApi()],
        rate: getFilterItem("rate") ?? 1,
        interval: getFilterItem("interval") ?? "minute",

        view: getFilterItem("view") ?? "fleet-view",
        columns: getFilterItem("columns") ?? ["all"],
        dateOrder: getFilterItem("dateOrder") ?? "ascend",
        fixedColumn: getFilterItem("fixedColumn") ?? "vin",
        displayedDays: getFilterItem("displayedDays") ?? 7,
        displayedWeeks: getFilterItem("displayedWeeks") ?? 4,

        cddaCategories: getFilterItem("cddaCategories") ?? ["all"],
        cmCluster: getFilterItem("cmCluster") ?? ["all"],
        engineeringErrors: getFilterItem("engineeringErrors") ?? ["all"]
    });

    const filter = useMemo(
        () =>
            pickBy(currentFilter, (value, key) => {
                const option = options.find(option => option.name === key);

                if (!option) return false;

                if (has(option, "allowed")) return option.allowed(value);

                return true;
            }),
        [options, currentFilter]
    );

    useRedirectEffect(filter);

    return {
        params: filter,
        setParams: filter => {
            setCurrentFilter(filter);

            Object.entries(filter).forEach(([key, value]) => {
                if (isNil(value)) return;

                persistFilterItem(key, value);
            });
        }
    };
};

useParams.propTypes = {
    options: PropTypes.shape({
        name: PropTypes.string.isRequired,
        defaultValue: PropTypes.any,
        allowed: PropTypes.func
    })
};

export default useParams;
