import { useQuery, useMutation } from "react-query";

import { fleetconfigurationUrls } from "misc/urls";

import { queryClient } from "queryClient";

export const useSubfleets = queryProps => {
    const { data = {}, ...query } = useQuery(["subfleets"], {
        meta: { request: { method: "GET", url: fleetconfigurationUrls.subfleets.baseUrl() } },
        ...queryProps
    });

    return {
        subfleets: data ?? [],
        ...query
    };
};

export const useCreateSubfleet = () => {
    const { mutate, ...mutation } = useMutation();

    return {
        createSubfleet: (subfleet, { onSuccess, ...props } = {}) => {
            mutate(
                {
                    url: fleetconfigurationUrls.subfleets.baseUrl(),
                    method: "POST",
                    body: JSON.stringify({
                        name: subfleet.name,
                        owner: subfleet.owner,
                        organisationalUnits: subfleet.organisationalUnits,
                        vehicles: subfleet.vehicles,
                        users: subfleet.users,
                        parameters: subfleet.parameters
                    })
                },
                {
                    onSuccess: newData => {
                        queryClient.setQueryData(["subfleets"], oldData => [...oldData, newData]);
                        onSuccess && onSuccess();
                    },
                    ...props
                }
            );
        },
        ...mutation
    };
};

export const useUpdateSubfleet = () => {
    const { mutate, ...mutation } = useMutation();

    return {
        updateSubfleet: (subfleet, { onSuccess, ...props } = {}) => {
            mutate(
                {
                    url: `${fleetconfigurationUrls.subfleets.baseUrl()}${subfleet.subfleetId}/`,
                    method: "PATCH",
                    body: JSON.stringify({
                        name: subfleet.name,
                        owner: subfleet.owner,
                        organisationalUnits: subfleet.organisationalUnits,
                        vehicles: subfleet.vehicles,
                        users: subfleet.users,
                        parameters: subfleet.parameters
                    })
                },
                {
                    onSuccess: newData => {
                        queryClient.setQueryData(["subfleets"], oldData =>
                            oldData.map(subfleet => (subfleet.subfleetId === newData.subfleetId ? newData : subfleet))
                        );
                        onSuccess && onSuccess();
                    },
                    ...props
                }
            );
        },
        ...mutation
    };
};

export const useDeleteSubfleet = () => {
    const { mutate, ...mutation } = useMutation();

    return {
        deleteSubfleet: (subfleet, { onSuccess, ...props } = {}) => {
            mutate(
                {
                    method: "DELETE",
                    url: `${fleetconfigurationUrls.subfleets.baseUrl()}${subfleet.subfleetId}/`
                },
                {
                    onSuccess: () => {
                        queryClient.setQueryData(["subfleets"], oldData => oldData.filter(item => item.subfleetId !== subfleet.subfleetId));
                        onSuccess && onSuccess();
                    },
                    ...props
                }
            );
        },
        ...mutation
    };
};

export const useUnsubsribeSubfleet = () => {
    const { mutate, ...mutation } = useMutation();
    return {
        unsubsribeSubfleet: (subfleet, { onSuccess, ...props } = {}) => {
            mutate(
                {
                    method: "PUT",
                    url: `${fleetconfigurationUrls.subfleets.baseUrl()}${subfleet.subfleetId}/unsubsribe/`,
                    body: JSON.stringify({})
                },
                {
                    onSuccess: () => {
                        queryClient.setQueryData(["subfleets"], oldData => oldData.filter(item => item.subfleetId !== subfleet.subfleetId));
                        onSuccess && onSuccess();
                    },
                    ...props
                }
            );
        },
        ...mutation
    };
};
