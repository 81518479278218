import { library } from "@fortawesome/fontawesome-svg-core";

import {
    faArrowsRotate as falArrowsRotate,
    faArrowDownToLine as falArrowDownToLine,
    faArrowToRight as falArrowToRight,
    faChevronLeft as falChevronLeft,
    faChevronsLeft as falChevronsLeft,
    faChevronRight as falChevronRight,
    faCircleInfo as falCircleInfo,
    faCopy as falCopy,
    faFileArrowUp as falFileArrowUp,
    faFileExcel as falFileExcel,
    faFilePdf as falFilePdf,
    faGear as falGear,
    faGears as falGears,
    faGlobe as falGlobe,
    faGrid2 as falGrid2,
    faHeadSideBrain as falHeadSideBrain,
    faKey as falKey,
    faListUl as falListUl,
    faPenToSquare as falPenToSquare,
    faPlus as falPlus,
    faShareNodes as falShareNodes,
    faTable as falTable,
    faTrash as falTrash,
    faUser as falUser,
    faUserGear as falUserGear,
    faTimesCircle as falTimesCircle,
    faWaveSquare as falWaveSquare,
    faEmptySet as falEmptySet
} from "@fortawesome/pro-light-svg-icons";

import {
    faArrowDownToLine as farArrowDownToLine,
    faArrowRightFromBracket as farArrowRightFromBracket,
    faArrowsRotate as farArrowsRotate,
    faBatteryEmpty as farBatteryEmpty,
    faBatteryFull as farBatteryFull,
    faBatteryHalf as farBatteryHalf,
    faBatteryQuarter as farBatteryQuarter,
    faBatteryThreeQuarters as farBatteryThreeQuarters,
    faChargingStation as farChargingStation,
    faCircleXmark as farCircleXmark,
    faClock as farClock,
    faCrosshairs as farCrosshairs,
    faDisplay as farDisplay,
    faFilter as farFilter,
    faGaugeHigh as farGaugeHigh,
    faGear as farGear,
    faGlobe as farGlobe,
    faMapMarkerAlt as farMapMarkerAlt,
    faPlugCircleBolt as farPlugCircleBolt,
    faPowerOff as farPowerOff,
    faSliders as farSliders,
    faSlidersUp as farSlidersUp,
    faUser as farUser,
    faUserGear as farUserGear,
    faWaveSquare as farWaveSquare,
    faWifi as farWifi,
    faWifiSlash as farWifiSlash
} from "@fortawesome/pro-regular-svg-icons";

import {
    faArrowUpRightFromSquare as fasArrowUpRightFromSquare,
    faBan as fasBan,
    faBolt as fasBolt,
    faBatteryEmpty as fasBatteryEmpty,
    faBatteryFull as fasBatteryFull,
    faBatteryHalf as fasBatteryHalf,
    faBatteryQuarter as fasBatteryQuarter,
    faBatteryThreeQuarters as fasBatteryThreeQuarters,
    faBellExclamation as fasBellExclamation,
    faCalendarDay as fasCalendarDay,
    faCar as fasCar,
    faCarBattery as fasCarBattery,
    faCarCrash as fasCarCrash,
    faCars as fasCars,
    faCaretUp as fasCaretUp,
    faChargingStation as fasChargingStation,
    faCheck as fasCheck,
    faCircleCheck as fasCircleCheck,
    faCircleInfo as fasCircleInfo,
    faCircleQuestion as fasCircleQuestion,
    faChevronDown as fasChevronDown,
    faChevronsLeft as fasChevronsLeft,
    faChevronsRight as fasChevronsRight,
    faCircle as fasCircle,
    faCircleXmark as fasCircleXmark,
    faClipboardListCheck as fasClipboardListCheck,
    faClock as fasClock,
    faCopy as fasCopy,
    faCrosshairs as fasCrosshairs,
    faDatabase as fasDatabase,
    faBrakeWarning as fasBrakeWarning,
    faEnvelopesBulk as fasEnvelopesBulk,
    faGear as fasGear,
    faGears as fasGears,
    faGlobe as fasGlobe,
    faHouse as fasHouse,
    faIndustryWindows as fasIndustryWindows,
    faInfo as fasInfo,
    faKey as fasKey,
    faLanguage as fasLanguage,
    faListOl as fasListOl,
    faListUl as fasListUl,
    faLocationDot as fasLocationDot,
    faMagnifyingGlassChart as fasMagnifyingGlassChart,
    faMoneyCheckPen as fasMoneyCheckPen,
    faNfcMagnifyingGlass as fasNfcMagnifyingGlass,
    faPersonCarryBox as fasPersonCarryBox,
    faPlus as fasPlus,
    faPowerOff as fasPowerOff,
    faRoad as fasRoad,
    faSearch as fasSearch,
    faShareNodes as fasShareNodes,
    faSitemap as fasSitemap,
    faSignalBars as fasSignalBars,
    faSignalStream as fasSignalStream,
    faSlash as fasSlash,
    faSliders as fasSliders,
    faSpinner as fasSpinner,
    faStar as fasStar,
    faSteeringWheel as fasSteeringWheel,
    faStethoscope as fasStethoscope,
    faStopwatch as fasStopwatch,
    faTachometerAlt as fasTachometerAlt,
    faTachometerAltFastest as fasTachometerAltFastest,
    faTally as fasTally,
    faTools as fasTools,
    faTriangleExclamation as fasTriangleExclamation,
    faTruck as fasTruck,
    faTruckFront as fasTruckFront,
    faTruckBolt as fasTruckBolt,
    faUnlock as fasUnlock,
    faUser as fasUser,
    faUsers as fasUsers,
    faUsersGear as fasUsersGear,
    faUserLock as fasUserLock,
    faUserCheck as fasUserCheck,
    faUserGear as fasUserGear,
    faUserHelmetSafety as fasUserHelmetSafety,
    faUserPlus as fasUserPlus,
    faUserSlash as fasUserSlash,
    faWarehouseFull as fasWarehouseFull,
    faWifi as fasWifi,
    faWrench as fasWrench,
    faWifiSlash as fasWifiSlash,
    faXmark as fasXmark
} from "@fortawesome/pro-solid-svg-icons";

library.add(
    // Light
    falArrowsRotate,
    falArrowDownToLine,
    falArrowToRight,
    falChevronLeft,
    falChevronsLeft,
    falChevronRight,
    falCircleInfo,
    falCopy,
    falFileArrowUp,
    falFileExcel,
    falFilePdf,
    falGear,
    falGears,
    falGlobe,
    falGrid2,
    falHeadSideBrain,
    falKey,
    falListUl,
    falPenToSquare,
    falPlus,
    falShareNodes,
    falTable,
    falTrash,
    falTimesCircle,
    falUser,
    falUserGear,
    falWaveSquare,
    falEmptySet,

    // Regular
    farArrowDownToLine,
    farArrowRightFromBracket,
    farArrowsRotate,
    farBatteryEmpty,
    farBatteryFull,
    farBatteryHalf,
    farBatteryQuarter,
    farBatteryThreeQuarters,
    farChargingStation,
    farCircleXmark,
    farClock,
    farCrosshairs,
    farDisplay,
    farFilter,
    farGaugeHigh,
    farGear,
    farGlobe,
    farMapMarkerAlt,
    farPlugCircleBolt,
    farPowerOff,
    farSliders,
    farSlidersUp,
    farUser,
    farUserGear,
    farWaveSquare,
    farWifi,
    farWifiSlash,

    // Solid
    fasArrowUpRightFromSquare,
    fasBan,
    fasBolt,
    fasBatteryEmpty,
    fasBatteryFull,
    fasBatteryHalf,
    fasBatteryQuarter,
    fasBatteryThreeQuarters,
    fasBellExclamation,
    fasBolt,
    fasCalendarDay,
    fasCarBattery,
    fasCar,
    fasCarCrash,
    fasCars,
    fasCaretUp,
    fasChargingStation,
    fasCheck,
    fasCircleCheck,
    fasCircleInfo,
    fasCircleXmark,
    fasChevronDown,
    fasChevronsLeft,
    fasChevronsRight,
    fasCircle,
    fasCircleQuestion,
    fasClipboardListCheck,
    fasClock,
    fasCopy,
    fasCrosshairs,
    fasDatabase,
    fasBrakeWarning,
    fasEnvelopesBulk,
    fasGear,
    fasGears,
    fasGlobe,
    fasHouse,
    fasIndustryWindows,
    fasInfo,
    fasKey,
    fasLanguage,
    fasListOl,
    fasListUl,
    fasLocationDot,
    fasMagnifyingGlassChart,
    fasMoneyCheckPen,
    fasNfcMagnifyingGlass,
    fasPersonCarryBox,
    fasPlus,
    fasPowerOff,
    fasRoad,
    fasSearch,
    fasShareNodes,
    fasSitemap,
    fasSlash,
    fasSliders,
    fasSignalBars,
    fasSignalStream,
    fasSpinner,
    fasStar,
    fasSteeringWheel,
    fasStethoscope,
    fasStopwatch,
    fasTachometerAlt,
    fasTachometerAltFastest,
    fasTally,
    fasTools,
    fasTriangleExclamation,
    fasTruck,
    fasTruckBolt,
    fasTruckFront,
    fasUnlock,
    fasUser,
    fasUsers,
    fasUsersGear,
    fasUserCheck,
    fasUserGear,
    fasUserHelmetSafety,
    fasUserLock,
    fasUserPlus,
    fasUserSlash,
    fasWarehouseFull,
    fasWifi,
    fasWrench,
    fasWifiSlash,
    fasXmark
);
