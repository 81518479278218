import { QueryClient } from "react-query";

import { datalayerUrls } from "misc/urls";
import { buildUrl } from "misc/helpfunctions";

import { keycloak } from "./keycloak";

const defaultQueryFn = query => {
    // TODO: Remove this workaround and use unique querykeys

    const request = query.meta?.request ?? query.queryKey[0];

    const { url, params, filter } = request;

    const headers = {
        "Content-Type": "application/json",
        Authorization: keycloak.token
    };

    if (filter) headers.filter = JSON.stringify(filter);

    // To cancel running fetches
    const controller = new AbortController();

    const promise = new Promise(async (resolve, reject) => {
        try {
            const response = await fetch(buildUrl({ path: url, queryParameters: params, props: { arrayFormat: "bracket" } }), {
                headers,
                signal: controller.signal
            });

            if (!response.ok) {
                return reject({ status: response.status, statusText: response.statusText });
            }

            const data = await response.json();

            // Specific for backend service datalayer
            if (url.includes(datalayerUrls.baseUrl())) return resolve(params?.type ? data[params.type] : resolve(data));

            return resolve(data);
        } catch (error) {
            if (error && error.name === "AbortError") {
                reject(new Error(error.name));
            }
            // reject(new Error(error.name));
        }
    });

    promise.cancel = () => {
        controller.abort();
    };

    return promise;
};

const defaultMutationFn = request => {
    const { url, params, filter, headers = {} } = request;

    const defaultHeaders = {
        "Content-Type": "application/json",
        Authorization: keycloak.token
    };

    if (filter) headers.filter = JSON.stringify(filter);

    // To cancel running fetches
    const controller = new AbortController();

    const promise = new Promise(async (resolve, reject) => {
        try {
            const response = await fetch(buildUrl({ path: url, queryParameters: params, props: { arrayFormat: "bracket" } }), {
                ...request,
                headers: { ...defaultHeaders, ...headers },
                signal: controller.signal
            });

            if (!response.ok) {
                return reject({ status: response.status, statusText: response.statusText });
            }

            if (response.status === 204) resolve();

            const data = await (headers["Content-Type"] === "application/zip" ? response.blob() : response.json());

            // Specific for backend service datalayer
            if (url.includes(datalayerUrls.baseUrl())) return resolve(params?.type ? data[params.type] : resolve(data));

            return resolve(data);
        } catch (error) {
            if (error && error.name === "AbortError") {
                reject(new Error(error.name));
            }
            // reject(new Error(error.name));
        }
    });

    promise.cancel = () => {
        controller.abort();
    };

    return promise;
};

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            queryFn: defaultQueryFn,
            retry: false,
            refetchOnWindowFocus: false,
            refetchIntervalInBackground: true,
            refetchInterval: 6 * 3600 * 1000, // 6h
            staleTime: 1 * 3600 * 1000, // 1h
            refetchOnMount: false
        },
        mutations: {
            mutationFn: defaultMutationFn
        }
    }
});
