import React from "react";
import { Switch, Route, Redirect } from "react-router";

import { clientUrls } from "misc/urls";

import CddaComponent from "../components/CddaComponent";
import CddaFleetConfigurationComponent from "../components/CddaFleetConfigurationComponent";

const CddaContainer = () => {
    return (
        <Switch>
            <Route exact path={clientUrls.modules.cdda.baseUrl()}>
                <CddaComponent />
            </Route>
            <Route exact path={clientUrls.modules.cdda.fleetConfiguration()}>
                <CddaFleetConfigurationComponent />
            </Route>

            <Redirect to={clientUrls.modules.cdda.baseUrl()} />
        </Switch>
    );
};

export default CddaContainer;
