import React from "react";
import { Space } from "antd";
import { isNil } from "lodash";
import PropTypes from "prop-types";

import { TextField } from "misc/fields";

const RangeField = ({ first, last, render = value => value, renderFirst, renderLast, separator = "/" }) => {
    if (isNil(first) && isNil(last)) return <TextField value="-" />;

    return (
        <p>
            <Space>
                {renderFirst ? renderFirst(first) : render(first)}
                <TextField value={separator} />
                {renderLast ? renderLast(last) : render(last)}
            </Space>
        </p>
    );
};

RangeField.propTypes = {
    first: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    last: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    renderFirst: PropTypes.func,
    renderLast: PropTypes.func,
    separator: PropTypes.string
};

export default RangeField;
