import { useMemo } from "react";
import { Col, Row } from "antd";

import { Card } from "misc/card";
import { Result } from "misc/results";
import { useParams } from "misc/hooks";
import { clientUrls, datalayerUrls } from "misc/urls";
import { Selector, MultiSelector } from "misc/selectors";
import { CmDashboardTable } from "misc/widgets/consumers";
import { useSubfleets } from "misc/api/fleetconfiguration";

import ModuleSettings from "modules/ModuleSettings";
import SideHeader from "navigation/SideHeader";

const VIEW_ITEMS = [
    {
        name: "displayedDays",
        label: "t_cm_displayed_days",
        render: () => (
            <Selector
                options={[
                    { value: 7, label: "t_days_7" },
                    { value: 10, label: "t_days_10" },
                    { value: 14, label: "t_days_14" }
                ]}
            />
        )
    },
    {
        name: "dateOrder",
        label: "t_cm_date_order",
        render: () => (
            <Selector
                options={[
                    { value: "asc", label: "t_ascend" },
                    { value: "desc", label: "t_descend" }
                ]}
            />
        )
    },
    {
        name: "cmCluster",
        label: "t_cm_cluster",
        render: () => (
            <MultiSelector
                options={[
                    { value: "post", label: "t_cm_wiki_location_post" },
                    { value: "production", label: "t_cm_wiki_location_production" },
                    { value: "test", label: "t_cm_wiki_location_test_vehicle" }
                ]}
                maxTagCount={1}
            />
        )
    }
];

const CmDashboardComponent = () => {
    const { subfleets } = useSubfleets({ suspense: true });

    const { params, setParams } = useParams({
        options: [
            { name: "fleet", persist: "global" },
            { name: "cmCluster", defaultValue: ["all"], persist: "module" },
            { name: "displayedDays", defaultValue: "7", persist: "site" },
            { name: "dateOrder", defaultValue: "desc", persist: "site" }
        ]
    });

    const fleet = useMemo(() => subfleets.find(fleet => fleet.subfleetId === params.fleet), [params.fleet, subfleets]);

    const exportItem = useMemo(
        () => ({
            label: "t_cm_dashboard",
            columns: [
                { value: "date", label: "t_date" },
                { value: "case_1_1_1", label: "1.1.1" },
                { value: "case_1_1_2", label: "1.1.2" },
                { value: "case_1_2_1", label: "1.2.1" },
                { value: "case_1_2_2", label: "1.2.2" },
                { value: "case_1_3_1", label: "1.3.1" },
                { value: "case_1_3_2", label: "1.3.2" },
                { value: "case_1_4_1", label: "1.4.1" },
                { value: "case_1_4_2", label: "1.4.2" },
                { value: "case_1_5", label: "1.5" },
                { value: "case_2_1_2", label: "2.1.2" },
                { value: "case_2_2_1", label: "2.2.1" },
                { value: "case_2_2_2", label: "2.2.2" },
                { value: "case_2_3_1", label: "2.3.1" },
                { value: "case_2_3_2", label: "2.3.2" },
                { value: "case_2_4", label: "2.4" },
                { value: "case_3_1", label: "3.1" },
                { value: "case_3_2", label: "3.2" },
                { value: "case_3_3", label: "3.3" }
            ],
            request: {
                method: "GET",
                url: datalayerUrls.fleet.widgets.list(),
                params: { type: "chargingMonitorDashboardExport" },
                filter: {
                    hierarchy: fleet?.organisationalUnits,
                    vehicles: fleet?.vehicles,
                    range: fleet?.parameters.cddaRange
                }
            }
        }),
        [fleet]
    );

    return (
        <SideHeader>
            <Row gutter={[10, 10]}>
                <Col span={24}>
                    <ModuleSettings
                        title="t_cm"
                        values={params}
                        fleetOptions={{ redirectUrl: clientUrls.modules.cm.fleetConfiguration() }}
                        onChange={values => setParams({ ...params, ...values })}
                        viewOptions={{ items: VIEW_ITEMS }}
                        exportOptions={{ export: exportItem }}
                    />
                </Col>

                {fleet ? (
                    <Col span={24}>
                        <CmDashboardTable
                            filter={{ hierarchy: fleet.organisationalUnits, vehicles: fleet.vehicles, range: fleet.parameters.cddaRange }}
                            view={{ cmCluster: params.cmCluster, displayedDays: params.displayedDays, dateOrder: params.dateOrder }}
                        />
                    </Col>
                ) : (
                    <Col span={24}>
                        <Card height={500}>
                            <Result type="noFleetSelected" url={clientUrls.modules.cm.fleetConfiguration()} />
                        </Card>
                    </Col>
                )}
            </Row>
        </SideHeader>
    );
};

export default CmDashboardComponent;
