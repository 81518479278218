import React, { useMemo } from "react";
import { useQuery } from "react-query";
import PropTypes from "prop-types";
import _ from "lodash";

import { Card } from "misc/card";
import { request } from "misc/propTypes";
import { RouteMap } from "misc/map";

const RouteMapWidget = ({ title, request, extractData, extractLastUpdate = () => null, height = 750, isStandalone = true, queryProps }) => {
    const { data, isLoading, isRefetching, isError } = useQuery([request], queryProps);

    const extractedData = useMemo(() => (data ? (_.isFunction(extractData) ? extractData(data) : data[extractData]) : []), [data, extractData]);

    const extractedLastUpdate = useMemo(
        () => (data ? (_.isFunction(extractLastUpdate) ? extractLastUpdate(data) : data[extractLastUpdate]) : null),
        [data, extractLastUpdate]
    );

    const Component = () => <RouteMap height={0.8 * height} features={extractedData} isLoading={isLoading} />;

    if (isStandalone)
        return (
            <Card title={title} height={height} isUpdating={isRefetching} isError={isError} lastUpdate={extractedLastUpdate}>
                <Component />
            </Card>
        );

    return <Component />;
};

RouteMapWidget.propTypes = {
    title: PropTypes.string,
    request: request.isRequired,
    extractData: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    extractLastUpdate: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    height: PropTypes.number,
    isStandalone: PropTypes.bool,
    queryProps: PropTypes.object
};

export default RouteMapWidget;
