import React, { Component } from "react";
import Moment from "react-moment";
import PropTypes from "prop-types";
import moment from "moment";

class IntervalField extends Component {
    render() {
        const props = this.props;

        const start = typeof props.start === "number" ? moment.unix(props.start)._d : props.start;
        const end = typeof props.end === "number" ? moment.unix(props.end)._d : props.end;

        return (
            <p className={props.className} style={props.style}>
                <Moment duration={start} date={end} />
            </p>
        );
    }
}

IntervalField.propTypes = {
    start: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    end: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default IntervalField;
