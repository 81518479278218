import React from "react";
import { Select as AntSelect } from "antd";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { TextField } from "misc/fields";

const Selector = ({ defaultValue = [], value = [], onChange = () => null, options = [], ...props }) => {
    const [t] = useTranslation();

    return (
        <AntSelect
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            placeholder={t("t_selector_placeholder_default")}
            style={{ width: "100%" }}
            {...props}
        >
            {options.map(option => (
                <AntSelect.Option key={option.value} value={option.value}>
                    <TextField value={option.label} />
                </AntSelect.Option>
            ))}
        </AntSelect>
    );
};

Selector.propTypes = {
    defaultValue: PropTypes.array,
    value: PropTypes.array,
    onChange: PropTypes.func,
    options: PropTypes.array
};

export default Selector;
