import React from "react";
import { Switch, Route, Redirect } from "react-router";

import { clientUrls } from "misc/urls";

import DefectTicketsComponent from "../components/DefectTicketsComponent";
import DefectTicketsFleetConfigurationComponent from "../components/DefectTicketsFleetConfigurationComponent";

const DefectTicketsContainer = () => {
    return (
        <Switch>
            <Route exact path={clientUrls.modules.defectTickets.baseUrl()}>
                <DefectTicketsComponent />
            </Route>

            <Route exact path={clientUrls.modules.defectTickets.fleetConfiguration()}>
                <DefectTicketsFleetConfigurationComponent />
            </Route>

            <Redirect to={clientUrls.modules.defectTickets.baseUrl()} />
        </Switch>
    );
};

export default DefectTicketsContainer;
