import React from "react";
import moment from "moment";
import PropTypes from "prop-types";

import { TextField } from "misc/fields";

const DatetimeField = ({ value, format = "DD.MM.YY HH:mm:ss", UTCToLocal = true, ...props }) => {
    const dateTime = UTCToLocal ? moment.utc(value).local() : moment(value);

    return <TextField value={value ? dateTime.format(format) : null} {...props} />;
};

DatetimeField.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(moment)]).isRequired,
    format: PropTypes.string,
    UTCToLocal: PropTypes.bool
};

export default DatetimeField;
