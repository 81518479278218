import React from "react";
import { merge } from "lodash";

import { ErrorWidget } from "misc/widgets/consumers";
import { standard as colors, stsColors } from "misc/styles/Colors";

const defaultChartConfig = {
    autofit: true,
    // renderer: "svg", // default: canvas
    legend: {
        layout: "horizontal",
        position: "bottom",
        flipPage: false,
        padding: [20, 0, 0, 0]
    },
    meta: {
        date: {
            // type: "timeCat",
            formatter: v => (v.includes("-") ? new Date(v).toLocaleDateString("de-DE", { day: "2-digit", month: "2-digit", year: "2-digit" }) : v) // format date
        },
        week: {
            formatter: v => v
        }
        // date: {
        //     formatter: v => moment(v).format("DD.MM.YY")
        // } // Preparation for new backend type "week" / "date"
    },
    // animation: false,
    color: stsColors.blue, //Object.values(stsColorsWithoutWhite),
    label: {
        position: "middle",
        formatter: ({ value, count }) => (value > 3 || count > 3 ? value || count : null)
    },
    errorTemplate: err => <ErrorWidget error={err} />,
    // theme: {
    //     defaultColor: stsColors.blue,
    //     colors10: Object.values(stsColors),
    //     colors20: [...Object.values(stsColors), ...colors]
    // },
    appendPadding: [5, 0],
    animation: false
};

export const defaultBarConfig = merge({}, defaultChartConfig, {
    color: stsColors.blue
});

export const defaultBulletConfig = merge({}, defaultChartConfig, {
    height: 60,
    xAxis: { line: null },
    yAxis: false,
    tooltip: false,
    color: {
        range: stsColors.grey1,
        measure: stsColors.blue,
        target: stsColors.blue
    }
});

export const defaultColumnConfig = merge({}, defaultChartConfig, {
    color: stsColors.blue
});

export const defaultDualAxesConfig = merge({}, defaultChartConfig, {
    tooltip: {
        // marker: { symbol: "square" },
        domStyles: { "g2-tooltip-marker": { borderRadius: 0 } } // same as symbol: square
    }
});

export const defaultLineConfig = merge({}, defaultChartConfig, {
    color: colors,
    label: false // remove label from LineChart
});

export const defaultPieChartConfig = merge({}, defaultChartConfig, {
    radius: 0.6,
    innerRadius: 0.8,
    statistic: { title: null },
    legend: {
        marker: {
            symbol: "square"
        },
        padding: [5, 0, 0, 0]
    }
});

export default defaultChartConfig;
