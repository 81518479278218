import moment from "moment";
import { isNaN } from "lodash";
import styled from "styled-components";
import { Collapse, Progress, Space } from "antd";
import React, { useState, useEffect, useMemo } from "react";

import { Card } from "misc/card";
import { TransparentButton } from "misc/buttons";
import { TextField, NumberField } from "misc/fields";
import { CheckAppPermissions } from "misc/authentication";
import { RangeSelector, SamplingRateSelector, VehicleSelector, SignalSelector } from "misc/selectors";

import { stsColors } from "styles";

const MAX_DATA_POINTS = 400000;

const SuccessTextWrapper = styled.div({
    color: stsColors.grey3
});

const ExceptionWrapper = styled.div({
    color: stsColors.red2
});

// const RESTRICTED_SIGNALS = [
//     "online_status",
//     "ican_550_bcm_c2c_uacmains_bool",
//     "ican_550_bcm_c2c_iacmains_bool",
//     "ICAN_510_BCM_C2C_tAmbient",
//     "input_kl15b",
//     "gps_latitude",
//     "gps_longitude",
//     "ICAN_560_BCM_C2C_percSoc",
//     "input_kl15a",
//     "event_LVBatteryLow",
//     "event_EngineError",
//     "event_TurtleLamp",
//     "event_PlugWithoutACVoltage",
//     "event_HvOverheating",
//     "event_EmmcNotMounted",
//     "event_ShutdownNoLvDetected",
//     "event_ShutdownStopPeriodicWakeup",
//     "event_RebootEmmcMountFailure",
//     "event_EmergencyChargeRequest",
//     "event_GpsDefective",
//     "event_CanDefective",
//     "event_PreconDefective",
//     "event_SleepModeActivated",
//     "event_BoxWasOffline",
//     "event_ForcedChargingEnabled",
//     "event_FirstBoot",
//     "charger_power_ack",
//     "power_applied",
//     "current_applied",
//     "ICAN_382_batt_ch_completed"
// ];

const EngineeringSignalsHeader = ({ params, setParams }) => {
    const [currentRange, setCurrentRange] = useState(params.range);
    const [currentRate, setCurrentRate] = useState(params.rate);
    const [currentInterval, setCurrentInterval] = useState(params.interval);
    const [currentVehicles, setCurrentVehicles] = useState(params.vehicles);
    const [currentSignals, setCurrentSignals] = useState(params.signals);

    useEffect(() => window.scrollTo(0, 0), []);

    const dataPoints = useMemo(() => {
        const seconds = moment(currentRange[1]).unix() - moment(currentRange[0]).unix();

        const dataPoints = isNaN(seconds / currentRate) ? 0 : seconds / currentRate;

        switch (currentInterval) {
            case "minute":
                return dataPoints / 60;

            case "hour":
                return dataPoints / 3600;

            case "day":
                return dataPoints / 86400;

            case "second":
                return dataPoints;

            default:
                return 0;
        }
    }, [currentRange, currentRate, currentInterval]);

    return (
        <Card>
            <Space direction="vertical" size={20} style={{ width: "100%" }}>
                <Space direction="vertical" size={5} style={{ width: "100%" }}>
                    <CheckAppPermissions
                        allowed={["permission_module_engineering-extended"]}
                        fallback={
                            <RangeSelector
                                value={currentRange}
                                onChange={setCurrentRange}
                                allowClear={false}
                                ranges={["lastDay", "lastThreeDays", "lastWeek"]}
                                minDate={moment().subtract(14, "days")}
                            />
                        }
                    >
                        <RangeSelector
                            value={currentRange}
                            onChange={setCurrentRange}
                            allowClear={false}
                            ranges={["lastDay", "lastThreeDays", "lastWeek"]}
                        />
                    </CheckAppPermissions>

                    <CheckAppPermissions
                        allowed={["permission_module_engineering-extended"]}
                        fallback={
                            <VehicleSelector value={currentVehicles} onChange={value => value.length <= 2 && setCurrentVehicles(value)} multiselect />
                        }
                    >
                        <VehicleSelector value={currentVehicles} onChange={value => value.length <= 5 && setCurrentVehicles(value)} multiselect />
                    </CheckAppPermissions>

                    <CheckAppPermissions
                        allowed={["permission_module_engineering-extended"]}
                        fallback={
                            <SignalSelector
                                value={currentSignals}
                                onChange={value => value.length <= 8 && setCurrentSignals(value)}
                                restrictions={signal => signal.name.includes("normalized")}
                                mode="multiple"
                            />
                        }
                    >
                        <SignalSelector value={currentSignals} onChange={value => value.length <= 8 && setCurrentSignals(value)} mode="multiple" />
                    </CheckAppPermissions>
                </Space>

                <Collapse>
                    <Collapse.Panel key="extendedSettings" header={<TextField value="t_vlv_signals_header_extended_settings" />}>
                        <Space direction="vertical" size={10} style={{ width: "100%" }}>
                            <TextField value="t_vlv_signals_sampling_rate_max" />
                            <SamplingRateSelector
                                value={{ rate: currentRate, interval: currentInterval }}
                                onChange={({ rate, interval }) => {
                                    setCurrentRate(rate);
                                    setCurrentInterval(interval);
                                }}
                                style={{ width: "20%" }}
                            />
                        </Space>
                    </Collapse.Panel>
                </Collapse>

                <Progress
                    percent={(dataPoints / MAX_DATA_POINTS) * 100}
                    strokeColor={dataPoints > MAX_DATA_POINTS ? stsColors.red2 : stsColors.grey1}
                    status={dataPoints > MAX_DATA_POINTS ? "exception" : "success"}
                />
            </Space>

            <Space direction="vertical" size={40}>
                {dataPoints > MAX_DATA_POINTS ? (
                    <ExceptionWrapper>
                        <Space size={1}>
                            <NumberField value={dataPoints} inline />
                            <NumberField prefix="&nbsp;/" value={MAX_DATA_POINTS} suffix="&nbsp;" inline />
                            <TextField value="t_vlv_signals_error_too_much_datapoints" inline />
                        </Space>
                    </ExceptionWrapper>
                ) : (
                    <SuccessTextWrapper>
                        <Space size={1}>
                            <NumberField value={dataPoints} inline />
                            <NumberField prefix="&nbsp;/" value={MAX_DATA_POINTS} suffix="&nbsp;" inline />
                            <TextField value="t_vlv_signals_data_points_max" inline />
                        </Space>
                    </SuccessTextWrapper>
                )}

                <TransparentButton
                    disabled={
                        !currentVehicles.length ||
                        !currentRange.length ||
                        !currentSignals.length ||
                        !currentInterval ||
                        !currentRate ||
                        dataPoints > MAX_DATA_POINTS
                    }
                    onClick={() => {
                        setParams({
                            range: currentRange,
                            rate: currentRate,
                            interval: currentInterval,
                            vehicles: currentVehicles,
                            signals: currentSignals
                        });
                    }}
                >
                    <TextField value="t_apply" />
                </TransparentButton>
            </Space>
        </Card>
    );
};

export default EngineeringSignalsHeader;
