import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Spin, Upload, notification, Space } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { TextField } from "misc/fields";
import { Transfer } from "misc/transfer";
import { useVehicles } from "misc/api/datalayer";
import { ConditionalWrapper } from "misc/conditional";

const VehicleTransfer = ({ defaultValue, value, onChange = () => null, isLoading, disabled, ...props }) => {
    const [t] = useTranslation();
    const { vehicles } = useVehicles({ suspense: true });

    const [targetKeys, setTargetKeys] = useState(value ?? defaultValue ?? []);

    useEffect(() => setTargetKeys(value), [value]);

    const beforeUpload = file => {
        const reader = new FileReader();

        const openNotificationWithIcon = (type, message, description) => {
            notification[type]({
                message: message && <TextField value={message} />,
                description: description && <TextField value={description} />
            });
        };

        const parseCSV = text => text.split("\n").map(line => line.split(/[;,]/).filter(item => item.length > 0));

        reader.onload = e => {
            const vehicles = parseCSV(e.target.result).flat();

            console.log(vehicles);

            const sortedVehicles = vehicles.reduce(
                (result, current) =>
                    current.length !== 17 ? { ...result, invalid: [...result.invalid, current] } : { ...result, valid: [...result.valid, current] },
                { valid: [], invalid: [] }
            );

            if (sortedVehicles.invalid.length === 0) {
                openNotificationWithIcon("success", "t_vehicle_transfer_csv_import_success");
            } else {
                openNotificationWithIcon("warning", "t_vehicle_transfer_csv_import_error", `${sortedVehicles.invalid.join("\n")}`);
            }

            onChange(sortedVehicles.valid);
        };

        reader.onerror = e => openNotificationWithIcon("error", "t_read_csv_file_error", e.target.error);

        reader.readAsText(file);

        // Prevent upload
        return false;
    };

    return (
        <ConditionalWrapper condition={isLoading || disabled} wrapper={children => <Spin indicator={null}>{children}</Spin>}>
            <Transfer
                {...props}
                listStyle={{
                    width: "100%"
                }}
                dataSource={vehicles}
                rowKey={item => item.vin}
                render={item =>
                    `${item.vin} | ${item.licensePlate ?? t("t_license_plate_missing")} | ${
                        item.organisationalUnit?.name ?? t("t_organisational_unit_missing")
                    }`
                }
                targetKeys={targetKeys}
                titles={[t("t_available_vehicles"), t("t_selected_vehicles")]}
                onChange={targetKeys => {
                    setTargetKeys(targetKeys);
                    onChange(targetKeys);
                }}
                filterOption={(inputValue, option) => {
                    const input = inputValue.toLowerCase();

                    if (input.length < 3) return true;

                    return (
                        option.vin?.toLowerCase().includes(input) ||
                        option.licensePlate?.toLowerCase().includes(input) ||
                        option.organisationalUnit?.name?.toLowerCase().includes(input)
                    );
                }}
                disabled={disabled}
                pagination={{ pageSize: 10 }}
                footer={({ direction }) =>
                    direction === "right" && (
                        <Upload accept=".csv" beforeUpload={beforeUpload} showUploadList={false}>
                            <div style={{ padding: 10, cursor: "pointer" }}>
                                <Space>
                                    <FontAwesomeIcon icon={["fal", "file-arrow-up"]} />
                                    <TextField value="t_fleet_configuration_import_vehicles_via_csv" />
                                </Space>
                            </div>
                        </Upload>
                    )
                }
            />
        </ConditionalWrapper>
    );
};

VehicleTransfer.propTypes = {
    defaultValue: PropTypes.array,
    value: PropTypes.array,
    onChange: PropTypes.func,
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool
};

export default VehicleTransfer;
