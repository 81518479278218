import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Result as AntResult, Avatar, Button, Space } from "antd";
import PropTypes from "prop-types";

import { TextField } from "misc/fields";
import { StackIcon } from "misc/icons";
import { stsColors } from "styles";

const Result = ({ type, url, ...props }) => {
    switch (type) {
        case "noFleetSelected":
            return (
                <AntResult
                    title={<TextField value="t_result_title_no_fleet_selected" />}
                    subTitle={<TextField value="t_result_sub_title_no_fleet_selected" />}
                    icon={<Avatar size={70} icon={<FontAwesomeIcon icon={["fas", "truck"]} />} style={{ background: stsColors.blue2 }} />}
                    extra={[
                        <Link to={url}>
                            <Button type="primary" style={{ background: stsColors.blue2 }}>
                                <Space>
                                    {/* Workaround: icon blur instead of white when screen width change */}
                                    <FontAwesomeIcon style={{ transform: "rotate(0.03deg)" }} icon={["fal", "plus"]} />
                                    <TextField value="t_fleet_create" />
                                </Space>
                            </Button>
                        </Link>
                    ]}
                />
            );

        case "noVehicleSelected":
            return (
                <AntResult
                    title={<TextField value="t_result_title_no_vehicle_selected" />}
                    subTitle={<TextField value="t_result_sub_title_no_vehicle_selected" />}
                    icon={<Avatar size={70} icon={<FontAwesomeIcon icon={["fas", "truck"]} />} style={{ background: stsColors.blue2 }} />}
                />
            );

        case "roleExpired":
            return (
                <AntResult
                    title={<TextField value="t_result_title_role_expired" />}
                    subTitle={<TextField value="t_result_sub_title_role_expired" />}
                    icon={
                        <Avatar
                            size={70}
                            icon={
                                <StackIcon>
                                    <FontAwesomeIcon icon={["fas", "money-check-pen"]} />
                                    <FontAwesomeIcon icon={["fas", "slash"]} color={stsColors.blue} style={{ position: "absolute", top: "6px" }} />
                                    <FontAwesomeIcon icon={["fas", "slash"]} />
                                </StackIcon>
                            }
                        />
                    }
                />
            );

        default:
            return <AntResult {...props} />;
    }
};

Result.propTypes = {
    type: PropTypes.string.isRequired,
    url: PropTypes.string
};

export default Result;
