import dayjs from "dayjs";
import PropTypes from "prop-types";
import { Space, Radio } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { TextField } from "misc/fields";
import { DateSelector } from "misc/selectors";

const ExpirationSelector = ({ defaultValue, value, onChange = () => null, ...props }) => {
    const [t] = useTranslation();

    const [date, setDate] = useState(dayjs());
    const [selectedOption, setSelectedOption] = useState();

    useEffect(() => {
        if (value === "unlimited") {
            setSelectedOption("unlimited");
        } else if (dayjs(value).isValid()) {
            setDate(dayjs(value));
            setSelectedOption("limited");
        }
    }, [value]);

    const onChangeSelectedOption = option => {
        onChange(option === "unlimited" ? option : date.format("YYYY-MM-DD"));
        setSelectedOption(option);
    };

    const onChangeDate = date => {
        onChange(date.format("YYYY-MM-DD"));
        setDate(date);
    };

    return (
        <Space direction="vertical" size={15} style={{ width: "100%" }}>
            <Radio.Group value={selectedOption} onChange={e => onChangeSelectedOption(e.target.value)}>
                <Space direction="vertical">
                    <Radio value="unlimited">
                        <TextField value="t_role_expiration_unlimited" />
                    </Radio>
                    <Radio value="limited">
                        <TextField value="t_role_expiration_limited" />
                    </Radio>
                </Space>
            </Radio.Group>

            {selectedOption === "limited" && (
                <DateSelector
                    value={date}
                    onChange={onChangeDate}
                    placeholder={t("t_role_expiration_placeholder")}
                    showToday={false}
                    disabledDate={date => !dayjs(date).isAfter(dayjs())}
                    disableShortcuts={false}
                    {...props}
                />
            )}
        </Space>
    );
};

ExpirationSelector.propTypes = {
    defaultValue: PropTypes.any,
    value: PropTypes.any,
    onChange: PropTypes.func
};

export default ExpirationSelector;
