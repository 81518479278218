import { Table as AntTable, Skeleton } from "antd";
import React, { useMemo, useState, useEffect } from "react";

import { Error } from "misc/error";
import { Empty } from "misc/empty";

const Table = ({ dataSource, isLoading, isUpdating, isError, empty, columns, onChange, enumerate = true, stretchWidth = true, ...props }) => {
    const [pagination, setPagination] = useState(null);

    useEffect(() => {
        props.pagination &&
            setPagination({
                ...pagination,
                ...props.pagination
            });
    }, [props.pagination]);

    const topLevelRows = useMemo(() => dataSource?.map(({ key }) => key) || [], [dataSource]);

    const transformedColumns = useMemo(() => {
        let cols = columns.map(column => ({
            ...column,
            render: (text, record, index) => {
                const value = column?.render ? column?.render(text, record, index) : text;

                return value;
            }
        }));

        if (enumerate)
            cols = [
                {
                    key: "index",
                    title: "#",
                    render: (text, record, index) => {
                        if (record.enumerate === false) return;

                        if (!topLevelRows.includes(record.key)) return;
                        const currentPage = pagination?.current ?? 1;
                        const currentPageSize = pagination?.pageSize ?? pagination?.defaultPageSize ?? 20;
                        return (currentPage - 1) * currentPageSize + index + 1;
                    },
                    width: 80,
                    fixed: true
                },
                ...cols
            ];

        if (stretchWidth) cols = [...cols, { title: null }];

        return cols;
    }, [pagination, columns, enumerate]);

    return (
        <AntTable
            dataSource={dataSource}
            columns={transformedColumns}
            locale={{
                emptyText: isLoading ? (
                    <Skeleton active title={false} paragraph={{ rows: props.pagination?.pageSize ?? 20, width: "100%" }} />
                ) : isError ? (
                    <Error />
                ) : isUpdating ? (
                    " "
                ) : (
                    <Empty title={empty?.title} subTitle={empty?.subTitle} />
                )
            }}
            loading={isUpdating}
            onChange={(paginationUpdate, filters, sorter, extra) => {
                const newPagination = {
                    ...props.pagination,
                    ...pagination,
                    ...paginationUpdate
                };
                setPagination(newPagination);
                onChange && onChange(newPagination, filters, sorter, extra);
            }}
            showSorterTooltip={false}
            {...props}
        />
    );
};

export default Table;
