import React from "react";
import PropTypes from "prop-types";
import { Result, Row, Space } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { TextField } from "misc/fields";

import { stsColors, fonts } from "styles";

const Error = ({ direction }) => {
    if (direction === "horizontal")
        return (
            <Row justify="center">
                <Space size={40}>
                    <FontAwesomeIcon size="3x" icon={["fas", "triangle-exclamation"]} style={{ color: stsColors.blue2, opacity: 0.85 }} />

                    <Space direction="vertical" size={2}>
                        <TextField value="t_http_error_occurred_sorry" style={{ fontSize: fonts.size20 }} />
                        <TextField value="t_http_error_occurred" style={{ fontSize: fonts.size18, fontWeight: 300 }} />
                    </Space>
                </Space>
            </Row>
        );

    return <Result title={<TextField value="t_http_error_occurred_sorry" />} subTitle={<TextField value="t_http_error_occurred" />} />;
};

Error.propTypes = {
    direction: PropTypes.oneOf(["horizontal", "vertical"])
};

export default Error;
