import React from "react";
import round from "lodash/round";
import isFinite from "lodash/isFinite";
import isNull from "lodash/isNull";
import { TimeSeries } from "pondjs";
import PropTypes from "prop-types";
import moment from "moment";
import { LineChart, ScatterChart, Charts, ChartRow, YAxis, ValueAxis, LabelAxis, styler } from "react-timeseries-charts";

const TimeseriesLineChartElement = ({ trackerPosition, title, data, showDatapoints, columns, selectedColumns = columns, colors }) => {
    const series = (() => {
        const temp = {};

        columns.forEach(
            (column, index) =>
                (temp[column] = new TimeSeries({
                    name: "data",
                    columns: ["time", ...columns],
                    points: data.reduce((previous, current) => [...previous, [moment(current[0]).format("x"), ...current.slice(1)]], [])
                    //data.filter(element => isNumber(element[index + 1]))
                    // TODO: This was for filtering out null lines, better to this in datalayer
                }))
        );

        return temp;
    })();

    const max = round(Math.max(...selectedColumns.map(column => series[column].max(column)).filter(max => isFinite(max))), 2);
    const min = round(Math.min(...selectedColumns.map(column => series[column].min(column)).filter(min => isFinite(min))), 2);

    const average = round(series[selectedColumns[0]]?.avg(selectedColumns[0]), 2);
    const stddev = round(series[selectedColumns[0]]?.stdev(selectedColumns[0]), 2);

    const statistics = [
        { label: "Max", value: max },
        { label: "Min", value: min },
        { label: "Avg", value: selectedColumns.length > 1 ? "-" : average },
        { label: "Stddev", value: selectedColumns.length > 1 ? "-" : stddev }
    ];

    const currentValues = selectedColumns.map(column => {
        const value = trackerPosition ? series[column]?.atTime(trackerPosition)?.get(column) ?? "-" : "-";

        return {
            label: column,
            value: isNull(value) ? "--" : value
        };
    });

    const renderCharts = () => {
        //const datapoints = data.filter((element) => element.slice(1).some((item) => !isNull(item))).length;

        if (showDatapoints)
            return (
                <Charts>
                    {columns.map(column => (
                        <LineChart
                            key={`${title}_line_chart_${column}`}
                            axis={`${title}_y`}
                            series={series[column]}
                            columns={selectedColumns}
                            interpolation="curveStepAfter"
                            breakLine
                            style={styler(
                                columns.map((column, index) => ({ key: column, color: colors && colors[index % colors.length], width: 2 }))
                            )}
                        />
                    ))}

                    {columns.map(column => (
                        <ScatterChart
                            key={`${title}_scatter_chart_${column}`}
                            axis={`${title}_y`}
                            series={series[column]}
                            columns={selectedColumns}
                        />
                    ))}
                </Charts>
            );
        else
            return (
                <Charts>
                    {columns.map(column => (
                        <LineChart
                            key={`${title}_line_chart_${column}`}
                            axis={`${title}_y`}
                            series={series[column]}
                            columns={selectedColumns}
                            interpolation="curveStepAfter"
                            breakLine={false}
                            style={styler(
                                columns.map((column, index) => ({ key: column, color: colors && colors[index % colors.length], width: 2 }))
                            )}
                        />
                    ))}
                </Charts>
            );
    };

    return (
        <ChartRow height={250} axisMargin={10}>
            <LabelAxis
                id={`${title}_label2`}
                values={statistics}
                label={trackerPosition ? moment(trackerPosition).format("DD.MM.YY HH:mm:ss") : "--:--:--"}
                width={140}
                min={min}
                max={max}
                style={{ label: { fontSize: 12 } }}
                hideScale
            />

            <YAxis id={`${title}_y`} format={format => format} min={min} max={max} width={40} ticks={4} label={title} />

            {renderCharts()}

            {selectedColumns.length <= 1 ? (
                <ValueAxis id={`${title}_value`} value={currentValues[0]?.value} detail={currentValues[0]?.label} width={220} min={min} max={max} />
            ) : (
                <LabelAxis id={`${title}_value`} values={currentValues} label="" width={220} min={min} max={max} hideScale />
            )}
        </ChartRow>
    );
};

TimeseriesLineChartElement.propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.array).isRequired,
    columns: PropTypes.arrayOf(PropTypes.string).isRequired,
    selectedColumns: PropTypes.arrayOf(PropTypes.string),
    colors: PropTypes.arrayOf(PropTypes.string),
    showDatapoints: PropTypes.bool
};

export default TimeseriesLineChartElement;
