import React from "react";
import PropTypes from "prop-types";
import { Space, Row, Col, Skeleton } from "antd";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import isFunction from "lodash/isFunction";

import { Card } from "misc/card";
import { TextField } from "misc/fields";

import { stsColors, fonts } from "styles";

const StatusBoxIcon = styled(FontAwesomeIcon)({
    color: stsColors.blue2,
    opacity: 0.85
});

const Loading = styled.div({
    minHeight: 29,
    paddingTop: 10
});

const Value = styled.p({
    minHeight: 29,
    fontSize: fonts.size20
});

const Title = styled(TextField)({
    fontSize: fonts.size18,
    fontWeight: 300
});

const StatusBox = ({ title, icon, value, renderValue, lastUpdate, isLoading, isUpdating, isError, ...props }) => {
    if (isError)
        return (
            <StatusBox
                title="t_http_error_occurred"
                icon={["fas", "triangle-exclamation"]}
                value="t_http_error_occurred_sorry"
                renderValue={value => <TextField value={value} />}
            />
        );

    return (
        <Card minHeight={140} isUpdating={isUpdating} lastUpdate={lastUpdate} {...props}>
            <Row>
                <Col span={6} offset={2}>
                    <StatusBoxIcon size="3x" icon={isFunction(icon) ? icon(value) : icon} />
                </Col>

                <Col span={16}>
                    <Space direction="vertical" size={2}>
                        {isLoading ? (
                            <Loading>
                                <Skeleton title={{ width: "65%" }} paragraph={false} active />
                            </Loading>
                        ) : (
                            <Value>{renderValue ? renderValue(value) : value}</Value>
                        )}

                        <Title value={isFunction(title) ? title(value) : title} />
                    </Space>
                </Col>
            </Row>
        </Card>
    );
};

StatusBox.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    icon: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.array]).isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    renderValue: PropTypes.func,
    lastUpdate: PropTypes.number,
    isLoading: PropTypes.bool,
    isUpdating: PropTypes.bool,
    isError: PropTypes.bool
};

export default StatusBox;
