import React, { PureComponent } from "react";

import LegalComponent from "../components/LegalComponent";

class CookiesContainer extends PureComponent {
    render() {
        return <LegalComponent title="t_cookies" fileName="cookies.md" />;
    }
}

export default CookiesContainer;
