import React from "react";
import { Select, Space } from "antd";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { useLoggedInUser } from "misc/api/keycloakApi";
import { useSubfleets } from "misc/api/fleetconfiguration";

import { stsColors } from "styles";

const SubfleetSelector = ({ onChange, options = [], ...props }) => {
    const [t] = useTranslation();

    const { subfleets } = useSubfleets({ suspense: true });
    const { loggedInUser } = useLoggedInUser({ suspense: true });

    return (
        <Select
            onChange={value =>
                onChange &&
                onChange(
                    value,
                    subfleets.find(subfleet => subfleet.subfleetId === value)
                )
            }
            placeholder={t("t_selector_placeholder_default")}
            style={{ width: "100%" }}
            {...props}
            filterOption={(inputValue, option) => option.name.toLowerCase().includes(inputValue.toLowerCase())}
            showSearch
        >
            {options.map(option => (
                <Select.Option key={option.value} value={option.value} name={option.label}>
                    {option.label}
                </Select.Option>
            ))}
            {subfleets.map(subfleet => (
                <Select.Option key={subfleet.subfleetId} value={subfleet.subfleetId} name={subfleet.name}>
                    <Space>
                        <FontAwesomeIcon
                            icon={subfleet.default || subfleet.owner === loggedInUser.id ? ["fal", "user"] : ["fal", "share-nodes"]}
                            style={{ color: stsColors.black1 }}
                        />
                        {subfleet.name}
                    </Space>
                </Select.Option>
            ))}
        </Select>
    );
};

SubfleetSelector.propTypes = {
    options: PropTypes.arrayOf(PropTypes.object),
    onChange: PropTypes.func
};

export default SubfleetSelector;
