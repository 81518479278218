export const standard = ["#7BE0AD", "#AEE5D8", "#155292", "#8AE1FC", "#E7E5E5"];

export const errors = { error: "#8C3639", warning: "#CCAD33", ok: "#34916D" };

export const availability = {
    drivenPlugged: errors.ok,
    drivenNotPlugged: errors.error,
    depot: errors.ok,
    wrongDepot: errors.error,
    workshop: errors.warning,
    other: "#AAA",
    noGPS: "#888",
    offline: "#666"
};

export const drivemode = { eco: "#34916D", drive: "#155292", neutral: "#888", reverse: "#CCAD33" };

export const colorForError = {
    5: "#E50B0B",
    102: "#CC0A0A",
    2: "#CC2314",
    1: "#BC392D",
    4: "#E55639",
    103: "#E56D44",
    101: "#E5895B",
    3: "#F9AB84",
    105: "#A3A3A3"
};

export const defectCategories = {
    7: "#C00000", // Motor/Antrieb
    13: "#70AD47", // Allgemeines Elektrikproblem
    6: "#BFBFBF", // Karosserie (inkl. Tür)
    5: "#00C7A2",
    10: "#A5ABBD",
    11: "#717788",
    15: "#8C314A",
    18: "#C5657A",
    20: "#C5657A",
    28: "#FFF7D6",
    29: "#ABD1FF",
    35: "#358E89",
    37: "#008A61",
    41: "#D8A31A",
    46: "#2DC194",
    48: "#F4F9FF",
    50: "#414756",
    54: "#9F7E3A",
    60: "#89E482",
    62: "#684D07",
    86: "#F9F871"
};

export const stsColors = {
    blue: "#155292",
    black: "#323232",
    darkgrey: "#5f5f5f",
    grey: "#a1a1a1",
    lightgrey: "#d9d9d9",
    yellow: "#ffcc00",
    green: "#337f4c",
    white: "#ffffff",
    red: "#db222a",
    electricblue: "#a1a8c3",
    antiflashwhite: "#f2f2f2"
};
export default class Colors {
    lastUsedColor = -1;

    getNextColor = () => {
        this.lastUsedColor = (this.lastUsedColor + 1) % standard.length;
        return standard[this.lastUsedColor];
    };
}
