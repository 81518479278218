import { Spin } from "antd";
import ReactDOM from "react-dom";
import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider } from "react-query";
import { LoadingOutlined } from "@ant-design/icons";
import { ReactQueryDevtools } from "react-query/devtools";
import { ReactKeycloakProvider } from "@react-keycloak/web";

import { ErrorBoundary } from "misc/error";

import { keycloak } from "./keycloak";
import { queryClient } from "./queryClient";

import { stsColors } from "styles";

import Root from "root/Root";

import "./styles/cssReset.less";
import "./styles/index.less";
import "./styles/react-error-overlay-fix.css";

import "./i18n";
import "./dayjs";

// TODO: Remove, because deprecated
import "./moment";

if (process.env.NODE_ENV === "development") {
    const { worker } = require("./mocks/browser");
    worker.start({
        onUnhandledRequest: "bypass"
    });
}

Spin.setDefaultIndicator(<LoadingOutlined style={{ color: stsColors.grey2 }} />);

ReactDOM.render(
    <ReactKeycloakProvider authClient={keycloak}>
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <Suspense fallback={<Spin delay={200} style={{ marginTop: "calc(50vh - 40px)", marginLeft: "calc(50vw - 40px)" }} />}>
                    <ErrorBoundary>
                        <Root />
                    </ErrorBoundary>
                </Suspense>
            </BrowserRouter>
            <ReactQueryDevtools />
        </QueryClientProvider>
    </ReactKeycloakProvider>,
    document.getElementById("root")
);

/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: http://bit.ly/CRA-PWA
 * serviceWorker.unregister();
 */
