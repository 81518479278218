import React from "react";

import { ErrorSite } from "misc/error";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null
        };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { error: error.toString() };
    }

    componentDidCatch(error) {
        console.error(error);
    }

    render() {
        if (this.state.error) {
            // You can render any custom fallback UI
            return <ErrorSite error={this.state.error} />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
