import React from "react";

import FleetConfiguration from "modules/FleetConfiguration";

const FLEET_PARAMETERS = [];

const DefectTicketsFleetConfigurationComponent = () => {
    return <FleetConfiguration parameters={FLEET_PARAMETERS} />;
};

export default DefectTicketsFleetConfigurationComponent;
