import React, { useEffect } from "react";
import { Button, Skeleton } from "antd";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import L from "leaflet";

import { Empty } from "misc/empty";

import "leaflet/dist/leaflet.css";

const Legend = styled.div({
    background: "white",
    padding: 5
});

const MapZoomControl = ({ bounds, center, zoom }) => {
    const map = useMap();

    useEffect(() => {
        const zoomControl = L.control({ position: "topright" });

        const jsx = (
            <div className="leaflet-bar leaflet-control">
                <Button
                    role="button"
                    className="leaflet-control-zoom-in"
                    onClick={() => {
                        bounds ? map.flyToBounds(bounds) : map.flyTo(center, zoom);
                    }}
                    style={{
                        display: "flex",
                        padding: 4
                    }}
                >
                    <FontAwesomeIcon icon={["far", "crosshairs"]} />
                </Button>
            </div>
        );
        zoomControl.onAdd = () => {
            const div = L.DomUtil.create("div", "zoomcontrol");
            ReactDOM.render(jsx, div);
            return div;
        };

        zoomControl.addTo(map);

        return () => zoomControl.remove();
    }, [bounds, center, map, zoom]);
    return null;
};

const MapLegend = ({ position, children }) => {
    const map = useMap();

    useEffect(() => {
        const legend = L.control({ position });

        const jsx = <div className="leaflet-bar leaflet-control">{children}</div>;
        legend.onAdd = () => {
            const div = L.DomUtil.create("div", "legend");
            ReactDOM.render(jsx, div);
            return div;
        };

        legend.addTo(map);

        return () => legend.remove();
    }, [children, map, position]);
    return null;
};

const Map = ({ height, isLoading, bounds, center, zoom = 14, onZoom, isStatic, renderLegend, children }) => {
    // Loading
    if (isLoading) return <Skeleton title={{ width: "100%", style: { height: height } }} paragraph={false} active />;

    // Empty
    if (!center?.every(item => item) && !bounds?.some(item => item?.every(element => element)))
        return <Empty height={height} title="t_no_gps_data" subTitle="t_no_gps_data_available" />;

    // Workaoroud that avoids a grey map after rendering
    const setMap = map => {
        const resizeObserver = new ResizeObserver(() => {
            map.invalidateSize();
        });
        const container = document.getElementById("map-container");
        container && resizeObserver.observe(container);
    };

    return (
        <MapContainer
            id="map-container"
            center={center}
            bounds={bounds}
            zoom={zoom}
            onViewportChanged={({ zoom }) => onZoom && onZoom(zoom)}
            style={{ height: height }}
            whenCreated={setMap}
            {...(isStatic && {
                dragging: false,
                doubleClickZoom: false,
                scrollWheelZoom: false,
                zoomControl: false
            })}
        >
            <TileLayer
                attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
            />
            {children}
            {!isStatic && <MapZoomControl bounds={bounds} center={center} zoom={zoom} />}
            {renderLegend && (
                <MapLegend position="bottomright" className="leaflet-bar leaflet-control">
                    <Legend>{renderLegend}</Legend>
                </MapLegend>
            )}
        </MapContainer>
    );
};

Map.propTypes = {
    height: PropTypes.number.isRequired,
    isLoading: PropTypes.bool,
    center: PropTypes.array,
    bounds: PropTypes.array,
    zoom: PropTypes.number,
    onZoom: PropTypes.func,
    isStatic: PropTypes.bool,
    renderLegend: PropTypes.func
};

export default Map;
