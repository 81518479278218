import { find } from "lodash";
import { TreeSelect } from "antd";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { useConfigurations } from "misc/api/datalayer";

const ConfigurationSelector = ({ defaultValue, value, onChange, onOk, restrictions = ["B", "D", "E"], allowClear = true, ...props }) => {
    const [t] = useTranslation();

    const { configurations } = useConfigurations();

    const getFilteredConfigurations = (configurations, restrictions) => {
        const flattenItems = (items, key) => {
            return items.reduce((flattenedItems, item) => {
                flattenedItems.push(item);
                if (Array.isArray(item[key])) {
                    flattenedItems = flattenedItems.concat(flattenItems(item[key], key));
                }
                return flattenedItems;
            }, []);
        };

        const items = [];

        restrictions.forEach(configuration => items.push(find(flattenItems(configurations, "children"), ["value", configuration])));

        return items;
    };

    const treeData = useMemo(() => {
        const formatData = data => {
            const getTitle = title => {
                switch (title) {
                    case "B":
                        return "B / Work";

                    case "D":
                        return "D / Work L";

                    case "E":
                        return "E / Work XL";

                    default:
                        return title.split("-").join("");
                }
            };

            return data.map(element => {
                return {
                    ...element,
                    key: element.value,
                    title: getTitle(element.value),
                    children: element.children ? formatData(element.children) : null
                };
            });
        };

        return formatData(getFilteredConfigurations(configurations, restrictions));
    }, [restrictions, configurations]);

    return (
        <TreeSelect
            value={value}
            defaultValue={defaultValue}
            treeData={treeData}
            treeCheckable={true}
            showCheckedStrategy={TreeSelect.SHOW_PARENT}
            placeholder={t("t_select_configuration")}
            onChange={value => {
                if (value.length < 1) allowClear && onChange && onChange(value);
                else onChange && onChange(value);
            }}
            treeNodeLabelProp="title"
            treeNodeFilterProp="title"
            maxTagCount="responsive"
            allowClear={allowClear}
            style={{ width: "100%" }}
            {...props}
        />
    );
};

ConfigurationSelector.propTypes = {
    defaultValue: PropTypes.array,
    value: PropTypes.array,
    restrictions: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
    allowClear: PropTypes.bool
};

export default ConfigurationSelector;
