import { useKeycloak } from "misc/api/keycloak";

const CheckAuthentication = ({ children }) => {
    const { keycloak, initialized } = useKeycloak();

    if (!initialized) return null;

    if (!keycloak.authenticated) {
        const url = keycloak.createLoginUrl();
        window.location.href = url;

        return null;
    }

    return children;
};

export default CheckAuthentication;
