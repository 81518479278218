import React from "react";
import { toNumber } from "lodash";
import PropTypes from "prop-types";
import styled from "styled-components";
import NumberFormat from "react-number-format";

import { Field } from "misc/fields";

const StyledNumberFormat = styled.p(props => ({
    display: props.inline ? "inline" : "block"
}));

const NumberField = props => {
    const numberFieldProps = {
        value: toNumber(props.value),
        prefix: props.prefix && `${props.prefix} `,
        suffix: props.suffix && ` ${props.suffix}`,
        decimalScale: props.decimals ?? 0,
        fixedDecimalScale: true,
        displayType: "text",
        renderText: value => (
            <StyledNumberFormat className={props.className} style={props.style} inline={props.inline}>
                {value}
            </StyledNumberFormat>
        )
    };

    switch (props.locale) {
        case "en-US":
            numberFieldProps.decimalSeparator = ".";
            numberFieldProps.thousandSeparator = ",";
            break;

        case "de-DE":
        default:
            numberFieldProps.decimalSeparator = ",";
            numberFieldProps.thousandSeparator = ".";
            break;
    }

    return (
        <Field showDefaultValue={[NaN, null, undefined].includes(props.value)} {...props}>
            <NumberFormat {...numberFieldProps} />
        </Field>
    );
};

NumberField.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    decimals: PropTypes.number,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    inline: PropTypes.bool
};

export default NumberField;
