import dayjs from "dayjs";
import PropTypes from "prop-types";
import { Space, Tooltip } from "antd";
import { useQuery } from "react-query";
import { useHistory } from "react-router";
import React, { useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Card } from "misc/card";
import { Table } from "misc/tables";
import { DateSelector } from "misc/selectors";
import { clientUrls, datalayerUrls } from "misc/urls";
import { getDatesFromRange } from "misc/helpfunctions";
import { DateField, NumberField, TextField } from "misc/fields";

import { cases } from "modules/cm/components/cases";

import CmCaseInfoModal from "modules/cm/components/CmCaseInfoModal";

const CmDashboardTable = ({ filter = {}, view = {}, queryProps }) => {
    const history = useHistory();

    const [selectedDate, setSelectedDate] = useState(dayjs());

    const range = useMemo(
        () => [
            dayjs(selectedDate)
                .subtract(view.displayedDays - 1, "day")
                .startOf("day"),
            dayjs(selectedDate).endOf("day")
        ],
        [selectedDate, view.displayedDays]
    );

    const dates = useMemo(() => {
        const dates = getDatesFromRange(range);

        if (view.dateOrder === "desc") return dates.sort((a, b) => b.unix() - a.unix());

        return dates.sort((a, b) => a.unix() - b.unix());
    }, [range, view.dateOrder]);

    const { data, isLoading, isError, isRefetching } = useQuery(["chargingMonitorDashboard", { ...filter, range }], {
        meta: {
            request: {
                method: "GET",
                url: datalayerUrls.fleet.widgets.list(),
                params: { type: "chargingMonitorDashboard" },
                filter: { ...filter, range }
            }
        },
        keepPreviousData: true
    });

    const columns = useMemo(
        () => [
            {
                title: () => <TextField value="t_description" />,
                dataIndex: "title",
                render: text => <TextField value={text} />,
                fixed: true,
                width: "200px",
                onCell: record => ({
                    rowSpan:
                        {
                            "1.1.1": 10,
                            "2.1.1": 8,
                            3.1: 6
                        }[record?.case] || 0
                })
            },
            {
                title: () => (
                    <Space>
                        <TextField value="t_cm_case" />
                        <FontAwesomeIcon
                            icon={["fal", "circle-info"]}
                            style={{ cursor: "pointer" }}
                            onClick={() => history.push(clientUrls.modules.cm.wiki())}
                        />
                    </Space>
                ),
                dataIndex: "case",
                render: text => <CmCaseInfoModal case={text} />,
                fixed: true,
                width: 65,
                align: "center"
            },
            {
                title: () => (
                    <Tooltip
                        className="tooltip"
                        align={{
                            offset: [5, 10], // offset sourceNode by 5px in x and 10px in y,
                            targetOffset: ["30%", "40%"] // offset targetNode by 30% of targetNode width in x and 40% of targetNode height in y,
                        }}
                        title={
                            <Space>
                                {/* <DateField value={startDate} /> */}
                                <span>-</span>
                                {/* <DateField value={endDate} /> */}
                            </Space>
                        }
                    >
                        <TextField value="t_total" />
                    </Tooltip>
                ),
                key: "sum",
                render: (_, record) => <NumberField value={record.sum} />,
                onCell: () => ({ style: { borderLeft: "2px solid #f0f0f0", borderRight: "3px solid #f0f0f0" } }),
                onHeaderCell: () => ({ style: { borderLeft: "2px solid #f0f0f0", borderRight: "3px solid #f0f0f0" } }),
                fixed: true,
                align: "center",
                width: 85
            },
            ...dates.map(date => ({
                title: () => <DateField value={date} />,
                dataIndex: date.format("YYYY-MM-DD"),
                render: text => <NumberField value={text} />,
                width: "auto"
            }))
        ],
        [dates]
    );

    const extractedData = useMemo(() => {
        if (!data) return [];

        return (
            data.data
                .reduce(
                    (previous, current) =>
                        previous.map(item => ({
                            ...item,
                            [current.date]: current.caseCount[`case_${item.case.replaceAll(".", "_")}`]
                        })),
                    cases.map(element => ({ case: element.case }))
                )
                .map(original => {
                    const data = [
                        { title: "t_cm_dashboard_vehicles_at_post_location_by_llw", cluster: "post", case: "1.1.1" },
                        { cluster: "post", case: "1.1.2" },
                        { cluster: "post", case: "1.2.1" },
                        { cluster: "post", case: "1.2.2" },
                        { cluster: "post", case: "1.3.1" },
                        { cluster: "post", case: "1.3.2" },
                        { cluster: "post", case: "1.4.1" },
                        { cluster: "post", case: "1.4.2" },
                        { cluster: "post", case: "1.5" },
                        { title: "t_cm_dashboard_vehicles_at_production_location_by_llw", cluster: "production", case: "2.1.1" },
                        { cluster: "production", case: "2.1.2" },
                        { cluster: "production", case: "2.2.1" },
                        { cluster: "production", case: "2.2.2" },
                        { cluster: "production", case: "2.3.1" },
                        { cluster: "production", case: "2.3.2" },
                        { cluster: "production", case: "2.4" },
                        { title: "t_cm_dashboard_vehicles_as_testing_by_llw", cluster: "test", case: "3.1" },
                        { cluster: "test", case: "3.2" },
                        { cluster: "test", case: "3.3" }
                    ];

                    const item = data.find(item => item.case === original.case);

                    return item ? { ...original, ...item } : original;
                })
                .map(original => {
                    const displayedDaysWithData = Object.keys(original)
                        ?.map(value => dates?.includes(value) && original[value])
                        .filter(item => item !== false);
                    const sumValue = displayedDaysWithData.reduce((accumulator, value) => {
                        return accumulator + value;
                    }, 0);

                    return { sum: sumValue, ...original };
                })
                .filter(item => view.cmCluster.some(name => ["all", item?.cluster].includes(name))) || []
        );
    }, [data, view.cmCluster]);

    return (
        <Card
            title="t_cm_dashboard"
            extra={<DateSelector value={selectedDate} onChange={setSelectedDate} disabledDate={date => date.isAfter(dayjs())} allowClear={false} />}
        >
            <Table
                dataSource={extractedData}
                isLoading={isLoading}
                isError={isError}
                isUpdating={isRefetching}
                tableLayout="fixed"
                columns={columns}
                enumerate={false}
                rowKey="case"
                size="small"
                bordered
                pagination={false}
                sticky
            />
        </Card>
    );
};

CmDashboardTable.propTypes = {
    filter: PropTypes.object,
    view: PropTypes.object
};

export default CmDashboardTable;
