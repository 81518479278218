import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Menu } from "antd";
import styled from "styled-components";

import { CheckAppPermissions } from "misc/authentication";

import MenuItemIcon from "./MenuItemIcon";

// TODO: Refactor: Remove SubMenu if no Menu item exists

const MenuTitle = styled.span({
    fontWeight: 600
});

const MenuItem = ({ item, ...props }) => {
    const { title, url, allowed, icon, items, disabled } = item;

    const [t] = useTranslation();

    // Multiple menu items
    if (items && items.length) {
        return (
            <CheckAppPermissions fallback={null} allowed={allowed || []}>
                <Menu.SubMenu
                    title={
                        <span>
                            <MenuItemIcon icon={icon} />
                            <MenuTitle>{t(title)}</MenuTitle>
                        </span>
                    }
                    {...props}
                >
                    {items.map(item => (
                        <MenuItem key={item.key} item={item} />
                    ))}
                </Menu.SubMenu>
            </CheckAppPermissions>
        );
    }

    // Single menu item
    if (url) {
        return (
            <CheckAppPermissions fallback={null} allowed={allowed || []}>
                <Menu.Item disabled={disabled} {...props}>
                    <MenuItemIcon icon={icon} />
                    <span>{t(title)}</span>
                    <Link to={url} />
                </Menu.Item>
            </CheckAppPermissions>
        );
    }

    // Just headline without any functionality
    return (
        <Menu.Item disabled={true} {...props}>
            {icon && <MenuItemIcon icon={icon} />}
            <span style={{ fontSize: "15px", color: "white", fontWeight: "bold" }}>{t(title)}</span>
        </Menu.Item>
    );
};

export default MenuItem;
