import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { Tooltip, GeoJSON } from "react-leaflet";
import { Avatar, Select, Descriptions, Space, Empty } from "antd";
import snakeCase from "lodash/snakeCase";
import { useQuery } from "react-query";
import { Widget } from "misc/widgets";
import { TextField, NumberField } from "misc/fields";
import { ErrorWidget } from "misc/widgets/consumers";
import { Map } from "misc/map";

import { stsColors } from "styles/colors";

import { datalayerUrls } from "misc/urls";

// import divisions from "./divisions.json";

const statistics = ["avgDoorOpenPerTrip", "avgDistancePerTrip", "avgDoorOpenPerKm"];
const L = require("leaflet");
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

const Legend = () => (
    <Space direction="vertical" size={1}>
        <Space>
            <Avatar style={{ background: "#55a630", height: 10, width: 10 }} />
            <TextField value="< 90%" />
        </Space>

        <Space>
            <Avatar style={{ background: "#90BE6D", height: 10, width: 10 }} />
            <TextField value="90% - 110%" />
        </Space>
        <Space direction="horizontal">
            <Avatar style={{ background: "#F9C74F", height: 10, width: 10 }} />
            <TextField value="110% - 120%" />
        </Space>
        <Space direction="horizontal">
            <Avatar style={{ background: "#F8961E", height: 10, width: 10 }} />
            <TextField value="120% - 130%" />
        </Space>
        <Space direction="horizontal">
            <Avatar style={{ background: "#F3722C", height: 10, width: 10 }} />
            <TextField value="130% - 140%" />
        </Space>
        <Space direction="horizontal">
            <Avatar style={{ background: "#F94144", height: 10, width: 10 }} />
            <TextField value="> 140%" />
        </Space>
    </Space>
);

const StatisticsHeatmap = ({ height, filter = {} }) => {
    const divisions = useQuery([{ url: process.env.PUBLIC_URL + "/divisions.json" }]);

    const { data, isFetching, error } = useQuery(
        [
            {
                url: datalayerUrls.fleet.widgets.map(),
                params: { type: "statisticsHeatmap" },
                filter: filter
            }
        ],
        { placeholderData: { data: [] } }
    );
    const [selectedStatistic, setSelectedStatistic] = useState(statistics[0]);
    const [
        selectedResolution
        // setSelectedResolution
    ] = useState("divisionLevel");

    if (divisions.isFetching) return <Widget title="t_statistics" height={height} loading />;

    const getColor = element => {
        if (element[selectedStatistic] / data.statistics[selectedStatistic] < 0.9) return stsColors.green3;

        if (element[selectedStatistic] / data.statistics[selectedStatistic] > 1.4) return stsColors.red1;

        if (element[selectedStatistic] / data.statistics[selectedStatistic] > 1.3) return stsColors.yellow3;

        if (element[selectedStatistic] / data.statistics[selectedStatistic] > 1.2) return stsColors.yellow2;

        if (element[selectedStatistic] / data.statistics[selectedStatistic] > 1.1) return stsColors.yellow1;

        return stsColors.green1;
    };

    if (error) return <ErrorWidget title="t_statistics" error={error} style={{ height: height }} />;
    if (data.data.length === 0)
        return (
            <Widget title="t_statistics" height={height}>
                <Empty description={<TextField value="t_no_data" />} style={{ marginTop: 0.25 * height }} />
            </Widget>
        );

    return (
        <Widget
            title="t_statistics"
            height={height}
            lastUpdate={data.statistics.lastUpdate}
            download={{ data: selectedResolution === "depotLevel" ? data.data.level3 : data.data.level2 }}
            loading={isFetching}
            extra={
                <>
                    <Select value={selectedStatistic} onChange={setSelectedStatistic} style={{ width: 220 }}>
                        {statistics.map(element => (
                            <Select.Option key={element} value={element}>
                                <TextField value={`t_${snakeCase(element)}`} />
                            </Select.Option>
                        ))}
                    </Select>
                </>
            }
        >
            <Space direction="vertical" size={10}>
                <Descriptions column={1} size="small">
                    <Descriptions.Item label={<TextField value="t_fleet_average" inline />}>
                        <NumberField value={data.statistics[selectedStatistic]} decimals={data.format[selectedStatistic]?.decimals} />
                    </Descriptions.Item>
                </Descriptions>

                <Map height={0.79 * height} zoom={6} center={[51.163, 10.447]} renderLegend={<Legend />}>
                    {(selectedResolution === "depotLevel" ? data.data.level3 : data.data.level2).map(element => {
                        if (!element.id) return null;
                        return (
                            <GeoJSON
                                key={element.id}
                                data={divisions.data[element.id]}
                                style={() => ({
                                    fillColor: getColor(element),
                                    fillOpacity: 0.6,
                                    color: getColor(element),
                                    weight: 1.5
                                })}
                            >
                                <Tooltip direction="top">
                                    <TextField value={element.name} />
                                    <NumberField value={element[selectedStatistic]} decimals={data.format[selectedStatistic].decimals} />
                                </Tooltip>
                            </GeoJSON>
                        );
                    })}
                </Map>
            </Space>
        </Widget>
    );
};

StatisticsHeatmap.propTypes = {
    height: PropTypes.number.isRequired,
    filter: PropTypes.object,
    dependencies: PropTypes.array
};

export default StatisticsHeatmap;
