import PropTypes from "prop-types";
import React, { useMemo } from "react";

import { Card } from "misc/card";
import { TextField, TitleField } from "misc/fields";
import { datalayerUrls } from "misc/urls";
import { DefectTicketsTable, VehiclesTableTemplate } from "misc/widgets/consumers";

const DefectTicketsPerVehicleTable = ({ filter, view, params, queryProps, ...props }) => {
    const columns = useMemo(
        () => [
            {
                title: () => <TextField value="t_defect_tickets_defect_tickets_count" />,
                dataIndex: "defectTicketsCount",
                key: "defectTicketsCount",
                sorter: true,
                width: 200
            },
            {
                title: () => <TextField value="t_defect_tickets_defect_tickets_open_count" />,
                dataIndex: "defectTicketsOpenCount",
                key: "defectTicketsOpenCount",
                sorter: true,
                width: 200
            },
            {
                title: () => <TextField value="t_defect_tickets_error_codes" />,
                dataIndex: "errorCodes",
                render: text => text.join(", "),
                key: "errorCodes",
                width: 650
            }
        ],
        []
    );

    const expandedRowRender = record => {
        return (
            <Card>
                <TitleField value="t_defect_tickets_details" level={5} />
                <DefectTicketsTable
                    style={{ minHeight: "100px", marginLeft: -40 }}
                    filter={{ vehicles: [record.vin], range: filter.range }}
                    view={{ columns: ["vin"], fixedColumn: "vin" }}
                    scroll={{
                        x: "calc(700px + 50%)"
                    }}
                    hideSearch
                />
            </Card>
        );
    };

    return (
        <VehiclesTableTemplate
            queryKey="defectTicketsPerVehicleTable"
            rowKey="vin"
            filter={filter}
            view={view}
            request={{
                method: "GET",
                url: datalayerUrls.fleet.widgets.list(),
                params: { type: "defectTicketsPerVehicle" },
                filter: filter
            }}
            extractData="data"
            extractCount={data => data.statistics.count}
            columns={columns}
            tableLayout="fixed"
            scroll={{ x: "calc(700px + 50%" }}
            expandable={{ expandedRowRender }}
            queryProps={queryProps}
            sticky
            {...props}
        />
    );
};

DefectTicketsPerVehicleTable.propTypes = {
    filter: PropTypes.object,
    view: PropTypes.object,
    params: PropTypes.object,
    queryProps: PropTypes.object
};

export default DefectTicketsPerVehicleTable;
