import React from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

import { stsColors } from "styles";

const style = {
    background: stsColors.aliceblue,
    color: stsColors.ceil
};

const SecondaryButton = ({ children, disabled, ...props }) => {
    const [t] = useTranslation();
    if (typeof children === "string") children = t(children);

    if (disabled) return <Button disabled {...props} children={children} />;

    return <Button {...props} children={children} style={style} />;
};

export default SecondaryButton;
