import { useMutation } from "react-query";
import moment from "moment";

import { telematicsservicesUrls } from "misc/urls";

export const useRequestLogData = () => {
    const { mutate, ...mutation } = useMutation();

    return {
        requestLogData: (range, telematicControlUnits, signals, { onSuccess, ...props } = {}) => {
            mutate(
                {
                    method: "POST",
                    url: telematicsservicesUrls.request.logdata(),
                    body: JSON.stringify({
                        start: moment(range[0]).unix(),
                        end: moment(range[1]).unix(),
                        telematicControlUnits: telematicControlUnits,
                        signals
                    })
                },
                {
                    onSuccess: () => {
                        onSuccess && onSuccess();
                    },
                    ...props
                }
            );
        },
        ...mutation
    };
};
