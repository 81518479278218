import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { Drawer, Space, Form, Row, Skeleton, Button, Input, Modal, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { Table } from "misc/tables";
import { TextField } from "misc/fields";

const EditableTable = ({
    data,
    columns,
    rowKey,
    form,
    extra,
    onAddItem,
    onEditItem,
    onDeleteItem,
    onOpenDrawer,
    filterItems,
    extraActions,
    isLoading,
    isUpdating,
    pagination,
    ...props
}) => {
    const [t] = useTranslation();

    const [drawerForm] = Form.useForm();

    const [drawerIsOpen, setDrawerIsOpen] = useState(false);
    const [drawerCompletelyLoaded, setDrawerCompletelyLoaded] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [filter, setFilter] = useState("");
    const [dataSource, setDataSource] = useState(data);

    useEffect(() => {
        if (drawerCompletelyLoaded) {
            drawerForm.resetFields();
            onOpenDrawer && onOpenDrawer(selectedItem);
        } else setSelectedItem(null);

        // eslint-disable-next-line
    }, [drawerCompletelyLoaded]);

    useEffect(() => {
        modalIsOpen || setSelectedItem(null);
        // eslint-disable-next-line
    }, [modalIsOpen]);

    useEffect(() => filterItems && setDataSource(data.filter(item => filterItems(filter, item))), [data, filter]);

    const onSave = item => {
        selectedItem ? onEditItem && onEditItem({ ...selectedItem, ...item }) : onAddItem && onAddItem(item);
        setDrawerIsOpen(false);
    };

    const onDelete = () => {
        onDeleteItem && onDeleteItem(selectedItem);
        setModalIsOpen(false);
    };

    return (
        <Space direction="vertical" size={25} style={{ width: "100%" }}>
            <Row justify="space-between">
                {filterItems && (
                    <Input style={{ width: 250 }} placeholder={t("t_search")} onChange={event => setFilter(event.target.value)} allowClear />
                )}
                <Space>
                    {onAddItem && (
                        <Button onClick={() => setDrawerIsOpen(true)} icon={<FontAwesomeIcon icon={["fal", "plus"]} style={{ marginRight: 5 }} />}>
                            <TextField value="t_add" inline />
                        </Button>
                    )}
                    {extra && extra}
                </Space>
            </Row>
            <Table
                dataSource={dataSource}
                columns={[
                    ...columns,
                    {
                        key: "actions",
                        render: (text, record) => (
                            <Space>
                                {onEditItem && (
                                    <Tooltip title={<TextField value="t_edit" />}>
                                        <Button
                                            icon={<FontAwesomeIcon icon={["fal", "pen-to-square"]} />}
                                            onClick={() => {
                                                setDrawerIsOpen(true);
                                                setSelectedItem(record);
                                            }}
                                            disabled={!record.editable}
                                        />
                                    </Tooltip>
                                )}
                                {onDeleteItem && (
                                    <Tooltip title={<TextField value="t_delete" />}>
                                        <Button
                                            icon={<FontAwesomeIcon icon={["fal", "trash"]} />}
                                            onClick={() => {
                                                setModalIsOpen(true);
                                                setSelectedItem(record);
                                            }}
                                            disabled={!record.deletable}
                                        />
                                    </Tooltip>
                                )}
                                {extraActions &&
                                    extraActions(record).map(action => (
                                        <Tooltip key={action.key} title={<TextField value={action.tooltip} />}>
                                            <Button {...action} />
                                        </Tooltip>
                                    ))}
                            </Space>
                        ),
                        width: 120
                    }
                ]}
                rowKey={rowKey}
                size="middle"
                pagination={{ ...pagination, total: dataSource.length }}
                isLoading={isLoading}
                isUpdating={isUpdating}
                {...props}
            />
            <Drawer
                visible={drawerIsOpen}
                title={selectedItem ? <TextField value="t_edit_item" /> : <TextField value="t_add_item" />}
                onClose={() => setDrawerIsOpen(false)}
                width={500}
                afterVisibleChange={visible => setDrawerCompletelyLoaded(visible)}
            >
                {drawerCompletelyLoaded ? (
                    <Form form={drawerForm} initialValues={form.initialValues(selectedItem)} layout="vertical" onFinish={onSave} hideRequiredMark>
                        {form.items}
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                <TextField value="t_save" inline />
                            </Button>
                        </Form.Item>
                    </Form>
                ) : (
                    <Skeleton />
                )}
            </Drawer>

            <Modal
                title={<TextField value="t_delete_item_title" />}
                visible={modalIsOpen}
                onCancel={() => setModalIsOpen(false)}
                onOk={onDelete}
                cancelText={<TextField value="t_cancel" />}
                okText={<TextField value="t_delete" />}
                okButtonProps={{ danger: true }}
            >
                <Space direction="vertical" size={20}>
                    <div>
                        <TextField value="t_delete_item_description" />

                        <TextField value="t_this_can_not_be_undone" />
                    </div>

                    <TextField value="t_do_you_want_to_continue" />
                </Space>
            </Modal>
        </Space>
    );
};

EditableTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    rowKey: PropTypes.string.isRequired,
    form: PropTypes.shape({
        initialValues: PropTypes.func.isRequired,
        items: PropTypes.arrayOf(PropTypes.node).isRequired
    }),
    onAddItem: PropTypes.func,
    onEditItem: PropTypes.func,
    onDeleteItem: PropTypes.func,
    onOpenDrawer: PropTypes.func,
    filterItems: PropTypes.func,
    extraActions: PropTypes.func,
    extra: PropTypes.node
};

export default EditableTable;
