import dayjs from "dayjs";
import PropTypes from "prop-types";
import { Button, Input, DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DateSelector = ({ defaultValue, value, onChange = () => null, disabledDate = () => null, bodyStyle, disableShortcuts = true, ...props }) => {
    const [date, setDate] = useState(value ?? defaultValue ?? dayjs());

    useEffect(() => setDate(value), [value]);

    const onChangeDate = date => {
        setDate(date);
        onChange(date);
    };

    return (
        <Input.Group style={bodyStyle} compact>
            {disableShortcuts && (
                <Button onClick={() => onChangeDate(dayjs(date).subtract(1, "day"))} disabled={disabledDate(dayjs(date).subtract(1, "day"))}>
                    <FontAwesomeIcon icon={["fal", "chevron-left"]} />
                </Button>
            )}

            <DatePicker
                format="DD.MM.YYYY"
                defaultValue={dayjs(defaultValue)}
                value={dayjs(value)}
                onChange={onChangeDate}
                disabledDate={disabledDate}
                style={{ width: "100%" }}
                {...props}
            />
            {disableShortcuts && (
                <Button onClick={() => onChangeDate(dayjs(date).add(1, "day"))} disabled={disabledDate(dayjs(date).add(1, "day"))}>
                    <FontAwesomeIcon icon={["fal", "chevron-right"]} />
                </Button>
            )}
        </Input.Group>
    );
};

DateSelector.propTypes = {
    defaultValue: PropTypes.instanceOf(dayjs),
    value: PropTypes.instanceOf(dayjs),
    onChange: PropTypes.func,
    disabledDate: PropTypes.func
};

export default DateSelector;
