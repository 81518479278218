import PropTypes from "prop-types";

import { usePermissions } from "misc/api/keycloak";

const CheckAppPermissions = ({ allowed, fallback = null, children }) => {
    const { permissions } = usePermissions();

    return allowed.every(permission => permissions.includes(permission)) ? children : fallback;
};

CheckAppPermissions.propTypes = {
    allowed: PropTypes.arrayOf(PropTypes.string),
    fallback: PropTypes.node
};

export default CheckAppPermissions;
