import React from "react";

import { Transfer } from "misc/transfer";
import { useManufacturers } from "misc/api/datalayer";

const ManufacturerTransfer = props => {
    const { manufacturers } = useManufacturers({ suspense: true });

    return (
        <Transfer
            dataSource={manufacturers.map(manufacturer => ({
                key: manufacturer.uuid,
                title: manufacturer.name
            }))}
            filterOption={(inputValue, option) => {
                return option.title.toLowerCase().includes(inputValue.toLowerCase());
            }}
            {...props}
        />
    );
};

export default ManufacturerTransfer;
