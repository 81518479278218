import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import i18n from "i18next";

import de from "translations/de.json";
import en from "translations/en.json";
import jp from "translations/jp.json";

/*
 * not like to use this?
 * have a look at the Quick start guide
 * for passing in lng and translations on init
 */

const resources = {
    de: {
        translation: de
    },
    en: {
        translation: en
    },
    jp: {
        translation: jp
    }
};

i18n

    /*
     * detect user language
     * learn more: https://github.com/i18next/i18next-browser-languageDetector
     */
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)

    /*
     * init i18next
     * for all options read: https://www.i18next.com/overview/configuration-options
     */
    .init({
        resources,
        fallbackLng: ["de"],
        debug: false,
        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        }
    });

export default i18n;
