import PropTypes from "prop-types";
import { uniqBy, isNil } from "lodash";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import React, { useState, useEffect, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Space, Input, Form, Switch, Row, Col, Tooltip, Button, Modal, Alert, Radio } from "antd";

import { Card } from "misc/card";
import { datalayerUrls } from "misc/urls";
import { SubfleetSelector } from "misc/selectors";
import { useLoggedInUser } from "misc/api/keycloakApi";
import { TextField, NumberField, TitleField } from "misc/fields";
import { OrganisationalUnitTransfer, VehicleTransfer, UserTransfer } from "misc/transfer";
import { useCreateSubfleet, useDeleteSubfleet, useSubfleets, useUpdateSubfleet, useUnsubsribeSubfleet } from "misc/api/fleetconfiguration";

import { stsColors } from "styles";
import SideHeader from "navigation/SideHeader";

const FleetConfiguration = ({ parameters = [] }) => {
    const [modal, contextHolder] = Modal.useModal();

    const history = useHistory();

    const location = useLocation();

    const [t] = useTranslation();

    const [form] = Form.useForm();

    const { subfleets } = useSubfleets({ suspense: true });
    const { loggedInUser } = useLoggedInUser({ suspense: true });

    const { createSubfleet, isLoading: isCreating } = useCreateSubfleet();
    const { updateSubfleet, isLoading: isUpdating } = useUpdateSubfleet();
    const { deleteSubfleet, isLoading: isDeleting } = useDeleteSubfleet();
    const { unsubsribeSubfleet, isLoading: isUnsubscribe } = useUnsubsribeSubfleet();

    const [mode, setMode] = useState(location.state?.fleet ? "edit" : "create");
    const [selectedFleet, setSelectedFleet] = useState(location.state?.fleet ?? "newFleet");

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const [disabledParameters, setDisabledParameters] = useState([]);
    const [selectedOrganisationalUnits, setSelectedOrganisationalUnits] = useState([]);
    const [vehiclesCount, setVehiclesCount] = useState(null);

    const [configurationMode, setConfigurationMode] = useState("vehicles");

    // TODO: Better useMutation ?
    const { refetch, isFetching } = useQuery(["vehiclesCount", selectedOrganisationalUnits], {
        meta: {
            request: {
                method: "GET",
                params: { type: "vehiclesCount" },
                filter: { hierarchy: selectedOrganisationalUnits },
                url: datalayerUrls.fleet.values()
            }
        },
        onSuccess: newData => {
            setVehiclesCount(newData.value);
        },
        onError: () => {
            alert("error");
        },
        enabled: false
    });

    const fleet = useMemo(() => subfleets.find(fleet => fleet.subfleetId === selectedFleet), [subfleets, selectedFleet]);

    const isDefault = useMemo(() => {
        return fleet?.default;
    }, [fleet]);

    const isShared = useMemo(() => {
        return fleet && fleet.owner !== loggedInUser.id;
    }, [fleet]);

    const isReadOnly = useMemo(() => {
        if (!selectedFleet) return true;
        if (isDefault) return true;
        if (isShared) return true;

        return false;
    }, [selectedFleet, isDefault, isShared]);

    useEffect(() => {
        if (selectedOrganisationalUnits.length) refetch();
        else setVehiclesCount(null);
    }, [selectedOrganisationalUnits]);

    useEffect(() => {
        if (selectedFleet === "newFleet") {
            form.resetFields();
            form.setFieldsValue({ users: [loggedInUser.id] });

            setDisabledParameters([]);
        } else if (selectedFleet) {
            const formattedFleet = { ...fleet, ...fleet.parameters, users: [...fleet.users, fleet.owner] };

            form.resetFields();
            form.setFieldsValue(formattedFleet);

            setDisabledParameters(parameters.map(parameter => parameter.name).filter(parameter => isNil(formattedFleet[parameter])));
            setConfigurationMode(fleet.organisationalUnits.length ? "organisationalUnits" : "vehicles");
            setSelectedOrganisationalUnits(fleet.organisationalUnits);
        } else {
            form.resetFields();
            setDisabledParameters([]);
        }
    }, [selectedFleet]);

    const onCreate = () => {
        setMode("create");
        setSelectedFleet("newFleet");
    };

    const onEdit = () => {
        setMode("edit");
        setSelectedFleet(null);
    };

    const onContinue = () => {
        setModalIsOpen(false);
        setSelectedFleet(null);
        setConfigurationMode("vehicles");
        setMode("edit");

        window.scrollTo(0, 0);
    };

    const onFinish = values => {
        if (selectedFleet === "newFleet")
            createSubfleet(
                {
                    name: values.name,
                    owner: loggedInUser.id,
                    vehicles: configurationMode === "vehicles" ? values.vehicles : [],
                    organisationalUnits: configurationMode === "organisationalUnits" ? values.organisationalUnits : [],
                    users: values.users.filter(user => user !== loggedInUser.id),
                    parameters: parameters.reduce(
                        (previous, current) => ({
                            ...previous,
                            [current.name]: disabledParameters.includes(current.name) ? null : values[current.name]
                        }),
                        {}
                    )
                },
                {
                    onSuccess: () => {
                        history.goBack();
                    },
                    onError: () => {
                        modal.error({
                            title: <TextField value="t_http_error_placeholder" />,
                            content: <TextField value="t_http_error_placeholder_description" style={{ marginBottom: 15 }} />,
                            okText: t("t_close"),
                            okButtonProps: { type: "default" },
                            icon: <FontAwesomeIcon icon={["far", "circle-xmark"]} style={{ color: stsColors.red2, fontSize: 30, marginBottom: 15 }} />
                        });
                    }
                }
            );
        else {
            updateSubfleet(
                {
                    subfleetId: selectedFleet,
                    name: values.name,
                    owner: loggedInUser.id,
                    vehicles: configurationMode === "vehicles" ? values.vehicles : [],
                    organisationalUnits: configurationMode === "organisationalUnits" ? values.organisationalUnits : [],
                    users: values.users.filter(user => user !== loggedInUser.id),
                    parameters: parameters.reduce(
                        (previous, current) => ({
                            ...previous,
                            [current.name]: disabledParameters.includes(current.name) ? null : values[current.name]
                        }),
                        {}
                    )
                },
                {
                    onSuccess: () => {
                        history.goBack();
                    },
                    onError: () => {
                        modal.error({
                            title: <TextField value="t_http_error_placeholder" />,
                            content: <TextField value="t_http_error_placeholder_description" style={{ marginBottom: 15 }} />,
                            okText: t("t_close"),
                            okButtonProps: { type: "default" },
                            icon: <FontAwesomeIcon icon={["far", "circle-xmark"]} style={{ color: stsColors.red2, fontSize: 30, marginBottom: 15 }} />
                        });
                    }
                }
            );
        }
    };

    const onDelete = values => {
        deleteSubfleet(values, {
            onSuccess: () => {
                modal.confirm({
                    title: <TextField value="t_fleet_configuration_submit_deleted" />,
                    content: <TextField value="t_fleet_configuration_submit_description" style={{ marginBottom: 15 }} />,
                    okText: t("t_finished"),
                    cancelText: t("t_continue"),
                    icon: <FontAwesomeIcon icon={["fas", "circle-check"]} style={{ color: stsColors.blue2, fontSize: 30, marginBottom: 15 }} />,
                    onOk: () => history.goBack(),
                    onCancel: onContinue
                });
            },
            onError: () => {
                modal.error({
                    title: <TextField value="t_http_error_placeholder" />,
                    content: <TextField value="t_http_error_placeholder_description" style={{ marginBottom: 15 }} />,
                    okText: t("t_close"),
                    okButtonProps: { type: "default" },
                    icon: <FontAwesomeIcon icon={["far", "circle-xmark"]} style={{ color: stsColors.red2, fontSize: 30, marginBottom: 15 }} />
                });
            }
        });
    };

    const onUnsubsribeSharedFleet = values => {
        unsubsribeSubfleet(values, {
            onSuccess: () => {
                modal.confirm({
                    title: <TextField value="t_fleet_configuration_submit_unsubscribe" />,
                    content: <TextField value="t_fleet_configuration_submit_unsubscribe_description" style={{ marginBottom: 15 }} />,
                    okText: t("t_finished"),
                    cancelText: t("t_continue"),
                    icon: <FontAwesomeIcon icon={["fas", "circle-check"]} style={{ color: stsColors.blue2, fontSize: 30, marginBottom: 15 }} />,
                    onOk: () => history.goBack(),
                    onCancel: onContinue
                });
            },
            onError: () => {
                modal.error({
                    title: <TextField value="t_http_error_placeholder" />,
                    content: <TextField value="t_http_error_placeholder_description" style={{ marginBottom: 15 }} />,
                    okText: t("t_close"),
                    okButtonProps: { type: "default" },
                    icon: <FontAwesomeIcon icon={["far", "circle-xmark"]} style={{ color: stsColors.red2, fontSize: 30, marginBottom: 15 }} />
                });
            }
        });
    };

    return (
        <SideHeader>
            {contextHolder}
            <Card
                title={<TextField value={mode === "edit" ? "t_fleet_configuration_edit_fleet" : "t_fleet_configuraton_new_fleet"} />}
                extra={
                    <Space>
                        {mode === "edit" ? (
                            <Space size={3}>
                                <Button onClick={onCreate}>
                                    <Space>
                                        <FontAwesomeIcon icon={["fal", "plus"]} />
                                        <TextField value="t_fleet_create" />
                                    </Space>
                                </Button>
                                <Button type="primary" danger onClick={() => setModalIsOpen(true)} disabled={isReadOnly}>
                                    <Space>
                                        <FontAwesomeIcon icon={["fal", "trash"]} />
                                        <TextField value="t_fleet_delete" />
                                    </Space>
                                </Button>
                            </Space>
                        ) : (
                            <Button onClick={onEdit}>
                                <Space>
                                    <FontAwesomeIcon icon={["fal", "pen-to-square"]} />
                                    <TextField value="t_fleet_edit" />
                                </Space>
                            </Button>
                        )}
                    </Space>
                }
            >
                <Modal
                    title={<TextField value="t_fleet_delete" />}
                    visible={modalIsOpen}
                    onOk={() => onDelete({ subfleetId: selectedFleet })}
                    onCancel={() => setModalIsOpen(false)}
                    okText={<TextField value="t_delete" />}
                    okButtonProps={{ danger: true, loading: isDeleting }}
                    cancelText={<TextField value="t_cancel" />}
                >
                    <TextField value="t_fleet_delete_confirm" />
                </Modal>

                <Row gutter={[40, 40]}>
                    {mode === "edit" && (
                        <Col span={24}>
                            <Space direction="vertical" size={10} style={{ width: "100%" }}>
                                <TextField value="t_fleet_selected" />
                                <SubfleetSelector value={selectedFleet} onChange={setSelectedFleet} />
                            </Space>
                        </Col>
                    )}

                    <Col span={24}>
                        {isDefault && (
                            <Alert
                                message={<TextField value="t_fleet_default_title" />}
                                description={<TextField value="t_fleet_default_description" />}
                                type="info"
                                showIcon
                                style={{ height: 80 }}
                            />
                        )}
                        {isShared && (
                            <Alert
                                message={<TextField value="t_fleet_shared_title" />}
                                description={<TextField value="t_fleet_shared_description" />}
                                type="info"
                                action={
                                    <Button danger onClick={() => onUnsubsribeSharedFleet({ subfleetId: selectedFleet })}>
                                        <TextField value="t_fleet_shared_unsubscribe_button_text" />
                                    </Button>
                                }
                                showIcon
                                style={{ height: 80 }}
                            />
                        )}
                    </Col>

                    <Col span={24}>
                        <Space direction="vertical" size={20} style={{ width: "100%" }}>
                            <Space>
                                <Avatar size={40} icon={<FontAwesomeIcon icon={["fas", "gears"]} />} />
                                <TextField value="t_fleet_configuration_basic" style={{ fontSize: 16 }} />
                            </Space>

                            <Form form={form} layout="vertical">
                                <Form.Item name="name" label={t("t_fleet_name")} rules={[{ required: true }]}>
                                    <Input disabled={isReadOnly} />
                                </Form.Item>
                            </Form>
                        </Space>
                    </Col>

                    <Col span={24}>
                        <Space direction="vertical" size={30} style={{ width: "100%" }}>
                            <Radio.Group
                                value={configurationMode}
                                onChange={e => setConfigurationMode(e.target.value)}
                                disabled={isReadOnly}
                                buttonStyle="solid"
                            >
                                <Radio.Button value="vehicles">
                                    <TextField value="t_fleet_configuration_mode_vehicles" />
                                </Radio.Button>
                                <Radio.Button value="organisationalUnits">
                                    <TextField value="t_fleet_configuration_mode_organisational_units" />
                                </Radio.Button>
                            </Radio.Group>

                            {configurationMode === "vehicles" && (
                                <Space direction="vertical" size={0}>
                                    <TitleField value="t_fleet_configuration_mode_description" level={5} />
                                    <Space direction="vertical" size={3}>
                                        <TextField value="t_fleet_configuration_mode_vehicles_description" />
                                        <TextField value="t_fleet_configuration_mode_vehicles_description_details" />
                                    </Space>
                                </Space>
                            )}

                            {configurationMode === "organisationalUnits" && (
                                <Space direction="vertical" size={0}>
                                    <TitleField value="t_fleet_configuration_mode_description" level={5} />
                                    <Space direction="vertical" size={3}>
                                        <TextField value="t_fleet_configuration_mode_organisational_units_description" />
                                        <TextField value="t_fleet_configuration_mode_organisational_units_description_details" />
                                    </Space>
                                </Space>
                            )}

                            <Form form={form} layout="vertical">
                                <Form.Item
                                    name="organisationalUnits"
                                    label={t("t_fleet_organisational_units")}
                                    rules={[{ required: configurationMode === "organisationalUnits" }]}
                                    hidden={configurationMode === "vehicles"}
                                >
                                    <OrganisationalUnitTransfer
                                        onChange={setSelectedOrganisationalUnits}
                                        disabled={isReadOnly}
                                        footer={({ direction }) =>
                                            direction === "right" && (
                                                <div style={{ padding: 10 }}>
                                                    <Space>
                                                        <TextField value="t_fleet_configuration_vehicles_count" />
                                                        {isFetching ? <TextField value="t_is_loading" /> : <NumberField value={vehiclesCount} />}
                                                    </Space>
                                                </div>
                                            )
                                        }
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="vehicles"
                                    label={t("t_fleet_vehicles")}
                                    rules={[{ required: configurationMode === "vehicles" }]}
                                    hidden={configurationMode === "organisationalUnits"}
                                >
                                    <VehicleTransfer disabled={isReadOnly} />
                                </Form.Item>
                            </Form>
                        </Space>
                    </Col>

                    <Col span={24}>
                        <Space direction="vertical" size={20} style={{ width: "100%" }}>
                            <Space>
                                <Avatar size={40} icon={<FontAwesomeIcon icon={["fas", "share-nodes"]} />} />
                                <TextField value="t_fleet_sharing" style={{ fontSize: 16 }} />
                            </Space>

                            <Form form={form} layout="vertical">
                                <Form.Item name="users" label={t("t_fleet_shared_users")} rules={[{ required: true }]}>
                                    <UserTransfer disableItem={item => item.id === loggedInUser.id} disabled={isReadOnly} />
                                </Form.Item>
                            </Form>
                        </Space>
                    </Col>

                    <Col span={24}>
                        {parameters.length ? (
                            <Space direction="vertical" size={20}>
                                <Space>
                                    <Avatar size={40} icon={<FontAwesomeIcon icon={["fas", "sliders"]} />} />
                                    <TextField value="t_fleet_parameters" style={{ fontSize: 16 }} />
                                </Space>

                                {uniqBy(parameters, parameter => parameter.group).map(parameter => (
                                    <Space direction="vertical" size={20}>
                                        <TextField value={parameter.group ?? ""} />
                                        <div>
                                            {parameters
                                                .filter(item => item.group === parameter.group)
                                                .map(item => (
                                                    <>
                                                        <Space size={15} style={{ width: "100%" }}>
                                                            <Space size={15}>
                                                                <Form form={form} layout="vertical">
                                                                    <Form.Item
                                                                        label={t(item.label)}
                                                                        name={item.name}
                                                                        rules={[
                                                                            {
                                                                                required: disabledParameters.includes(item.name)
                                                                                    ? false
                                                                                    : item.required
                                                                            }
                                                                        ]}
                                                                        initialValue={item.initialValue}
                                                                    >
                                                                        {React.cloneElement(item.render(), {
                                                                            disabled: disabledParameters.includes(item.name) || isReadOnly
                                                                        })}
                                                                    </Form.Item>
                                                                </Form>
                                                                {parameters.filter(item => item.group === parameter.group).length > 1 ? (
                                                                    <Tooltip title={<TextField value="t_fleet_parameters_switch_description" />}>
                                                                        <Switch
                                                                            checked={!disabledParameters.includes(item.name)}
                                                                            onChange={checked => {
                                                                                if (checked) {
                                                                                    setDisabledParameters(
                                                                                        disabledParameters.filter(
                                                                                            parameter => parameter !== item.name
                                                                                        )
                                                                                    );
                                                                                } else {
                                                                                    setDisabledParameters([...disabledParameters, item.name]);
                                                                                    form.validateFields([item.name]);
                                                                                }
                                                                            }}
                                                                            disabled={isReadOnly}
                                                                            style={{ marginRight: 20 }}
                                                                        />
                                                                    </Tooltip>
                                                                ) : (
                                                                    <div style={{ width: 44, height: 22, marginRight: 20 }} />
                                                                )}
                                                            </Space>
                                                            <Space direction="vertical">
                                                                <TextField value={item.description} />
                                                                {item?.definition && <TextField value={item.definition} />}
                                                            </Space>
                                                        </Space>
                                                    </>
                                                ))}
                                        </div>
                                    </Space>
                                ))}
                            </Space>
                        ) : null}
                    </Col>

                    <Col span={24}>
                        <Row justify="end">
                            <Form
                                form={form}
                                onFinish={onFinish}
                                validateMessages={{ required: `\${label} ${t("t_is_required")} ` }}
                                scrollToFirstError
                            >
                                <Form.Item>
                                    <Space size={3}>
                                        <Button
                                            htmlType="submit"
                                            type="primary"
                                            loading={isCreating || isUpdating}
                                            disabled={isReadOnly}
                                            style={isReadOnly ? {} : { background: stsColors.blue2 }}
                                        >
                                            <TextField value="t_save" />
                                        </Button>
                                        <Button>
                                            <TextField value="t_cancel" onClick={() => history.goBack()} />
                                        </Button>
                                    </Space>
                                </Form.Item>
                            </Form>
                        </Row>
                    </Col>
                </Row>
            </Card>
        </SideHeader>
    );
};

FleetConfiguration.propTypes = {
    parameters: PropTypes.arrayOf(PropTypes.object)
};

export default FleetConfiguration;
