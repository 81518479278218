import PropTypes from "prop-types";
import styled from "styled-components";
import React, { useMemo } from "react";
import { Popover, Space, Tag } from "antd";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Table } from "misc/tables";
import { CheckedIcon } from "misc/icons/CheckedIcon";
import { NumberField, TextField } from "misc/fields";
import { useChargingMonitorParameters } from "misc/api/datalayer";

import { cases } from "app/modules/cm/components/cases";

import { stsColors } from "styles";
import _ from "lodash";

const Definition = styled.span({
    display: "flex",
    gap: "6px",
    fontSize: "10px"
});

const CmCasesTable = ({ params, ...props }) => {
    const { t } = useTranslation();

    const { chargingMonitorParameters, isLoading } = useChargingMonitorParameters({ latest: true, suspense: true });

    // Merges rows by index for "expected values" column
    const mergedRows = [2, 0, 2, 0, 2, 0, 2, 0, 1, 2, 0, 2, 0, 2, 0, 1, 2, 0, 1, 1];

    const columns = useMemo(
        () => [
            {
                title: () => <TextField value="t_cm_case" />,
                dataIndex: "case",
                render: text => <TextField value={text} />,
                fixed: true,
                width: "50px"
            },
            {
                dataIndex: "locationDescription",
                title: () => <TextField value="t_cm_wiki_vehicle_location_description" />,
                render: text => <TextField value={text} />,
                fixed: true
            },
            {
                key: "description",
                title: () => <TextField value="t_cm_wiki_description" />,
                children: [
                    {
                        key: "location-check",
                        dataIndex: "locationCheck",
                        title: () => (
                            <Space direction="vertical">
                                <Space>
                                    <TextField value="t_cm_location_check" />
                                    <Popover
                                        content={<TextField value="t_cm_wiki_related_to_target_location_in_llw" />}
                                        trigger="click"
                                        overlayStyle={{ width: 300 }}
                                        placement="bottom"
                                    >
                                        <FontAwesomeIcon icon={["fal", "circle-info"]} />
                                    </Popover>
                                </Space>
                                <Definition>
                                    <TextField value={t("t_cm_wiki_location_distance")} />
                                </Definition>
                            </Space>
                        ),
                        render: text => (typeof text == "boolean" ? <CheckedIcon checked={text} /> : <TextField />),
                        width: 190
                    },
                    {
                        key: "charging-station-check",
                        dataIndex: "chargingStationCheck",
                        title: () => <TextField value="t_cm_charging_station" />,
                        render: text => {
                            if (text === undefined) return <TextField />;
                            if (text === null)
                                return (
                                    <Space>
                                        <CheckedIcon checked={true} />
                                        <span>/</span>
                                        <CheckedIcon checked={false} />
                                    </Space>
                                );

                            return <CheckedIcon checked={text} />;
                        },
                        width: 190
                    },
                    {
                        key: "connectivity-check",
                        dataIndex: "connectivityCheck",
                        title: () => (
                            <Space direction="vertical">
                                <Space>
                                    <TextField value="t_cm_connectivity" />
                                    <Popover
                                        content={
                                            <Space direction="vertical">
                                                <TextField value="t_connectivity_description" />
                                            </Space>
                                        }
                                        trigger="click"
                                        overlayStyle={{ width: 300 }}
                                        placement="bottom"
                                    >
                                        <FontAwesomeIcon icon={["fal", "circle-info"]} />
                                    </Popover>
                                </Space>
                                {chargingMonitorParameters?.threshold_connectivity ? (
                                    <Definition>
                                        <TextField
                                            value={t("t_cm_connectivity_definition", {
                                                parameter: chargingMonitorParameters.threshold_connectivity
                                            })}
                                        />
                                    </Definition>
                                ) : (
                                    <TextField />
                                )}
                            </Space>
                        ),
                        render: text => <CheckedIcon checked={text} />,
                        width: 190
                    },
                    {
                        key: "charge-status",
                        dataIndex: "chargeStatus",
                        title: () => (
                            <Space direction="vertical">
                                <Space>
                                    <TextField value="t_cm_charge_status" />
                                    <Popover
                                        content={
                                            <Space direction="vertical">
                                                <TextField value="t_cm_charge_status_description" />
                                                <TextField value="t_cm_wiki_related_to_expected_values" />
                                            </Space>
                                        }
                                        trigger="click"
                                        overlayStyle={{ width: 300 }}
                                        placement="bottom"
                                    >
                                        <FontAwesomeIcon icon={["fal", "circle-info"]} />
                                    </Popover>
                                </Space>
                                {chargingMonitorParameters?.threshold_evaluation ? (
                                    <Definition>
                                        <TextField
                                            value={t("t_cm_charge_status_definition", {
                                                parameter: chargingMonitorParameters.threshold_evaluation * 100
                                            })}
                                        />
                                    </Definition>
                                ) : (
                                    <TextField />
                                )}
                            </Space>
                        ),
                        render: text => {
                            if (_.isNil(text)) return <TextField />;

                            return (
                                <Tag color={text ? stsColors.green1 : stsColors.red1} style={{ width: 60, textAlign: "center" }}>
                                    <TextField value={text ? "t_cm_wiki_ok" : "t_cm_wiki_not_ok"} />
                                </Tag>
                            );
                        },
                        onCell: () => ({ style: { borderRightWidth: "3px" } }),
                        onHeaderCell: () => ({ style: { borderRightWidth: "3px" } }),
                        width: 190
                    }
                ],
                onHeaderCell: () => ({ style: { borderRightWidth: "3px", fontWeight: "bold" } })
            },
            {
                key: "expected-values",
                title: () => <TextField value="t_cm_wiki_expected_values" />,
                children: [
                    {
                        key: "location-post",
                        dataIndex: "locationPost",
                        title: () => (
                            <Space size="small" direction="vertical">
                                <Space>
                                    <TextField value="t_cm_wiki_location_post" />
                                    <Popover
                                        content={
                                            <Space direction="vertical">
                                                <TextField value="t_cm_wiki_method" />
                                                <TextField value="t_cm_wiki_expected_values_method_post" />
                                            </Space>
                                        }
                                        trigger="click"
                                        overlayStyle={{ width: 300 }}
                                        placement="bottom"
                                    >
                                        <FontAwesomeIcon icon={["fal", "circle-info"]} />
                                    </Popover>
                                </Space>
                                {chargingMonitorParameters?.distance_limit_operation ? (
                                    <Definition>
                                        <Space size={2}>
                                            <TextField value={t("t_cm_wiki_current_value")} suffix="=" />
                                            <NumberField value={chargingMonitorParameters.distance_limit_operation} />
                                            <TextField value="t_meter" />
                                        </Space>
                                    </Definition>
                                ) : (
                                    <TextField />
                                )}
                            </Space>
                        ),
                        render: text => text && <TextField value={text} />,
                        onCell: (record, index) => ({ rowSpan: mergedRows[index] || 0 }),
                        width: 200
                    },
                    {
                        key: "location-production",
                        dataIndex: "locationProduction",
                        title: () => (
                            <Space size="small" direction="vertical">
                                <Space>
                                    <TextField value="t_cm_wiki_location_production" />
                                    <Popover
                                        content={
                                            <Space direction="vertical">
                                                <TextField value="t_cm_wiki_method" />
                                                <TextField value="t_cm_wiki_expected_values_method_production" />
                                            </Space>
                                        }
                                        trigger="click"
                                        overlayStyle={{ width: 300 }}
                                        placement="bottom"
                                    >
                                        <FontAwesomeIcon icon={["fal", "circle-info"]} />
                                    </Popover>
                                </Space>
                                {chargingMonitorParameters?.distance_limit_production ? (
                                    <Definition>
                                        <Space size={2}>
                                            <TextField value={t("t_cm_wiki_current_value")} suffix="=" />
                                            <NumberField value={chargingMonitorParameters.distance_limit_production} />
                                            <TextField value="t_meter" />
                                        </Space>
                                    </Definition>
                                ) : (
                                    <TextField />
                                )}
                            </Space>
                        ),
                        render: text => text && <TextField value={text} />,
                        onCell: (record, index) => ({ rowSpan: mergedRows[index] || 0 }),
                        width: 200
                    },
                    {
                        key: "location-test-vehicle",
                        dataIndex: "locationTestVehicle",
                        title: () => (
                            <Space>
                                <TextField value="t_cm_wiki_location_test_vehicle" />
                                <Popover
                                    content={
                                        <Space direction="vertical">
                                            <TextField value="t_cm_wiki_method" />
                                            <TextField value="t_cm_wiki_expected_values_method_test_vehicles" />
                                        </Space>
                                    }
                                    trigger="click"
                                    overlayStyle={{ width: 300 }}
                                    placement="bottom"
                                >
                                    <FontAwesomeIcon icon={["fal", "circle-info"]} />
                                </Popover>
                            </Space>
                        ),
                        render: text => text && <TextField value={text} />,
                        onHeaderCell: () => ({ style: { borderRightWidth: "3px" } }),
                        onCell: (record, index) => ({
                            rowSpan: mergedRows[index] || 0,
                            style: { borderRightWidth: "3px" }
                        }),
                        width: 200
                    }
                ],
                onHeaderCell: () => ({ style: { borderRightWidth: "3px", fontWeight: "bold" } })
            },
            {
                key: "responsible",
                title: () => <TextField value="t_cm_wiki_responsible" />,
                children: [
                    {
                        key: "dpdhl",
                        title: () => <TextField value="t_cm_wiki_dpdhl" />,
                        children: [
                            {
                                key: "task",
                                dataIndex: "dpdhlTask",
                                title: () => <TextField value="t_cm_wiki_task" />,
                                render: text => <TextField value={text} />,
                                width: 80
                            },
                            {
                                key: "description",
                                dataIndex: "dpdhlDescription",
                                title: () => <TextField value="t_cm_wiki_description" />,
                                render: text => <TextField value={text} />
                            }
                        ]
                    },
                    {
                        key: "sts",
                        title: () => <TextField value="t_cm_wiki_sts" />,
                        children: [
                            {
                                key: "task",
                                dataIndex: "stsTask",
                                title: () => <TextField value="t_cm_wiki_task" />,
                                render: text => <TextField value={text} />,
                                width: 80
                            },
                            {
                                key: "description",
                                dataIndex: "stsDescription",
                                title: () => <TextField value="t_cm_wiki_description" />,
                                render: text => <TextField value={text} />
                            }
                        ]
                    }
                ],
                onHeaderCell: () => ({ style: { fontWeight: "bold" } })
            }
        ],
        [chargingMonitorParameters]
    );

    return (
        <Table
            isLoading={isLoading}
            tableLayout="fixed"
            dataSource={cases}
            columns={columns}
            enumerate={false}
            rowKey="case"
            size="small"
            bordered
            pagination={false}
            scroll={{ x: 2800 }}
            sticky
            {...props}
        />
    );
};

CmCasesTable.propTypes = {
    filter: PropTypes.object,
    multiple: PropTypes.bool,
    queryProps: PropTypes.object
};

export default CmCasesTable;
