import dayjs from "dayjs";

import PropTypes from "prop-types";
import { Button, Input, DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const WeekSelector = ({ defaultValue, value, onChange = () => null, disabledDate = () => null, bodyStyle, ...props }) => {
    const [week, setWeek] = useState(value ?? defaultValue ?? dayjs());

    useEffect(() => setWeek(value), [value]);

    const onChangeWeek = week => {
        setWeek(week);
        onChange(week);
    };

    return (
        <Input.Group style={bodyStyle} compact>
            <Button
                onClick={() => onChangeWeek(dayjs(week).subtract(1, "week"))}
                disabled={disabledDate(dayjs(week).subtract(1, "week").endOf("week"))}
            >
                <FontAwesomeIcon icon={["fal", "chevron-left"]} />
            </Button>
            <DatePicker
                value={dayjs(week)}
                picker="week"
                format={value => `KW ${value.week()} / ${value.weekYear()}`}
                onChange={onChangeWeek}
                disabledDate={disabledDate}
                {...props}
            />
            <Button onClick={() => onChangeWeek(dayjs(week).add(1, "week"))} disabled={disabledDate(dayjs(week).add(1, "week").startOf("week"))}>
                <FontAwesomeIcon icon={["fal", "chevron-right"]} />
            </Button>
        </Input.Group>
    );
};

WeekSelector.propTypes = {
    defaultValue: PropTypes.instanceOf(dayjs),
    value: PropTypes.instanceOf(dayjs),
    onChange: PropTypes.func,
    disabledDate: PropTypes.func
};

export default WeekSelector;
