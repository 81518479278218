import { Tree, Spin } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import React, { useState, useMemo } from "react";

import { Transfer } from "misc/transfer";
import { ConditionalWrapper } from "misc/conditional";
import { useFlatHierarchy, usePermittedHierarchy } from "misc/api/datalayer";

const OrganisationalUnitTransfer = ({ value, defaultValue, onChange = () => null, disabled, isLoading, ...props }) => {
    const [t] = useTranslation();

    const { permittedHierarchy, isLoading: isLoadingHierarchy } = usePermittedHierarchy({ suspense: true });
    const { flatHierarchy, isLoading: isLoadingFlatHierarchy } = useFlatHierarchy({ suspense: true });

    const [targetKeys, setTargetKeys] = useState(value ?? defaultValue ?? []);

    const [searchString, setSearchString] = useState("");
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [autoExpandParent, setAutoExpandParent] = useState(true);

    const formattedPermittedHierarchy = useMemo(() => {
        const formatData = data => {
            return data.map(element => {
                return {
                    ...element,
                    key: element.uuid,
                    title: element.name,
                    children: element.children ? formatData(element.children) : null
                };
            });
        };

        return formatData(permittedHierarchy);
    }, [permittedHierarchy]);

    const treeData = useMemo(() => {
        function filter(array, title) {
            const getChildren = (result, object) => {
                if (object.title.toLowerCase().indexOf(title.toLowerCase()) > -1) {
                    result.push(object);
                    return result;
                }
                if (Array.isArray(object.children)) {
                    const children = object.children.reduce(getChildren, []);
                    if (children.length) result.push({ ...object, children });
                }
                return result;
            };

            return array.reduce(getChildren, []);
        }

        return filter(formattedPermittedHierarchy, searchString);
    }, [formattedPermittedHierarchy, searchString]);

    const onExpand = expandedKeysValue => {
        // if not set autoExpandParent to false, if children expanded when searching, parent can not collapse.
        setExpandedKeys(expandedKeysValue);
        setAutoExpandParent(false);
    };

    const onSearch = (dir, value) => {
        if (value.length > 2) {
            setSearchString(value);

            const getParentKey = (key, tree) => {
                let parentKey;
                for (let i = 0; i < tree.length; i++) {
                    const node = tree[i];
                    if (node.children) {
                        if (node.children.some(item => item.key === key)) {
                            parentKey = node.key;
                        } else if (getParentKey(key, node.children)) {
                            parentKey = getParentKey(key, node.children);
                        }
                    }
                }

                return parentKey;
            };
            const _expandedKeys = flatHierarchy
                .map(item => {
                    if (item.name.toLowerCase().indexOf(value.toLowerCase()) > -1) {
                        return getParentKey(item.uuid, treeData);
                    }
                    return null;
                })
                .filter((item, i, self) => item && self.indexOf(item) === i);

            setExpandedKeys(_expandedKeys);
            setAutoExpandParent(true);
        } else {
            setSearchString("");
            setExpandedKeys([]);
            setAutoExpandParent(false);
        }
    };

    const isChecked = (selectedKeys, eventKey) => selectedKeys.includes(eventKey);

    return (
        <ConditionalWrapper
            condition={disabled || isLoading || isLoadingHierarchy || isLoadingFlatHierarchy}
            wrapper={children => (disabled ? <Spin indicator={null}>{children}</Spin> : <Spin>{children}</Spin>)}
        >
            <Transfer
                {...props}
                dataSource={flatHierarchy}
                rowKey={item => item.uuid}
                render={item => item.name}
                targetKeys={targetKeys}
                titles={[t("t_available_organisational_units"), t("t_selected_organisational_units")]}
                onChange={targetKeys => {
                    setTargetKeys(targetKeys);
                    onChange(targetKeys);
                }}
                onSearch={onSearch}
                showSelectAll={false}
            >
                {({ direction, onItemSelect, selectedKeys }) => {
                    if (direction === "left") {
                        const checkedKeys = [...selectedKeys, ...targetKeys];

                        return (
                            <Tree
                                height={233}
                                onExpand={onExpand}
                                expandedKeys={expandedKeys}
                                autoExpandParent={autoExpandParent}
                                treeData={treeData}
                                blockNode
                                checkable
                                fieldNames={{ title: "name", key: "uuid", children: "children" }}
                                checkedKeys={checkedKeys}
                                onCheck={(_, { node: { key } }) => {
                                    onItemSelect(key, !isChecked(checkedKeys, key));
                                }}
                                onSelect={(_, { node: { key } }) => {
                                    onItemSelect(key, !isChecked(checkedKeys, key));
                                }}
                                {...props}
                            />
                        );
                    }
                }}
            </Transfer>
        </ConditionalWrapper>
    );
};

OrganisationalUnitTransfer.propTypes = {
    value: PropTypes.array,
    defaultValue: PropTypes.array,
    onChange: PropTypes.func
};

export default OrganisationalUnitTransfer;
