import PropTypes from "prop-types";
import React, { useMemo } from "react";

import { datalayerUrls } from "misc/urls";
import { VehiclesTableTemplate } from "misc/widgets/consumers";
import { TextField, DatetimeField, BooleanField, DateField } from "misc/fields";

const DefectTicketsTable = ({ filter, view, params, queryProps, ...props }) => {
    const columns = useMemo(
        () => [
            {
                key: "defectTicketId",
                dataIndex: "defectTicketId",
                title: () => <TextField value="t_defect_tickets_id" />,
                sorter: true,
                width: 300
            },
            {
                key: "timestampReport",
                dataIndex: "timestampReport",
                title: () => <TextField value="t_defect_tickets_timestamp_report" />,
                render: value => <DatetimeField value={value} />,
                sorter: true,
                defaultSortOrder: "descend",
                width: 200
            },
            {
                key: "errorCodes",
                dataIndex: "errorCodes",
                title: () => <TextField value="t_defect_tickets_error_codes" />,
                render: text => text.join(", "),
                ellipsis: true,
                width: 150
            },
            {
                key: "errorDescription",
                dataIndex: "errorDescription",
                title: () => <TextField value="t_defect_tickets_error_description" />,
                render: text => (text.length ? text[0] : "-"),
                ellipsis: true,
                width: 250
            },
            {
                key: "remarks",
                dataIndex: "remarks",
                title: () => <TextField value="t_defect_tickets_remarks" />,
                ellipsis: true,
                width: 250
            },

            {
                key: "isAccident",
                dataIndex: "isAccident",
                title: () => <TextField value="t_defect_tickets_is_accident" />,
                render: text => <BooleanField value={text} />,
                sorter: true,
                filters: [
                    { text: <TextField value="t_yes" inline />, value: true },
                    { text: <TextField value="t_no" inline />, value: false }
                ],
                ellipsis: true,
                width: 250
            },
            {
                key: "canRoll",
                dataIndex: "canRoll",
                title: () => <TextField value="t_defect_tickets_can_roll" />,
                render: text => <BooleanField value={text} />,
                sorter: true,
                filters: [
                    { text: <TextField value="t_yes" inline />, value: true },
                    { text: <TextField value="t_no" inline />, value: false }
                ],
                width: 250,
                ellipsis: true
            },
            {
                key: "canDrive",
                dataIndex: "canDrive",
                title: () => <TextField value="t_defect_tickets_can_drive" />,
                render: text => <BooleanField value={text} />,
                sorter: true,
                filters: [
                    { text: <TextField value="t_yes" inline />, value: true },
                    { text: <TextField value="t_no" inline />, value: false }
                ],
                width: 250,
                ellipsis: true
            },
            {
                key: "replacementRequired",
                dataIndex: "replacementRequired",
                title: () => <TextField value="t_defect_tickets_replacement_required" />,
                render: text => <BooleanField value={text} />,
                sorter: true,
                filters: [
                    { text: <TextField value="t_yes" inline />, value: true },
                    { text: <TextField value="t_no" inline />, value: false }
                ],
                width: 250,
                ellipsis: true
            },

            {
                key: "dateRepairPlanned",
                dataIndex: "dateRepairPlanned",
                title: () => <TextField value="t_defect_tickets_date_repair_planned" />,
                render: text => <DateField value={text} />,
                sorter: true,
                width: 250,
                ellipsis: true
            },

            {
                key: "processId",
                dataIndex: "processId",
                title: () => <TextField value="t_defect_tickets_process_id" />,

                sorter: true,
                width: 250,
                ellipsis: true
            },

            {
                key: "status",
                dataIndex: "status",
                title: () => <TextField value="t_defect_tickets_status" />,
                render: text => (
                    <TextField
                        value={
                            {
                                closed: "t_defect_tickets_status_closed",
                                open: "t_defect_tickets_status_open",
                                cancelled: "t_defect_tickets_status_cancelled"
                            }[text]
                        }
                    />
                ),
                sorter: true,
                filters: [
                    { text: <TextField value="t_defect_tickets_status_closed" inline />, value: "closed" },
                    { text: <TextField value="t_defect_tickets_status_open" inline />, value: "open" },
                    { text: <TextField value="t_defect_tickets_status_cancelled" inline />, value: "cancelled" }
                ],
                width: 200
            }
        ],
        []
    );

    // TODO: Maybe own endpoint instead of workaround

    return (
        <VehiclesTableTemplate
            queryKey="defectTickets"
            rowKey="defectTicketId"
            filter={filter}
            view={view}
            request={{
                method: "GET",
                url: datalayerUrls.fleet.widgets.list(),
                params: { type: "defectTickets" },
                filter: filter
            }}
            extractData="data"
            extractCount={data => data.statistics.count}
            columns={columns}
            queryProps={queryProps}
            sticky
            {...props}
        />
    );
};

DefectTicketsTable.propTypes = {
    filter: PropTypes.object,
    view: PropTypes.object,
    params: PropTypes.object,
    queryProps: PropTypes.object
};

export default DefectTicketsTable;
