import React from "react";
import PropTypes from "prop-types";
import { Marker as MapMarker } from "react-leaflet";

//import "leaflet/dist/leaflet.css";

const Marker = ({ position, ...props }) => {
    // useEffect(() => {
    //     const L = require("leaflet");
    //     delete L.Icon.Default.prototype._getIconUrl;
    //     L.Icon.Default.mergeOptions({
    //         iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    //         iconUrl: require("leaflet/dist/images/marker-icon.png"),
    //         shadowUrl: require("leaflet/dist/images/marker-shadow.png")
    //     });
    // }, []);
    return <MapMarker position={position} {...props} />;
};

Marker.propTypes = {
    position: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired
};

export default Marker;
