import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { stsColors } from "styles";
import { TextField } from "misc/fields";

export const CheckedIcon = ({ checked, colorChecked = stsColors.grey3, colorUnchecked = stsColors.grey3, colored }) => {
    if (_.isNil(checked)) return <TextField />;

    return checked ? (
        <FontAwesomeIcon icon={["fas", "check"]} color={colored ? stsColors.green2 : colorChecked} />
    ) : (
        <FontAwesomeIcon icon={["fas", "xmark"]} color={colored ? stsColors.red1 : colorUnchecked} />
    );
};
