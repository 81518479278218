import React, { Component } from "react";
import Moment from "react-moment";
import PropTypes from "prop-types";

class TimeField extends Component {
    render() {
        const props = this.props;

        const format = props.format ? props.format : "HH:mm:ss";

        return (
            <p className={props.className} style={props.style}>
                <Moment format={format} unix={typeof props.value === "number"}>
                    {props.value}
                </Moment>
            </p>
        );
    }
}

TimeField.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    format: PropTypes.string
};

export default TimeField;
