import moment from "moment";

moment.updateLocale("de", {
    relativeTime: {
        past: "Vor %s"
    },
    invalidDate: "-"
});

export default moment;
