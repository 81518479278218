import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Select } from "antd";

import { TextField } from "misc/fields";

const VIEWS = [
    { key: "day", label: <TextField value="t_day_view" /> },
    { key: "week", label: <TextField value="t_week_view" /> },
    { key: "month", label: <TextField value="t_month_view" /> },
    { key: "year", label: <TextField value="t_year_view" /> }
];

const ViewSelector = ({ value, options = [], onChange, ...props }) => {
    const filteredViews = useMemo(() => VIEWS.filter(view => options.includes(view.key)), [options]);

    return (
        <Select value={value} onChange={value => onChange && onChange(value)} style={{ width: 145 }} {...props}>
            {filteredViews.map(view => (
                <Select.Option key={view.key}>{view.label}</Select.Option>
            ))}
        </Select>
    );
};

ViewSelector.propTypes = {
    value: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.string)
};

export default ViewSelector;
