import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import { CheckAppPermissions } from "misc/authentication";

const ProtectedRoute = ({ allowed = [], fallbackPath = "/", children, ...props }) => {
    return (
        <CheckAppPermissions allowed={allowed} renderFallback={() => <Redirect to={fallbackPath} />}>
            <Route {...props}>{children}</Route>
        </CheckAppPermissions>
    );
};

ProtectedRoute.propTypes = {
    allowed: PropTypes.arrayOf(PropTypes.string),
    fallbackPath: PropTypes.string
};

export default ProtectedRoute;
