import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { ErrorHelp } from "misc/help";
import { datalayerUrls } from "misc/urls";
import { colorForError } from "misc/styles/Colors";
import { BarChartWidget } from "misc/widgets/consumers";

const ErrorsHistogram = ({ filter, vin, view = "day", height, ...props }) => {
    const { t } = useTranslation();

    return (
        <BarChartWidget
            title="t_errors_histogram"
            height={height}
            request={{
                url: datalayerUrls.vehicles.widgets.barChart(),
                params: { type: "errorsPerDay", vin: vin, view: view },
                filter: filter
            }}
            extractData={data =>
                data.reduce(
                    (previous, current) => [
                        ...previous,
                        { date: current.date, week: current.week, value: current.error1, error: "error1" },
                        { date: current.date, week: current.week, value: current.error2, error: "error2" },
                        { date: current.date, week: current.week, value: current.error3, error: "error3" },
                        { date: current.date, week: current.week, value: current.error4, error: "error4" },
                        { date: current.date, week: current.week, value: current.error5, error: "error5" },
                        { date: current.date, week: current.week, value: current.error6, error: "error6" },
                        { date: current.date, week: current.week, value: current.error101, error: "error101" },
                        { date: current.date, week: current.week, value: current.error102, error: "error102" },
                        { date: current.date, week: current.week, value: current.error103, error: "error103" }
                    ],
                    []
                )
            }
            chartConfig={{
                isStack: true,
                xField: view === "day" ? "date" : "week",
                yField: "value",
                seriesField: "error",
                color: ({ error }) => error && colorForError[error.substring(5)],
                meta: {
                    error: { formatter: value => value && t(`t_error_${value.substring(5)}`) },
                    date: { formatter: date => moment(date).format("DD.MM") },
                    week: { formatter: week => week }
                },
                empty: {
                    isEmpty: data => data.length < 1 || !data.some(item => item.value > 0),
                    title: "t_no_errors",
                    subTitle: "t_no_errors_existing"
                }
            }}
            help={{ renderHelp: () => <ErrorHelp /> }}
            {...props}
        />
    );
};

ErrorsHistogram.propTypes = {
    filter: PropTypes.object,
    vin: PropTypes.string,
    height: PropTypes.number
};

export default ErrorsHistogram;
