import React from "react";
import { Switch, Route, Redirect } from "react-router";

import { clientUrls } from "misc/urls";

import EngineeringErrors from "../components/EngineeringErrors";
import EngineeringSignals from "../components/EngineeringSignals";
import EngineeringDiagnostics from "../components/EngineeringDiagnostics";

const EngineeringContainer = () => {
    return (
        <Switch>
            <Route exact path={clientUrls.modules.engineering.errors()}>
                <EngineeringErrors />
            </Route>

            <Route exact path={clientUrls.modules.engineering.signals()}>
                <EngineeringSignals />
            </Route>

            <Route exact path={clientUrls.modules.engineering.diagnostics()}>
                <EngineeringDiagnostics />
            </Route>

            <Redirect to={clientUrls.modules.engineering.errors()} />
        </Switch>
    );
};

export default EngineeringContainer;
