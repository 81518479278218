import PropTypes from "prop-types";
import React, { useMemo } from "react";

import { datalayerUrls } from "misc/urls";
import { TextField, DatetimeField } from "misc/fields";

import { VehiclesTableTemplate } from "misc/widgets/consumers";

const ErrorsTable = ({ filter, view, params, queryProps, ...props }) => {
    const columns = useMemo(
        () => [
            {
                key: "timestamp",
                dataIndex: "timestamp",
                title: () => <TextField value="t_errors_timestamp" />,
                render: text => <DatetimeField value={text} />,
                sorter: true,
                defaultSortOrder: "descend",
                width: 220
            },
            {
                key: "errorType",
                dataIndex: "error",
                title: () => <TextField value="t_errors_error_type" />,
                render: text => (
                    <TextField
                        value={
                            {
                                1: "t_errors_error_type_1",
                                2: "t_errors_error_type_2",
                                3: "t_errors_error_type_3",
                                4: "t_errors_error_type_4",
                                5: "t_errors_error_type_5",
                                6: "t_errors_error_type_6",
                                101: "t_errors_error_type_101",
                                102: "t_errors_error_type_102",
                                103: "t_errors_error_type_103"
                            }[text]
                        }
                    />
                ),
                sorter: true,
                width: 220
            }
        ],
        []
    );

    return (
        <VehiclesTableTemplate
            queryKey="errors"
            filter={filter}
            view={view}
            request={{
                method: "GET",
                url: datalayerUrls.fleet.widgets.list(),
                params: { type: "errors" },
                filter: filter
            }}
            extractData="data"
            extractCount={data => data.statistics.count}
            rowKey={record => `${record.timestamp}-${record.error}`}
            columns={columns}
            queryProps={queryProps}
            {...props}
        />
    );
};

ErrorsTable.propTypes = {
    filter: PropTypes.object,
    view: PropTypes.object,
    params: PropTypes.object,
    queryProps: PropTypes.object
};

export default ErrorsTable;
