import { useKeycloak as useKeycloakHook } from "@react-keycloak/web";

export const useKeycloak = () => {
    const { initialized, keycloak } = useKeycloakHook();

    return { initialized, keycloak: { authenticated: false, ...keycloak } };
};

export const usePermissions = () => {
    const { keycloak } = useKeycloak();

    return { permissions: [...keycloak.resourceAccess.analysisframework.roles, ...keycloak.realmAccess.roles] };
};
