import React, { useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Space } from "antd";
import moment from "moment";
import styled from "styled-components";
import { TimeRange } from "pondjs";
import { Resizable, ChartContainer } from "react-timeseries-charts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { TextField } from "misc/fields";

import { fonts } from "styles/fonts";

const Header = styled(Row)({
    height: 40
});

const HeaderText = styled(TextField)({
    color: "rgb(102, 102, 102)"
});

const ZoomResetIcon = styled(FontAwesomeIcon)({
    cursor: "pointer"
});

const ZoomLevelText = styled(TextField)({
    fontWeight: 600,
    fontSize: fonts.size16
});

const TimeseriesChartWrapper = props => {
    const defaultTimeRange = new TimeRange(props.timeRange.map(timestamp => moment.unix(timestamp)));

    const [timeRange, setTimeRange] = useState(defaultTimeRange);
    const [trackerPosition, setTrackerPosition] = useState(null);
    const [zoomEnabled, setZoomEnabled] = useState(false);
    const [zoomLevel, setZoomLevel] = useState(0);

    // const dateFormatter = (datetime, duration) => {
    //     duration /= 1000;

    //     const minute = 60;
    //     const hour = minute * 60;
    //     const day = hour * 24;
    //     const year = day * 365;
    //     const month = year / 12;

    //     if (duration >= year) return moment(datetime).format("MM.YY");
    //     else if (duration >= month) return moment(datetime).format("DD.MM.YY");
    //     else if (duration >= day) return moment(datetime).format("DD.MM");
    //     else if (duration >= hour) return moment(datetime).format("HH:mm");
    //     else if (duration >= minute) return moment(datetime).format("HH:mm");
    //     else return moment(datetime).format("HH:mm:ss");
    // };

    const onZoom = newTimeRange => {
        if (newTimeRange.duration() !== timeRange.duration()) {
            if (newTimeRange > timeRange) setZoomLevel(zoomLevel + 1);
            else setZoomLevel(zoomLevel - 1);
        }

        setTimeRange(newTimeRange);
    };

    const onResetZoom = () => {
        setTimeRange(defaultTimeRange);
        setZoomLevel(0);
    };

    return (
        <Space direction="vertical" size={20} style={{ width: "100%" }}>
            <Header type="flex">
                <Col offset={6} span={12}>
                    <Row type="flex" justify="center">
                        <HeaderText value={zoomEnabled ? "t_click_on_chart_to_disable_zoom" : "t_click_on_chart_to_enable_zoom"} />
                    </Row>
                </Col>

                <Col span={6}>
                    {zoomEnabled && (
                        <Row type="flex" justify="end" align="middle">
                            <Space size={10}>
                                <ZoomResetIcon icon={["fas", "crosshairs"]} onClick={onResetZoom} />

                                <Space size={5}>
                                    <FontAwesomeIcon icon={["fas", "search"]} />

                                    <ZoomLevelText value={zoomLevel > 0 ? `+${zoomLevel}` : zoomLevel} />
                                </Space>
                            </Space>
                        </Row>
                    )}
                </Col>
            </Header>

            <Resizable>
                <ChartContainer
                    timeRange={timeRange}
                    onTrackerChanged={trackerPosition => setTrackerPosition(trackerPosition)}
                    onBackgroundClick={() => setZoomEnabled(!zoomEnabled)}
                    trackerPosition={trackerPosition}
                    onTimeRangeChanged={onZoom}
                    enablePanZoom={zoomEnabled}
                    format={datetime => moment(datetime).format("DD.MM.YY HH:mm:ss")}
                >
                    {React.Children.map(props.children, child => {
                        return React.cloneElement(child.type({ ...child.props, trackerPosition: trackerPosition }));
                    })}
                </ChartContainer>
            </Resizable>

            {props.renderExtraContent?.() ?? null}
        </Space>
    );
};

TimeseriesChartWrapper.propTypes = {
    timeRange: PropTypes.arrayOf(PropTypes.number).isRequired,
    title: PropTypes.string,
    extraContent: PropTypes.func
};

export default TimeseriesChartWrapper;
