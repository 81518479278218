import React from "react";
import { Switch, Route, Redirect } from "react-router";

import { clientUrls } from "misc/urls";

import EnergyMonitorComponent from "../components/EnergyMonitorComponent";
import EnergyMonitorFleetConfigurationComponent from "../components/EnergyMonitorFleetConfigurationComponent";

const EnergyMonitorContainer = () => {
    return (
        <Switch>
            <Route exact path={clientUrls.modules.energyMonitor.baseUrl()}>
                <EnergyMonitorComponent />
            </Route>

            <Route exact path={clientUrls.modules.energyMonitor.fleetConfiguration()}>
                <EnergyMonitorFleetConfigurationComponent />
            </Route>
            <Redirect to={clientUrls.modules.energyMonitor.baseUrl()} />
        </Switch>
    );
};

export default EnergyMonitorContainer;
