import { Spin, Space } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { TextField } from "misc/fields";
import { Transfer } from "misc/transfer";
import { useUsers } from "misc/api/keycloakApi";
import ConditionalWrapper from "misc/conditional/ConditionalWrapper";

const UserTransfer = ({ defaultValue, value, onChange = () => null, disabled, disableItem = () => false, isLoading, ...props }) => {
    const [t] = useTranslation();

    const { users, isLoading: isLoadingUsers } = useUsers({ suspense: true });

    const [targetKeys, setTargetKeys] = useState(value ?? defaultValue ?? []);
    const [leftSearch, setLeftSearch] = useState(null);

    return (
        <ConditionalWrapper condition={isLoading || disabled || isLoadingUsers} wrapper={children => <Spin indicator={null}>{children}</Spin>}>
            <Transfer
                listStyle={{
                    width: "100%"
                }}
                dataSource={users
                    .filter(user => targetKeys.includes(user.id) || user.email?.toLowerCase().includes(leftSearch?.toLowerCase()))
                    .map(user => ({ ...user, disabled: disableItem(user, targetKeys) }))}
                rowKey={item => item.id}
                render={item => item.email}
                targetKeys={targetKeys}
                titles={[t("t_available_users"), t("t_selected_users")]}
                onChange={targetKeys => {
                    setTargetKeys(targetKeys);
                    onChange(targetKeys);
                }}
                filterOption={(inputValue, option) => option.email?.toLowerCase().includes(inputValue.toLowerCase())}
                onSearch={(direction, value) => {
                    if (direction === "left") {
                        if (value.length >= 2) setLeftSearch(value);
                        else setLeftSearch(null);
                    }
                }}
                locale={{
                    itemUnit: t("t_item"),
                    itemsUnit: t("t_items"),
                    searchPlaceholder: t("t_search"),
                    notFoundContent: [
                        leftSearch ? (
                            <Space direction="vertical">
                                <FontAwesomeIcon icon={["fas", "user-slash"]} size="xl" />
                                <TextField value="t_user_transfer_empty_list" style={{ fontSize: 14 }} />
                            </Space>
                        ) : (
                            <Space direction="vertical">
                                <FontAwesomeIcon icon={["fas", "user-plus"]} size="xl" />
                                <TextField value="t_user_transfer_add_user_description" style={{ fontSize: 14 }} />
                            </Space>
                        )
                    ]
                }}
                disabled={disabled}
                {...props}
            />
        </ConditionalWrapper>
    );
};

UserTransfer.propTypes = {
    defaultValue: PropTypes.array,
    value: PropTypes.array,
    onChange: PropTypes.func,
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
    disbaleItem: PropTypes.func
};

export default UserTransfer;
