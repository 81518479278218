import L from "leaflet";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { Marker, Tooltip } from "react-leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconRetina from "leaflet/dist/images/marker-icon-2x.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

import { Map } from "misc/map";
import { TextField } from "misc/fields";

const DefaultIcon = L.icon({
    iconUrl: icon,
    iconRetinaUrl: iconRetina,
    shadowUrl: iconShadow,
    iconSize: [24, 36],
    iconAnchor: [12, 36],
    shadowAnchor: [12, 36]
});

L.Marker.prototype.options.icon = DefaultIcon;

// const L = require("leaflet");
const MarkerMap = ({ height = 450, markers = [], ...props }) => {
    const bounds = useMemo(() => (markers.length > 1 ? markers.map(marker => marker.position) : null), [markers]);
    const center = useMemo(() => (markers.length === 1 ? markers[0].position : null), [markers]);

    // useEffect(() => {
    //     delete L.Icon.Default.prototype._getIconUrl;
    //     L.Icon.Default.mergeOptions({
    //         iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    //         iconUrl: require("leaflet/dist/images/marker-icon.png"),
    //         shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    //     });
    // }, []);

    return (
        <Map height={height} bounds={bounds} center={center} {...props}>
            {markers.map((marker, index) => (
                <Marker key={index} position={marker.position}>
                    {marker.tooltip && (
                        <Tooltip direction="right" offset={[0, 0]} opacity={1} permanent>
                            <TextField value={marker.tooltip} />
                        </Tooltip>
                    )}
                </Marker>
            ))}
        </Map>
    );
};

MarkerMap.propTypes = {
    height: PropTypes.number,
    markers: PropTypes.arrayOf(PropTypes.shape({ position: PropTypes.array })).isRequired
};

export default MarkerMap;
