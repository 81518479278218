import moment from "moment";
import { keys, entries } from "lodash";
import { useTranslation } from "react-i18next";
import { Legend, styler } from "react-timeseries-charts";
import React, { useMemo, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Drawer, Space, Switch, Button, message, Form } from "antd";

import { Card } from "misc/card";
import { Error } from "misc/error";
import { Export } from "misc/exports";
import { useParams } from "misc/hooks";
import { TextField } from "misc/fields";
import { useRawData } from "misc/api/datalayer";
import { CheckAppPermissions } from "misc/authentication";
import { useRequestLogData } from "misc/api/telematicsservices";
import { TimeseriesChartWrapper, TimeseriesLineChartElement } from "misc/charts";

import SideHeader from "navigation/SideHeader";
import EngineeringSignalsHeader from "./EngineeringSignalsHeader";

import { TelemacticControlUnitTag, OnlineStatusTag } from "misc/tags";
import { useOnlineStatus, useTelematicControlUnit } from "misc/api/datalayer";

const COLORS = ["#5ad8a6", "#1890ff", "#50607C", "#f6bd16", "#e86452"];

const RequestBox = ({ vehicle, onChange = () => null }) => {
    const { onlineStatus, isFetching: isFetchingOnlineStatus, isError: isErrorOnlineStatus } = useOnlineStatus({ params: { vin: vehicle } });

    const {
        telematicControlUnit,
        isFetching: isFetchingTelematicControlUnit,
        isError: isErrorTelematicControlUnit
    } = useTelematicControlUnit({ params: { vin: vehicle } });

    const requestIsPossible = useMemo(() => {
        if (telematicControlUnit.value?.includes("tcu")) return onlineStatus.value > 0;
        if (telematicControlUnit.value?.includes("olu")) return onlineStatus.value > 0;
        return false;
    }, [telematicControlUnit, onlineStatus]);

    useEffect(() => {
        onChange({ telematicControlUnit: telematicControlUnit.value, onlineStatus: onlineStatus.value, requestIsPossible });
    }, [requestIsPossible]);

    if (isErrorOnlineStatus || isErrorTelematicControlUnit)
        return (
            <Card style={{ width: 350, height: 130 }}>
                <Error direction="horizontal" />
            </Card>
        );

    return (
        <Card style={{ width: 350, border: "solid 1px lightgrey" }} isLoading={isFetchingOnlineStatus || isFetchingTelematicControlUnit} height={160}>
            <Space direction="vertical" size={15}>
                <Space direction="horizontal" size={15}>
                    <TextField value={vehicle} style={{ fontSize: 16, fontWeight: 500 }} />
                    <Space size={-5}>
                        <OnlineStatusTag onlineStatus={onlineStatus.value} timestamp={onlineStatus.timestamp} />
                        <TelemacticControlUnitTag telematicControlUnit={telematicControlUnit.value} />
                    </Space>
                </Space>

                <Space size={2} direction="vertical">
                    <Space direction="horizontal" size={7}>
                        <FontAwesomeIcon icon={["fas", "database"]} style={{ width: 15 }} />
                        <TextField value="t_existing_data_available" />
                    </Space>

                    <Space direction="horizontal" size={7}>
                        <FontAwesomeIcon icon={requestIsPossible ? ["fas", "wifi"] : ["fas", "wifi-slash"]} style={{ width: 15 }} />
                        <TextField value={requestIsPossible ? "t_log_data_request_possible" : "t_log_data_request_not_possible"} />
                    </Space>
                </Space>
            </Space>
        </Card>
    );
};
// const changeFormatTimestamp = (signalsData)=>{
// signalsData.map( signalItem =>{
//     console.log(signalItem);
// })
// };

const EngineeringSignals = () => {
    const [t] = useTranslation();

    const { params, setParams } = useParams({
        options: [
            { name: "vehicles", defaultValue: [], persist: "site" },
            { name: "range", defaultValue: [moment().subtract(3, "days").formatDatetimeForApi(), moment().formatDatetimeForApi()], persist: "site" },
            { name: "rate", defaultValue: 1, persist: "site" },
            { name: "interval", defaultValue: "minute", persist: "site" },
            { name: "signals", defaultValue: [], persist: "site" }
        ]
    });

    const {
        rawData: signalsData,
        refetch,
        isLoading,
        isRefetching,
        isError
    } = useRawData({
        staleTime: 5 * 1000,
        params: {
            vins: params.vehicles,
            start: params.range[0],
            end: params.range[1],
            rate: params.rate,
            interval: params.interval,
            signals: params.signals
        }
    });

    const [form] = Form.useForm();

    const { requestLogData, isLoading: isRequestingLogData } = useRequestLogData();

    const [selectedVins, setSelectedVins] = useState(params.vehicles);
    const [showDatapoints, setShowDatapoints] = useState(false);
    const [drawerIsOpen, setDrawerIsOpen] = useState(false);

    const legend = useMemo(
        () =>
            params.vehicles.map(vin => ({
                key: vin,
                label: vin,
                disabled: !selectedVins.includes(vin)
            })),
        [params.vehicles, selectedVins]
    );

    const onChangeSelectedVins = selectedVin => {
        if (selectedVins.includes(selectedVin)) {
            if (selectedVins.length === 1) return;

            setSelectedVins(selectedVins.filter(vin => vin !== selectedVin));
        } else {
            setSelectedVins(selectedVins.concat([selectedVin]));
        }
    };

    const onRequestLogdata = () => {
        const telematicControlUnits = params.vehicles.map(vehicle => form.getFieldsValue()[vehicle].telematicControlUnit);

        requestLogData(params.range, telematicControlUnits, keys(signalsData.data), {
            onSuccess: () => {
                setDrawerIsOpen(false);
                message.success(t("t_signals_log_data_request_successfully_sent"));
            },
            onError: error => {
                setDrawerIsOpen(false);
                message.error(t(`t_http_error_${error.status}`));
            }
        });
    };

    return (
        <SideHeader>
            <Row gutter={[0, 10]}>
                <Col span={24}>
                    <Row justify="end">
                        <CheckAppPermissions allowed={["permission_module_engineering-extended"]}>
                            <Export
                                title="t_signals_export"
                                exportOptions={entries(signalsData.data).map(([key, value]) => {
                                    value = value.map(item => [moment(item[0]).format("DD.MM.YYYY hh:mm:ss"), ...item.slice(1)]);
                                    return {
                                        title: key,
                                        header: ["t_signals_export_timestamp", ...params.vehicles],
                                        data: value
                                    };
                                })}
                                disabled={isLoading || isRefetching}
                            />
                        </CheckAppPermissions>
                    </Row>
                </Col>

                <Col span={24}>
                    <EngineeringSignalsHeader
                        params={params}
                        setParams={params => {
                            setParams(params);
                            setSelectedVins(params.vehicles);
                        }}
                    />
                </Col>

                <Col span={24}>
                    <Card
                        title="t_signals"
                        height={500}
                        extra={
                            <Space>
                                <Button
                                    icon={<FontAwesomeIcon icon={["fal", "arrows-rotate"]} spin={isRefetching} />}
                                    onClick={() => !isRefetching && refetch()}
                                    disabled={isRefetching}
                                />
                                <Button icon={<FontAwesomeIcon icon={["far", "wifi"]} />} onClick={() => setDrawerIsOpen(true)} />
                            </Space>
                        }
                        isLoading={isLoading}
                        isError={isError}
                        style={{ paddingBottom: 40 }}
                        stretchHeight
                    >
                        <Row justify="end">
                            <Space size={7}>
                                <TextField value="t_signals_show_datapoints" />
                                <Switch onChange={setShowDatapoints} />
                            </Space>
                        </Row>

                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Space direction="vertical" size={15} style={{ width: "100%" }}>
                                    <Legend
                                        type="dot"
                                        categories={legend}
                                        style={styler(legend.map(({ key }, index) => ({ key, color: COLORS[index], width: 3 })))}
                                        onSelectionChange={onChangeSelectedVins}
                                    />

                                    <TimeseriesChartWrapper timeRange={[moment(params.range[0]).unix(), moment(params.range[1]).unix()]}>
                                        {signalsData.data
                                            ? Object.entries(signalsData.data).map(([signal, signalData]) => {
                                                  return (
                                                      <TimeseriesLineChartElement
                                                          key={signal}
                                                          title={signal}
                                                          data={signalData}
                                                          showDatapoints={showDatapoints}
                                                          selectedColumns={selectedVins}
                                                          // Sorting of vehicles and colors because the data is sorted by vin
                                                          columns={[...params.vehicles].sort((a, b) => a.localeCompare(b))}
                                                          colors={[...params.vehicles]
                                                              .map((vehicle, index) => ({ vehicle: vehicle, color: COLORS[index] }))
                                                              .sort((a, b) => a.vehicle.localeCompare(b.vehicle))
                                                              .map(item => item.color)}
                                                      />
                                                  );
                                              })
                                            : null}
                                    </TimeseriesChartWrapper>
                                </Space>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Drawer visible={drawerIsOpen} onClose={() => setDrawerIsOpen(false)} closable={false} width={400}>
                <Form form={form} onFinish={onRequestLogdata}>
                    {params.vehicles.map(vehicle => (
                        <Form.Item key={vehicle} name={vehicle}>
                            <RequestBox vehicle={vehicle} />
                        </Form.Item>
                    ))}

                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={isRequestingLogData}
                            disabled={
                                isRequestingLogData || params.vehicles.every(vehicle => form.getFieldsValue()[vehicle]?.requestIsPossible === false)
                            }
                        >
                            <TextField value="t_signals_request_data" inline />
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>
        </SideHeader>
    );
};

export default EngineeringSignals;
