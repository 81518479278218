import Keycloak from "keycloak-js";

const keycloakUrl =
    process.env.REACT_APP_STAGE === "production"
        ? "https://analysis.streetscooter-cloud-system.eu/auth/"
        : process.env.REACT_APP_STAGE === "staging"
        ? "https://auth-staging.streetscooter-cloud-system.eu/auth/"
        : "https://auth-staging.streetscooter-cloud-system.eu/auth/"; // "http://localhost:8080/auth/" ;

const keycloakData = {
    realm: "streetscooter",
    url: keycloakUrl,
    clientId: "analysisframework"
};

export const keycloak = Keycloak(keycloakData);
