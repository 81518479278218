import React from "react";

import { Transfer } from "misc/transfer";
import { useTranslation } from "react-i18next";
import { usePermissions } from "misc/api/keycloakApi";

const FeatureTransfer = props => {
    const [t] = useTranslation();
    const { permissions } = usePermissions({ suspense: true });

    return (
        <Transfer
            dataSource={permissions
                .filter(module => module.name.includes("permission_feature_"))
                .map(permission => ({
                    key: permission.name.replace("permission_feature_", ""),
                    title: t(permission.name.replace("permission_feature_", "t_feature_").replace("-", "_"))
                }))}
            filterOption={(inputValue, option) => {
                return option.title.toLowerCase().includes(inputValue.toLowerCase());
            }}
            {...props}
        />
    );
};

export default FeatureTransfer;
