import moment from "moment";

moment.fn.formatDatetimeForApi = function () {
    return this.utc().format("YYYY-MM-DDTHH:mm:ss.SSSSSS") + "Z";
};

moment.fn.formatDateForApi = function () {
    return this.format("YYYY-MM-DD");
};

export default moment;
