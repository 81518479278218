import React from "react";
import { Radio } from "antd";
import PropTypes from "prop-types";

import { TextField } from "misc/fields";
import { TooltipIcon } from "misc/icons";

const ModeSelector = ({ value, onChange = () => null }) => {
    return (
        <Radio.Group value={value} buttonStyle="solid" onChange={e => onChange(e.target.value)}>
            <Radio.Button value="default">
                <TooltipIcon icon={value === "default" ? ["fas", "user"] : ["fal", "user"]} tooltip={<TextField value="t_view_default" />} />
            </Radio.Button>
            <Radio.Button value="technical">
                <TooltipIcon
                    icon={value === "technical" ? ["fas", "user-gear"] : ["fal", "user-gear"]}
                    tooltip={<TextField value="t_view_technical" />}
                />
            </Radio.Button>
        </Radio.Group>
    );
};

ModeSelector.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func
};

export default ModeSelector;
