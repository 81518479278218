import React from "react";
import { PropTypes } from "prop-types";
import { Space } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import isNil from "lodash/isNil";
import styled from "styled-components";
import { useQuery } from "react-query";
import { request } from "misc/propTypes";
import { Widget } from "misc/widgets";
import { StatisticsBox } from "misc/boxes";
import { TextField } from "misc/fields";

import { stsColors, fonts } from "styles";

const ErrorIcon = styled(FontAwesomeIcon)({
    color: stsColors.blue2,
    opacity: 0.85
});

const StatisticsBoxWidget = ({ title, requests, value, height = 200, dependencies, ...props }) => {
    const { data, isFetching, error } = useQuery([requests], { placeholderData: { data: [], statistics: {} } });

    if (error)
        return (
            <Widget height={height}>
                <Space direction="vertical" size={8} align="center">
                    <ErrorIcon size="3x" icon="triangle-exclamation" />

                    <Space direction="vertical" size={2} align="center">
                        <TextField value={`t_http_error_${error}`} style={{ fontSize: fonts.size20 }} />
                        <TextField value="t_http_error_occurred" />
                    </Space>
                </Space>
            </Widget>
        );

    return (
        <Widget lastUpdate={data.statistics?.lastUpdate} height={height}>
            <StatisticsBox
                title={title}
                data={data.data || data.value}
                loading={isFetching}
                delta={
                    !isNil(data.statistics?.delta)
                        ? {
                              delta: data.statistics?.delta,
                              range: data.statistics?.range
                          }
                        : null
                }
                {...props}
            />
        </Widget>
    );
};

StatisticsBoxWidget.propTypes = {
    title: PropTypes.string,
    requests: request.isRequired,
    value: PropTypes.func.isRequired,
    height: PropTypes.number
};

export default StatisticsBoxWidget;
