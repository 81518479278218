import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import snakeCase from "lodash/snakeCase";
import PropTypes from "prop-types";

import { datalayerUrls } from "misc/urls";
import { PieChartWidget } from "misc/widgets/consumers";

import { stsColors } from "styles";

const TelematicTypeDistribution = ({ filter, height }) => {
    const [t] = useTranslation();

    const chartConfig = useMemo(
        () => ({
            angleField: "value",
            colorField: "key",
            color: ({ key }) => (key === "c2cCount" ? stsColors.yellow2 : key === "noneCount" ? stsColors.red2 : stsColors.green2),
            meta: {
                key: {
                    formatter: value => t(`t_${snakeCase(value).replace("c_2_c", "c2c")}`)
                }
            },
            label: {
                type: "outer",
                formatter: v =>
                    v.value > 0 ? `${t(`t_${snakeCase(v.key).replace("c_2_c", "c2c")}`)}: ${v.value} / ${(v.percent * 100).toFixed(1)} %` : null
            },
            statistic: {
                title: {
                    formatter: () => t("t_total")
                }
            }
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <PieChartWidget
            title="t_telematic_type_distribution"
            request={{
                url: datalayerUrls.fleet.widgets.pieChart(),
                params: { type: "telematicTypeDistribution" },
                filter: filter
            }}
            extractData={data => Object.entries(data).map(([key, value]) => ({ key: key, value: value }))}
            height={height}
            chartConfig={chartConfig}
        />
    );
};

TelematicTypeDistribution.propTypes = {
    filter: PropTypes.object,
    height: PropTypes.number
};

export default TelematicTypeDistribution;
