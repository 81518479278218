import { Modal, Space, Table, Tag } from "antd";

import { Empty } from "misc/empty";
import { TextField } from "misc/fields";
import { CheckedIcon } from "misc/icons/CheckedIcon";

import { cases } from "./cases";
import { stsColors } from "styles";

const CmCaseInfoModal = ({ case: caseId }) => {
    const [modal, contextHolder] = Modal.useModal();

    const currentCase = cases.find(item => item.case === caseId);

    if (!currentCase) return <Empty />;

    const columns = [
        {
            title: () => <TextField value="t_name" />,
            dataIndex: "title",
            width: 200
        },
        {
            title: () => <TextField value="t_description" />,
            dataIndex: "description",
            width: 300
        }
    ];

    const rows = [
        { dataKey: "case", title: <TextField value="t_cm_case" /> },
        {
            dataKey: "locationDescription",
            title: <TextField value="t_cm_wiki_vehicle_location_description" />,
            render: text => <TextField value={text} />
        },
        { dataKey: "locationCheck", title: <TextField value="t_cm_location_check" />, render: text => <CheckedIcon checked={text} /> },
        { dataKey: "chargingStationCheck", title: <TextField value="t_cm_charging_station" />, render: text => <CheckedIcon checked={text} /> },
        { dataKey: "connectivityCheck", title: <TextField value="t_cm_connectivity" />, render: text => <CheckedIcon checked={text} /> },
        {
            dataKey: "chargeStatus",
            title: <TextField value="t_cm_charge_status" />,
            render: text => (
                <Tag color={text ? stsColors.green1 : stsColors.red1} style={{ width: 60, textAlign: "center" }}>
                    <TextField value={text ? "t_cm_wiki_ok" : "t_cm_wiki_not_ok"} />
                </Tag>
            )
        },
        { dataKey: "locationPost", title: <TextField value="t_cm_wiki_location_post" />, render: text => <TextField value={text} /> },
        { dataKey: "locationProduction", title: <TextField value="t_cm_wiki_location_production" />, render: text => <TextField value={text} /> },
        { dataKey: "locationTestVehicle", title: <TextField value="t_cm_wiki_location_test_vehicle" />, render: text => <TextField value={text} /> },
        {
            dataKey: "dpdhlTask",
            title: (
                <Space size={0}>
                    <TextField value="t_cm_wiki_dpdhl" />
                    -
                    <TextField value="t_cm_wiki_task" />
                </Space>
            ),
            render: text => <TextField value={text} />
        },
        {
            dataKey: "dpdhlDescription",
            title: (
                <Space size={0}>
                    <TextField value="t_cm_wiki_dpdhl" />
                    -
                    <TextField value="t_cm_wiki_description" />
                </Space>
            ),
            render: text => <TextField value={text} />
        },
        {
            dataKey: "stsTask",
            title: (
                <Space size={0}>
                    <TextField value="t_cm_wiki_sts" />
                    -
                    <TextField value="t_cm_wiki_task" />
                </Space>
            ),
            render: text => <TextField value={text} />
        },
        {
            dataKey: "stsDescription",
            title: (
                <Space size={0}>
                    <TextField value="t_cm_wiki_sts" />
                    -
                    <TextField value="t_cm_wiki_description" />
                </Space>
            ),
            render: text => <TextField value={text} />
        }
    ];

    const dataSource = Object.entries(currentCase).map(([key, value]) => {
        const row = rows.find(element => element.dataKey === key);
        return {
            title: row?.title || "",
            description: row?.render ? row.render(value, currentCase) : value
        };
    });

    const info = () => {
        modal.info({
            title: (
                <Space>
                    <TextField value="t_cm_case" />
                    <TextField value={caseId} />
                </Space>
            ),
            content: <Table showHeader={false} size="small" rowKey="title" dataSource={dataSource} columns={columns} pagination={false} />,
            width: 600,
            maskClosable: true,
            closable: true,
            okButtonProps: { disabled: true, style: { display: "none" } }
        });
    };

    return (
        <div>
            {contextHolder}
            <span onClick={info} style={{ cursor: "pointer" }}>
                {caseId}
            </span>
        </div>
    );
};

export default CmCaseInfoModal;
