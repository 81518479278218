import { Widget } from "misc/widgets";
import CmCasesTable from "misc/widgets/consumers/tables/CmCasesTable";
import SideHeader from "navigation/SideHeader";

const CmWikiComponent = () => {
    return (
        <SideHeader>
            <Widget title="t_wiki">
                <CmCasesTable params={{ distanceAssignedLocation: 100, connectivity: 80, chargeStatus: 80 }} />
            </Widget>
        </SideHeader>
    );
};

export default CmWikiComponent;
