const baseUrl =
    process.env.REACT_APP_STAGE === "production"
        ? "https://analysis.streetscooter-cloud-system.eu/services/fleetconfiguration"
        : process.env.REACT_APP_STAGE === "staging"
        ? "https://api-staging.streetscooter-cloud-system.eu/services/fleetconfiguration"
        : "https://api-staging.streetscooter-cloud-system.eu/services/fleetconfiguration"; // "http://localhost:8000/services/fleetconfiguration"

export const fleetconfigurationUrls = {
    subfleets: {
        baseUrl: () => `${baseUrl}/subfleets/subfleets/`,
        activate: () => `${fleetconfigurationUrls.subfleets.baseUrl()}activate/`,
        share: () => `${fleetconfigurationUrls.subfleets.baseUrl()}share/`
    },
    sharedSubfleets: {
        baseUrl: () => `${baseUrl}/subfleets/shared/`,
        import: () => `${fleetconfigurationUrls.sharedSubfleets.baseUrl()}import-subfleet/`
    }
};
