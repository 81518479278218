import { isNull } from "lodash";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Row, Col, Space, Tooltip } from "antd";

import { Card } from "misc/card";
import { Table } from "misc/tables";
import { MarkerMap } from "misc/map";
import { Widget } from "misc/widgets";
import { TooltipIcon } from "misc/icons";
import { datalayerUrls } from "misc/urls";
import { VehiclesTableTemplate } from "misc/widgets/consumers";
import { TextField, NumberField, DatetimeField, TitleField } from "misc/fields";

import { fonts, stsColors } from "styles";
import { CheckAppPermissions } from "misc/authentication";

const Icon = styled(TooltipIcon)(({ style }) => ({
    color: stsColors.grey3,
    opacity: 0.85,
    fontSize: fonts.size18,
    ...style
}));

const FleetStatusTable = ({ filter, view, params, queryProps = {} }) => {
    const [t] = useTranslation();

    const expandedRowRender = rowData => {
        const expanderColumns = [
            {
                dataIndex: "name",
                title: <TextField value="t_name" />,
                render: name => <TextField value={name} />,
                width: "33%"
            },
            {
                dataIndex: "value",
                title: <TextField value="t_value" />,
                render: (text, record) =>
                    record.renderValue ? (
                        record.renderValue(text, record)
                    ) : (
                        <NumberField value={text} decimals={record.decimals} suffix={record.suffix} />
                    ),
                width: "33%"
            },
            {
                dataIndex: "timestamp",
                title: <TextField value="t_timestamp" />,
                render: (text, record) => (record.renderTimestamp ? record.renderTimestamp(text, record) : <DatetimeField value={text} />),
                width: "33%"
            }
        ];

        const tableData = [
            {
                name: "t_license_plate",
                value: rowData.licensePlate,
                timestamp: null,
                renderValue: text => <TextField value={text} />,
                renderTimestamp: () => ""
            },
            {
                name: "t_manufacturer",
                value: rowData.manufacturer,
                timestamp: null,
                renderValue: text => <TextField value={text} />,
                renderTimestamp: () => ""
            },
            {
                name: "t_model",
                value: rowData.model,
                timestamp: null,
                renderValue: text => <TextField value={text} />,
                renderTimestamp: () => ""
            },
            {
                name: "t_organisational_unit",
                value: rowData.organisationalUnit,
                timestamp: null,
                renderValue: text => <TextField value={text} />,
                renderTimestamp: () => ""
            },
            {
                name: "t_charging_station",
                value: rowData.chargingStation,
                timestamp: null,
                renderValue: text => <TextField value={text} />,
                renderTimestamp: () => ""
            },
            {
                name: "t_battery",
                value: rowData.battery,
                timestamp: null,
                suffix: "kWh",
                decimals: 0,
                renderTimestamp: () => ""
            },
            {
                name: "t_telematic_control_unit",
                value: rowData.telematicControlUnit,
                timestamp: null,
                renderValue: text => text,
                renderTimestamp: () => ""
            },
            {
                name: "t_last_sign_of_life",
                value: rowData.lastSignOfLifeSignal,
                timestamp: rowData.lastSignOfLifeTimestamp,
                renderValue: text => text
            },
            {
                name: "t_fleet_status_mileage",
                value: rowData.odometerMileage,
                timestamp: rowData.odometerTimestamp,
                suffix: "km"
            },
            {
                name: "t_fleet_status_soc",
                value: rowData.soc,
                timestamp: rowData.socTimestamp,
                suffix: "%"
            },
            {
                name: "t_fleet_status_chargeplug_status",
                value: rowData.chargeplugStatus,
                timestamp: rowData.chargeplugStatusTimestamp
            },
            { name: "t_fleet_status_charging_current", value: rowData.chargingCurrent, timestamp: rowData.chargingCurrentTimestamp },
            { name: "t_fleet_status_ignition_status", value: rowData.ignitionStatus, timestamp: rowData.ignitionStatusTimestamp },
            { name: "t_fleet_status_online_status", value: rowData.onlineStatus, timestamp: rowData.onlineStatusTimestamp }
        ];

        const basicInformation = (
            <Card height={650}>
                <TitleField value="t_fleet_status_details" level={5} />
                <Table
                    size="small"
                    dataSource={tableData}
                    columns={expanderColumns}
                    pagination={false}
                    style={{ paddingTop: 5 }}
                    showHeader={false}
                    enumerate={false}
                    stretchWidth={false}
                    bordered
                />
            </Card>
        );

        const gpsInformation = (
            <Card height={650} style={{ paddingTop: 40 }}>
                <MarkerMap
                    center={[rowData.gpsLatitude, rowData.gpsLongitude]}
                    markers={[{ position: [rowData.gpsLatitude, rowData.gpsLongitude] }]}
                />
            </Card>
        );

        return (
            <Row align="start" gutter={[10, 10]}>
                <CheckAppPermissions allowed={["permission_feature_gps-data"]} fallback={<Col span={24}>{basicInformation}</Col>}>
                    <Col xl={16} lg={24}>
                        {basicInformation}
                    </Col>
                    <Col xl={8} lg={24}>
                        {gpsInformation}
                    </Col>
                </CheckAppPermissions>
            </Row>
        );
    };

    const columns = useMemo(() => {
        const getIcon = (data, icon) => (isNull(data) ? null : icon);

        const getBatteryIcon = soc =>
            soc >= 95
                ? ["far", "battery-full"]
                : soc >= 75
                ? ["far", "battery-three-quarters"]
                : soc >= 50
                ? ["far", "battery-half"]
                : soc >= 25
                ? ["far", "battery-quarter"]
                : ["far", "battery-empty"];

        return [
            {
                key: "odometerMileage",
                dataIndex: "odometerMileage",
                title: () => <TextField value="t_fleet_status_mileage" />,
                render: (text, record) => <NumberField value={text ?? record.odometerMileage} decimals={0} suffix="km" />,
                sorter: true,
                width: 140
            },
            {
                key: "chargeplugStatus",
                dataIndex: "soc",
                title: () => <TextField value="t_fleet_status_soc" />,
                render: (text, record) => (
                    <Space>
                        {getIcon(record.soc, <Icon icon={getBatteryIcon(record.soc)} tooltip={record.soc + "%"} />)}
                        {record.chargeplugStatus === 1 && (
                            <Tooltip title={t("t_fleet_status_chargeplug_status_plugged")}>
                                <Icon icon={["far", "plug-circle-bolt"]} />
                            </Tooltip>
                        )}
                    </Space>
                ),

                sorter: true,
                filters: [
                    { text: <TextField value="t_fleet_status_chargeplug_status_plugged" inline />, value: 1 },
                    { text: <TextField value="t_fleet_status_chargeplug_status_unplugged" inline />, value: 0 }
                ],
                sortFields: ["soc", "socTimestamp"],
                width: 150
            },
            {
                key: "ignitionStatus",
                dataIndex: "ignitionStatus",
                title: () => <TextField value="t_fleet_status_ignition_status" />,
                render: (text, record) =>
                    record.ignitionStatus === 1 && (
                        <Tooltip title={t("t_fleet_status_ignition_status_on")}>
                            <Icon icon={["far", "power-off"]} />
                        </Tooltip>
                    ),
                sorter: true,
                filters: [
                    { text: <TextField value="t_fleet_status_ignition_status_on" inline />, value: 1 },
                    { text: <TextField value="t_fleet_status_ignition_status_off" inline />, value: 0 }
                ],
                sortFields: ["ignitionStatus", "ignitionStatusTimestamp"],
                width: 150
            },
            // {
            //     key: "defectTickets",
            //     dataIndex: "openHotlineTickets",
            //     title: () => <TextField value="t_defect_tickets" />,
            //     render: text => text,
            //     sorter: true,
            //     width: 130
            // },
            {
                key: "active",
                dataIndex: "active",
                title: () => (
                    <Space>
                        <TextField value="t_fleet_status_vehicle_activity" />
                        <TooltipIcon
                            tooltip="t_fleet_status_vehicle_activity_description"
                            color={stsColors.blue2}
                            icon={["fas", "circle-info"]}
                            size="l"
                            style={{ margin: "auto", color: stsColors.blue2, opacity: 1 }}
                        />
                    </Space>
                ),
                render: (text, record) =>
                    record.active && (
                        <Tooltip title={t("t_fleet_status_vehicle_activity_active")}>
                            <Icon icon={["far", "gauge-high"]} />
                        </Tooltip>
                    ),
                sorter: true,
                sortFields: ["active"],
                filters: [
                    { text: <TextField value="t_fleet_status_vehicle_activity_active" inline />, value: true },
                    { text: <TextField value="t_fleet_status_vehicle_activity_inactive" inline />, value: false }
                ],
                width: 150
            },
            {
                key: "onlineStatus",
                dataIndex: "onlineStatus",
                title: () => <TextField value="t_fleet_status_online_status" />,
                render: (text, record) =>
                    record.onlineStatus === 1 && (
                        <Tooltip title={t(`t_fleet_status_online_status_online`)}>
                            <Icon icon={["far", "wifi"]} />
                        </Tooltip>
                    ),
                sorter: true,
                sortFields: ["onlineStatus", "onlineStatusTimestamp"],
                filters: [
                    { text: <TextField value="t_online" inline />, value: 1 },
                    { text: <TextField value="t_offline" inline />, value: 0 }
                ],
                width: 150
            }
        ];
    }, []);

    const request = useMemo(
        () => ({ method: "GET", url: datalayerUrls.fleet.widgets.list(), params: { type: "vehicleStatus" }, filter: filter }),
        [filter]
    );

    return (
        <Widget title="t_fleet_status" height={700} stretchHeight>
            <VehiclesTableTemplate
                queryKey="fleetStatus"
                request={request}
                view={view}
                extractData="data"
                extractCount={data => data.statistics.count}
                rowKey="vin"
                columns={columns}
                expandedRowRender={expandedRowRender}
                scroll={{ x: "100%" }}
                queryProps={{ refetchInterval: 30 * 60 * 1000, ...queryProps }}
                enableManuallyRefresh
            />
        </Widget>
    );
};

FleetStatusTable.propTypes = {
    filter: PropTypes.object,
    view: PropTypes.object,
    params: PropTypes.object,
    queryProps: PropTypes.object
};

export default FleetStatusTable;
