const baseUrl =
    process.env.REACT_APP_STAGE === "production"
        ? "https://analysis.streetscooter-cloud-system.eu/services/keycloakapi"
        : process.env.REACT_APP_STAGE === "staging"
        ? "https://api-staging.streetscooter-cloud-system.eu/services/keycloakapi"
        : "https://api-staging.streetscooter-cloud-system.eu/services/keycloakapi"; // "http://localhost:8005/services/keycloakapi";

export const keycloakapiUrls = {
    users: () => `${baseUrl}/users/`,
    roles: () => `${baseUrl}/roles/`,
    permissions: () => `${baseUrl}/permissions/`
};
