import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { clientUrls } from "misc/urls";

import CmContainer from "./cm/containers/CmContainer";
import VlvContainer from "./vlv/containers/VlvContainer";
import CddaContainer from "./cdda/containers/CddaContainer";
import EngineeringContainer from "./engineering/containers/EngineeringContainer";
import DiagnosticsContainer from "./diagnostics/containers/DiagnosticsContainer";
import EnergyMonitorContainer from "./energy_monitor/containers/EnergyMonitorContainer";
import DefectTicketsContainer from "./defect_tickets/containers/DefectTicketsContainer";

const ModulesContainer = () => {
    return (
        <Switch>
            <Route path={clientUrls.modules.vlv.baseUrl()} component={VlvContainer} />
            <Route path={clientUrls.modules.cdda.baseUrl()} component={CddaContainer} />
            <Route path={clientUrls.modules.defectTickets.baseUrl()} component={DefectTicketsContainer} />
            <Route path={clientUrls.modules.diagnostics.baseUrl()} component={DiagnosticsContainer} />
            <Route path={clientUrls.modules.cm.baseUrl()} component={CmContainer} />
            <Route path={clientUrls.modules.energyMonitor.baseUrl()} component={EnergyMonitorContainer} />
            <Route path={clientUrls.modules.engineering.baseUrl()} component={EngineeringContainer} />

            <Redirect to={clientUrls.modules.vlv.baseUrl()} />
        </Switch>
    );
};

export default ModulesContainer;
