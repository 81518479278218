import React from "react";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";

const TitleField = ({ value, ...props }) => {
    const [t] = useTranslation();

    return <Typography.Title {...props}>{t(value)}</Typography.Title>;
};

export default TitleField;
