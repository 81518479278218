import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-query";
import _ from "lodash";

import { Card } from "misc/card";
import { request } from "misc/propTypes";
import { MarkerMap } from "misc/map";

const PositionMapWidget = ({ title, height = 600, request, extractData, extractLastUpdate, zoom, isStatic, queryProps }) => {
    const { data, isLoading, isRefetching, isError } = useQuery([request], queryProps);

    const extractedData = useMemo(() => (data ? (_.isFunction(extractData) ? extractData(data) : data[extractData]) : null), [data, extractData]);

    const extractedLastUpdate = useMemo(
        () => (data && extractLastUpdate ? (_.isFunction(extractLastUpdate) ? extractLastUpdate(data) : data[extractLastUpdate]) : null),
        [data, extractLastUpdate]
    );

    return (
        <Card title={title} height={height} isUpdating={isRefetching} isError={isError} lastUpdate={extractedLastUpdate}>
            <MarkerMap
                height={0.75 * height}
                center={extractedData}
                markers={[{ position: extractedData }]}
                zoom={zoom}
                isStatic={isStatic}
                isLoading={isLoading}
            />
        </Card>
    );
};

PositionMapWidget.propTypes = {
    title: PropTypes.string,
    height: PropTypes.number,
    request: request.isRequired,
    extractData: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
    extractLastUpdate: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    zoom: PropTypes.number,
    isStatic: PropTypes.bool,
    queryProps: PropTypes.object
};

export default PositionMapWidget;
