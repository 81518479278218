import React from "react";

import { TextField } from "misc/fields";

const BooleanField = ({ value, renderTrue = () => "t_yes", renderFalse = () => "t_no", ...props }) => {
    const renderValue = () => {
        if (value === true) return renderTrue();

        if (value === false) return renderFalse();

        return value;
    };

    return <TextField value={renderValue()} {...props} />;
};

export default BooleanField;
