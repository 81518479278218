export const cases = [
    {
        case: "1.1.1",
        locationDescription: "t_cm_wiki_vehicle_close_to_llw",
        locationCheck: true,
        chargingStationCheck: true,
        connectivityCheck: true,
        chargeStatus: true,
        locationPost: "t_cm_wiki_charge_preset_depends_on_database_entry",
        dpdhlTask: "t_no",
        stsTask: "t_no"
    },
    {
        case: "1.1.2",
        locationDescription: "t_cm_wiki_vehicle_close_to_llw",
        locationCheck: true,
        chargingStationCheck: true,
        connectivityCheck: true,
        chargeStatus: false,
        dpdhlTask: "t_no",
        stsTask: "t_yes",
        stsDescription: "t_cm_wiki_analysis"
    },
    {
        case: "1.2.1",
        locationDescription: "t_cm_wiki_vehicle_far_away_from_llw_pampa",
        locationCheck: false,
        chargingStationCheck: null,
        connectivityCheck: true,
        chargeStatus: true,
        locationPost: "t_cm_wiki_charging_capacity_max",
        dpdhlTask: "t_yes",
        dpdhlDescription: "t_cm_wiki_return_to_llw_location",
        stsTask: "t_no"
    },
    {
        case: "1.2.2",
        locationDescription: "t_cm_wiki_vehicle_far_away_from_llw_pampa",
        locationCheck: false,
        chargingStationCheck: null,
        connectivityCheck: true,
        chargeStatus: false,
        dpdhlTask: "t_yes",
        dpdhlDescription: "t_cm_wiki_return_to_llw_location",
        stsTask: "t_yes",
        stsDescription: "t_cm_wiki_analysis"
    },
    {
        case: "1.3.1",
        locationDescription: "t_cm_wiki_vehicle_far_away_from_llw_post",
        locationCheck: false,
        chargingStationCheck: null,
        connectivityCheck: true,
        chargeStatus: true,
        locationPost: "t_cm_wiki_charging_capacity_max",
        dpdhlTask: "t_yes",
        dpdhlDescription: "t_cm_wiki_return_to_llw_location",
        stsTask: "t_no"
    },
    {
        case: "1.3.2",
        locationDescription: "t_cm_wiki_vehicle_far_away_from_llw_post",
        locationCheck: false,
        chargingStationCheck: null,
        connectivityCheck: true,
        chargeStatus: false,
        dpdhlTask: "t_yes",
        dpdhlDescription: "t_cm_wiki_return_to_llw_location",
        stsTask: "t_yes",
        stsDescription: "t_cm_wiki_analysis"
    },
    {
        case: "1.4.1",
        locationDescription: "t_cm_wiki_vehicle_close_to_llw",
        locationCheck: true,
        chargingStationCheck: false,
        connectivityCheck: true,
        chargeStatus: true,
        locationPost: "500W",
        dpdhlTask: "t_yes",
        dpdhlDescription: "t_cm_wiki_assign_to_charging_station",
        stsTask: "t_no"
    },
    {
        case: "1.4.2",
        locationDescription: "t_cm_wiki_vehicle_close_to_llw",
        locationCheck: true,
        chargingStationCheck: false,
        connectivityCheck: true,
        chargeStatus: false,
        dpdhlTask: "t_yes",
        dpdhlDescription: "t_cm_wiki_assign_to_charging_station",
        stsTask: "t_yes",
        stsDescription: "t_cm_wiki_analysis"
    },
    {
        case: "1.5",
        chargingStationCheck: true,
        connectivityCheck: false,
        chargeStatus: false,
        locationPost: "t_cm_wiki_charging_capacity_fallback",
        dpdhlTask: "t_no",
        stsTask: "t_yes",
        stsDescription: "t_cm_wiki_analysis"
    },
    { case: "1.6" },
    {
        case: "2.1.1",
        locationDescription: "t_cm_wiki_vehicle_close_to_llw",
        locationCheck: true,
        chargingStationCheck: false,
        connectivityCheck: true,
        chargeStatus: true,
        locationProduction: "t_cm_wiki_charging_capacity_max",
        dpdhlTask: "t_no",
        stsTask: "t_no"
    },
    {
        case: "2.1.2",
        locationDescription: "t_cm_wiki_vehicle_close_to_llw",
        locationCheck: true,
        chargingStationCheck: false,
        connectivityCheck: true,
        chargeStatus: false,
        dpdhlTask: "t_no",
        stsTask: "t_yes",
        stsDescription: "t_cm_wiki_analysis"
    },
    {
        case: "2.2.1",
        locationDescription: "t_cm_wiki_vehicle_far_away_from_llw_pampa",
        locationCheck: false,
        chargingStationCheck: false,
        connectivityCheck: true,
        chargeStatus: true,
        locationProduction: "500W",
        dpdhlTask: "t_no",
        stsTask: "t_yes",
        stsDescription: "t_cm_wiki_return_to_production_location"
    },
    {
        case: "2.2.2",
        locationDescription: "t_cm_wiki_vehicle_far_away_from_llw_pampa",
        locationCheck: false,
        chargingStationCheck: false,
        connectivityCheck: true,
        chargeStatus: false,
        dpdhlTask: "t_no",
        stsTask: "t_yes",
        stsDescription: "t_cm_wiki_return_to_production_location"
    },
    {
        case: "2.3.1",
        locationDescription: "t_cm_wiki_vehicle_far_away_from_llw_post",
        chargingStationCheck: false,
        connectivityCheck: true,
        chargeStatus: true,
        locationProduction: "500W",
        dpdhlTask: "t_yes",
        dpdhlDescription: "t_cm_wiki_assign_charging_station_in_llw",
        stsTask: "t_yes",
        stsDescription: "t_cm_wiki_return_to_production_location"
    },
    {
        case: "2.3.2",
        locationDescription: "t_cm_wiki_vehicle_far_away_from_llw_post",
        chargingStationCheck: false,
        connectivityCheck: true,
        chargeStatus: false,
        dpdhlTask: "t_yes",
        dpdhlDescription: "t_cm_wiki_assign_charging_station_in_llw",
        stsTask: "t_yes",
        stsDescription: "t_cm_wiki_return_to_production_location_and_analyse"
    },
    {
        case: "2.4",
        chargingStationCheck: false,
        connectivityCheck: false,
        chargeStatus: false,
        locationProduction: "t_cm_wiki_charging_capacity_fallback",
        dpdhlTask: "t_no",
        stsTask: "t_yes",
        stsDescription: "t_cm_wiki_analysis"
    },
    { case: "2.5" },
    {
        case: "3.1",
        locationDescription: "t_cm_wiki_no_location_check",
        locationCheck: false,
        chargingStationCheck: false,
        connectivityCheck: true,
        chargeStatus: true,
        locationTestVehicle: "t_cm_wiki_charging_capacity_max",
        dpdhlTask: "t_yes",
        dpdhlDescription: "t_cm_wiki_assign_charging_station_in_llw",
        stsTask: "t_yes",
        stsDescription: "t_cm_wiki_return_to_avantis"
    },
    {
        case: "3.2",
        locationDescription: "t_cm_wiki_no_location_check",
        locationCheck: false,
        chargingStationCheck: false,
        connectivityCheck: true,
        chargeStatus: false,
        locationTestVehicle: "t_cm_wiki_charging_capacity_max",
        dpdhlTask: "t_yes",
        dpdhlDescription: "t_cm_wiki_assign_charging_station_in_llw",
        stsTask: "t_yes",
        stsDescription: "t_cm_wiki_return_to_avantis_and_analyse"
    },
    {
        case: "3.3",
        chargingStationCheck: false,
        connectivityCheck: false,
        chargeStatus: false,
        locationTestVehicle: "t_cm_wiki_charging_capacity_fallback",
        dpdhlTask: "t_no",
        stsTask: "t_yes",
        stsDescription: "t_cm_wiki_analysis"
    },
    { case: "3.4" },
    { case: "4.1" },
    { case: "4.2" }
];
