import React from "react";

import FleetConfiguration from "modules/FleetConfiguration";

const FLEET_PARAMETERS = [];

const CddaFleetConfigurationComponent = () => {
    return <FleetConfiguration parameters={FLEET_PARAMETERS} />;
};

export default CddaFleetConfigurationComponent;
