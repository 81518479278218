export const theme = {
    token: {
        colorPrimary: "#155292",
        colorInfo: "#155292",
        colorTextBase: "#393939",
        colorLink: "#393939",
        colorLinkHover: "#393939"
    },
    components: {
        Transfer: {
            listHeight: "350px !important",
            listWidth: "calc(50% - 20px) !important"
        }
    }
};
