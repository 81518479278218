import React from "react";
import { Link } from "react-router-dom";
import { Layout, Row, Space } from "antd";
import moment from "moment";

import { TextField } from "misc/fields";

import { stsColors } from "styles";

import { clientUrls } from "misc/urls/clientUrls";

const FooterItem = props => <TextField {...props} style={{ color: stsColors.grey3 }} />;

const Footer = () => (
    <Layout.Footer style={{ padding: "20px 5px" }}>
        <Row justify="space-between">
            <FooterItem prefix={moment().format("YYYY")} value="&copy; StreetScooter GmbH" />

            <Space direction="horizontal" size={20}>
                <Link target="_blank" to={clientUrls.legal.imprint()}>
                    <FooterItem value="t_imprint" />
                </Link>

                <Link target="_blank" to={clientUrls.legal.privacyPolicy()}>
                    <FooterItem value="t_privacy_policy" />
                </Link>

                <Link target="_blank" to={clientUrls.legal.cookies()}>
                    <FooterItem value="t_cookies" />
                </Link>
            </Space>
        </Row>
    </Layout.Footer>
);
export default Footer;
