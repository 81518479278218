import { useMemo } from "react";
import { useLocation, useHistory } from "react-router";

import { buildUrl, getQueryParameters } from "misc/helpfunctions";

const useRedirectEffect = params => {
    const history = useHistory();
    const location = useLocation();

    const url = useMemo(
        () => buildUrl({ path: location.pathname, queryParameters: { ...getQueryParameters(location), ...params } }),
        [location, params]
    );

    if (url !== location.pathname + location.search) {
        history.push(url);
        return true;
    }

    return false;
};

export default useRedirectEffect;
