import React, { useMemo } from "react";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import PropTypes from "prop-types";
import _ from "lodash";

import { Widget } from "misc/widgets";
import { request } from "misc/propTypes";
import { ColumnChart } from "misc/charts";
import { getQueryParameter } from "misc/helpfunctions";

const BarChartWidget = ({ title, request, extractData, extractLastUpdate = () => null, height = 500, chartConfig, queryProps, ...props }) => {
    const { data = [], isLoading, isRefetching, isError } = useQuery([request], queryProps);

    const { t } = useTranslation();

    const location = useLocation();

    const view = useMemo(() => getQueryParameter(location, "view", "day"), [location]);

    const extractedData = useMemo(() => (data ? (_.isFunction(extractData) ? extractData(data) : data[extractData]) : null), [data, extractData]);

    const extractedLastUpdate = useMemo(
        () => (data ? (_.isFunction(extractLastUpdate) ? extractLastUpdate(data) : data[extractLastUpdate]) : null),
        [data, extractLastUpdate]
    );

    return (
        <Widget title={title} height={height} isUpdating={isRefetching} isError={isError} lastUpdate={extractedLastUpdate} {...props}>
            <ColumnChart
                chartConfig={{
                    data: extractedData,
                    height: 0.82 * (height - 100),
                    xField: view === "day" ? "date" : "week",
                    xAxis: {
                        title: {
                            text: t(`t_${view}_view`)
                        }
                    },
                    onReady: () => {}, // Fixed legend loading issue
                    loading: isLoading,
                    ...chartConfig
                }}
            />
        </Widget>
    );
};

BarChartWidget.propTypes = {
    title: PropTypes.string,
    request: request.isRequired,
    extractData: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    extractLastUpdate: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    height: PropTypes.number,
    chartConfig: PropTypes.object,
    queryProps: PropTypes.object
};

export default BarChartWidget;
