import React from "react";
import PropTypes from "prop-types";
import { round, isNumber } from "lodash";
import { Card as AntdCard, Skeleton, Tooltip, Space } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import moment from "moment";

import { Error } from "misc/error";
import { TextField, DatetimeField } from "misc/fields";

import { fonts, stsColors } from "styles";
import { Empty } from "misc/empty";

const StyledCard = styled(AntdCard)`
    background: white;
    border: 1px solid ${stsColors.grey1};
    border-radius: 8px;
`;

// ${({ bordered = false }) => {
//     if (bordered) return `border: 1px solid ${stsColors.white2};`;
//     else return "box-shadow: 0 0 35px 0 rgba(154,161,171,.15);";
// }}

const Title = styled(TextField)({
    fontSize: fonts.size20
});

const Footer = styled(Space)({
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    fontSize: fonts.size12,
    height: "10px",
    color: stsColors.grey2,
    marginRight: "20px"
});

const BaseCard = ({ title, centered, height, stretchHeight, extra, actions, style, children, ...props }) => (
    <StyledCard
        title={title ? <Title value={title} /> : null}
        bordered={false}
        {...props}
        style={{ ...style, minHeight: stretchHeight ? height : null, height: stretchHeight ? null : height }}
        extra={extra ?? (title && <div style={{ height: 32 }} />)}
        actions={actions}
    >
        {/* Align Error in the middle of the card */}
        {centered ? <div style={{ paddingTop: (height - 450) / 2 }}>{children}</div> : children}
    </StyledCard>
);

const Card = ({ title, height, error, isLoading, isUpdating, isError, lastUpdate, stretchHeight = false, ...props }) => {
    if (isLoading) {
        return (
            <BaseCard title={title} {...props}>
                <Skeleton round active paragraph={{ rows: round(height / 50 ?? 1) }} style={{ height: "100%" }} />
            </BaseCard>
        );
    }

    if (isError || error) {
        if (error?.status === 403)
            return (
                <BaseCard title={title} centered height={height} stretchHeight={stretchHeight} {...props}>
                    <Empty />
                </BaseCard>
            );

        return (
            <BaseCard title={title} height={height} stretchHeight={stretchHeight} {...props}>
                <Error />
            </BaseCard>
        );
    }

    return (
        <BaseCard
            title={title}
            height={height}
            stretchHeight={stretchHeight}
            actions={[
                isUpdating ? (
                    <Footer size={5}>
                        <FontAwesomeIcon icon={["far", "arrows-rotate"]} spin />
                        <TextField value="t_is_updating" />
                    </Footer>
                ) : lastUpdate ? (
                    <Tooltip title={<DatetimeField value={lastUpdate} />} placement="topRight">
                        <Footer size={5}>
                            <FontAwesomeIcon icon={["fas", "clock"]} />
                            {isNumber(lastUpdate) ? moment.unix(lastUpdate).fromNow() : moment(lastUpdate).fromNow()}
                        </Footer>
                    </Tooltip>
                ) : null
            ]}
            {...props}
        >
            {props.children}
        </BaseCard>
    );
};

Card.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    height: PropTypes.number,
    isLoading: PropTypes.bool,
    isUpdating: PropTypes.bool,
    isError: PropTypes.bool,
    lastUpdate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    stretchHeight: PropTypes.bool
};

export default Card;
